<template>
  <div
    class="modal-action-wrapper"
    :class="{ 'is-right': isRight }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: "ModalActionWrapper",

  props: {
    isRight: { type: Boolean, required: false, default: false }
  }
};
</script>

<style scoped lang="scss">
.modal-action-wrapper {
  padding: 12px;

  @media screen and (max-width: $screen-sm-max) {
    flex: 1;
    padding: 8px;

    &.is-right {
      min-width: 100%;
      text-align: center;
    }

    & .btn {
      min-width: 100%;
    }
  }

  @media screen and (min-width: $screen-md-min) {
    &.is-right {
      margin-left: auto;
    }
  }
}
</style>
