<template>
  <ModalWrapper size="md">
    <template slot="title">
      Distribute funds
    </template>

    <div class="distribute-funds-modal__row">
      <div class="distribute-funds-modal__row-item">
        <FormSelect
          label="From account"
          :value="accountFrom"
          :options="[
            'Savings account 1',
            'Savings account 2',
            'Savings account 3'
          ]"
          @change="accountFrom = $event"
        />
      </div>

      <div class="distribute-funds-modal__row-item">
        <FormSelect
          label="To account"
          :value="accountTo"
          :options="['Account 1', 'Account 2', 'Account 3']"
          @change="accountTo = $event"
        />
      </div>
    </div>

    <FormItemGroup
      label="Goal amount (sum)"
      class="mt-lg-40 mt-sm-32"
    >
      <FormInput
        type="number"
        :has-error="goalError"
        :value="goal"
        @input="goal = $event"
      />

      <FormSelect
        el-width="120px"
        :value="currency"
        :options="[
          { value: 'usd', title: 'USD', flag: 'us' },
          { value: 'eur', title: 'EUR', flag: 'eu' },
          { value: 'gbp', title: 'GBP', flag: 'gb' }
        ]"
        @change="currency = $event"
      />
    </FormItemGroup>

    <ButtonLink
      :label="`Transfer all: ${availableFunds}`"
      btn-style="dark"
      btn-size="sm"
      class="mt-12"
      :click-handler="handleTransferAll"
    />

    <template slot="footer">
      <ModalActionWrapper>
        <Button
          label="Transfer"
          btn-style="primary"
          :click-handler="handleSubmit"
        />
      </ModalActionWrapper>
    </template>
  </ModalWrapper>
</template>

<script>
import { mapActions } from "vuex";

import ModalWrapper from "@/components/Modal/ModalWrapper";
import ModalActionWrapper from "@/components/Modal/ModalActionWrapper";
import Button from "@/components/Button";
import FormInput from "@/components/Form/FormInput";
import FormSelect from "@/components/Form/FormSelect";
import FormItemGroup from "@/components/Form/FormItemGroup";
import ButtonLink from "@/components/ButtonLink";

export default {
  name: "NewCardModal",

  components: {
    ModalWrapper,
    ModalActionWrapper,
    Button,
    FormInput,
    FormSelect,
    FormItemGroup,
    ButtonLink
  },

  data() {
    return {
      accountFrom: "Savings account 1",
      accountTo: "Account 2",
      goal: 1000.0,
      goalError: "",
      currency: "gbp",
      availableFunds: "12,564.92 (GBP)",
      availableFundsNumber: 12564.92
    };
  },

  methods: {
    ...mapActions(["showModal"]),

    handleSubmit() {
      this.showModal({
        component: "DistributeFundsSuccessModal"
      });
    },

    handleTransferAll() {
      this.goal = this.availableFundsNumber;
    }
  }
};
</script>

<style scoped lang="scss">
.distribute-funds-modal {
  &__row {
    display: flex;
    flex-wrap: wrap;
    margin: 40px -12px;
  }

  &__row-item {
    width: 50%;
    padding: 0 12px;
  }
}
</style>
