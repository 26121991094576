<template>
  <div class="payout-modal-transaction-group">
    <div class="payout-modal-transaction-group-header">
      <div class="payout-modal-transaction-group-header__currency">
        {{ data.currency }}
      </div>
      <div class="payout-modal-transaction-group-header__info">
        <div class="payout-modal-transaction-group-header__info-item">
          <span class="text-grey">Total payments: </span>
          <span class="text-sm-sb">{{ data.total }}</span>
        </div>

        <div class="payout-modal-transaction-group-header__info-item">
          <span class="text-grey">Total amount: </span>
          <span class="text-sm-sb">{{ data.amount | moneyformat }}</span>
        </div>
      </div>
    </div>

    <div
      class="payout-modal-transaction-group-table"
      :class="{ 'is-collapsed': isCollapsed }"
    >
      <div class="payout-modal-transaction-group-table__header">
        <div
          class="payout-modal-transaction-group-table__header-cell payout-modal-transaction-group-table__header-cell--conterparty"
        >
          Counterparty
        </div>

        <div
          class="payout-modal-transaction-group-table__header-cell payout-modal-transaction-group-table__header-cell--details"
        >
          Details
        </div>

        <div
          class="payout-modal-transaction-group-table__header-cell payout-modal-transaction-group-table__header-cell--swift"
        >
          SWIFT
        </div>

        <div
          class="payout-modal-transaction-group-table__header-cell payout-modal-transaction-group-table__header-cell--amount"
        >
          Amount
        </div>
      </div>

      <PaymentModalTransactionGroupItem
        v-for="(item, j) in data.payments.slice(0, 3)"
        :key="j"
        :data="item"
      />

      <transition-group name="payment-list">
        <template v-if="!isCollapsed">
          <PaymentModalTransactionGroupItem
            v-for="(item, k) in data.payments.slice(3)"
            :key="k"
            :data="item"
          />
        </template>
      </transition-group>

      <button
        v-if="data.payments.length > 3"
        class="payout-modal-transaction-group-table__more"
        @click="isCollapsed = !isCollapsed"
      >
        <span
          v-if="isCollapsed"
          class="payout-modal-transaction-group-table__more-label"
        >
          View all payments ({{ data.payments.length }})
        </span>
        <span
          v-if="!isCollapsed"
          class="payout-modal-transaction-group-table__more-label"
        >
          Show less
        </span>

        <span
          v-if="data.rejected"
          class="payout-modal-transaction-group-table__more-error"
        >
          {{ data.rejected }} payments not accepted
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import PaymentModalTransactionGroupItem from "@/components/Modal/PaymentModalTransactionGroupItem";

export default {
  name: "PayoutModalTransactionGroup",

  components: {
    PaymentModalTransactionGroupItem
  },

  props: {
    data: {
      type: Object,
      required: false,
      default: null
    }
  },

  data() {
    return {
      isCollapsed: true
    };
  }
};
</script>

<style scoped lang="scss">
.payout-modal-transaction-group {
  border-radius: 8px;
  border: 1px solid $grey-light-01;

  & + & {
    margin-top: 24px;
  }

  @media screen and (min-width: $screen-md-min) {
    & + & {
      margin-top: 32px;
    }
  }
}

.payout-modal-transaction-group-header {
  display: flex;
  border-radius: 8px 8px 0 0;
  background-color: rgba($accent-normal, 0.05);
  padding: 16px;
  font-size: 14px;
  line-height: 18px;

  &__currency {
    font-family: $font-semibold;
  }

  &__info {
    text-align: right;
    margin-left: auto;
  }

  &__info-item + &__info-item {
    margin-top: 8px;
  }

  @media screen and (min-width: $screen-md-min) {
    padding: 16px 28px;

    &__info {
      display: flex;
    }

    &__info-item + &__info-item {
      margin-top: 0;
      margin-left: 12px;
      padding-left: 12px;
      border-left: 1px solid $grey-light-01;
    }
  }
}

.payout-modal-transaction-group-table {
  &__header {
    display: flex;
    background-color: $grey-light-02;
    color: $accent-dark;
    border-top: 1px solid $grey-light-01;
    padding: 16px 20px;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
  }

  &__header-cell {
    padding: 0 8px;
  }

  &__header-cell--conterparty {
    width: 32%;
  }

  &__header-cell--details {
    width: 38%;
  }

  &__header-cell--swift {
    width: 15%;
  }

  &__header-cell--amount {
    width: 15%;
    text-align: right;
  }

  &__more {
    width: 100%;
    background-color: rgba($grey-light-02, 0.4);
    font-size: 14px;
    line-height: 18px;
    padding: 16px;
    border-radius: 0 0 8px 8px;
    text-align: left;
    border-top: 1px solid $grey-light-01;
  }

  &__more span {
    display: block;
  }

  &__more-label {
    color: $grey-dark-01;
    transition: color 0.1s linear;
  }

  &__more-label:after {
    content: "\e912";
    @include inline-icon;
    font-size: 16px;
    vertical-align: text-bottom;
  }

  .is-collapsed &__more-label:after {
    content: "\e911";
  }

  &__more-error {
    color: $red;
    margin-top: 8px;
  }

  &__more-error:before {
    content: "\e93f";
    @include inline-icon;
    font-size: 16px;
    vertical-align: text-bottom;
    margin-right: 4px;
  }

  @media (hover: hover) {
    &__more:hover &__more-label {
      color: $additional-brown;
    }
  }

  @media screen and (max-width: $screen-sm-max) {
    &__header {
      display: none;
    }
  }

  @media screen and (min-width: $screen-md-min) {
    &__more {
      display: flex;
      padding: 10px 28px;
    }

    &__more-error {
      display: flex;
      margin: 0 0 0 auto;
    }

    &__more-error:before {
      display: none;
    }

    &__more-error:after {
      content: "\e93f";
      @include inline-icon;
      font-size: 16px;
      vertical-align: text-bottom;
      margin-left: 4px;
    }
  }
}

.payment-list-enter-active,
.payment-list-leave-active {
  transition: all 0.2s;
}

.payment-list-enter,
.payment-list-leave-to {
  opacity: 0;
}
</style>
