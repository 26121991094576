<template>
  <ModalWrapper
    size="lg"
    fix-height
  >
    <template slot="title">
      {{ messages.subject }}
    </template>

    <template slot="toolbox">
      <ButtonCircle
        icon="icon-reply"
        btn-style="light"
        :click-handler="handleReply"
        title="Reply"
      />

      <ButtonCircle
        icon="icon-trash-m"
        btn-style="light"
        title="Delete"
      />
    </template>

    <div class="message-modal-list">
      <div class="message-modal-list__inner">
        <div
          v-for="(message, i) in messages.items"
          :key="i"
          class="message-modal-item"
        >
          <div class="message-modal-item__header">
            <div class="message-modal-item__info">
              <div class="message-modal-item__info-item">
                <span class="message-modal-item__info-label">From:</span><span>{{ message.from }}</span>
              </div>

              <div class="message-modal-item__info-item">
                <span class="message-modal-item__info-label">For:</span><span>{{ message.for }}</span>
              </div>
            </div>

            <div class="message-modal-item__date">
              {{ message.date }}
            </div>
          </div>

          <div class="message-modal-item__body">
            {{ message.body }}

            <div
              v-if="message.attachments"
              class="message-modal-item__attachments"
            >
              <div class="message-modal-item__attachments-title">
                Attachment
              </div>

              <div class="message-modal-item__attachments-list">
                <a
                  v-for="(attachment, j) in message.attachments"
                  :key="j"
                  :href="attachment.link"
                  target="_blank"
                  class="message-modal-item__attachments-item"
                >
                  <div class="message-modal-item__attachments-name">
                    {{ attachment.name }}
                  </div>
                  <div class="message-modal-item__attachments-size">
                    {{ attachment.size }}
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <template slot="footer">
      <ModalActionWrapper>
        <Button
          label="Reply"
          btn-style="primary"
          :click-handler="handleReply"
        />
      </ModalActionWrapper>
    </template>
  </ModalWrapper>
</template>

<script>
import { mapActions } from "vuex";

import { messageBranch } from "@/fixtures";

import ModalWrapper from "@/components/Modal/ModalWrapper";
import ModalActionWrapper from "@/components/Modal/ModalActionWrapper";
import ButtonCircle from "@/components/ButtonCircle";
import Button from "@/components/Button";

export default {
  name: "NewMessageModal",

  components: {
    ModalWrapper,
    ModalActionWrapper,
    ButtonCircle,
    Button
  },

  created() {
    this.messages = messageBranch;
  },

  methods: {
    ...mapActions(["hideModal", "showModal"]),

    handleReply() {
      this.showModal({
        component: "NewMessageModal"
      });
    }
  }
};
</script>

<style scoped lang="scss">
.message-modal-list {
  &__inner {
    display: flex;
    flex-direction: column;
    padding: 16px 0;
  }

  @media screen and (min-width: $screen-md-min) {
    &__inner {
      padding: 24px 0;
    }
  }
}

.message-modal-item {
  border-radius: 8px;
  background-color: $grey-light-02;

  & + & {
    margin-top: 16px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    border-bottom: 1px solid $grey-normal;
  }

  &__info {
    font-size: 14px;
    line-height: 18px;
  }

  &__info-item + &__info-item {
    margin-top: 8px;
  }

  &__info-label {
    color: $grey-dark-01;
    margin-right: 4px;
  }

  &__date {
    color: $grey-dark-01;
    font-size: 14px;
    line-height: 18px;
    text-align: right;
  }

  &__body {
    padding: 24px 16px;
    font-size: 16px;
    line-height: 20px;
    white-space: pre-line;
  }

  &__attachments {
    margin-top: 24px;
  }

  &__attachments-title {
    font-size: 16px;
    line-height: 20px;
    color: $grey-dark-01;
    margin-bottom: 8px;
  }

  &__attachments-title:before {
    content: "\e93e";
    @include inline-icon;
  }

  &__attachments-item {
    display: inline-block;
    position: relative;
    background-color: $white;
    border-radius: 4px;
    padding: 8px 56px 8px 16px;
    margin: 4px;
  }

  &__attachments-item:after {
    content: "\e92b";
    @include inline-icon;
    font-size: 16px;
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    color: $grey-dark-02;
  }

  &__attachments-name {
    font-size: 16px;
    line-height: 20px;
    color: $additional-brown;
    transition: color 0.1s linear;
  }

  &__attachments-size {
    margin-top: 2px;
    font-size: 12px;
    line-height: 16px;
    color: $grey-dark-01;
  }

  @media (hover: hover) {
    &__attachments-item:hover &__attachments-name {
      color: $accent-light;
    }
  }

  @media screen and (min-width: $screen-md-min) {
    & + & {
      margin-top: 24px;
    }

    &__info {
      font-size: 16px;
      line-height: 20px;
    }

    &__info-label {
      margin-right: 8px;
    }

    &__date {
      font-size: 16px;
      line-height: 20px;
    }

    &__attachments-list {
      margin: -4px;
    }
  }
}
</style>
