<template>
  <div class="payout-modal-summary">
    <div class="payout-modal-summary__currencies">
      <div
        v-for="(item, i) in data.currencies"
        :key="i"
        class="payout-modal-summary__currencies-item"
      >
        <span class="text-grey payout-modal-summary__currencies-label">{{
          item.currency
        }}</span>
        {{ item.amount | moneyformat }}
      </div>
    </div>

    <div class="payout-modal-summary__total">
      <div class="payout-modal-summary__total-item">
        <span class="text-grey">Total number of payments received:</span>
        {{ data.received }}
      </div>
      <div class="payout-modal-summary__total-item">
        <span class="text-grey">Total number of payments accepted:</span>
        {{ data.accepted }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PayoutModalSummary",

  props: {
    data: {
      type: Object,
      required: false,
      default: null
    }
  }
};
</script>

<style scoped lang="scss">
.payout-modal-summary {
  margin: 24px -16px 0 -16px;
  border-top: 1px solid $grey-light-01;
  border-bottom: 1px solid $grey-light-01;
  padding: 24px 0px;
  font-size: 14px;

  &__currencies {
    padding: 0 16px;
    column-count: 2;
    column-gap: 16px;
  }

  &__currencies-item {
    break-inside: avoid-column;
    line-height: 22px;
  }

  &__currencies-label {
    display: inline-block;
    width: 42px;
  }

  &__total {
    margin-top: 16px;
    padding: 0 16px;
  }

  &__total-item {
    line-height: 18px;
  }

  &__total-item + &__total-item {
    margin-top: 4px;
  }

  @media screen and (min-width: $screen-md-min) {
    display: flex;
    margin: 32px -24px 0 -24px;
    padding: 24px 8px;

    &__total {
      margin-top: 0;
      border-left: 1px solid $grey-light-01;
    }

    &__total-item + &__total-item {
      margin-top: 6px;
    }
  }
}
</style>
