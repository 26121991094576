<template>
  <OnboardingBusinessLayout
    :current-step="currentStep"
    :side-msg="sideMsg"
  >
    <NotificationPushContainer />

    <Header>
      <template slot="title">
        Company representative
      </template>
    </Header>

    <div class="mt-lg-24 mt-sm-16">
      <h4 class="text-sb">
        Choose your status in the Company
      </h4>

      <div class="mt-lg-20 mt-sm-12">
        <div class="text-grey text-sm mb-4">
          Are you a director of Umbrella Inc?
        </div>

        <div
          class="arrange"
          role="radiogroup"
        >
          <FormRadioBox
            id="radio_1"
            name="radio-1"
            :label="true"
            el-width="50%"
            @change="form.isOnlyDirector = $event"
          >
            <template slot="title">
              Yes
            </template>
          </FormRadioBox>

          <FormRadioBox
            id="radio_2"
            name="radio-1"
            :label="false"
            el-width="50%"
            class="ml-lg-24 ml-sm-16"
            @change="form.isOnlyDirector = $event"
          >
            <template slot="title">
              No
            </template>
          </FormRadioBox>
        </div>
      </div>

      <div class="mt-lg-20 mt-sm-12">
        <div class="text-grey text-sm mb-4">
          Are you the only shareholder of Umbrella Inc?
        </div>

        <div
          class="arrange"
          role="radiogroup"
        >
          <FormRadioBox
            id="radio_3"
            name="radio-2"
            :label="true"
            el-width="50%"
            @change="form.isOnlyShareholder = $event"
          >
            <template slot="title">
              Yes
            </template>
          </FormRadioBox>

          <FormRadioBox
            id="radio_4"
            name="radio-2"
            :label="false"
            el-width="50%"
            class="ml-lg-24 ml-sm-16"
            @change="form.isOnlyShareholder = $event"
          >
            <template slot="title">
              No
            </template>
          </FormRadioBox>
        </div>
      </div>

      <!-- only shareholder but not only director -->
      <div
        v-if="form.isOnlyShareholder && !form.isOnlyDirector"
        class="mt-lg-24 mt-sm-16"
      >
        <div>
          <h4 class="text-sb mb-lg-12 mb-sm-8">
            Add directors
          </h4>

          <div
            v-if="form.addedDirectors.length"
            class="form__list mb-lg-32 mb-lg-24"
          >
            <div
              v-for="(item, i) in form.addedDirectors"
              :key="i"
              class="form__list-item"
            >
              <div>
                <div>
                  <span class="mr-4 text-grey">
                    {{ item.type === 'legal' ? 'Company name:' : 'Name:' }}
                  </span>
                  <span>
                    {{ item.type === 'legal' ? item.name : item.fullName }}
                  </span>
                </div>

                <div class="mt-lg-8 mt-sm-4">
                  <span class="mr-4 text-grey">
                    {{ item.type === 'legal' ? 'Registration country:' : 'Date of birth:' }}
                  </span>
                  <span>
                    {{ item.type === 'legal' ? item.country : item.dateOfBirth }}
                  </span>
                </div>

                <div class="mt-lg-8 mt-sm-4">
                  <span class="mr-4 text-grey">
                    {{ item.type === 'legal' ? 'Registration number:' : 'Country' }}
                  </span>
                  <span>{{ item.type === 'legal' ? item.number : item.country }}</span>
                </div>
              </div>

              <button
                class="form__list-btn text-grey"
                type="button"
                @click="editEntry('director', item.id)"
              >
                Edit
              </button>
            </div>
          </div>

          <div
            class="arrange mt-lg-12 mt-sm-4"
            role="radiogroup"
          >
            <FormRadioBox
              id="radio_5"
              v-model="form.directorType"
              name="radio-3"
              label="private"
              el-width="50%"
              @change="form.directorType = $event"
            >
              <template slot="title">
                Private person
              </template>
            </FormRadioBox>

            <FormRadioBox
              id="radio_6"
              v-model="form.directorType"
              name="radio-3"
              label="legal"
              el-width="50%"
              class="ml-lg-24 ml-sm-16"
              @change="form.directorType = $event"
            >
              <template slot="title">
                Legal person
              </template>
            </FormRadioBox>
          </div>
        </div>

        <div
          v-if="form.directorType === 'legal'"
          class="mt-lg-40 mt-sm-32"
        >
          <div>
            <FormInput
              id="director-company-name"
              name="director-company-name"
              type="text"
              :value="form.directorCompanyName"
              label="Company Name"
              :has-error="form.directorCompanyNameError"
              @input="form.directorCompanyName = $event"
            />
          </div>

          <div class="mt-lg-40 mt-sm-32">
            <FormSelect
              id="director-company-country"
              name="director-company-country"
              label="Registration country"
              el-width="100%"
              el-style="input"
              :value="form.directorCompanyCountry"
              :options="[
                { value: 'Lithuania', title: 'Lithuania', flag: 'lt' },
                { value: 'Latvia', title: 'Latvia', flag: 'lv' },
                { value: 'Estonia', title: 'Estonia', flag: 'ee' }
              ]"
              @change="form.directorCompanyCountry = $event"
            />
          </div>

          <div class="mt-lg-40 mt-sm-32">
            <FormInput
              id="director-company-number"
              name="director-company-number"
              type="number"
              :value="form.directorCompanyNumber"
              label="Registration number"
              :has-error="form.directorCompanyNumberError"
              @input="form.directorCompanyNumber = $event"
            />
          </div>

          <div class="mt-lg-16 mt-sm-12">
            <Button
              icon="icon-add"
              label="Add directors"
              btn-style="transparent"
              btn-width="138px"
              btn-size="sm"
              :click-handler="() => addEntry('director')"
            />
          </div>
        </div>

        <div
          v-if="form.directorType === 'private'"
          class="mt-lg-40 mt-sm-32"
        >
          <div class="arrange">
            <FormInput
              id="director-private-name"
              name="director-private-name"
              type="text"
              :value="form.directorPrivateName"
              label="Name"
              el-width="auto"
              :has-error="form.directorPrivateNameError"
              @input="form.directorPrivateName = $event"
            />

            <Datepicker
              id="director-dob"
              name="director-dob"
              placeholder="DD/MM/YYYY"
              label="Date of birth"
              el-width="154px"
              el-style="input"
              :value="form.directorDateOfBirth"
              :has-error="form.directorDateOfBirthError"
              class="ml-24"
              input-format="DD/MM/YYYY"
              @change="form.directorDateOfBirth = $event"
            />
          </div>

          <div class="mt-lg-40 mt-sm-32">
            <FormInput
              id="director-private-surname"
              name="director-private-surname"
              type="text"
              :value="form.directorPrivateSurname"
              label="Surname"
              :has-error="form.directorPrivateSurnameError"
              @input="form.directorPrivateSurname = $event"
            />
          </div>

          <div class="mt-lg-40 mt-sm-32">
            <FormSelect
              id="director-private-country"
              name="director-private-country"
              label="Сountry of residence"
              el-width="100%"
              el-style="input"
              :value="form.directorPrivateCountry"
              :options="[
                { value: 'Lithuania', title: 'Lithuania', flag: 'lt' },
                { value: 'Latvia', title: 'Latvia', flag: 'lv' },
                { value: 'Estonia', title: 'Estonia', flag: 'ee' }
              ]"
              @change="form.directorPrivateCountry = $event"
            />
          </div>

          <div class="mt-lg-16 mt-sm-12">
            <Button
              icon="icon-add"
              label="Add directors"
              btn-style="transparent"
              btn-width="138px"
              btn-size="sm"
              :click-handler="() => addEntry('director')"
            />
          </div>
        </div>
      </div>

      <!-- only director but not only shareholder -->
      <div
        v-else-if="!form.isOnlyShareholder && form.isOnlyDirector"
        class="mt-lg-24 mt-sm-16"
      >
        <div>
          <h4 class="text-sb mb-lg-12 mb-sm-8">
            Add shareholders
          </h4>

          <div
            v-if="form.addedShareholders.length"
            class="form__list mb-lg-32 mb-lg-24"
          >
            <div
              v-for="(item, i) in form.addedShareholders"
              :key="i"
              class="form__list-item"
            >
              <div>
                <div>
                  <span class="mr-4 text-grey">
                    {{ item.type === 'legal' ? 'Company name:' : 'Name:' }}
                  </span>
                  <span>
                    {{ item.type === 'legal' ? item.name : item.fullName }}
                  </span>
                </div>

                <div class="mt-lg-8 mt-sm-4">
                  <span class="mr-4 text-grey">
                    {{ item.type === 'legal' ? 'Registration country:' : 'Date of birth:' }}
                  </span>
                  <span>
                    {{ item.type === 'legal' ? item.country : item.dateOfBirth }}
                  </span>
                </div>

                <div class="mt-lg-8 mt-sm-4">
                  <span class="mr-4 text-grey">
                    {{ item.type === 'legal' ? 'Registration number:' : 'Country' }}
                  </span>
                  <span>{{ item.type === 'legal' ? item.number : item.country }}</span>
                </div>

                <div class="mt-lg-8 mt-sm-4">
                  <span class="mr-4 text-grey">% of ownership:</span>
                  <span>{{ item.percent }}%</span>
                </div>
              </div>

              <button
                class="form__list-btn text-grey"
                type="button"
                @click="editEntry('share', item.id)"
              >
                Edit
              </button>
            </div>
          </div>

          <div
            class="arrange mt-lg-12 mt-sm-4"
            role="radiogroup"
          >
            <FormRadioBox
              id="radio_7"
              v-model="form.shareholderType"
              name="radio-4"
              label="private"
              el-width="50%"
              @change="form.shareholderType = $event"
            >
              <template slot="title">
                Private person
              </template>
            </FormRadioBox>

            <FormRadioBox
              id="radio_8"
              v-model="form.shareholderType"
              name="radio-4"
              label="legal"
              el-width="50%"
              class="ml-lg-24 ml-sm-16"
              @change="form.shareholderType = $event"
            >
              <template slot="title">
                Legal person
              </template>
            </FormRadioBox>
          </div>
        </div>

        <div
          v-if="form.shareholderType === 'legal'"
          class="mt-lg-40 mt-sm-32"
        >
          <div>
            <FormInput
              id="share-company-name"
              name="share-company-name"
              type="text"
              :value="form.shareCompanyName"
              label="Company Name"
              :has-error="form.shareCompanyNameError"
              @input="form.shareCompanyName = $event"
            />
          </div>

          <div class="mt-lg-40 mt-sm-32">
            <FormSelect
              id="share-company-country"
              name="share-company-country"
              label="Registration country"
              el-width="100%"
              el-style="input"
              :value="form.shareCompanyCountry"
              :options="[
                { value: 'Lithuania', title: 'Lithuania', flag: 'lt' },
                { value: 'Latvia', title: 'Latvia', flag: 'lv' },
                { value: 'Estonia', title: 'Estonia', flag: 'ee' }
              ]"
              @change="form.shareCompanyCountry = $event"
            />
          </div>

          <div class="mt-lg-40 mt-sm-32">
            <FormInput
              id="share-company-number"
              name="share-company-number"
              type="number"
              :value="form.shareCompanyNumber"
              label="Registration number"
              :has-error="form.shareCompanyNumberError"
              @input="form.shareCompanyNumber = $event"
            />
          </div>

          <div class="mt-lg-40 mt-sm-32">
            <FormInput
              id="share-company-percent"
              name="share-company-percent"
              type="number"
              :value="form.shareCompanyPercent"
              label="% of ownership"
              el-width="112px"
              :has-error="form.shareCompanyPercentError"
              @input="form.shareCompanyPercent = $event"
            />
          </div>

          <div class="mt-lg-16 mt-sm-12">
            <Button
              icon="icon-add"
              label="Add shareholders"
              btn-style="transparent"
              btn-width="138px"
              btn-size="sm"
              :click-handler="() => addEntry('share')"
            />
          </div>
        </div>

        <div
          v-if="form.shareholderType === 'private'"
          class="mt-lg-40 mt-sm-32"
        >
          <div class="arrange">
            <FormInput
              id="share-private-name"
              name="share-private-name"
              type="text"
              :value="form.sharePrivateName"
              label="Name"
              el-width="auto"
              :has-error="form.sharePrivateNameError"
              @input="form.sharePrivateName = $event"
            />

            <Datepicker
              id="share-dob"
              name="share-dob"
              placeholder="DD/MM/YYYY"
              label="Date of birth"
              el-width="154px"
              el-style="input"
              :value="form.shareDateOfBirth"
              :has-error="form.shareDateOfBirthError"
              class="ml-24"
              input-format="DD/MM/YYYY"
              @change="form.shareDateOfBirth = $event"
            />
          </div>

          <div class="mt-lg-40 mt-sm-32">
            <FormInput
              id="share-private-surname"
              name="share-private-surname"
              type="text"
              :value="form.sharePrivateSurname"
              label="Surname"
              :has-error="form.sharePrivateSurnameError"
              @input="form.sharePrivateSurname = $event"
            />
          </div>

          <div class="mt-lg-40 mt-sm-32">
            <FormSelect
              id="share-private-country"
              name="share-private-country"
              label="Сountry of residence"
              el-width="100%"
              el-style="input"
              :value="form.sharePrivateCountry"
              :options="[
                { value: 'Lithuania', title: 'Lithuania', flag: 'lt' },
                { value: 'Latvia', title: 'Latvia', flag: 'lv' },
                { value: 'Estonia', title: 'Estonia', flag: 'ee' }
              ]"
              @change="form.sharePrivateCountry = $event"
            />
          </div>

          <div class="mt-lg-40 mt-sm-32">
            <FormInput
              id="share-private-percent"
              name="share-private-percent"
              type="number"
              :value="form.sharePrivatePercent"
              label="% of ownership"
              el-width="112px"
              :has-error="form.sharePrivatePercentError"
              @input="form.sharePrivatePercent = $event"
            />
          </div>

          <div class="mt-lg-16 mt-sm-12">
            <Button
              icon="icon-add"
              label="Add shareholders"
              btn-style="transparent"
              btn-width="138px"
              btn-size="sm"
              :click-handler="() => addEntry('share')"
            />
          </div>
        </div>
      </div>

      <!-- not only director and not only shareholder -->
      <div
        v-else-if="!form.isOnlyShareholder && !form.isOnlyDirector"
        class="mt-lg-24 mt-sm-16"
      >
        <div>
          <h4 class="text-sb mb-lg-12 mb-sm-8">
            Specify associated parties of Umbrella Inc
          </h4>

          <div
            v-if="form.addedAssociates.length"
            class="form__list mb-lg-32 mb-lg-24"
          >
            <div
              v-for="(item, i) in form.addedAssociates"
              :key="i"
              class="form__list-item"
            >
              <div>
                <div>
                  <span class="mr-4 text-grey">
                    {{ item.type === 'legal' ? 'Company name:' : 'Name:' }}
                  </span>
                  <span>
                    {{ item.type === 'legal' ? item.name : item.fullName }}
                  </span>
                </div>

                <div class="mt-lg-8 mt-sm-4">
                  <span class="mr-4 text-grey">
                    {{ item.type === 'legal' ? 'Registration country:' : 'Date of birth:' }}
                  </span>
                  <span>
                    {{ item.type === 'legal' ? item.country : item.dateOfBirth }}
                  </span>
                </div>

                <div class="mt-lg-8 mt-sm-4">
                  <span class="mr-4 text-grey">
                    {{ item.type === 'legal' ? 'Registration number:' : 'Country' }}
                  </span>
                  <span>{{ item.type === 'legal' ? item.number : item.country }}</span>
                </div>

                <div class="mt-lg-8 mt-sm-4">
                  <span class="mr-4 text-grey">
                    Role:
                  </span>
                  <span
                    v-for="(role, j) in item.roles"
                    :key="j"
                  >
                    {{ capitalize(role) }}{{ j + 1 !== item.roles.length ? ',' : '' }}
                  </span>
                </div>

                <div
                  v-if="item.type === 'legal' && item.roles.includes('shareholder')"
                  class="mt-lg-8 mt-sm-4"
                >
                  <span class="mr-4 text-grey">% of ownership:</span>
                  <span>{{ item.percent }}%</span>
                </div>

                <div
                  v-else-if="item.type === 'private' && item.roles.includes('shareholder')"
                  class="mt-lg-8 mt-sm-4"
                >
                  <span class="mr-4 text-grey">% of ownership:</span>
                  <span>{{ item.percent }}%</span>
                </div>
              </div>

              <button
                class="form__list-btn text-grey"
                type="button"
                @click="editEntry('associate', item.id)"
              >
                Edit
              </button>
            </div>
          </div>

          <div
            class="arrange mt-lg-12 mt-sm-4"
            role="radiogroup"
          >
            <FormRadioBox
              id="radio_9"
              v-model="form.associateType"
              name="radio-5"
              label="private"
              el-width="50%"
              @change="form.associateType = $event"
            >
              <template slot="title">
                Private person
              </template>
            </FormRadioBox>

            <FormRadioBox
              id="radio_10"
              v-model="form.associateType"
              name="radio-5"
              label="legal"
              el-width="50%"
              class="ml-lg-24 ml-sm-16"
              @change="form.associateType = $event"
            >
              <template slot="title">
                Legal person
              </template>
            </FormRadioBox>
          </div>
        </div>

        <div
          v-if="form.associateType === 'legal'"
          class="mt-lg-40 mt-sm-32"
        >
          <div>
            <FormInput
              id="associate-company-name"
              name="associate-company-name"
              type="text"
              :value="form.associateCompanyName"
              label="Company Name"
              :has-error="form.associateCompanyNameError"
              @input="form.associateCompanyName = $event"
            />
          </div>

          <div class="mt-lg-40 mt-sm-32">
            <FormSelect
              id="associate-company-country"
              name="associate-company-country"
              label="Registration country"
              el-width="100%"
              el-style="input"
              :value="form.associateCompanyCountry"
              :options="[
                { value: 'Lithuania', title: 'Lithuania', flag: 'lt' },
                { value: 'Latvia', title: 'Latvia', flag: 'lv' },
                { value: 'Estonia', title: 'Estonia', flag: 'ee' }
              ]"
              @change="form.associateCompanyCountry = $event"
            />
          </div>

          <div class="mt-lg-40 mt-sm-32">
            <FormInput
              id="associate-company-number"
              name="associate-company-number"
              type="number"
              :value="form.associateCompanyNumber"
              label="Registration number"
              :has-error="form.associateCompanyNumberError"
              @input="form.associateCompanyNumber = $event"
            />
          </div>

          <div class="mt-lg-20 mt-sm-12">
            <div class="text-grey text-sm mb-4">
              Choose role
            </div>

            <div class="arrange">
              <FormCheckboxBox
                id="checkbox_box_id_1"
                name="checkbox-box-name1"
                el-width="50%"
                :value="form.associateCompanyRoles.includes('director')"
                @change="() => selectAssociateRole('Company', 'director')"
              >
                <template slot="title">
                  Director
                </template>
              </FormCheckboxBox>

              <FormCheckboxBox
                id="checkbox_box_id_2"
                name="checkbox-box-name2"
                el-width="50%"
                class="ml-lg-24 ml-sm-16"
                :value="form.associateCompanyRoles.includes('shareholder')"
                @change="() => selectAssociateRole('Company', 'shareholder')"
              >
                <template slot="title">
                  Shareholder
                </template>
              </FormCheckboxBox>
            </div>
          </div>

          <div
            v-if="form.associateCompanyRoles.includes('shareholder')"
            class="mt-lg-40 mt-sm-32"
          >
            <FormInput
              id="associate-company-percent"
              name="associate-company-percent"
              type="number"
              :value="form.associateCompanyPercent"
              label="% of ownership"
              el-width="112px"
              :has-error="form.associateCompanyPercentError"
              @input="form.associateCompanyPercent = $event"
            />
          </div>

          <div class="mt-lg-16 mt-sm-12">
            <Button
              icon="icon-add"
              label="Add an associated party for Umbrella Inc"
              btn-style="transparent"
              btn-width="138px"
              btn-size="sm"
              :click-handler="() => addEntry('associate')"
            />
          </div>
        </div>

        <div
          v-if="form.associateType === 'private'"
          class="mt-lg-40 mt-sm-32"
        >
          <div class="arrange">
            <FormInput
              id="associate-private-name"
              name="associate-private-name"
              type="text"
              :value="form.associatePrivateName"
              label="Name"
              el-width="auto"
              :has-error="form.associatePrivateNameError"
              @input="form.associatePrivateName = $event"
            />

            <Datepicker
              id="associate-dob"
              name="associate-dob"
              placeholder="DD/MM/YYYY"
              label="Date of birth"
              el-width="154px"
              el-style="input"
              :value="form.associateDateOfBirth"
              :has-error="form.associateDateOfBirthError"
              class="ml-24"
              input-format="DD/MM/YYYY"
              @change="form.associateDateOfBirth = $event"
            />
          </div>

          <div class="mt-lg-40 mt-sm-32">
            <FormInput
              id="associate-private-surname"
              name="associate-private-surname"
              type="text"
              :value="form.associatePrivateSurname"
              label="Surname"
              :has-error="form.associatePrivateSurnameError"
              @input="form.associatePrivateSurname = $event"
            />
          </div>

          <div class="mt-lg-40 mt-sm-32">
            <FormSelect
              id="associate-private-country"
              name="associate-private-country"
              label="Сountry of residence"
              el-width="100%"
              el-style="input"
              :value="form.associatePrivateCountry"
              :options="[
                { value: 'Lithuania', title: 'Lithuania', flag: 'lt' },
                { value: 'Latvia', title: 'Latvia', flag: 'lv' },
                { value: 'Estonia', title: 'Estonia', flag: 'ee' }
              ]"
              @change="form.associatePrivateCountry = $event"
            />
          </div>

          <div class="mt-lg-20 mt-sm-12">
            <div class="text-grey text-sm mb-4">
              Choose role
            </div>

            <div class="arrange">
              <FormCheckboxBox
                id="checkbox_box_id_3"
                name="checkbox-box-name3"
                el-width="50%"
                :value="form.associatePrivateRoles.includes('director')"
                @change="() => selectAssociateRole('Private', 'director')"
              >
                <template slot="title">
                  Director
                </template>
              </FormCheckboxBox>

              <FormCheckboxBox
                id="checkbox_box_id_4"
                name="checkbox-box-name4"
                el-width="50%"
                class="ml-lg-24 ml-sm-16"
                :value="form.associatePrivateRoles.includes('shareholder')"
                @change="() => selectAssociateRole('Private', 'shareholder')"
              >
                <template slot="title">
                  Shareholder
                </template>
              </FormCheckboxBox>
            </div>
          </div>

          <div
            v-if="form.associatePrivateRoles.includes('shareholder')"
            class="mt-lg-40 mt-sm-32">
            <FormInput
              id="associate-private-percent"
              name="associate-private-percent"
              type="number"
              :value="form.associatePrivatePercent"
              label="% of ownership"
              el-width="112px"
              :has-error="form.associatePrivatePercentError"
              @input="form.associatePrivatePercent = $event"
            />
          </div>

          <div class="mt-lg-16 mt-sm-12">
            <Button
              icon="icon-add"
              label="Add an associated party for Umbrella Inc"
              btn-style="transparent"
              btn-width="138px"
              btn-size="sm"
              :click-handler="() => addEntry('associate')"
            />
          </div>
        </div>
      </div>

      <div class="arrange mt-lg-32 mt-sm-24">
        <Button
          icon="icon-back-mobile"
          label="Return"
          btn-style="transparent"
          btn-width="92px"
        />

        <Button
          label="Continue"
          btn-style="primary"
          class="w-100 ml-24"
          :click-handler="verifySharesSum"
        />
      </div>
    </div>
  </OnboardingBusinessLayout>
</template>

<script>
import { mapActions } from "vuex";

import OnboardingBusinessLayout from "@/components/Layout/OnboardingBusinessLayout";
import NotificationPushContainer from "@/components/Notification/NotificationPushContainer";
import Header from "@/components/Onboarding/Header";
import FormInput from "@/components/Form/FormInput";
import FormSelect from "@/components/Form/FormSelect";
import Button from "@/components/Button";
import FormRadioBox from "@/components/Form/FormRadioBox";
import Datepicker from "@/components/Form/FormDatepicker";
import FormCheckboxBox from "@/components/Form/FormCheckboxBox";

export default {
  components: {
    OnboardingBusinessLayout,
    NotificationPushContainer,
    Header,
    FormInput,
    FormSelect,
    Button,
    FormRadioBox,
    Datepicker,
    FormCheckboxBox,
  },

  data() {
    return {
      currentStep: 7,

      sideMsg:
        "Some short tooltip. Why do we need to know about this info about user",

      form: {
        isOnlyDirector: true,
        isOnlyShareholder: true,

        shareholderType: '',
        directorType: '',
        associateType: '',

        shareCompanyName: '',
        shareCompanyNameError: '',
        shareCompanyCountry: 'Latvia',
        shareCompanyNumber: '',
        shareCompanyNumberError: '',
        shareCompanyPercent: '',
        shareCompanyPercentError: '',

        sharePrivateName: '',
        sharePrivateNameError: '',
        shareDateOfBirth: null,
        shareDateOfBirthError: '',
        sharePrivateSurname: '',
        sharePrivateSurnameError: '',
        sharePrivateCountry: 'Latvia',
        sharePrivatePercent: '',
        sharePrivatePercentError: '',

        directorCompanyName: '',
        directorCompanyNameError: '',
        directorCompanyCountry: 'Latvia',
        directorCompanyNumber: '',
        directorCompanyNumberError: '',

        directorPrivateName: '',
        directorPrivateNameError: '',
        directorDateOfBirth: null,
        directorDateOfBirthError: '',
        directorPrivateSurname: '',
        directorPrivateSurnameError: '',
        directorPrivateCountry: 'Latvia',

        associateCompanyName: '',
        associateCompanyNameError: '',
        associateCompanyCountry: 'Latvia',
        associateCompanyNumber: '',
        associateCompanyNumberError: '',
        associateCompanyRoles: [],
        associateCompanyPercent: '',
        associateCompanyPercentError: '',

        associatePrivateName: '',
        associatePrivateNameError: '',
        associateDateOfBirth: null,
        associateDateOfBirthError: '',
        associatePrivateSurname: '',
        associatePrivateSurnameError: '',
        associatePrivateCountry: 'Latvia',
        associatePrivateRoles: [],
        associatePrivatePercent: '',
        associatePrivatePercentError: '',

        addedShareholders: [],
        addedDirectors: [],
        addedAssociates: [],
        addedItemId: 0,
        isSharesSumCorrect: null,
      },
    };
  },

  methods: {
    ...mapActions(["pushNotification", "hideNotification"]),

    getBirthday(date) {
      const day = () => {
        const d = new Date(date).getDate();

        return d >= 10 ? d : `0${d}`;
      };

      const month = () => {
        const m = new Date(date).getMonth() + 1;

        return m >= 10 ? m : `0${m}`;
      };

      const year = new Date(date).getFullYear();

      return `${day()}.${month()}.${year}`;
    },

    addEntry(entryType) {
      const type = () => {
        switch (entryType) {
          case 'share':
            return this.form.shareholderType;

          case 'director':
            return this.form.directorType;

          case 'associate':
            return this.form.associateType;

          default:
            return;
        }
      }

      let data = {};

      if (type() === 'legal') {
        data = {
          id: this.form.addedItemId,
          name: this.form[`${entryType}CompanyName`],
          country: this.form[`${entryType}CompanyCountry`],
          number: this.form[`${entryType}CompanyNumber`],
          roles: this.form[`${entryType}CompanyRoles`],
          percent: this.form[`${entryType}CompanyPercent`],
          type: type(),
        };
      } else {
        data = {
          id: this.form.addedItemId,
          name: this.form[`${entryType}PrivateName`],
          surname: this.form[`${entryType}PrivateSurname`],
          fullName: this.form[`${entryType}PrivateName`] + ' ' + this.form[`${entryType}PrivateSurname`],
          dob: this.form[`${entryType}DateOfBirth`],
          dateOfBirth: this.getBirthday(this.form[`${entryType}DateOfBirth`]),
          country: this.form[`${entryType}PrivateCountry`],
          roles: this.form[`${entryType}PrivateRoles`],
          percent: this.form[`${entryType}PrivatePercent`],
          type: type(),
        };
      }

      switch (entryType) {
        case 'share':
          this.form.addedShareholders.push(data);

          break;

        case 'director':
          this.form.addedDirectors.push(data);

          break;

        case 'associate':
          this.form.addedAssociates.push(data);

          break;

        default:
          return;
      }

      this.form.addedItemId++;

      this.form[`${entryType}CompanyName`] = '';
      this.form[`${entryType}CompanyNumber`] = '';
      this.form[`${entryType}CompanyRoles`] = [];
      this.form[`${entryType}CompanyPercent`] = '';
      this.form[`${entryType}PrivateName`] = '';
      this.form[`${entryType}PrivateSurname`] = '';
      this.form[`${entryType}PrivateRoles`] = [];
      this.form[`${entryType}PrivatePercent`] = '';
      this.form[`${entryType}DateOfBirth`] = null;
    },

    editEntry(entryType, id) {
      const itemsArray = () => {
        switch (entryType) {
          case 'share':
            return this.form.addedShareholders;

          case 'director':
            return this.form.addedDirectors;

          case 'associate':
            return this.form.addedAssociates;

          default:
            return;
        }
      };

      const item = itemsArray().find(item => item.id === id);
      const itemIndex = itemsArray().indexOf(item);
      const type = item.type;

      switch (entryType) {
        case 'share':
          this.form.shareholderType = type;

          break;

        case 'director':
          this.form.directorType = type;

          break;

        case 'associate':
          this.form.associateType = type;

          break;

        default:
          return;
      }

      if (type === 'legal') {
        this.form[`${entryType}CompanyName`] = item.name;
        this.form[`${entryType}CompanyCountry`] = item.country;
        this.form[`${entryType}CompanyNumber`] = item.number;
        this.form[`${entryType}CompanyRoles`] = item.roles;
        this.form[`${entryType}CompanyPercent`] = item.percent;
      } else {
        this.form[`${entryType}PrivateName`] = item.name;
        this.form[`${entryType}PrivateSurname`] = item.surname;
        this.form[`${entryType}PrivateCountry`] = item.country;
        this.form[`${entryType}PrivateRoles`] = item.roles;
        this.form[`${entryType}PrivatePercent`] = item.percent;
        this.form[`${entryType}DateOfBirth`] = item.dob; // doesn't work
      }

      if (itemIndex !== -1) itemsArray().splice(itemIndex, 1);
    },

    verifySharesSum() {
      if (this.form.isOnlyShareholder) return;

      const sharesArray = () => {
        if (!this.form.isOnlyDirector && !this.form.isOnlyShareholder) {
          return this.form.addedAssociates.map(share => share.percent);
        } else {
          return this.form.addedShareholders.map(share => share.percent);
        }
      };

      const notification = {
        type: "danger",
        title: "Impossible to continue",
        message: "Total amount of shares should be 100%"
      };

      let sharesSum = 0;

      sharesArray().forEach(share => {
        sharesSum += Number(share);
      });


      sharesSum !== 100 ? this.pushNotification(notification) : null;
    },

    selectAssociateRole(type, role) {
      const roles = this.form[`associate${type}Roles`];
      const roleIndex = roles.indexOf(role);
      const roleAlreadyAssigned = roleIndex !== -1;

      roleAlreadyAssigned ? roles.splice(roleIndex, 1) : roles.push(role);
    },

    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  }
};
</script>

<style lang="scss" scoped>
.form {
  &__list-item {
    position: relative;
    padding: 16px;
    background-color: $grey-light-02;
    border-radius: 8px;
    font-size: 14px;
  }

  &__list-item + &__list-item {
    margin-top: 24px;
  }

  &__list-btn {
    position: absolute;
    top: 16px;
    right: 16px;
  }
}
</style>
