<template>
  <div
    class="form-input form-item"
    :class="{
      'has-error': hasError,
      'is-disabled': disabled,
      'default-style': elStyle === 'default',
      'inverse-style': elStyle === 'inverse'
    }"
    :style="{
      width: elWidth,
      minWidth: elMinWidth
    }"
  >
    <label
      v-if="label"
      :for="id"
    >{{ label }}</label>
    <input
      :id="id"
      v-model="model"
      v-mask="mask"
      :name="name"
      :type="getInputType(type)"
      :placeholder="placeholder"
      :disabled="disabled"
      :readonly="readonly"
      :required="required"
      @keypress="handleValidation($event)"
    >
    <Button
      v-if="type === 'list'"
      icon="icon-add"
      btn-style="input"
      class="form-input__btn"
      :click-handler="addItemToList"
    />
    <span
      v-if="hasError"
      class="form-input__error"
    >{{ hasError }}</span>

    <ul
      v-if="type === 'list' && list.length"
      class="form-input__list"
    >
      <li
        v-for="listItem in list"
        :key="listItem.id"
        class="form-input__list-item"
      >
        <button
          class="form-input__list-btn"
          @click="removeItemFromList(listItem)"
        >
          {{ listItem.value }}
          <i class="icon icon-trash" />
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import Button from '@/components/Button';

export default {
  name: "FormInput",

  components: {
    Button,
  },

  props: {
    label: { type: String, required: false, default: "" },
    elWidth: { type: String, required: false, default: "" },
    elMinWidth: { type: String, required: false, default: "" },
    elStyle: {
      type: String,
      required: false,
      default: "default",
      validator: function(value) {
        return ["default", "inverse"].indexOf(value) !== -1;
      }
    },
    id: { type: String, required: false, default: "" },
    name: { type: String, required: false, default: "" },
    type: { type: String, required: false, default: "text" },
    value: { type: [String, Number], required: false, default: "" },
    placeholder: { type: String, required: false, default: "" },
    disabled: { type: Boolean, required: false, default: false },
    readonly: { type: Boolean, required: false, default: false },
    required: { type: Boolean, required: false, default: false },
    hasError: { type: String, required: false, default: "" },
    mask: { type: String, required: false, default: "" }
  },

  data() {
    return {
      model: this.value,
      list: [
        {
          id: 0,
          value: 'http://umbrellainc.com/'
        },
        {
          id: 1,
          value: 'http://www.conecom.com'
        }
      ],
      listItemIndex: 2,
    };
  },

  watch: {
    model(val) {
      this.$emit("input", val);
    },

    value(val) {
      this.model = val;
    }
  },

  methods: {
    handleValidation(e) {
      if (this.type === "number") {
        let keyCode = e.keyCode ? e.keyCode : e.which;
        if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
          e.preventDefault();
        }
      }
    },

    getInputType() {
      if (this.type === 'list') {
        return 'text';
      }

      return this.type;
    },

    addItemToList() {
      if (!this.model) return;

      const listItem = {
        id: this.listItemIndex,
        value: this.model
      };

      this.list.push(listItem);
      this.listItemIndex++;
      this.model = '';
    },

    removeItemFromList(listItem) {
      const index = this.list.indexOf(listItem);
      if (index !== -1) this.list.splice(index, 1);
    }
  }
};
</script>

<style scoped lang="scss">
.form-input {
  position: relative;
  display: inline-block;
  font-family: $font-regular;
  width: 100%;

  & label {
    position: absolute;
    top: -22px;
    left: 0;
    display: inline-block;
    font-size: 14px;
    line-height: 18px;
    color: $grey-dark-01;
  }

  & input {
    font-size: 16px;
    width: 100%;
    min-width: 1%;
    border: 1px solid transparent;
    padding: 14px 16px;
    border-radius: 8px;
    transition: border-color 0.1s linear;
  }

  .form-item-group & {
    flex: 1;
  }

  .form-item-group & input {
    border-radius: 0;
  }

  .form-item-group &:first-child input {
    border-radius: 8px 0 0 8px;
  }

  .form-item-group &:last-child input {
    border-radius: 0 8px 8px 0;
  }

  &.default-style input {
    background-color: $grey-light-02;
  }

  &.inverse-style input {
    background-color: $white;
  }

  & input:focus {
    border-color: $accent-normal;
  }

  & input::-webkit-input-placeholder {
    color: $grey-dark-01;
  }

  & input:-ms-input-placeholder {
    color: $grey-dark-01;
  }

  & input::placeholder {
    color: $grey-dark-01;
  }

  & input::-webkit-outer-spin-button,
  & input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  & input[type="number"] {
    -moz-appearance: textfield;
  }

  &.has-error input {
    border-color: $red;
  }

  &__error {
    display: inline-block;
    font-size: 12px;
    line-height: 16px;
    color: $red;
    margin-top: 4px;
  }

  &.is-disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &__btn {
    position: absolute;
    top: 1px;
    right: 1px;
    height: 48px;
    width: 48px;
    font-size: 25px;
    color: $grey-dark-01;
    border-left: 1px solid $grey-normal;
  }

  &__list {
    margin-top: 8px;
  }

  &__list-btn {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    text-align: left;
    border-radius: 4px;
    color: $grey-dark-01;
    padding: 8px 16px;
    transition:
      background-color 0.1s linear,
      color 0.1s linear;
  }

  &__list-btn .icon {
    display: flex;
    align-items: center;
    opacity: 0;
    color: $red;
    transition: opacity 0.1s linear;
  }

  @media (hover: hover) {
    &__list-btn:hover {
      background-color: $grey-light-02;
      color: $black;
    }

    &__list-btn:hover .icon {
      opacity: 1;
    }
  }
}
</style>
