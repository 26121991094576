<template>
  <ModalWrapper
    size="md"
    modal-style="success"
  >
    <template slot="title">
      Payment successful
    </template>

    <div class="text-center text-sb">
      Transaction number: LT3256 2345 2345 2321
    </div>

    <div class="mt-24">
      <ModalTableRow
        v-for="(row, i) in tableRows"
        :key="i"
      >
        <template slot="title">
          {{ row.title }}
        </template>

        <template slot="value">
          {{ row.value }}
        </template>
      </ModalTableRow>
    </div>

    <template slot="footer">
      <Button
        label="Ok"
        btn-style="primary"
        btn-width="110px"
        :click-handler="hideModal"
      />
    </template>
  </ModalWrapper>
</template>

<script>
import { mapActions } from "vuex";

import ModalWrapper from "@/components/Modal/ModalWrapper";
import Button from "@/components/Button";
import ModalTableRow from "@/components/Modal/ModalTableRow";

export default {
  name: "SuccessAccountModal",

  components: {
    ModalWrapper,
    Button,
    ModalTableRow
  },

  data() {
    return {
      tableRows: [
        { title: "Sender", value: "Umbrella INC" },
        { title: "Account of the sender", value: "DE654467654321" },
        { title: "Beneficiary", value: "Dustin Steward" },
        {
          title: "Address",
          value: "5 New Street Square London, EC4A 3TW United Kingdom"
        },
        { title: "Account", value: "LT12657401408033217001" },
        { title: "Bank", value: "MisterTango Lithuania" },
        { title: "SWIFT", value: "MIEGLT21" },
        { title: "Amount", value: "10.99 USD" }
      ]
    };
  },

  methods: {
    ...mapActions(["hideModal"])
  }
};
</script>
