<template>
  <ModalWrapper size="md">
    <template slot="title">
      Currency Exchange
    </template>

    <div class="exchange-modal-body">
      <div class="arrange">
        <TabButton
          label="Buy"
          target="buy"
          :is-selected="currentTab === 'buy'"
          @select="currentTab = $event"
        />

        <TabButton
          label="Sell"
          target="sell"
          :is-selected="currentTab === 'sell'"
          @select="currentTab = $event"
        />
      </div>

      <div
        v-if="currentTab === 'buy'"
        key="buy"
      >
        <div class="mt-40">
          <FormSelect
            label="Account"
            el-style="input"
            :value="buy.account"
            :options="['Account 1', 'Account 2', 'Account 3']"
            @change="buy.account = $event"
          />
        </div>

        <div class="exchange-modal-body__main-row">
          <div class="exchange-modal-body__main-primary">
            <FormItemGroup label="Amount (I'm buying)">
              <FormInput
                type="number"
                :value="buy.amount"
                @input="buy.amount = $event"
              />

              <FormSelect
                el-width="120px"
                el-style="input"
                :value="buy.buyCurrency"
                :options="[
                  { value: 'gbp', title: 'GBP', flag: 'gb' },
                  { value: 'usd', title: 'USD', flag: 'us' },
                  { value: 'eur', title: 'EUR', flag: 'eu' }
                ]"
                @change="buy.buyCurrency = $event"
              />
            </FormItemGroup>
          </div>

          <div class="exchange-modal-body__main-secondary">
            <FormSelect
              label="For"
              el-style="input"
              :value="buy.sellCurrency"
              :options="[
                { value: 'gbp', title: 'GBP', flag: 'gb' },
                { value: 'usd', title: 'USD', flag: 'us' },
                { value: 'eur', title: 'EUR', flag: 'eu' }
              ]"
              @change="buy.sellCurrency = $event"
            />
          </div>

          <span class="exchange-modal-body__main-subscript">
            Rate: 1 GBP = 1.31 USD
          </span>
        </div>
      </div>

      <div
        v-if="currentTab === 'sell'"
        key="sell"
      >
        <div class="mt-40">
          <FormSelect
            label="Account"
            el-style="input"
            :value="sell.account"
            :options="['Account 1', 'Account 2', 'Account 3']"
            @change="sell.account = $event"
          />
        </div>

        <div class="exchange-modal-body__main-row">
          <div class="exchange-modal-body__main-primary">
            <FormItemGroup label="Amount (I'm selling)">
              <FormInput
                el-width="236px"
                type="number"
                :value="sell.amount"
                @input="sell.amount = $event"
              />

              <FormSelect
                el-width="120px"
                el-style="input"
                :value="sell.buyCurrency"
                :options="[
                  { value: 'gbp', title: 'GBP', flag: 'gb' },
                  { value: 'usd', title: 'USD', flag: 'us' },
                  { value: 'eur', title: 'EUR', flag: 'eu' }
                ]"
                @change="sell.buyCurrency = $event"
              />
            </FormItemGroup>
          </div>

          <div class="exchange-modal-body__main-secondary">
            <FormSelect
              label="For"
              el-style="input"
              :value="sell.sellCurrency"
              :options="[
                { value: 'gbp', title: 'GBP', flag: 'gb' },
                { value: 'usd', title: 'USD', flag: 'us' },
                { value: 'eur', title: 'EUR', flag: 'eu' }
              ]"
              @change="sell.sellCurrency = $event"
            />
          </div>

          <span class="exchange-modal-body__main-subscript">
            Rate: 1 USD = 0.91 EUR
          </span>
        </div>
      </div>
    </div>

    <ModalCallout>
      <div class="exchange-modal-info">
        <div class="exchange-modal-info__primary">
          {{ currentTab === "buy" ? "You will need" : "You will get" }}
          <span class="text-black">130.74</span> USD
        </div>

        <div class="exchange-modal-info__secondary">
          Conversion will be performed at the market rate at the moment of
          transaction
        </div>
      </div>
    </ModalCallout>

    <template slot="footer">
      <ModalActionWrapper>
        <Button
          label="Exchange"
          btn-style="primary"
          :click-handler="handleSubmit"
        />
      </ModalActionWrapper>
    </template>
  </ModalWrapper>
</template>

<script>
import { mapActions } from "vuex";

import ModalWrapper from "@/components/Modal/ModalWrapper";
import ModalCallout from "@/components/Modal/ModalCallout";
import ModalActionWrapper from "@/components/Modal/ModalActionWrapper";
import Button from "@/components/Button";
import TabButton from "@/components/TabButton";
import FormSelect from "@/components/Form/FormSelect";
import FormItemGroup from "@/components/Form/FormItemGroup";
import FormInput from "@/components/Form/FormInput";

export default {
  name: "CurrencyExchangeModal",

  components: {
    ModalWrapper,
    ModalCallout,
    ModalActionWrapper,
    Button,
    TabButton,
    FormSelect,
    FormItemGroup,
    FormInput
  },

  data() {
    return {
      currentTab: "buy",

      buy: {
        account: "Account 1",
        amount: 100,
        buyCurrency: "gbp",
        sellCurrency: "usd"
      },

      sell: {
        account: "Account 1",
        amount: 100,
        sellCurrency: "usd",
        buyCurrency: "gbp"
      }
    };
  },

  methods: {
    ...mapActions(["pushNotification", "hideModal"]),

    handleSubmit() {
      this.hideModal();

      const notification = {
        type: "success",
        title: "Conversion has been successfully performed."
      };

      this.pushNotification(notification);
    }
  }
};
</script>

<style scoped lang="scss">
.exchange-modal-body {
  padding: 16px 0 56px 0;

  &__main-row {
    position: relative;
    margin-top: 32px;
  }

  &__main-primary {
    width: 100%;
  }

  &__main-primary .form-input {
    flex: 1;
  }

  &__main-secondary {
    width: 100%;
    margin-top: 32px;
  }

  &__main-subscript {
    position: absolute;
    bottom: -24px;
    left: 0;
    font-size: 12px;
    line-height: 16px;
    color: $grey-dark-01;
  }

  @media screen and (min-width: $screen-md-min) {
    padding: 32px 0 48px 0;

    &__main-row {
      display: flex;
      margin-top: 40px;
    }

    &__main-primary {
      width: 356px;
    }

    &__main-secondary {
      width: 164px;
      margin-left: 32px;
      margin-top: 0;
    }
  }
}

.exchange-modal-info {
  color: $grey-dark-01;
  padding: 16px 0;

  &__primary {
    font-size: 16px;
    line-height: 20px;
  }

  &__secondary {
    font-size: 12px;
    line-height: 16px;
    margin-top: 4px;
  }

  @media screen and (min-width: $screen-md-min) {
    padding: 24px 0;

    &__primary {
      font-size: 18px;
      line-height: 24px;
    }
  }
}
</style>
