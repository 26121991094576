<template>
  <AuthLayout>
    <h1 class="h1-bold auth-title">
      Welcome to TPF
    </h1>

    <div class="mt-40">
      <FormInput
        id="email"
        name="email"
        type="email"
        placeholder="example@gmail.com"
        label="Email"
        el-width="100%"
        :value="form.email"
        :error="form.emailError"
        @input="form.email = $event"
      />
    </div>

    <div class="mt-40">
      <FormInput
        id="pass"
        name="pass"
        type="password"
        label="Password"
        el-width="100%"
        :value="form.pass"
        :error="form.passError"
        @input="form.pass = $event"
      />
    </div>

    <div class="mt-16 arrange arrange--between">
      <FormCheckbox
        id="remember"
        name="remember"
        :value="form.remember"
        @change="form.remember = $event"
      >
        <span class="text-grey text-sm">Remember me</span>
      </FormCheckbox>

      <ButtonLink
        to="/pass-recovery"
        btn-style="grey"
        btn-size="sm"
        label="Show details"
      />
    </div>

    <div class="mt-32">
      <Button
        label="Log in"
        btn-style="primary"
        btn-width="100%"
      />
    </div>
  </AuthLayout>
</template>

<script>
import AuthLayout from "@/components/Layout/AuthLayout";
import FormInput from "@/components/Form/FormInput";
import FormCheckbox from "@/components/Form/FormCheckbox";
import ButtonLink from "@/components/ButtonLink";
import Button from "@/components/Button";

export default {
  name: "Login",

  components: {
    AuthLayout,
    FormInput,
    FormCheckbox,
    ButtonLink,
    Button
  },

  data() {
    return {
      form: {
        email: "",
        emailError: "",
        pass: "",
        passEror: "",
        remember: true
      }
    };
  }
};
</script>
