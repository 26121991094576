<template>
  <div
    class="toggle"
    :class="{ 'is-disabled': disabled }"
  >
    <label>
      <input
        :id="id"
        type="checkbox"
        :name="name"
        :disabled="disabled"
        :checked="value"
        @change="$emit('change', $event.target.checked)"
      >
      <span
        class="toggle__switch"
        :data-label="[value ? labels.true : labels.false]"
      />
    </label>
  </div>
</template>

<script>
export default {
  name: "Toggle",

  props: {
    id: { type: String, required: false, default: "" },
    name: { type: String, required: false, default: "" },
    value: { type: Boolean, required: false, default: false },
    disabled: { type: Boolean, required: false, default: false },
    labelOutside: { type: Boolean, required: false, default: false },
    labels: {
      type: Object,
      required: false,
      default: function() {
        return { true: "", false: "" };
      }
    }
  }
};
</script>

<style scoped lang="scss">
.toggle {
  display: inline-block;

  &__switch {
    display: block;
    position: relative;
    width: 48px;
    height: 24px;
    border-radius: 16px;
    background-color: $grey-normal;
    cursor: pointer;
    transition: background-color 0.1s linear;
  }

  &__switch:before {
    content: attr(data-label);
    font-family: $font-semibold;
    color: rgba($grey-dark, 0.4);
    font-size: 10px;
    line-height: 16px;
    text-transform: uppercase;
    letter-spacing: 0.02em;
    position: absolute;
    right: 4px;
    top: 4px;
    text-align: center;
    width: 50%;
  }

  & input:checked ~ &__switch:before {
    color: $white;
    left: 4px;
    right: auto;
  }

  &__switch:after {
    content: "";
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: $white;
    position: absolute;
    left: 4px;
    top: 4px;
    transition: transform 0.1s linear;
  }

  & input {
    opacity: 0;
    width: 0;
    height: 0;
    visibility: hidden;
    position: absolute;
  }

  & input:checked ~ &__switch {
    background-color: $accent-normal;
  }

  & input:checked ~ &__switch:after {
    transform: translateX(24px);
  }

  &.is-disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
</style>
