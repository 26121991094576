<template>
  <component
    :is="type"
    :to="to"
    :class="`btn-circle btn-circle-${btnStyle} btn-circle-${btnSize}`"
    @click="clickHandler"
  >
    <span class="btn-circle__icon">
      <i
        v-if="icon"
        :class="`icon ${icon}`"
      />
    </span>
    <span
      v-if="label"
      class="btn-circle__label"
    >{{ label }}</span>
  </component>
</template>

<script>
export default {
  name: "ButtonCircle",

  props: {
    icon: { type: String, required: false, default: "" },
    label: { type: String, required: false, default: "" },
    btnStyle: { type: String, required: false, default: "" },
    btnSize: { type: String, required: false, default: "md" },
    to: { type: String, required: false, default: "" },
    clickHandler: { type: Function, required: false, default: () => {} }
  },

  computed: {
    type() {
      if (this.to) {
        return "router-link";
      }

      return "button";
    }
  }
};
</script>

<style scoped lang="scss">
.btn-circle {
  display: inline-block;
  text-align: center;
  color: $additional-brown;

  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: auto;
    transition: background-color 0.1s linear;
  }

  &__label {
    display: block;
  }

  &-sm &__icon {
    width: 24px;
    height: 24px;
    font-size: 16px;
  }

  &-sm &__label {
    font-size: 12px;
    line-height: 1.5;
    margin-top: 4px;
  }

  &-md &__icon {
    width: 48px;
    height: 48px;
    font-size: 24px;
  }

  &-md &__label {
    font-size: 16px;
    line-height: 1.5;
    margin-top: 4px;
  }
}

.btn-circle-dark {
  .btn-circle__icon {
    background-color: rgba($accent-normal, 0.1);
  }

  @media (hover: hover) {
    &:hover .btn-circle__icon {
      background-color: rgba($accent-normal, 0.3);
    }
  }
}

.btn-circle-light {
  .btn-circle__icon {
    background-color: $grey-light-02;
  }

  @media (hover: hover) {
    &:hover .btn-circle__icon {
      background-color: $additional-normal;
    }
  }
}
</style>
