<template>
  <button
    class="tab-btn"
    :class="{ 'is-selected': isSelected }"
    @click="handleSelect"
  >
    {{ label }}
  </button>
</template>

<script>
export default {
  name: "TabButton",

  props: {
    label: { type: String, required: true },
    target: { type: String, required: false, default: "" },
    isSelected: { type: Boolean, required: false, default: false }
  },

  methods: {
    handleSelect() {
      this.$emit("select", this.target);
    }
  }
};
</script>

<style scoped lang="scss">
.tab-btn {
  display: inline-block;
  flex: 1;
  text-align: center;
  padding: 0 8px;
  font-family: $font-semibold;
  font-size: 14px;
  line-height: 38px;
  color: rgba($additional-brown, 0.4);
  border: 1px solid $grey-light-01;
  transition: background-color 0.1s linear, color 0.1s linear;

  &:first-child {
    border-radius: 8px 0 0 8px;
  }

  &:last-child {
    border-radius: 0 8px 8px 0;
  }

  &.is-selected {
    color: $additional-brown;
    background-color: $grey-light-01;
  }
}
</style>
