<template>
  <DashboardLayout>
    <div style="height:120vh">
      <Button
        label="Show Success notification line"
        btn-style="primary"
        :click-handler="
          () => {
            handleShowNotification('success');
          }
        "
      />

      <br>
      <br>

      <Button
        label="Show Info notification line"
        btn-style="primary"
        :click-handler="
          () => {
            handleShowNotification('info');
          }
        "
      />

      <br>
      <br>

      <Button
        label="Show Warning notification line"
        btn-style="primary"
        :click-handler="
          () => {
            handleShowNotification('warning');
          }
        "
      />

      <br>
      <br>

      <Button
        label="Show Danger notification line"
        btn-style="primary"
        :click-handler="
          () => {
            handleShowNotification('danger');
          }
        "
      />

      <br>
      <br>

      <Button
        label="Hide notification line"
        btn-style="primary"
        :click-handler="hideNotification"
      />

      <hr>

      <Button
        label="Show Success push-notification"
        btn-style="dark-opacity"
        :click-handler="
          () => {
            handlePushNotification('success');
          }
        "
      />

      <br>
      <br>

      <Button
        label="Show Info push-notification"
        btn-style="dark-opacity"
        :click-handler="
          () => {
            handlePushNotification('info');
          }
        "
      />

      <br>
      <br>

      <Button
        label="Show Warning push-notification"
        btn-style="dark-opacity"
        :click-handler="
          () => {
            handlePushNotification('warning');
          }
        "
      />

      <br>
      <br>

      <Button
        label="Show Danger push-notification"
        btn-style="dark-opacity"
        :click-handler="
          () => {
            handlePushNotification('danger');
          }
        "
      />
    </div>
  </DashboardLayout>
</template>

<script>
import { mapActions } from "vuex";

import DashboardLayout from "@/components/Layout/DashboardLayout";
import Button from "@/components/Button";

export default {
  components: {
    DashboardLayout,
    Button
  },

  methods: {
    ...mapActions(["showNotification", "hideNotification", "pushNotification"]),

    handleShowNotification(type) {
      let notificationType = "";
      let notificationMsg = "";

      switch (type) {
        case "success":
          notificationType = "success";
          notificationMsg =
            "Card has been successfully ordered. Please wait for our specialist to contact you.";

          break;

        case "info":
          notificationType = "info";
          notificationMsg =
            "We will revert to you within 3 business days with the status of your account.";

          break;

        case "warning":
          notificationType = "warning";
          notificationMsg =
            "Attention. If you don't save, your changes will be lost. <a href='#'>Save changes</a> or <button>Leave anyway</button>";

          break;

        case "danger":
          notificationType = "danger";
          notificationMsg =
            "It is not possible to delete a card with an account balance";

          break;

        default:
          return;
      }

      this.showNotification({
        type: notificationType,
        message: notificationMsg
      });
    },

    handlePushNotification(type) {
      let notification = {};

      switch (type) {
        case "success":
          notification = {
            type: "success",
            title: "Card has been successfully ordered",
            message: "Please wait for our specialist to contact you"
          };

          break;

        case "info":
          notification = {
            type: "info",
            title: "Thank you!",
            message:
              "We will revert to you within 3 business days with the status of your account."
          };

          break;

        case "warning":
          notification = {
            type: "warning",
            title: "Attention",
            message: "If you don't save, your changes will be lost",
            confirmLabel: "Save changes",
            cancelLabel: "Leave anyway",
            confirmAction: () => {
              // eslint-disable-next-line no-console
              console.log("confirm");
            },
            cancelAction: () => {
              // eslint-disable-next-line no-console
              console.log("cancel");
            }
          };

          break;

        case "danger":
          notification = {
            type: "danger",
            title: "Can't delete the card",
            message:
              "It is not possible to delete a card with an account balance"
          };

          break;

        default:
          return;
      }

      this.pushNotification(notification);
    }
  }
};
</script>
