<template>
  <div
    class="payout-modal-transaction-group-item"
    :class="{ 'is-expandend': !isCollapsed, 'has-error': data.rejected }"
    @click="isCollapsed = !isCollapsed"
  >
    <div class="payout-modal-transaction-group-item__primary">
      <div
        class="payout-modal-transaction-group-item__cell payout-modal-transaction-group-item__cell--conterparty"
        :class="{ 'has-error': !data.conterparty }"
      >
        {{ data.conterparty || "–– / –– / ––" }}
      </div>

      <div
        class="payout-modal-transaction-group-item__cell payout-modal-transaction-group-item__cell--details"
        :class="{ 'has-error': !data.details }"
        data-header="Details"
      >
        {{ data.details || "–– / –– / ––" }}
      </div>

      <div
        class="payout-modal-transaction-group-item__cell payout-modal-transaction-group-item__cell--swift"
        :class="{ 'has-error': !data.swift }"
        data-header="SWIFT"
      >
        {{ data.swift || "–– / –– / ––" }}
      </div>

      <div
        class="payout-modal-transaction-group-item__cell payout-modal-transaction-group-item__cell--amount"
        :class="{ 'has-error': !data.amount }"
        data-header="Amount"
      >
        {{ data.amount | moneyformat }}
      </div>
    </div>

    <transition name="fade">
      <div
        v-if="!isCollapsed"
        class="payout-modal-transaction-group-item__secondary"
      >
        <div
          class="payout-modal-transaction-group-item__secondary-col payout-modal-transaction-group-item__secondary-col--first"
        >
          <div
            v-if="data.info.address"
            class="payout-modal-transaction-group-item__secondary-item"
          >
            <div class="payout-modal-transaction-group-item__secondary-label">
              Couterparty address:
            </div>
            <div class="text-grey">
              {{ data.info.address }}
            </div>
          </div>

          <div
            v-if="data.info.countryCode"
            class="payout-modal-transaction-group-item__secondary-item"
          >
            <div class="payout-modal-transaction-group-item__secondary-label">
              Country code:
            </div>
            <div class="text-grey">
              {{ data.info.countryCode }}
            </div>
          </div>
        </div>

        <div
          class="payout-modal-transaction-group-item__secondary-col payout-modal-transaction-group-item__secondary-col--second"
        >
          <div
            v-if="data.info.bank"
            class="payout-modal-transaction-group-item__secondary-item"
          >
            <div class="payout-modal-transaction-group-item__secondary-label">
              Bank:
            </div>
            <div class="text-grey">
              {{ data.info.bank }}
            </div>
          </div>

          <div
            v-if="data.info.bankCountry"
            class="payout-modal-transaction-group-item__secondary-item"
          >
            <div class="payout-modal-transaction-group-item__secondary-label">
              County of the bank:
            </div>
            <div class="text-grey">
              {{ data.info.bankCountry }}
            </div>
          </div>
        </div>

        <div
          class="payout-modal-transaction-group-item__secondary-col payout-modal-transaction-group-item__secondary-col--third"
        >
          <div
            v-if="data.info.account"
            class="payout-modal-transaction-group-item__secondary-item"
          >
            <div class="payout-modal-transaction-group-item__secondary-label">
              Account number:
            </div>
            <div class="text-grey">
              {{ data.info.account }}
            </div>
          </div>

          <div
            v-if="data.info.corrAccount"
            class="payout-modal-transaction-group-item__secondary-item"
          >
            <div class="payout-modal-transaction-group-item__secondary-label">
              Сorrespondent account:
            </div>
            <div class="text-grey">
              {{ data.info.corrAccount }}
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "PaymentModalTransactionGroupItem",

  props: {
    data: {
      type: Object,
      required: false,
      default: null
    }
  },

  data() {
    return {
      isCollapsed: true
    };
  }
};
</script>

<style scoped lang="scss">
.payout-modal-transaction-group-item {
  position: relative;
  border-top: 1px solid $grey-light-01;
  transition: background-color 0.1s linear;
  cursor: pointer;

  &.is-expandend {
    background-color: $grey-light-02;
  }

  &.has-error {
    background-color: rgba($red, 0.1);
  }

  &.has-error:before {
    content: "\e93f";
    @include inline-icon;
    position: absolute;
    right: 16px;
    top: 14px;
    font-size: 16px;
    color: $red;
  }

  &__primary {
    padding: 12px 16px;
    font-size: 14px;
    line-height: 18px;
  }

  &__cell {
    padding: 4px 0;
  }

  &__cell--conterparty {
    font-family: $font-semibold;
  }

  &__cell.has-error {
    color: $red;
  }

  &__secondary {
    padding: 0 16px 16px 16px;
    font-size: 12px;
    line-height: 16px;
  }

  &__secondary-item + &__secondary-item {
    margin-top: 12px;
  }

  &__secondary-label {
    margin-bottom: 2px;
  }

  @media (hover: hover) {
    &:hover {
      background-color: rgba($grey-light-02, 0.5);
    }

    &.is-expandend:hover {
      background-color: $grey-light-02;
    }

    &.has-error:hover {
      background-color: rgba($red, 0.2);
    }
  }

  @media screen and (max-width: $screen-sm-max) {
    &__cell {
      display: flex;
      text-align: right;
    }

    &__cell:before {
      content: attr(data-header);
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: $accent-dark;
      flex: 1;
      text-align: left;
      margin-right: 8px;
    }

    &__cell--conterparty {
      text-align: left;
      margin-bottom: 8px;
    }

    &__cell--conterparty:before {
      display: none;
    }

    &__secondary-col + &__secondary-col {
      margin-top: 12px;
    }
  }

  @media screen and (min-width: $screen-md-min) {
    &.has-error:before {
      left: 8px;
      right: auto;
      top: 14px;
      font-size: 14px;
    }

    &__primary {
      display: flex;
      padding: 12px 20px;
      font-size: 14px;
      line-height: 18px;
    }

    &__cell {
      padding: 0 8px;
    }

    &__cell--conterparty {
      width: 32%;
    }

    &__cell--details {
      width: 38%;
    }

    &__cell--swift {
      width: 15%;
    }

    &__cell--amount {
      width: 15%;
      text-align: right;
    }

    &__secondary {
      display: flex;
      padding: 12px 20px;
      font-size: 12px;
      line-height: 16px;
    }

    &__secondary-col {
      padding: 0 8px;
    }

    &__secondary-col--first {
      width: 32%;
    }

    &__secondary-col--second {
      width: 38%;
    }

    &__secondary-col--third {
      width: 30%;
    }

    &__secondary-item + &__secondary-item {
      margin-top: 8px;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
