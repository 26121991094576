<template>
  <AuthLayout>
    <template slot="backSlot">
      <ButtonLink
        icon="icon-arrow-left"
        label="Back"
        to="/login"
      />
    </template>

    <template slot="mobBackSlot">
      <ButtonLink
        icon="icon-arrow-left"
        label="Back"
        to="/login"
      />
    </template>

    <h1 class="h1-bold auth-title">
      Two-Factor Authentication
    </h1>

    <div class="mt-8 auth-text">
      <p class="text-grey">
        We've sent a code to the phone number ending in
        <span class="text-black">64</span>.
        <br>
        Add it below to finish logging in.
      </p>
    </div>

    <div class="mt-40">
      <FormInput
        id="code"
        name="code"
        type="text"
        label="Verification code"
        el-width="100%"
        :value="form.code"
        :error="form.codeError"
        @input="form.code = $event"
      />
    </div>

    <div class="mt-16 arrange">
      <ButtonLink
        btn-style="grey"
        btn-size="sm"
        label="Send again"
        :click-handler="resendHandler"
      />
    </div>

    <div class="mt-32">
      <Button
        label="Log in"
        btn-style="primary"
        btn-width="100%"
      />
    </div>
  </AuthLayout>
</template>

<script>
import AuthLayout from "@/components/Layout/AuthLayout";
import FormInput from "@/components/Form/FormInput";
import ButtonLink from "@/components/ButtonLink";
import Button from "@/components/Button";

export default {
  name: "LoginTwoFactor",

  components: {
    AuthLayout,
    FormInput,
    ButtonLink,
    Button
  },

  data() {
    return {
      form: {
        code: "",
        codeError: ""
      }
    };
  },

  methods: {
    resendHandler() {
      // eslint-disable-next-line no-console
      console.log("resend");
    }
  }
};
</script>
