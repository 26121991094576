<template>
  <div class="notification-msg-container">
    <transition-group
      name="list"
      tag="div"
    >
      <NotificationPushCard
        v-for="notification in pushNotifications"
        :key="notification.id"
        :notification="notification"
      />
    </transition-group>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import NotificationPushCard from "@/components/Notification/NotificationPushCard";

export default {
  name: "NotificationPushContainer",

  components: {
    NotificationPushCard
  },

  computed: {
    ...mapState(["pushNotifications"])
  },

  methods: {
    ...mapActions(["hideNotificationMsg"])
  }
};
</script>

<style scoped lang="scss">
.notification-msg-container {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 99;
  padding: 0 16px;
  overflow: auto;
  max-height: calc(100vh);
  max-width: 100vw;

  @media screen and (min-width: $screen-md-min) {
    padding: 0 30px;
    top: 0;
    bottom: auto;
  }
}
</style>

<style lang="scss">
.list-item {
  display: inline-block;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.3s;
}

.list-enter,
.list-leave-to {
  opacity: 0;
  transform: scale(0.9) translateX(30px);
}
</style>
