<template>
  <ModalWrapper
    size="md"
    modal-style="info"
  >
    <template slot="title">
      Revoke
    </template>

    <div class="text-center text-sb mb-8">
      Revoke all permissions?
    </div>

    <div class="text-center mb-16">
      This action will cancel your previous consent to Swedbank Lithuania for providing access rights to your account(s).
    </div>

    <template slot="footer">
      <div class="w-100 arrange arrange--center">
        <div class="modal-btn">
          <Button
            label="Ok"
            btn-style="primary"
            btn-width="110px"
            :click-handler="handleRevoke"
          />
        </div>

        <div class="modal-btn">
          <Button
            label="Cancel"
            btn-style="dark-opacity"
            btn-width="110px"
            :click-handler="handleClose"
          />
        </div>
      </div>
    </template>
  </ModalWrapper>
</template>

<script>
import { mapActions } from "vuex";
import ModalWrapper from "@/components/Modal/ModalWrapper";
import Button from "@/components/Button";

export default {
  name: "NewRequestModal",

  components: {
    ModalWrapper,
    Button
  },

  methods: {
    ...mapActions(["hideModal", "showNotification"]),

    handleClose() {
      this.hideModal();
    },

    handleRevoke() {
      this.hideModal();
      this.showNotification({
        type: "success",
        message: "Revoke successful"
      });
    }
  }
};
</script>

<style scoped lang="scss">
.modal-btn {
  & + & {
    margin-left: 16px;
  }

  @media screen and (min-width: $screen-md-min) {
    & + & {
      margin-left: 24px;
    }
  }
}
</style>
