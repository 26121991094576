<template>
  <ModalWrapper size="lg">
    <template slot="title">
      New Message
    </template>

    <template slot="toolbox">
      <ButtonCircle
        icon="icon-attach"
        btn-style="light"
        title="Attach"
        :click-handler="handleUpload"
      />
      <ButtonCircle
        icon="icon-save"
        btn-style="light"
        title="Save"
      />
    </template>

    <ModalCallout>
      <div class="new-message__header-row">
        <label class="new-message__header-label">
          Client:
        </label>

        <div class="new-message__select-wrapper">
          <FormSelect
            id="client"
            name="client"
            el-style="plain"
            :value="client"
            :options="clients"
            @change="client = $event"
          />
        </div>
      </div>

      <div class="new-message__header-row">
        <label class="new-message__header-label">
          Subject:
        </label>

        <input
          v-model="subject"
          class="new-message__header-input"
          type="text"
        >
      </div>
    </ModalCallout>

    <textarea
      id="message"
      v-model="message"
      name="message"
      placeholder="Say something"
      class="new-message__message"
    />

    <input
      id="files"
      ref="files"
      name="files"
      type="file"
      multiple
      class="new-message__attachment-input"
      @change="handleFileChange"
    >

    <div
      v-if="files.length"
      class="new-message__attachments"
    >
      <div class="new-message__attachments-label">
        <i class="icon icon-attach" /> Attachment
      </div>

      <div class="new-message__attachments-list">
        <div
          v-for="item in files"
          :key="item.uid"
          class="new-message__attachments-item"
        >
          <div class="new-message__attachments-item-name">
            {{ item.file.name }}
          </div>
          <div class="new-message__attachments-item-size">
            {{ fileSize(item.file.size) }}
          </div>
          <button
            class="new-message__attachments-item-remove"
            @click="e => removeFile(e, item.uid)"
          >
            Remove
          </button>
        </div>
      </div>
    </div>

    <template slot="footer">
      <ModalActionWrapper>
        <Button
          label="Send"
          btn-style="primary"
          :click-handler="handleSubmit"
        />
      </ModalActionWrapper>
    </template>
  </ModalWrapper>
</template>

<script>
import { mapActions } from "vuex";

import ModalWrapper from "@/components/Modal/ModalWrapper";
import ModalCallout from "@/components/Modal/ModalCallout";
import ModalActionWrapper from "@/components/Modal/ModalActionWrapper";
import ButtonCircle from "@/components/ButtonCircle";
import Button from "@/components/Button";
import FormSelect from "@/components/Form/FormSelect";

export default {
  name: "NewMessageModal",

  components: {
    ModalWrapper,
    ModalCallout,
    ModalActionWrapper,
    ButtonCircle,
    Button,
    FormSelect
  },

  data() {
    return {
      client: "ABC Company LTD",
      subject: "",
      message: "",
      clients: [
        "ABC Company LTD",
        "Transport Counsulting",
        "John Doe",
        "Michael Jordan"
      ],
      files: []
    };
  },

  methods: {
    ...mapActions(["hideModal"]),

    handleSubmit() {
      this.hideModal();
    },

    handleUpload() {
      this.$refs.files.click();
    },

    handleFileChange(e) {
      e.preventDefault();

      const files = e.target.files;

      for (var i = 0; i < files.length; i++) {
        const uid =
          "_" +
          Math.random()
            .toString(36)
            .substr(2, 9);

        const newFile = {
          uid: uid,
          file: files[i]
        };

        this.files.push(newFile);
      }
    },

    removeFile(e, id) {
      e.stopPropagation();
      this.files = this.files.filter(file => file.uid !== id);
    },

    fileSize(bytes) {
      if (bytes == 0) return "0 KB";

      let k = 1024;
      let sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      let i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(0)) + " " + sizes[i];
    }
  }
};
</script>

<style scoped lang="scss">
.new-message {
  &__header-row {
    display: flex;
    align-items: center;
    padding: 16px 0;
  }

  &__header-row + &__header-row {
    border-top: 1px solid $grey-normal;
  }

  &__header-label {
    font-size: 14px;
    line-height: 18px;
    color: $grey-dark-01;
    margin-right: 4px;
  }

  &__select-wrapper {
    flex: 1;
  }

  /deep/ &__select-wrapper .form-select__dropdown-container {
    margin-left: -8px;
  }

  &__header-input {
    min-width: 1%;
    flex: 1;
  }

  &__message {
    padding: 16px 0;
    font-size: 16px;
    line-height: 20px;
    flex: 1;
    width: 100%;
    min-height: 240px;
  }

  &__message::-webkit-input-placeholder {
    color: $grey-dark-01;
  }

  &__message:-moz-placeholder {
    color: $grey-dark-01;
  }

  &__message::-moz-placeholder {
    color: $grey-dark-01;
  }

  &__message:-ms-input-placeholder {
    color: $grey-dark-01;
  }

  &__message::placeholder {
    color: $grey-dark-01;
  }

  &__attachment-input {
    display: none;
  }

  &__attachments-label {
    color: $grey-dark-01;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 8px;
  }

  &__attachments-list {
    display: flex;
    flex-wrap: wrap;
    margin: -8px;
  }

  &__attachments-item {
    position: relative;
    margin: 8px;
    background-color: $grey-light-02;
    border-radius: 4px;
    padding: 8px 56px 8px 16px;
    transition: color 0.1s linear;
  }

  &__attachments-item-name {
    color: $additional-brown;
    font-size: 16px;
    line-height: 20px;
    word-break: break-all;
  }

  &__attachments-item-size {
    margin-top: 2px;
    font-size: 12px;
    line-height: 16px;
    color: $grey-dark-01;
  }

  &__attachments-item-remove {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    font-size: 0;
    color: $grey-dark-01;
  }

  &__attachments-item-remove:before {
    content: "\e934";
    @include inline-icon;
    font-size: 16px;
  }

  @media (hover: hover) {
    &__attachments-item-remove:hover {
      color: $accent-normal;
    }
  }

  @media screen and (min-width: $screen-md-min) {
    &__header-label {
      font-size: 16px;
      line-height: 20px;
    }

    &__message {
      padding: 24px 0;
      min-height: 340px;
    }
  }
}
</style>
