<template>
  <DashboardLayout>
    <DashboardMainScreen>
      <template slot="cards">
        <div class="dashboard-layout__item">
          <CardOffer />
        </div>
      </template>

      <template slot="accounts">
        <div class="dashboard-layout__item">
          <Button
            label="Add account"
            btn-style="dark-opacity"
            :click-handler="showAddAccountPopup"
            class="btn-layout-more"
          />
        </div>
      </template>

      <template slot="banner">
        <SidebarBanner :slides="slides" />
      </template>

      <template slot="transactions">
        <SidebarTransactions />
      </template>
    </DashboardMainScreen>
  </DashboardLayout>
</template>

<script>
import { mapActions } from "vuex";

import DashboardLayout from "@/components/Layout/DashboardLayout";
import DashboardMainScreen from "@/components/Dashboard/DashboardMainScreen";
import CardOffer from "@/components/Blocks/CardOffer";
import Button from "@/components/Button";
import SidebarBanner from "@/components/Blocks/SidebarBanner";
import SidebarTransactions from "@/components/Blocks/SidebarTransactions";

export default {
  name: "Dashboard",

  components: {
    DashboardLayout,
    DashboardMainScreen,
    CardOffer,
    Button,
    SidebarBanner,
    SidebarTransactions
  },

  data() {
    return {
      slides: [
        {
          style: "white",
          color: "#ffffff",
          title: "New account for start up",
          subtitle: "Offer matched to everyone",
          link: "/",
          linkLabel: "Touch me",
          img: "banner-1.svg"
        },
        {
          style: "white",
          background: "#ffffff",
          title: "New account for start up",
          subtitle: "Offer matched to everyone",
          link: "/",
          linkLabel: "Touch me",
          img: "banner-2.svg"
        },
        {
          style: "white",
          color: "#ffffff",
          title: "New account for start up",
          subtitle: "Offer matched to everyone",
          link: "/",
          linkLabel: "Touch me",
          img: "banner-1.svg"
        },
        {
          style: "white",
          background: "#ffffff",
          title: "New account for start up",
          subtitle: "Offer matched to everyone",
          link: "/",
          linkLabel: "Touch me",
          img: "banner-2.svg"
        }
      ]
    };
  },

  methods: {
    ...mapActions(["showModal"]),

    showAddAccountPopup() {
      this.showModal({
        component: "AddAccountModal"
      });
    }
  }
};
</script>
