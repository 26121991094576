<template>
  <div class="access-table">
    <div class="access-table-header">
      <div class="access-table-header__title">
        External Access to Accounts
      </div>
    </div>

    <div class="access-table-head">
      <div class="access-table-head__cell access-table-head__cell--name">
        Name
      </div>

      <div
        class="access-table-head__cell access-table-head__cell--validity is-sortable"
        :class="{ 'is-sorted': sortBy === 'validUntil' }"
        @click="handleSort('validUntil')"
      >
        Valid until
      </div>

      <div class="access-table-head__cell access-table-head__cell--action">
        Actions
      </div>
    </div>

    <div class="access-table-body">
      <div
        v-for="(access, i) in accesses"
        :key="i"
        class="access-table-item"
      >
        <div class="access-table-item__name">
          <span @click="handleAccessModal">{{ access.name }}</span>
        </div>

        <div class="access-table-item__validity">
          {{ access.validity }}
        </div>

        <div class="access-table-item__action" @click="handleRevokeModal">
          Revoke
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import { messages } from "@/fixtures";

export default {
  name: "ExternalAccessTable",

  data() {
    return {
      sortBy: null,

      accesses: [
        {
          name: 'Sweden Bank Lithuania',
          validity: '01/10/2020',
        },
        {
          name: 'Deutsche Bank',
          validity: '14/02/2020',
        },
        {
          name: 'Raiffeisen Bank',
          validity: '28/11/2019',
        },
      ]
    };
  },

  created() {
    this.messages = messages;
  },

  methods: {
    ...mapActions(["showModal"]),

    handleSort(target) {
      this.sortBy = this.sortBy !== target ? target : null;
    },

    handleAccessModal() {
      this.showModal({
        component: "ExternalAccessModal"
      });
    },

    handleRevokeModal() {
      this.showModal({
        component: "RevokeRightsModal"
      });
    }
  }
};
</script>

<style scoped lang="scss">
.access-table {
  @media screen and (min-width: $screen-md-min) {
    background-color: $white;
    border-radius: 8px;
  }
}

.access-table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__title {
    font-family: $font-bold;
    font-size: 20px;
    line-height: 24px;
  }

  @media screen and (max-width: $screen-sm-max) {
    display: none;
  }

  @media screen and (min-width: $screen-md-min) {
    padding: 20px 24px;
  }
}

.access-table-head {
  display: flex;
  background-color: $grey-light-01;
  padding: 16px 32px;

  &__cell {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: rgba($grey-dark, 0.6);
  }

  &__cell.is-sortable {
    cursor: pointer;
  }

  &__cell.is-sortable:after {
    content: "\e92f";
    @include inline-icon;
    color: $grey-dark-02;
    font-size: 16px;
    vertical-align: text-bottom;
  }

  &__cell.is-sorted:after {
    content: "\e930";
  }

  &__cell--name {
    flex: 1;
  }

  &__cell--validity {
    width: 254px;
    margin-right: 16px;
  }

  &__cell--action {
    width: 66px;
  }

  @media screen and (max-width: $screen-sm-max) {
    display: none;
  }
}

.access-table-body {
  @media screen and (max-width: $screen-sm-max) {
    background-color: $white;
    border-radius: 8px;
  }
}

.access-table-item {
  position: relative;
  padding: 16px;
  transition: background-color 0.1s linear;

  & + & {
    border-top: 1px solid $grey-light-01;
  }

  &__name span {
    font-family: $font-semibold;
  }

  &__name span,
  &__action {
    cursor: pointer;
    color: $additional-brown;
    transition: color 0.1s linear;
  }

  @media (hover: hover) {
    &:hover {
      background-color: rgba($grey-light-02, 0.5);
    }

    &__name span:hover,
    &__action:hover {
      color: $accent-normal;
    }
  }

  @media screen and (max-width: $screen-sm-max) {
    &__name {
      display: flex;
      max-width: 50%;
      font-size: 14px;
      line-height: 18px;
    }

    &__validity {
      font-size: 14px;
      line-height: 18px;
    }

    &__action {
      position: absolute;
      top: 0;
      right: 0;
      text-align: right;
      font-size: 14px;
      line-height: 18px;
      padding: 16px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  @media screen and (min-width: $screen-md-min) {
    display: flex;
    padding: 16px 32px;

    &__name {
      position: relative;
      flex: 1;
      font-size: 16px;
      line-height: 20px;
    }

    &__validity {
      width: 254px;
      margin-right: 16px;
    }

    &__action {
      width: 66px;
      font-size: 16px;
      line-height: 20px;
    }
  }
}
</style>
