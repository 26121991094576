<template>
  <OnboardingBusinessLayout
    :current-step="currentStep"
    :side-msg="sideMsg"
  >
    <Header>
      <template slot="title">
        Company profile
      </template>
    </Header>

    <div class="mt-40">
      <FormInput
        id="name"
        name="name"
        type="text"
        :value="form.name"
        label="Company name"
        :has-error="form.nameError"
        @input="form.name = $event"
      />
    </div>

    <div class="mt-lg-40 mt-sm-32">
      <FormSelect
        id="jurisdiction"
        name="jurisdiction"
        label="Jurisdiction"
        el-width="100%"
        el-style="input"
        :value="form.jurisdiction"
        :options="[
          { value: 'lt', title: 'Lithuania', flag: 'lt' },
          { value: 'lv', title: 'Latvia', flag: 'lv' },
          { value: 'ee', title: 'Estonia', flag: 'ee' }
        ]"
        @change="form.jurisdiction = $event"
      />
    </div>

    <div class="mt-lg-40 mt-sm-32">
      <FormInput
        id="reg-number"
        name="reg-number"
        type="number"
        :value="form.regNumber"
        label="Company reg. number"
        :has-error="form.regNumberError"
        @input="form.regNumber = $event"
      />
    </div>

    <div class="mt-lg-40 mt-sm-32">
      <FormInput
        id="website"
        name="website"
        type="list"
        :value="form.website"
        label="Web site"
        placeholder="http://site.com/"
        :has-error="form.websiteError"
        @input="form.website = $event"
      />
    </div>

    <div class="mt-lg-20 mt-sm-12">
      <div class="text-grey text-sm mb-4">
        Is your business address same as legal?
      </div>

      <div
        class="arrange"
        role="radiogroup"
      >
        <FormRadioBox
          id="radio_1"
          name="radio-1"
          :label="true"
          el-width="50%"
          @change="form.selectedRadio = $event"
        >
          <template slot="title">
            Yes
          </template>
        </FormRadioBox>

        <FormRadioBox
          id="radio_2"
          name="radio-1"
          :label="false"
          el-width="50%"
          class="ml-lg-24 ml-sm-16"
          @change="form.selectedRadio = $event"
        >
          <template slot="title">
            No
          </template>
        </FormRadioBox>
      </div>
    </div>

    <div
      v-if="!form.selectedRadio"
      class="mt-lg-40 mt-sm-32"
    >
      <div class="mt-lg-40 mt-sm-32">
        <FormInput
          id="address"
          name="address"
          type="text"
          :value="form.address"
          label="Address"
          :has-error="form.addressError"
          @input="form.address = $event"
        />
      </div>

      <div class="arrange mt-lg-40 mt-sm-32">
        <FormInput
          id="city"
          name="city"
          type="text"
          :value="form.city"
          label="City"
          :has-error="form.cityError"
          el-width="65%"
          @input="form.city = $event"
        />

        <FormInput
          id="zip"
          name="zip"
          type="text"
          :value="form.zip"
          label="Zip Code"
          :has-error="form.zipError"
          el-width="35%"
          placeholder="00 - 0000"
          mask="LV - ####"
          class="ml-lg-24 ml-sm-16"
          @input="form.zip = $event"
        />
      </div>

      <div class="arrange mt-lg-40 mt-sm-32">
        <FormSelect
          id="country"
          name="country"
          label="Country"
          el-width="65%"
          el-style="input"
          :value="form.country"
          :options="[
            { value: 'lt', title: 'Lithuania', flag: 'lt' },
            { value: 'lv', title: 'Latvia', flag: 'lv' },
            { value: 'ee', title: 'Estonia', flag: 'ee' }
          ]"
          @change="form.country = $event"
        />

        <FormInput
          id="state"
          name="state"
          type="text"
          :value="form.state"
          label="State"
          :has-error="form.stateError"
          el-width="35%"
          class="ml-lg-24 ml-sm-16"
          @input="form.state = $event"
        />
      </div>
    </div>

    <div class="arrange mt-lg-32 mt-sm-24">
      <Button
        icon="icon-back-mobile"
        label="Return"
        btn-style="transparent"
        btn-width="92px"
      />

      <Button
        label="Continue"
        btn-style="primary"
        class="w-100 ml-24"
      />
    </div>
  </OnboardingBusinessLayout>
</template>

<script>
import OnboardingBusinessLayout from "@/components/Layout/OnboardingBusinessLayout";
import Header from "@/components/Onboarding/Header";
import FormInput from "@/components/Form/FormInput";
import Button from "@/components/Button";
import FormRadioBox from "@/components/Form/FormRadioBox";
import FormSelect from "@/components/Form/FormSelect";

export default {
  components: {
    OnboardingBusinessLayout,
    Header,
    FormInput,
    Button,
    FormRadioBox,
    FormSelect
  },

  data() {
    return {
      currentStep: 6,

      sideMsg:
        "Some short tooltip. Why do we need to know about this info about user",

      form: {
        name: "",
        nameError: "",
        jurisdiction: "lv",
        regNumber: "",
        regNumberError: "",
        website: "",
        websiteError: "",
        selectedRadio: "",
        address: "",
        addressError: "",
        city: "",
        cityError: "",
        zip: "",
        zipError: "",
        country: "lv",
        state: "",
        stateError: "",
      }
    };
  }
};
</script>
