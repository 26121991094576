<template>
  <div
    class="savings-header"
    :style="{
      backgroundImage: 'url(' + require('@/assets/images/piggy_bank.svg') + ')'
    }"
  >
    <div class="savings-header__title">
      Savings account
    </div>
    <div class="h1 mt-8">
      {{ total | moneyformat }} {{ currency }}
    </div>
    <div class="savings-header__label">
      Total amount
    </div>

    <div class="savings-header__action">
      <Button
        label="Distribute funds"
        btn-style="dark"
        btn-width="190px"
        :click-handler="handleDistribute"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import Button from "@/components/Button";

export default {
  name: "SavingsHeader",

  components: {
    Button
  },

  data() {
    return {
      total: 3755.95,
      currency: "EUR"
    };
  },

  methods: {
    ...mapActions(["showModal"]),

    handleDistribute() {
      this.showModal({
        component: "DistributeFundsModal"
      });
    }
  }
};
</script>

<style scoped lang="scss">
.savings-header {
  width: 100%;
  border-radius: 8px;
  background-color: $additional-normal;
  color: $additional-brown;
  padding: 16px;
  background-size: 0;

  &__title {
    font-family: $font-semibold;
    font-size: 16px;
    line-height: 24px;
  }

  &__label {
    font-size: 14px;
    line-height: 18px;
    opacity: 0.5;
  }

  &__action {
    margin-top: 20px;
  }

  @media screen and (max-width: $screen-sm-max) {
    &__action .btn {
      width: 100%;
    }
  }

  @media screen and (min-width: $screen-md-min) {
    background-position: 98% top;
    background-repeat: no-repeat;
    background-size: 240px 240px;
    padding: 24px;

    &__title {
      font-family: $font-bold;
      font-size: 20px;
      line-height: 24px;
    }

    &__label {
      font-size: 16px;
      line-height: 20px;
    }
  }
}
</style>
