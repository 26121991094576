var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DashboardLayout',[_c('div',{staticStyle:{"height":"120vh"}},[_c('Button',{attrs:{"label":"Show Success notification line","btn-style":"primary","click-handler":function () {
          _vm.handleShowNotification('success');
        }}}),_c('br'),_c('br'),_c('Button',{attrs:{"label":"Show Info notification line","btn-style":"primary","click-handler":function () {
          _vm.handleShowNotification('info');
        }}}),_c('br'),_c('br'),_c('Button',{attrs:{"label":"Show Warning notification line","btn-style":"primary","click-handler":function () {
          _vm.handleShowNotification('warning');
        }}}),_c('br'),_c('br'),_c('Button',{attrs:{"label":"Show Danger notification line","btn-style":"primary","click-handler":function () {
          _vm.handleShowNotification('danger');
        }}}),_c('br'),_c('br'),_c('Button',{attrs:{"label":"Hide notification line","btn-style":"primary","click-handler":_vm.hideNotification}}),_c('hr'),_c('Button',{attrs:{"label":"Show Success push-notification","btn-style":"dark-opacity","click-handler":function () {
          _vm.handlePushNotification('success');
        }}}),_c('br'),_c('br'),_c('Button',{attrs:{"label":"Show Info push-notification","btn-style":"dark-opacity","click-handler":function () {
          _vm.handlePushNotification('info');
        }}}),_c('br'),_c('br'),_c('Button',{attrs:{"label":"Show Warning push-notification","btn-style":"dark-opacity","click-handler":function () {
          _vm.handlePushNotification('warning');
        }}}),_c('br'),_c('br'),_c('Button',{attrs:{"label":"Show Danger push-notification","btn-style":"dark-opacity","click-handler":function () {
          _vm.handlePushNotification('danger');
        }}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }