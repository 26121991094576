<template>
  <OnboardingBusinessLayout
    :current-step="currentStep"
    :side-msg="sideMsg"
  >
    <Header>
      <template slot="title">
        Contact information
      </template>
    </Header>

    <div class="mt-lg-24 mt-sm-16">
      <h4 class="text-sb">
        Personal info
      </h4>

      <div class="mt-32 arrange arrange--wrap arrange--align-center">
        <FormDatepicker
          id="birthsDate"
          name="births-date"
          placeholder="MM/DD/YYYY"
          label="Date of birth"
          el-width="154px"
          el-style="input"
          :value="form.birthsDate"
          :has-error="form.birthsDateError"
          class="mr-24"
          input-format="DD/MM/YYYY"
          @change="form.birthsDate = $event"
        />

        <PersonalInfo>
          <div class="text-sm">
            <span class="text-grey">Name:</span> John Doe
          </div>
          <div class="text-sm mt-4">
            <span class="text-grey">Phone:</span> +331 632 435 42 12
          </div>
        </PersonalInfo>
      </div>
    </div>

    <div class="mt-lg-24 mt-sm-16">
      <h4 class="text-sb">
        Residential Address
      </h4>

      <div class="mt-32">
        <FormInput
          id="address"
          name="address"
          type="text"
          :value="form.address"
          label="Address"
          :has-error="form.addressError"
          @input="form.address = $event"
        />
      </div>

      <div class="mt-lg-40 mt-sm-32">
        <FormInput
          id="city"
          name="city"
          type="text"
          :value="form.city"
          label="City"
          :has-error="form.cityError"
          @input="form.city = $event"
        />
      </div>

      <div class="arrange mt-lg-40 mt-sm-32">
        <FormInput
          id="zip"
          name="zip"
          type="text"
          :value="form.zip"
          label="Zip Code"
          :has-error="form.zipError"
          el-width="35%"
          placeholder="00 - 0000"
          mask="LV - ####"
          @input="form.zip = $event"
        />

        <FormInput
          id="state"
          name="state"
          type="text"
          :value="form.state"
          label="State / County"
          :has-error="form.stateError"
          el-width="65%"
          class="ml-lg-24 ml-sm-16"
          @input="form.state = $event"
        />
      </div>

      <div class="mt-lg-40 mt-sm-32">
        <FormSelect
          id="country"
          name="country"
          label="Country of residence"
          el-style="input"
          :value="form.country"
          :options="[
            { value: 'lt', title: 'Lithuania', flag: 'lt' },
            { value: 'lv', title: 'Latvia', flag: 'lv' },
            { value: 'ee', title: 'Estonia', flag: 'ee' }
          ]"
          @change="form.country = $event"
        />
      </div>

      <div class="mt-lg-20 mt-sm-12">
        <div class="text-grey text-sm mb-4">
          Are you resident or citizen of the United States of America?
        </div>

        <div
          class="arrange"
          role="radiogroup"
        >
          <FormRadioBox
            id="radio_1"
            name="radio-1"
            label="1"
            el-width="50%"
            @change="radio_1 = $event"
          >
            <template slot="title">
              Yes
            </template>
          </FormRadioBox>

          <FormRadioBox
            id="radio_2"
            name="radio-1"
            label="1"
            el-width="50%"
            class="ml-lg-24 ml-sm-16"
            @change="radio_2 = $event"
          >
            <template slot="title">
              No
            </template>
          </FormRadioBox>
        </div>
      </div>

      <div class="mt-lg-32 mt-sm-24">
        <Button
          label="Continue"
          btn-style="primary"
          btn-width="100%"
        />
      </div>
    </div>
  </OnboardingBusinessLayout>
</template>

<script>
import OnboardingBusinessLayout from "@/components/Layout/OnboardingBusinessLayout";
import Header from "@/components/Onboarding/Header";
import FormDatepicker from "@/components/Form/FormDatepicker";
import FormInput from "@/components/Form/FormInput";
import FormSelect from "@/components/Form/FormSelect";
import Button from "@/components/Button";
import FormRadioBox from "@/components/Form/FormRadioBox";
import PersonalInfo from "@/components/Onboarding/PersonalInfo";

export default {
  components: {
    OnboardingBusinessLayout,
    Header,
    FormDatepicker,
    FormInput,
    FormSelect,
    Button,
    FormRadioBox,
    PersonalInfo
  },

  data() {
    return {
      currentStep: 4,

      sideMsg:
        "Some short tooltip. Why do we need to know about this info about user",

      form: {
        birthsDate: null,
        birthsDateError: "",
        address: "",
        addressError: "",
        city: "",
        cityError: "",
        zip: "",
        zipError: "",
        country: "lv",
        countryError: "",
        state: "",
        stateError: "",
        usResident: false
      }
    };
  }
};
</script>
