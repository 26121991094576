<template>
  <OnboardingBusinessLayout
    :current-step="currentStep"
    :side-msg="sideMsg"
  >
    <Header>
      <template slot="title">
        Attach utility bill
      </template>

      <template slot="intro">
        Upload your utility bill to confirm your residence
      </template>
    </Header>

    <div class="mt-lg-32 mt-sm-24">
      <Dropzone
        id="state"
        name="state"
        :has-error="form.fileError"
        @fileChange="form.files = $event"
      />
    </div>

    <div class="arrange mt-lg-32 mt-sm-24">
      <Button
        icon="icon-back-mobile"
        label="Return"
        btn-style="transparent"
        btn-width="92px"
      />

      <Button
        label="Continue"
        btn-style="primary"
        class="w-100 ml-24"
        :disabled="!form.files || form.fileError ? true : false"
      />
    </div>
  </OnboardingBusinessLayout>
</template>

<script>
import OnboardingBusinessLayout from "@/components/Layout/OnboardingBusinessLayout";
import Header from "@/components/Onboarding/Header";
import Dropzone from "@/components/Onboarding/Dropzone";
import Button from "@/components/Button";

export default {
  components: {
    OnboardingBusinessLayout,
    Header,
    Dropzone,
    Button
  },

  data() {
    return {
      currentStep: 5,

      sideMsg:
        "We need your utility bill to confirm your residence. All your privacy data will be stored safely.",

      form: {
        files: null,
        fileError: ""
      }
    };
  }
};
</script>
