<template>
  <component
    :is="type"
    :to="to"
    :class="`btn-link btn-link-${btnStyle} btn-link-${btnSize}`"
    @click="clickHandler"
  >
    <i
      v-if="icon"
      :class="`icon ${icon}`"
    />
    <span>{{ label }}</span>
  </component>
</template>

<script>
export default {
  name: "ButtonLink",

  props: {
    icon: { type: String, required: false, default: "" },
    label: { type: String, required: false, default: "" },
    btnSize: { type: String, required: false, default: "md" },
    btnStyle: { type: String, required: false, default: "dark" },
    to: { type: String, required: false, default: "" },
    clickHandler: { type: Function, required: false, default: () => {} }
  },

  computed: {
    type() {
      if (this.to) {
        return "router-link";
      }

      return "button";
    }
  }
};
</script>

<style scoped lang="scss">
.btn-link {
  display: inline-flex;
  align-items: center;
  transition: color 0.1s linear, opacity 0.1s linear;

  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &-sm {
    font-family: $font-regular;
    font-size: 14px;
    line-height: 1.29;
  }

  &-sm .icon {
    font-size: 16px;
    margin-right: 2px;
  }

  &-md {
    font-family: $font-semibold;
    font-size: 16px;
    line-height: 1.5;
  }

  &-md .icon {
    font-size: 22px;
    margin-right: 8px;
  }
}

.btn-link-light {
  color: $white;

  @media (hover: hover) {
    &:hover {
      color: $accent-light;
    }
  }

  &:disabled {
    opacity: 0.5;
  }
}

.btn-link-dark {
  color: $additional-brown;

  @media (hover: hover) {
    &:hover {
      color: $accent-light;
    }
  }

  &:disabled {
    opacity: 0.5;
  }
}

.btn-link-grey {
  color: $grey-dark-01;

  @media (hover: hover) {
    &:hover {
      color: $accent-light;
    }
  }

  &:disabled {
    opacity: 0.5;
  }
}

.btn-link-red {
  color: $red;

  @media (hover: hover) {
    &:hover {
      opacity: 0.8;
    }
  }

  &:disabled {
    opacity: 0.5;
  }
}

.btn-link-underline {
  color: $grey-dark-01;
  text-decoration: underline;

  @media (hover: hover) {
    &:hover {
      color: $accent-normal;
    }
  }

  &:disabled {
    opacity: 0.5;
  }
}
</style>
