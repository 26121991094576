<template>
  <div class="modal-callout">
    <slot />
  </div>
</template>

<script>
export default {
  name: "ModalCallout"
};
</script>

<style scoped lang="scss">
.modal-callout {
  background-color: $grey-light-02;
  padding: 0 16px;
  margin: 0 -16px;

  @media screen and (min-width: $screen-md-min) {
    padding: 0 24px;
    margin: 0 -24px;
  }
}
</style>
