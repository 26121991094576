var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('OnboardingBusinessLayout',{attrs:{"current-step":_vm.currentStep,"side-msg":_vm.sideMsg}},[_c('Header',[_c('template',{slot:"title"},[_vm._v(" Hello, please sign up ")])],2),_c('div',{staticClass:"mt-40"},[_c('FormInput',{attrs:{"id":"company","name":"company","type":"text","value":_vm.form.companyName,"label":"Company name","has-error":_vm.form.companyNameError},on:{"input":function($event){_vm.form.companyName = $event}}})],1),_c('div',{staticClass:"mt-lg-40 mt-sm-32"},[_c('FormSelect',{attrs:{"id":"country","name":"country","label":"Registration country","el-width":"100%","el-style":"input","value":_vm.form.country,"options":[
        { value: 'lt', title: 'Lithuania', flag: 'lt' },
        { value: 'lv', title: 'Latvia', flag: 'lv' },
        { value: 'ee', title: 'Estonia', flag: 'ee' }
      ]},on:{"change":function($event){_vm.form.country = $event}}})],1),_c('div',{staticClass:"mt-lg-40 mt-sm-32"},[_c('FormInput',{attrs:{"id":"name","name":"name","type":"text","value":_vm.form.name,"label":"Name","has-error":_vm.form.nameError},on:{"input":function($event){_vm.form.name = $event}}})],1),_c('div',{staticClass:"mt-lg-40 mt-sm-32"},[_c('FormInput',{attrs:{"id":"surname","name":"surname","type":"text","value":_vm.form.surname,"label":"Surname","has-error":_vm.form.surnameError},on:{"input":function($event){_vm.form.surname = $event}}})],1),_c('div',{staticClass:"arrange mt-lg-40 mt-sm-32"},[_c('div',[_c('FormSelect',{attrs:{"id":"country","name":"country","label":"Country","el-width":"130px","el-style":"input","value":_vm.form.countryCode,"options":[
          { value: '+370', title: '+370', country: 'Lithuania', flag: 'lt' },
          { value: '+371', title: '+371', country: 'Latvia', flag: 'lv' },
          { value: '+372', title: '+372', country: 'Estonia', flag: 'ee' }
        ],"phone-code":""},on:{"change":function($event){_vm.form.countryCode = $event}}})],1),_c('FormInput',{staticClass:"ml-16",attrs:{"id":"phone","name":"phone","type":"tel","value":_vm.form.phone,"label":"Phone number","has-error":_vm.form.phoneError,"placeholder":"000 000 00 00","mask":"### ### ## ##"},on:{"input":function($event){_vm.form.phone = $event}}})],1),_c('div',{staticClass:"mt-lg-40 mt-sm-32"},[_c('FormInput',{attrs:{"id":"email","name":"email","type":"email","value":_vm.form.email,"label":"Email","has-error":_vm.form.emailError},on:{"input":function($event){_vm.form.email = $event}}})],1),_c('div',{staticClass:"mt-lg-32 mt-sm-24"},[_c('Button',{attrs:{"label":"Submit","btn-style":"primary","btn-width":"100%"}})],1),_c('div',{staticClass:"mt-16"},[_c('FormCheckbox',{attrs:{"id":"terms","name":"terms","value":_vm.form.terms,"has-error":_vm.form.termsError},on:{"change":function($event){_vm.form.terms = $event}}},[_c('span',{staticClass:"text-grey text-sm"},[_vm._v(" I confirm "),_c('a',{staticClass:"text-link",attrs:{"href":"#"}},[_vm._v("Terms & Conditions")])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }