<template>
  <div class="dashboard-layout">
    <NotificationBar />

    <NotificationPushContainer />

    <DashboardNav />

    <div class="dashboard-wrapper">
      <DashboardHeader />

      <DashboardHeaderMob />

      <main class="dashboard-main">
        <aside
          v-if="hasSidebar"
          class="dashboard-sidebar"
        >
          <slot name="sidebar" />
        </aside>

        <div class="dashboard-content">
          <slot />
        </div>
      </main>

      <DashboardFooter />
    </div>

    <ModalContainer />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { currentUser, availableUsers } from "@/fixtures";

import DashboardNav from "@/components/Dashboard/DashboardNav";
import DashboardHeader from "@/components/Dashboard/DashboardHeader";
import DashboardHeaderMob from "@/components/Dashboard/DashboardHeaderMob";
import DashboardFooter from "@/components/Dashboard/DashboardFooter";
import ModalContainer from "@/components/Modal/ModalContainer";
import NotificationBar from "@/components/Notification/NotificationBar";
import NotificationPushContainer from "@/components/Notification/NotificationPushContainer";

export default {
  name: "DashboardLayout",

  bodyClass: "_tpl-dashboard",

  components: {
    DashboardNav,
    DashboardHeader,
    DashboardHeaderMob,
    DashboardFooter,
    ModalContainer,
    NotificationBar,
    NotificationPushContainer
  },

  computed: {
    hasSidebar() {
      return !!this.$slots.sidebar;
    }
  },

  created() {
    const hasUser = this.$store.state.currentUser;

    if (!hasUser.id) this.setCurrentUser(currentUser);
  },

  methods: {
    ...mapActions(["setUser", "setAvailableUsers"]),

    setCurrentUser() {
      this.setUser(currentUser);

      this.setAvailableUsers(availableUsers);
    }
  }
};
</script>

<style scoped lang="scss">
.dashboard-layout {
  position: relative;
  background-color: $bg-color;
  min-height: 100vh;

  /deep/ &__item {
    margin-bottom: 16px;

    .btn:not(:hover):not(:focus):not(:active) {
      background-color: rgba($additional-brown, 0.04);
    }
  }

  @media screen and (min-width: $screen-md-min) {
    padding-left: 72px;

    /deep/ &__item {
      margin-bottom: 32px;
    }
  }

  @media screen and (min-width: $screen-lg-min) {
    padding-left: 88px;
  }

  @media screen and (min-width: $screen-xlg-min) {
    padding-left: 100px;
  }
}

.dashboard-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.dashboard-main {
  flex: 1;
  width: 100%;
  max-width: 1456px;
  margin: 0 auto;
  padding: 0 16px;

  @media screen and (min-width: $screen-xlg-min) {
    display: flex;
  }
}

.dashboard-sidebar {
  @media screen and (min-width: $screen-md-min) {
    padding: 0 16px;
  }

  @media screen and (min-width: $screen-xlg-min) {
    width: 328px;
    padding: 0 16px;
  }
}

.dashboard-content {
  @media screen and (min-width: $screen-md-min) {
    flex: 1;
    padding: 0 16px;
  }
}
</style>

<style lang="scss">
._tpl-dashboard {
  background-color: $bg-color;
}
</style>
