<template>
  <OnboardingBusinessLayout
    :current-step="currentStep"
    :side-msg="sideMsg"
  >
    <Header>
      <template slot="title">
        Business Information
      </template>
    </Header>

    <div class="mt-lg-24 mt-sm-16">
      <h4 class="text-sb">
        Monthly turnover
      </h4>

      <div class="arrange mt-lg-40 mt-sm-32">
        <FormInput
          id="turnover-incoming-amount"
          name="turnover-incoming-amount"
          type="number"
          :value="form.turnoverIn"
          label="Incoming"
          :has-error="form.turnoverInError"
          el-width="100%"
          @input="form.turnoverIn = $event"
        />

        <FormSelect
          id="turnover-incoming-currency"
          name="turnover-incoming-currency"
          el-min-width="130px"
          el-style="input"
          class="ml-8"
          :value="form.currencyIn"
          :options="form.currencies"
          @change="form.currencyIn = $event"
        />
      </div>

      <div class="arrange mt-lg-40 mt-sm-32">
        <FormInput
          id="turnover-outgoing-amount"
          name="turnover-outgoing-amount"
          type="number"
          :value="form.turnoverOut"
          label="Outgoing"
          :has-error="form.turnoverOutError"
          el-width="100%"
          @input="form.turnoverOut = $event"
        />

        <FormSelect
          id="turnover-outgoing-currency"
          name="turnover-outgoing-currency"
          el-min-width="130px"
          el-style="input"
          class="ml-8"
          :value="form.currencyOut"
          :options="form.currencies"
          @change="form.currencyOut = $event"
        />
      </div>
    </div>

    <div class="mt-lg-24 mt-sm-16">
      <h4 class="text-sb">
        Number of monthly transactions
      </h4>

      <div class="arrange mt-lg-40 mt-sm-32">
        <FormInput
          id="transactions-incoming-amount"
          name="transactions-incoming-amount"
          type="number"
          :value="form.transactionsIn"
          label="Incoming"
          :has-error="form.transactionsInError"
          el-width="50%"
          @input="form.transactionsIn = $event"
        />

        <FormInput
          id="transactions-outgoing-amount"
          name="transactions-outgoing-amount"
          type="number"
          :value="form.transactionsOut"
          label="Outgoing"
          :has-error="form.transactionsOutError"
          el-width="50%"
          class="ml-lg-24 ml-sm-16"
          @input="form.transactionsOut = $event"
        />
      </div>
    </div>

    <div class="mt-lg-24 mt-sm-16">
      <h4 class="text-sb">
        Other
      </h4>

      <div class="mt-lg-40 mt-sm-32">
        <FormSelect
          id="countries"
          name="countries"
          label="Main countries of operation"
          el-width="100%"
          el-style="input"
          placeholder="Choose countries"
          :value="form.chosenCountries"
          :options="form.availableCountries"
          multiple-choice
          @change="form.chosenCountries = $event"
        />
      </div>

      <div class="mt-lg-40 mt-sm-32">
        <FormInput
          id="description"
          name="description"
          type="text"
          :value="form.description"
          label="Describe your business activity"
          :has-error="form.descriptionError"
          @input="form.description = $event"
        />
      </div>
    </div>

    <div class="arrange mt-lg-32 mt-sm-24">
      <Button
        icon="icon-back-mobile"
        label="Return"
        btn-style="transparent"
        btn-width="92px"
      />

      <Button
        label="Continue"
        btn-style="primary"
        class="w-100 ml-24"
      />
    </div>
  </OnboardingBusinessLayout>
</template>

<script>
import OnboardingBusinessLayout from "@/components/Layout/OnboardingBusinessLayout";
import Header from "@/components/Onboarding/Header";
import FormInput from "@/components/Form/FormInput";
import FormSelect from "@/components/Form/FormSelect";
import Button from "@/components/Button";

export default {
  components: {
    OnboardingBusinessLayout,
    Header,
    FormInput,
    FormSelect,
    Button
  },

  data() {
    return {
      currentStep: 9,

      sideMsg:
        "Some short tooltip. Why do we need to know about this info about user",

      form: {
        turnoverIn: "",
        turnoverInError: "",
        turnoverOut: "",
        turnoverOutError: "",
        transactionsIn: "",
        transactionsInError: "",
        transactionsOut: "",
        transactionsOutError: "",
        countries: [],
        description: "",
        descriptionError: "",
        currencies: [
          { value: 'gbp', title: 'GBP', flag: 'gb' },
          { value: 'usd', title: 'USD', flag: 'us' },
          { value: 'eur', title: 'EUR', flag: 'eu' },
          { value: 'nok', title: 'NOK', flag: 'no' }
        ],
        currencyIn: 'nok',
        currencyOut: 'gbp',
        chosenCountries: 'Choose countries',
        availableCountries: [
          'Choose countries',
          { value: 'Lithuania', title: 'Lithuania', flag: 'lt' },
          { value: 'Latvia', title: 'Latvia', flag: 'lv' },
          { value: 'Estonia', title: 'Estonia', flag: 'ee' }
        ]
      }
    };
  }
};
</script>
