<template>
  <div class="onboarding-dropzone">
    <div
      class="onboarding-dropzone__zone"
      :class="{
        'has-dragover': hasDragover,
        'has-file': hasFile,
        'has-error': hasError
      }"
      @dragover="handleDragover"
      @dragenter="handleDragover"
      @dragleave="handleDragend"
      @dragend="handleDragend"
      @drop="handleDrop"
    >
      <label class="onboarding-dropzone__label">
        <span class="onboarding-dropzone__icon" />

        <span
          v-if="!hasFile && !hasError"
          class="onboarding-dropzone__placeholder onboarding-dropzone__placeholder--desktop"
        >Drop your file here or <span class="text-link">Choose</span></span>

        <span
          v-if="!hasFile && !hasError"
          class="onboarding-dropzone__placeholder onboarding-dropzone__placeholder--mob"
        ><span class="text-link">Choose file</span></span>

        <span
          v-if="hasFile && !hasError"
          class="onboarding-dropzone__placeholder"
        >Document uploaded successfully</span>

        <span
          v-if="hasError"
          class="onboarding-dropzone__placeholder"
        >Something went wrong. <span class="text-link">Try again</span></span>

        <input
          :id="id"
          ref="input"
          :name="name"
          type="file"
          @change="handleChange"
        >
      </label>
    </div>

    <div
      v-if="hasFile"
      class="onboarding-dropzone__file"
      :class="{ 'has-error': hasError }"
    >
      <i class="icon icon-doc-sm" />
      <span class="onboarding-dropzone__file-name">{{ fileName }}</span>
      <i
        class="icon"
        :class="[hasError ? 'icon-clear' : 'icon-info-request']"
      />
      <button
        class="onboarding-dropzone__file-remove"
        @click="handleReset"
      >
        remove
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Dropzone",

  props: {
    id: { type: String, required: false, default: "" },
    name: { type: String, required: false, default: "" },
    hasError: { type: String, required: false, default: "" }
  },

  data() {
    return {
      hasDragover: false,
      hasFile: false,
      fileName: "",
      file: null
    };
  },

  methods: {
    handleDragover(e) {
      e.preventDefault();

      if (this.hasDragover) return;

      this.hasDragover = true;
    },

    handleDragend(e) {
      e.preventDefault();

      this.hasDragover = false;
    },

    handleDrop(e) {
      e.preventDefault();

      const droppedFiles = e.dataTransfer.files;
      const input = this.$refs.input;

      input.files = droppedFiles;
    },

    handleChange(e) {
      e.preventDefault();

      const files = e.target.files;

      this.hasDragover = false;
      this.hasFile = true;
      this.fileName = files[0].name;

      this.$emit("fileChange", files);
    },

    handleReset() {
      const input = this.$refs.input;

      input.value = "";
      this.hasFile = false;
      this.fileName = "";
      this.file = null;

      this.$emit("fileChange", null);
    }
  }
};
</script>

<style scoped lang="scss">
.onboarding-dropzone {
  &__zone {
    position: relative;
    width: 100%;
    height: 180px;
    border: 1px dashed $grey-normal;
    border-radius: 4px;
  }

  &__zone.has-dragover:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($accent-normal, 0.1);
    pointer-events: none;
  }

  &__label {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  &__label input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
  }

  &__icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 4px solid transparent;
    background-color: $grey-light-02;
  }

  &__zone.has-file &__icon {
    background-color: transparent;
    border-color: $green;
  }

  &__zone.has-error &__icon {
    background-color: transparent;
    border-color: $red;
  }

  &__icon:before {
    content: "\e935";
    @include inline-icon;
    font-size: 40px;
    color: $grey-dark-02;
  }

  &__zone.has-file &__icon:before {
    content: "\e919";
    color: $green;
  }

  &__zone.has-error &__icon:before {
    content: "\e934";
    color: $red;
  }

  &__placeholder {
    font-size: 14px;
    line-height: 18px;
    margin-top: 16px;
    color: $grey-dark-01;
  }

  &__file {
    display: flex;
    width: 100%;
    padding: 12px;
    border-radius: 8px;
    background-color: rgba($green, 0.1);
    color: $green;
    margin-top: 16px;
  }

  &__file.has-error {
    background-color: rgba($red, 0.1);
    color: $red;
  }

  &__file-name {
    font-family: $font-semibold;
    font-size: 14px;
    line-height: 18px;
    margin: 0 6px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__file-remove {
    font-size: 0;
    width: 18px;
    height: 18px;
    margin-left: auto;
  }

  &__file-remove:before {
    content: "\e93a";
    @include inline-icon;
    font-size: 16px;
  }

  @media (hover: hover) {
    &__file-remove {
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.1s linear;
    }

    &__file:hover &__file-remove {
      opacity: 1;
      pointer-events: all;
    }
  }

  @media screen and (max-width: $screen-md-max) {
    &__placeholder--desktop {
      display: none;
    }
  }

  @media screen and (min-width: $screen-lg-min) {
    &__placeholder--mob {
      display: none;
    }
  }
}
</style>
