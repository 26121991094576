<template>
  <div class="settings-wrapper">
    <div class="settings-wrapper__header">
      {{ title }}
    </div>

    <div class="settings-wrapper__body">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "SettingsWrapper",

  props: {
    title: { type: String, required: false, default: "" }
  }
};
</script>

<style scoped lang="scss">
.settings-wrapper {
  background-color: $white;
  border-radius: 8px;

  &__header {
    border-bottom: 1px solid $grey-light-01;
    padding: 32px 24px;
    font-family: $font-bold;
    font-size: 20px;
    line-height: 24px;
  }

  &__body {
    padding: 16px 0 24px 0;
  }

  @media screen and (max-width: $screen-lg-max) {
    &__header {
      display: none;
    }
  }

  @media screen and (min-width: $screen-lg-min) {
    &__body {
      padding: 48px 0;
    }
  }
}
</style>
