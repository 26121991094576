<template>
  <div class="sidebar-transactions">
    <div class="sidebar-transactions__header">
      <div class="sidebar-transactions__header-title">
        Transactions
      </div>

      <button
        class="sidebar-transactions__search-toggle"
        @click="searchIsOpen = true"
      >
        Search
      </button>

      <form
        class="sidebar-transactions__search"
        :class="{ 'is-hidden': !searchIsOpen }"
        @submit="handleSearch"
      >
        <input
          class="sidebar-transactions__search-input"
          role="search"
          type="text"
          name="search"
          :model="search"
          placeholder="Search..."
        >

        <button
          class="sidebar-transactions__search-clear"
          type="reset"
          value="Reset"
          @click="searchIsOpen = false"
        >
          Clear
        </button>
      </form>
    </div>

    <div
      v-if="!transactions"
      class="sidebar-transactions__placeholder"
    >
      <div class="sidebar-transactions__placeholder-icon">
        <i class="icon icon-transactions" />
      </div>

      <div class="sidebar-transactions__placeholder-msg">
        No transactions have been executed yet
      </div>
    </div>

    <div
      v-if="transactions"
      class="sidebar-transactions__filters"
    >
      <FormSelectGroup el-width="100%">
        <FormSelect
          placeholder="All types"
          el-style="filter"
          el-width="50%"
          :value="typeFilter"
          :options="['All types', 'Payments', 'Exchange']"
          @change="typeFilter = $event"
        />

        <FormSelect
          placeholder="All statuses"
          el-style="filter"
          el-width="50%"
          :value="statusFilter"
          :options="[
            'All statuses',
            'Processing',
            'Postponed',
            'Rejected',
            'Canceled'
          ]"
          @change="statusFilter = $event"
        />
      </FormSelectGroup>
    </div>

    <div
      v-if="transactions"
      class="sidebar-transactions__mob-filters"
    >
      <div class="sidebar-transactions__mob-search">
        <form
          class="sidebar-transactions__mob-search-form"
          @submit="handleSearch"
        >
          <input
            class="sidebar-transactions__mob-search-input"
            role="search"
            type="text"
            name="search"
            :model="search"
            placeholder="Search..."
          >
        </form>

        <button
          class="sidebar-transactions__mob-filter-toggle"
          :class="{ 'is-active': mobileFiltersOpen }"
          @click="mobileFiltersOpen = !mobileFiltersOpen"
        >
          Filters
        </button>
      </div>

      <div
        class="sidebar-transactions__mob-filter-group"
        :class="{ 'is-open': mobileFiltersOpen }"
      >
        <FormSelect
          placeholder="All types"
          el-style="filter"
          el-width="100%"
          :value="typeFilter"
          :options="['All types', 'Payments', 'Exchange']"
          @change="typeFilter = $event"
        />

        <FormSelect
          placeholder="All statuses"
          el-style="filter"
          el-width="100%"
          :value="statusFilter"
          :options="[
            'All statuses',
            'Processing',
            'Postponed',
            'Rejected',
            'Canceled'
          ]"
          @change="statusFilter = $event"
        />
      </div>
    </div>

    <div
      v-if="transactions"
      class="sidebar-transactions__list"
    >
      <div
        v-for="(item, i) in transactions"
        :key="i"
        class="sidebar-transactions__section"
      >
        <div class="sidebar-transactions__section-head">
          {{ item.date }}
        </div>

        <div class="sidebar-transactions__section-list">
          <div
            v-for="(record, j) in item.records"
            :key="j"
            class="sidebar-transactions-item"
            @click="handleShowDetails(record.detailsData)"
          >
            <div class="sidebar-transactions-item__icon">
              <svg v-if="record.icon">
                <use
                  :xlink:href="
                    require('@/assets/images/svg-sprites.svg') +
                      '#' +
                      record.icon
                  "
                />
              </svg>
            </div>

            <div class="sidebar-transactions-item__info">
              <div class="sidebar-transactions-item__details">
                {{ record.details }}
              </div>
              <div class="sidebar-transactions-item__target">
                {{ record.target }}
              </div>
            </div>

            <div class="sidebar-transactions-item__amount">
              <div>{{ record.amount | moneyformat }} {{ record.currency }}</div>

              <Badge
                v-if="record.isProcessing"
                label="Processing"
                el-style="neutral"
              />
              <Badge
                v-if="record.isRejected"
                label="Rejected"
                el-style="danger"
              />
              <Badge
                v-if="record.isPostponed"
                label="Postponed"
                el-style="warning"
              />
              <Badge
                v-if="record.isCanceled"
                label="Canceled"
                el-style="danger"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="transactions"
      class="sidebar-transactions__footer"
    >
      <Button
        label="View more"
        btn-style="dark-opacity"
        btn-width="100%"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import FormSelect from "@/components/Form/FormSelect";
import FormSelectGroup from "@/components/Form/FormSelectGroup";
import Badge from "@/components/Badge";
import Button from "@/components/Button";

export default {
  name: "SidebarTransactions",

  components: {
    FormSelect,
    FormSelectGroup,
    Badge,
    Button
  },

  props: {
    transactions: { type: Array, required: false, default: null }
  },

  data() {
    return {
      search: "",
      searchIsOpen: false,
      mobileFiltersOpen: false,
      typeFilter: "All types",
      statusFilter: "All statuses"
    };
  },

  methods: {
    ...mapActions(["showModal"]),

    handleSearch(e) {
      e.preventDefault();
    },

    handleShowDetails(detailsData) {
      this.showModal({
        component: "PaymentDetailsModal",
        data: detailsData
      });
    }
  }
};
</script>

<style scoped lang="scss">
.sidebar-transactions {
  border-radius: 8px;
  margin-bottom: 16px;

  &__header {
    position: relative;
    display: flex;
    align-items: center;
    padding: 32px 24px;
  }

  &__header-title {
    font-family: $font-bold;
    font-size: 20px;
    line-height: 24px;
  }

  &__search-toggle,
  &__search-clear {
    width: 24px;
    height: 24px;
    font-size: 0;
    color: $grey-dark-02;
    margin-left: auto;
    transition: color 0.1s linear;
  }

  &__search-toggle:hover,
  &__search-clear:hover {
    color: $accent-light;
  }

  &__search-toggle:before,
  &__search-clear:before {
    @include inline-icon;
    font-size: 24px;
  }

  &__search-toggle:before {
    content: "\e92d";
  }

  &__search-clear:before {
    content: "\e934";
  }

  &__search {
    position: absolute;
    top: 4px;
    right: 4px;
    bottom: 4px;
    left: 4px;
    padding-right: 20px;
    background-color: $white;
    display: flex;
    align-items: center;
    transition: opacity 0.15s linear, visibility 0s linear 0s;
  }

  &__search.is-hidden {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.15s linear, visibility 0s linear 0.15s;
  }

  &__search-input {
    padding: 30px 20px;
  }

  &__filters {
    border-top: 1px solid $grey-light-01;
    padding: 24px;
  }

  &__mob-filters {
    margin-bottom: 16px;
  }

  &__mob-search {
    display: flex;
  }

  &__mob-search-form {
    flex: 1;
  }

  &__mob-search-input {
    background-color: $white;
    border-radius: 4px 0px 0px 4px;
    min-width: 1%;
    width: 100%;
    padding: 10px 12px;
  }

  /deep/
    &__mob-filters
    .from-select-group
    .form-select
    + .form-select
    .form-select__dropdown-toggle {
    border-left-color: $grey-light-02;
    background-color: rgba($additional-brown, 0.04);
  }

  &__mob-filter-toggle {
    display: inline-block;
    border-radius: 0 4px 4px 0;
    font-size: 0;
    width: 40px;
    min-width: 40px;
    background-color: rgba($additional-brown, 0.04);
    color: $additional-brown;
  }

  &__mob-filter-toggle.is-active {
    color: $accent-normal;
  }

  &__mob-filter-toggle:before {
    content: "\e917";
    @include inline-icon;
    font-size: 16px;
  }

  &__mob-filter-group {
    overflow: hidden;
    height: 0;
    opacity: 0;
    transition: opacity 0.15s linear, height 0.15s linear,
      overflow 0s linear 0.15s;
  }

  &__mob-filter-group.is-open {
    overflow: visible;
    height: 100%;
    opacity: 1;
    transition: opacity 0.15s linear, height 0.15s linear, overflow 0s linear 0s;
  }

  /deep/ &__mob-filter-group .form-select {
    margin-top: 4px;
  }

  &__section-head {
    font-size: 14px;
    line-height: 18px;
    padding: 10px 16px;
    border-bottom: 1px solid $grey-light-01;
    color: $grey-dark-01;
  }

  &__placeholder {
    min-height: 360px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__placeholder-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: $grey-light-02;
    color: $grey-dark-02;
    font-size: 40px;
  }

  &__placeholder-msg {
    margin-top: 24px;
    max-width: 260px;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: $grey-dark-01;
  }

  @media screen and (max-width: $screen-sm-max) {
    &__header,
    &__filters {
      display: none;
    }

    &__section {
      background-color: $white;
      border-radius: 8px;
    }

    &__section + &__section {
      margin-top: 16px;
    }

    &__footer {
      margin-top: 16px;
    }

    &__placeholder {
      background-color: $white;
      border-radius: 8px;
    }
  }

  @media screen and (min-width: $screen-md-min) {
    background-color: $white;
    margin-bottom: 32px;

    &__mob-filters {
      display: none;
    }

    &__section-head {
      padding: 10px 24px;
      border-top: 1px solid $grey-light-01;
    }

    &__footer {
      border-top: 1px solid $grey-light-01;
      padding: 24px;
    }

    &__placeholder {
      border-top: 1px solid $grey-light-01;
    }
  }
}

.sidebar-transactions-item {
  display: flex;
  align-items: center;
  padding: 16px 12px;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
  transition: background-color 0.1s linear;

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: $grey-light-02;
  }

  &__icon svg {
    max-width: 24px;
    max-height: 24px;
  }

  &__icon + &__info {
    margin-left: 16px;
  }

  &__details {
    font-family: $font-semibold;
  }

  &__target {
    margin-top: 4px;
    color: $grey-dark-01;
  }

  &__amount {
    margin-left: auto;
    margin-bottom: auto;
    text-align: right;
  }

  &__amount .badge {
    margin-top: 4px;
  }

  @media (hover: hover) {
    &:hover {
      background-color: rgba($grey-light-02, 0.5);
    }
  }

  @media screen and (min-width: $screen-md-min) {
    padding: 16px 24px;
  }
}
</style>
