<template>
  <div
    class="dashboard-nav-user-mob"
    :class="{ 'is-open': isOpen }"
  >
    <div class="dashboard-nav-user-mob__header">
      <img
        :src="require('@/assets/images/logo.svg')"
        alt="logo"
        class="dashboard-nav-user-mob__logo"
      >

      <button
        class="dashboard-nav-user-mob__close-btn"
        @click="closeHandler"
      >
        <i class="icon icon-close" />
        <span>Close</span>
      </button>
    </div>

    <ul class="dashboard-nav-user-mob__list">
      <li
        v-for="(item, i) in accounts"
        :key="i"
      >
        <button
          class="dashboard-nav-user-mob__list-item"
          :class="{ 'is-selected': item.id === user.id }"
          @click="handleSelect(item.id)"
        >
          <i
            class="icon"
            :class="{
              'icon-case': item.type === 'company',
              'icon-profile': item.type === 'private'
            }"
          />
          <span>{{ item.primary || item.name }}</span>
        </button>
      </li>

      <li>
        <button class="dashboard-nav-user-mob__list-item logout">
          <span>Log out</span><i class="icon icon-logout" />
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "DashboardNavUserMob",

  props: {
    isOpen: { type: Boolean, required: false, default: false },
    closeHandler: { type: Function, required: false, default: () => {} }
  },

  computed: {
    user() {
      return this.$store.state.currentUser;
    },

    accounts() {
      return this.$store.state.availableUsers;
    }
  },

  methods: {
    ...mapActions(["setUser"]),

    handleSelect(id) {
      const selected = this.accounts.find(item => {
        return item.id === id;
      });

      this.setUser(selected);

      this.closeHandler();
    }
  }
};
</script>

<style scoped lang="scss">
.dashboard-nav-user-mob {
  position: fixed;
  top: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background-color: mix($additional-brown, $additional-brown-light, 70%);
  color: $white;
  transition: top 0.2s ease-in-out;

  &.is-open {
    top: 0;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    height: 64px;
    flex: 0 0 64px;
  }

  &__logo {
    width: 40px;
    height: 30px;
  }

  &__close-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
  }

  &__close-btn span {
    font-size: 0;
  }

  &__close-btn .icon {
    font-size: 24px;
  }

  &__list {
    margin-top: 12px;
    border-bottom: 1px solid rgba($white, 0.2);
  }

  &__list li {
    border-top: 1px solid rgba($white, 0.2);
  }

  &__list-item {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 16px;
    font-size: 14px;
    line-height: 18px;
  }

  &__list-item.is-selected:after {
    content: "\e919";
    @include inline-icon;
    position: absolute;
    right: 16px;
    top: 16px;
    color: $accent-normal;
  }

  &__list-item.logout {
    color: $red;
  }

  &__list-item.logout .icon {
    font-size: 20px;
  }

  &__list-item span + .icon {
    margin-left: auto;
  }

  &__list-item .icon + span {
    margin-left: 8px;
  }
}
</style>
