import Vue from "vue";
import Vuex from "vuex";

import * as types from "./mutation-types";

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    currentUser: {},

    availableUsers: [],

    modal: null,

    notification: null,

    pushNotifications: []
  },

  getters: {
    isModalVisible: state => state.modal !== null
  },

  mutations: {
    [types.SHOW_MODAL](state, modal) {
      state.modal = { ...modal };
    },

    [types.HIDE_MODAL](state) {
      state.modal = null;
    },

    [types.SET_USER](state, user) {
      state.currentUser = { ...user };
    },

    [types.SET_AVAILABLE_USERS](state, users) {
      state.availableUsers = [...users];
    },

    [types.SHOW_NOTIFICATION](state, notification) {
      state.notification = { ...notification };
    },

    [types.HIDE_NOTIFICATION](state) {
      state.notification = null;
    },

    [types.ADD_PUSH_NOTIFICATION](state, notification) {
      const uid =
        "_" +
        Math.random()
          .toString(36)
          .substr(2, 9);

      notification.id = uid;

      state.pushNotifications.push(notification);
    },

    [types.REMOVE_PUSH_NOTIFICATION](state, id) {
      state.pushNotifications.splice(
        state.pushNotifications.findIndex(n => n.id === id),
        1
      );
    }
  },

  actions: {
    showModal({ commit, state }, modal) {
      if (state.modal !== modal) {
        commit(types.SHOW_MODAL, modal);
      }
    },

    hideModal({ commit, state }) {
      if (state.modal !== null) {
        commit(types.HIDE_MODAL);
      }
    },

    setUser({ commit }, user) {
      commit(types.SET_USER, user);
    },

    setAvailableUsers({ commit }, users) {
      commit(types.SET_AVAILABLE_USERS, users);
    },

    showNotification({ commit, state }, notification) {
      if (state.notification !== notification) {
        commit(types.SHOW_NOTIFICATION, notification);
      }
    },

    hideNotification({ commit, state }) {
      if (state.notification !== null) {
        commit(types.HIDE_NOTIFICATION);
      }
    },

    pushNotification({ commit }, notification) {
      commit(types.ADD_PUSH_NOTIFICATION, notification);
    },

    removePushNotification({ commit }, id) {
      commit(types.REMOVE_PUSH_NOTIFICATION, id);
    }
  }
});
