<template>
  <ModalWrapper
    size="lg"
    fix-height
  >
    <template slot="title">
      Mass payout
    </template>

    <FormSelect
      class="mt-lg-32 mt-sm-16"
      :value="account"
      :options="['Account 1', 'Account 2', 'Account 3']"
      @change="account = $event"
    />

    <div class="mt-lg-32 mt-sm-16">
      <PayoutModalTransactionGroup
        v-for="(transaction, i) in transactions"
        :key="i"
        :data="transaction"
      />
    </div>

    <PayoutModalError
      type="low_funds"
      msg="Not enough funds in the currency account"
      :details="[
        { currency: 'CHF', payment: 10230.9, available: 7500.23 },
        { currency: 'JPY', payment: 1324.0, available: 430.45 }
      ]"
      class="mt-lg-32 mt-sm-16"
    />

    <PayoutModalSummary :data="summary" />

    <template slot="footer">
      <ModalActionWrapper>
        <Button
          label="Confirm"
          btn-style="primary"
          :click-handler="handleConfirm"
        />
      </ModalActionWrapper>

      <ModalActionWrapper>
        <Button
          label="Cancel"
          btn-style="grey-light-opacity"
          :click-handler="handleCancel"
        />
      </ModalActionWrapper>
    </template>
  </ModalWrapper>
</template>

<script>
import { mapActions, mapState } from "vuex";

import ModalWrapper from "@/components/Modal/ModalWrapper";
import FormSelect from "@/components/Form/FormSelect";
import PayoutModalTransactionGroup from "@/components/Modal/PayoutModalTransactionGroup";
import PayoutModalError from "@/components/Modal/PayoutModalError";
import PayoutModalSummary from "@/components/Modal/PayoutModalSummary";
import ModalActionWrapper from "@/components/Modal/ModalActionWrapper";
import Button from "@/components/Button";

export default {
  name: "MassPayoutModal",

  components: {
    ModalWrapper,
    FormSelect,
    PayoutModalTransactionGroup,
    PayoutModalError,
    PayoutModalSummary,
    ModalActionWrapper,
    Button
  },

  data() {
    return {
      account: "Account 1",

      summary: {
        currencies: [
          { currency: "USD", amount: 107500.9 },
          { currency: "EUR", amount: 1000028.0 },
          { currency: "GBP", amount: 1794620.64 },
          { currency: "CAD", amount: 1000.92 }
        ],
        received: 72,
        accepted: 68
      }
    };
  },

  computed: {
    ...mapState(["modal"]),

    transactions() {
      return this.modal.data.payout || [];
    }
  },

  methods: {
    ...mapActions(["pushNotification", "hideModal"]),

    handleConfirm() {
      this.hideModal();

      const notification = {
        type: "success",
        title: "Mass payout has been successfully performed."
      };

      this.pushNotification(notification);
    },

    handleCancel() {
      this.hideModal();
    }
  }
};
</script>
