<template>
  <div
    class="savings-account-preview"
    :class="{ 'is-collapsed': isCollapsed }"
  >
    <div class="savings-account-preview-header">
      <div class="savings-account-preview-header__primary">
        <div class="savings-account-preview-header__title">
          {{ data.title }}
        </div>

        <div class="savings-account-preview-header__progress">
          <span class="savings-account-preview-header__progress-label">
            {{ data.progress * 100 }}%
          </span>

          <SavingsProgressGraph
            :progress="43"
            :size="64"
            :stroke="1"
            :stroke-progress="4"
          />
        </div>
      </div>

      <div class="savings-account-preview-header__info">
        <div class="savings-account-preview-header__balance">
          {{ data.balance | moneyformat }} {{ data.currency }}
        </div>
        <div class="savings-account-preview-header__goal">
          Target amount: {{ data.goal | moneyformat }} {{ data.currency }}
        </div>
      </div>

      <div class="savings-account-preview-header__toolbox">
        <button
          class="savings-account-preview-header__toolbox-toggle"
          @click="isCollapsed = !isCollapsed"
        >
          Toggle collapse
        </button>

        <div class="savings-account-preview-options">
          <Dropdown is-right>
            <template slot="toggle">
              <button class="savings-account-preview-options__toggle">
                Show options
              </button>
            </template>

            <template slot="menu">
              <ul class="savings-account-preview-options__list">
                <li>
                  <button class="savings-account-preview-options__item">
                    <span>Rename</span> <i class="icon icon-pencil" />
                  </button>
                </li>

                <li>
                  <button class="savings-account-preview-options__item">
                    <span>Statement</span> <i class="icon icon-statement" />
                  </button>
                </li>
              </ul>
            </template>
          </Dropdown>
        </div>
      </div>
    </div>

    <div class="savings-account-preview__body">
      <div class="savings-account-preview__secondary">
        <div class="savings-account-preview__actions">
          <div class="savings-account-preview__actions-item">
            <ButtonCircle
              icon="icon-add"
              label="Add money"
              btn-style="light"
              :click-handler="handleAddMoneyModal"
            />
          </div>

          <div class="savings-account-preview__actions-item">
            <ButtonCircle
              icon="icon-exchange"
              label="Distribute funds"
              btn-style="light"
              :click-handler="handleDistribute"
            />
          </div>

          <div class="savings-account-preview__actions-item">
            <ButtonCircle
              icon="icon-close"
              label="Close account"
              btn-style="light"
            />
          </div>
        </div>

        <div class="savings-account-preview__progress">
          <div class="savings-account-preview__progress-label">
            <div class="h1">
              {{ data.balance | moneyformat }}
            </div>
            <div class="text-grey">
              {{ data.currency }}
            </div>
          </div>

          <SavingsProgressGraph
            :progress="43"
            :size="180"
            :stroke="8"
            :stroke-progress="16"
          />
        </div>

        <div class="savings-account-preview__progress-mob-subscript">
          Target amount: {{ data.goal | moneyformat }} {{ data.currency }}
        </div>
      </div>

      <div class="savings-account-preview__primary">
        <div class="savings-account-preview-table">
          <div class="savings-account-preview-table__head">
            <div
              class="savings-account-preview-table__head-cell savings-account-preview-table__head-cell--counterparty is-sortable"
              :class="{ 'is-sorted': sortBy === 'counterparty' }"
              @click="sortBy = 'counterparty'"
            >
              Counterparty
            </div>

            <div
              class="savings-account-preview-table__head-cell savings-account-preview-table__head-cell--date is-sortable"
              :class="{ 'is-sorted': sortBy === 'date' }"
              @click="sortBy = 'date'"
            >
              Date
            </div>

            <div
              class="savings-account-preview-table__head-cell savings-account-preview-table__head-cell--amount"
            >
              Amount
            </div>

            <div
              class="savings-account-preview-table__head-cell savings-account-preview-table__head-cell--balance is-sortable"
              :class="{ 'is-sorted': sortBy === 'balance' }"
              @click="sortBy = 'balance'"
            >
              Balance
            </div>
          </div>

          <div
            v-for="(row, i) in data.transactions"
            :key="i"
            class="savings-account-preview-table__row"
          >
            <div
              class="savings-account-preview-table__cell savings-account-preview-table__cell--counterparty"
            >
              <div class="savings-account-preview-table__icon">
                <svg v-if="row.icon">
                  <use
                    :xlink:href="
                      require('@/assets/images/svg-sprites.svg') +
                        '#' +
                        row.icon
                    "
                  />
                </svg>
              </div>

              <div class="savings-account-preview-table__info">
                <div class="savings-account-preview-table__title">
                  {{ row.title }}
                </div>

                <div class="savings-account-preview-table__details">
                  <span>{{ row.fromAccount }}</span>
                  <br>
                  <span>{{ row.details }}</span>
                </div>
              </div>
            </div>

            <div
              class="savings-account-preview-table__cell savings-account-preview-table__cell--date"
            >
              <span>{{ row.date }}</span>
              <span class="savings-account-preview-table__time">
                {{ row.time }}
              </span>
            </div>

            <div
              class="savings-account-preview-table__cell savings-account-preview-table__cell--amount"
            >
              <span
                :class="{
                  'text-red': row.amount < 0,
                  'text-green': row.amount > 0
                }"
              >
                {{ row.amount | moneyformat }} {{ row.currency }}
              </span>
            </div>

            <div
              class="savings-account-preview-table__cell savings-account-preview-table__cell--balance"
            >
              <span> {{ row.balance | moneyformat }} {{ row.currency }} </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import { accountDetails } from "@/fixtures";

import Dropdown from "@/components/Dropdown";
import ButtonCircle from "@/components/ButtonCircle";
import SavingsProgressGraph from "@/components/Blocks/SavingsProgressGraph";

export default {
  name: "SavingsAccountPreview",

  components: {
    Dropdown,
    ButtonCircle,
    SavingsProgressGraph
  },

  props: {
    data: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      isCollapsed: false,
      sortBy: "date"
    };
  },

  methods: {
    ...mapActions(["showModal"]),

    handleDistribute() {
      this.showModal({
        component: "DistributeFundsModal"
      });
    },

    handleAddMoneyModal() {
      this.showModal({
        component: "AddMoneyModal",
        data: {
          details: accountDetails
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.savings-account-preview {
  background-color: $white;
  border-radius: 8px;

  &__secondary {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
  }

  &__actions {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
  }

  &__progress {
    position: relative;
  }

  &__progress-label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  &__progress-mob-subscript {
    order: 2;
    font-size: 14px;
    line-height: 18px;
    color: $grey-dark-01;
  }

  &__body {
    overflow: hidden;
    height: 100%;
    transition: opacity 0.1s linear, height 0.1s linear;
  }

  &.is-collapsed &__body {
    height: 0;
    opacity: 0;
    transition: opacity 0.1s linear, height 0.1s linear;
  }

  @media screen and (max-width: $screen-sm-max) {
    &__actions {
      order: 3;
    }

    &__actions-item {
      width: 78px;
    }

    &__progress {
      order: 1;
    }

    /deep/ &__actions-item .btn-circle__label {
      font-size: 14px;
      line-height: 18px;
    }
  }

  @media screen and (min-width: $screen-md-min) {
    &__progress-mob-subscript {
      display: none;
    }

    &__secondary {
      flex-direction: row;
      border-top: 1px solid $grey-light-01;
      padding: 24px;
    }

    &__progress {
      margin-left: auto;
    }

    &__actions {
      width: 480px;
      margin-top: 0;
    }
  }

  @media screen and (min-width: $screen-xlg-min) {
    &__body {
      display: flex;
      flex-wrap: wrap;
    }

    &__primary {
      order: 1;
      width: 64%;
      border-top: 1px solid $grey-light-01;
    }

    &__secondary {
      order: 2;
      width: 36%;
      flex-direction: column;
      padding: 32px;
      border-left: 1px solid $grey-light-01;
    }

    &__actions {
      width: 100%;
      // justify-content: space-between;
    }

    &__actions-item + &__actions-item {
      margin-left: unset;
    }

    &__progress {
      margin: auto;
    }
  }
}

.savings-account-preview-header {
  display: flex;
  align-items: center;
  min-height: 72px;
  padding: 0 16px;

  &__progress-label {
    font-family: $font-semibold;
    font-size: 14px;
    line-height: 18px;
    color: $green;
  }

  &__title {
    font-family: $font-bold;
    font-size: 18px;
    line-height: 22px;
  }

  &__info {
    margin-left: auto;
    text-align: right;
  }

  &__balance {
    font-size: 20px;
    line-height: 24px;
  }

  &__goal {
    font-size: 14px;
    line-height: 18px;
    color: $grey-dark-01;
  }

  &__toolbox {
    display: flex;
    align-items: center;
  }

  &__toolbox-toggle {
    margin-left: 16px;
    width: 24px;
    height: 24px;
    font-size: 0;
    color: $grey-dark-02;
  }

  &__toolbox-toggle:before {
    content: "\e912";
    @include inline-icon;
    font-size: 24px;
  }

  .is-collapsed &__toolbox-toggle:before {
    content: "\e911";
  }

  @media screen and (max-width: $screen-sm-max) {
    &__info {
      display: none;
    }

    &__toolbox {
      margin-left: auto;
    }

    &__progress .progress-ring {
      display: none;
    }
  }

  @media screen and (min-width: $screen-md-min) {
    padding: 0 24px;
    min-height: 88px;

    &__primary {
      display: flex;
      align-items: center;
    }

    &__title {
      order: 2;
      font-size: 20px;
      line-height: 24px;
    }

    &__progress {
      order: 1;
      margin-right: 16px;
      width: 64px;
      height: 64px;
      position: relative;
    }

    &__progress-label {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.savings-account-preview-options {
  &__toggle {
    position: relative;
    width: 40px;
    height: 36px;
    border-radius: 4px 4px 0px 0px;
    font-size: 0;
    color: $grey-dark-02;
    background-color: $white;
  }

  .is-open &__toggle {
    z-index: 101;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08),
      0px -4px 12px rgba(0, 0, 0, 0.08);
  }

  .is-open.to-top &__toggle {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08),
      0px 8px 12px rgba(0, 0, 0, 0.08);
  }

  .to-top &__toggle {
    border-radius: 0px 0px 4px 4px;
  }

  &__toggle:before {
    content: "\e922";
    @include inline-icon;
    font-size: 24px;
  }

  &__toggle:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -5px;
    height: 6px;
    background-color: $white;
  }

  .to-top &__toggle:after {
    top: -5px;
    bottom: unset;
  }

  &__list {
    min-width: 180px;
  }

  &__list li + li {
    border-top: 1px solid $grey-light-01;
  }

  &__item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 12px;
    font-size: 14px;
    line-height: 18px;
    color: $additional-brown;
    transition: background-color 0.1s linear;
  }

  &__item:hover {
    background-color: $grey-light-02;
  }

  &__item .icon {
    font-size: 22px;
    color: $grey-dark-02;
  }
}

.savings-account-preview-table {
  &__head {
    background-color: rgba($grey-light-01, 0.88);
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: rgba($grey-dark, 0.6);
    padding: 16px 20px;
  }

  &__head-cell {
    padding: 0 4px;
  }

  &__head-cell.is-sortable {
    cursor: pointer;
  }

  &__head-cell.is-sortable:after {
    content: "\e92f";
    @include inline-icon;
    color: $grey-dark-02;
    font-size: 16px;
    vertical-align: text-bottom;
  }

  &__head-cell.is-sorted:after {
    content: "\e930";
  }

  &__head-cell--counterparty {
    width: 46%;
  }

  &__head-cell--date {
    width: 22%;
  }

  &__head-cell--amount {
    width: 16%;
  }

  &__head-cell--balance {
    width: 16%;
    text-align: right;
  }

  &__row {
    position: relative;
    display: flex;
    padding: 16px 16px 16px 8px;
  }

  &__row + &__row {
    border-top: 1px solid $grey-light-01;
  }

  &__cell {
    padding: 0 4px;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    min-width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: $bg-color;
  }

  &__icon svg {
    max-width: 24px;
    max-height: 24px;
  }

  &__cell--counterparty {
    display: flex;
  }

  &__icon + &__info {
    margin-left: 8px;
  }

  &__title {
    font-family: $font-semibold;
  }

  &__details {
    color: $grey-dark-01;
    font-size: 14px;
    line-height: 18px;
    margin-top: 2px;
  }

  @media screen and (max-width: $screen-sm-max) {
    border-top: 1px solid $grey-light-01;

    &__head {
      display: none;
    }

    &__info {
      margin-top: 20px;
    }

    &__cell {
      font-size: 14px;
      line-height: 18px;
    }

    &__cell--date {
      position: absolute;
      top: 16px;
      left: 56px;
      color: $grey-dark-01;
    }

    &__time {
      display: none;
    }

    &__cell--balance {
      display: none;
    }

    &__cell--amount {
      position: absolute;
      right: 16px;
      top: 16px;
      text-align: right;
    }
  }

  @media screen and (min-width: $screen-md-min) {
    &__icon {
      margin: auto 0;
    }

    &__row {
      padding: 16px 20px;
    }

    &__icon + &__info {
      margin-left: 24px;
    }

    &__cell--date {
      width: 22%;
    }

    &__cell--date span {
      display: block;
      margin-bottom: 2px;
    }

    &__time {
      font-size: 14px;
      line-height: 18px;
      color: $grey-dark-01;
    }

    &__cell--counterparty {
      width: 46%;
    }

    &__cell--balance {
      width: 16%;
      text-align: right;
    }

    &__cell--amount {
      width: 16%;
    }

    &__title {
      font-size: 16px;
      line-height: 20px;
    }
  }
}
</style>
