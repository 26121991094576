<template>
  <div class="payment-doc-upload">
    <transition-group name="file">
      <div
        v-for="file in files"
        :key="file.uid"
        class="payment-doc-item"
      >
        <div class="payment-doc-item__row">
          <div class="payment-doc-item__type">
            <FormSelect
              label="Document type"
              el-style="input-inverse"
              :value="file.docType"
              :options="[
                { value: '1', title: 'Invoice' },
                { value: '2', title: 'Agreement' },
                { value: '3', title: 'Certificate of completed work' },
                { value: '4', title: 'Transportation document' },
                { value: '5', title: 'Customs/Tax declaration' },
              ]"
              @change="file.docType = $event"
            />
          </div>

          <div class="payment-doc-item__file">
            <span class="payment-doc-item__name">{{
              file.file.name
                .split(".")
                .slice(0, -1)
                .join(".")
            }}</span><span
              class="payment-doc-item__ext"
            >.{{ file.file.name.split(".").pop() }}</span>
            <button
              class="payment-doc-item__remove"
              @click="handleRemove(file.uid)"
            >
              Remove
            </button>
          </div>
        </div>

        <FormInput
          type="text"
          el-style="inverse"
          label="Note"
          class="mt-lg-40 mt-sm-32"
          :value="file.note"
          @input="file.note = $event"
        />
      </div>
    </transition-group>

    <div
      class="payment-doc-dropzone"
      :class="{
        'has-dragover': hasDragover,
        'has-error': hasError
      }"
      @dragover="handleDragover"
      @dragenter="handleDragover"
      @dragleave="handleDragend"
      @dragend="handleDragend"
      @drop="handleDrop"
    >
      <label class="payment-doc-dropzone__label">
        <template v-if="!hasError">
          <span
            class="payment-doc-dropzone__placeholder payment-doc-dropzone__placeholder--desktop"
          >Drop your file here or <span class="text-link">Choose</span></span>

          <span
            class="payment-doc-dropzone__placeholder payment-doc-dropzone__placeholder--mob"
          ><span class="text-link">Choose file</span></span>
        </template>

        <span
          v-if="hasError"
          class="payment-doc-dropzone__placeholder"
        >Something went wrong. <span class="text-link">Try again</span></span>

        <input
          :id="id"
          ref="input"
          :name="name"
          type="file"
          multiple
          @change="handleChange"
        >
      </label>
    </div>
  </div>
</template>

<script>
import FormSelect from "@/components/Form/FormSelect";
import FormInput from "@/components/Form/FormInput";

export default {
  name: "DocumentDropzone",

  components: {
    FormSelect,
    FormInput
  },

  props: {
    id: { type: String, required: false, default: "" },
    name: { type: String, required: false, default: "" },
    hasError: { type: String, required: false, default: "" }
  },

  data() {
    return {
      hasDragover: false,
      files: [],
      docType: "1",
      docNote: ""
    };
  },

  methods: {
    handleDragover(e) {
      e.preventDefault();
      if (this.hasDragover) return;
      this.hasDragover = true;
    },

    handleDragend(e) {
      e.preventDefault();
      this.hasDragover = false;
    },

    handleDrop(e) {
      e.preventDefault();

      const droppedFiles = e.dataTransfer.files;
      const input = this.$refs.input;

      input.files = droppedFiles;

      this.hasDragover = false;
    },

    handleChange(e) {
      e.preventDefault();

      const files = e.target.files;

      for (var i = 0; i < files.length; i++) {
        const uid =
          "_" +
          Math.random()
            .toString(36)
            .substr(2, 9);

        const newFile = {
          uid: uid,
          docType: "1",
          file: files[i],
          note: ""
        };

        this.files.push(newFile);
      }
    },

    handleRemove(id) {
      this.files = this.files.filter(file => file.uid !== id);
    }
  }
};
</script>

<style scoped lang="scss">
.payment-doc-dropzone {
  position: relative;
  width: 100%;
  border: 1px dashed $grey-normal;
  border-radius: 4px;
  padding: 16px;
  text-align: center;
  transition: background-color 0.1s linear;

  &.has-dragover {
    background-color: rgba($accent-normal, 0.1);
  }

  &__label {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
    font-size: 14px;
    line-height: 18px;
    color: rgba($grey-dark-01, 0.8);
  }

  &__label input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
  }

  &__placeholder:before {
    content: "\e93e";
    @include inline-icon;
    font-size: 18px;
    margin-right: 4px;
    vertical-align: text-bottom;
    color: $grey-dark-02;
  }

  @media screen and (max-width: $screen-md-max) {
    &__placeholder--desktop {
      display: none;
    }
  }

  @media screen and (min-width: $screen-lg-min) {
    &__placeholder--mob {
      display: none;
    }
  }
}

.payment-doc-item {
  background-color: $grey-light-02;
  border-radius: 8px;
  padding: 40px 16px 24px 16px;
  margin-bottom: 24px;

  &__row {
    display: flex;
    flex-wrap: wrap;
  }

  &__type {
    width: 100%;
  }

  &__file {
    width: 100%;
    background-color: rgba($accent-normal, 0.1);
    border-radius: 8px;
    color: $accent-dark;
    font-family: $font-semibold;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    height: 48px;
    padding: 0 8px;
    margin-top: 8px;
  }

  &__name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__name:before {
    content: "\e939";
    @include inline-icon;
    vertical-align: text-bottom;
    margin-right: 6px;
    font-size: 16px;
  }

  &__ext {
    margin-right: 4px;
  }

  &__ext:after {
    content: "\e919";
    @include inline-icon;
    vertical-align: text-bottom;
    margin-left: 4px;
    font-size: 16px;
  }

  &__remove {
    width: 16px;
    height: 16px;
    font-size: 0;
    color: $red;
    margin-left: auto;
  }

  &__remove:before {
    content: "\e93a";
    @include inline-icon;
    font-size: 16px;
  }

  @media screen and (min-width: $screen-md-min) {
    padding: 40px 16px 32px 16px;

    &__type {
      width: 270px;
    }

    &__file {
      width: 226px;
      margin: 0 0 0 24px;
    }
  }
}
</style>

<style lang="scss">
.file-enter-active,
.file-leave-active {
  transition: opacity 0.15s;
}

.file-enter,
.file-leave-to {
  opacity: 0;
}
</style>
