<template>
  <ModalWrapper size="lg">
    <template slot="title">
      Sweden Bank Lithuania
    </template>

    <div class="mt-lg-24 mt-sm-16">
      <AccessRightsTable :fields="fields" />
    </div>

    <template slot="footer">
      <div class="access-btn__container w-100 arrange arrange--between">
        <div class="access-btn">
          <Button
            label="Save"
            btn-style="primary"
            btn-width="100%"
            :click-handler="handleSave"
          />
        </div>

        <div class="access-btn">
          <Button
            label="Revoke"
            btn-style="dark-opacity"
            btn-width="100%"
            :click-handler="handleRevoke"
          />
        </div>
      </div>
    </template>
  </ModalWrapper>
</template>

<script>
import { mapActions } from "vuex";

import ModalWrapper from "@/components/Modal/ModalWrapper";
import AccessRightsTable from "@/components/Blocks/AccessRightsTable";
import Button from "@/components/Button";

export default {
  name: "NewCardModal",

  components: {
    ModalWrapper,
    AccessRightsTable,
    Button
  },

  data() {
    return {
      fields: [
        {
          title: 'Bank Name',
          values: [
            {
              main: 'Sweden Bank'
            }
          ]
        },
        {
          title: 'Bank Country',
          values: [
            {
              main: 'Latvia'
            }
          ]
        },
        {
          title: 'Accounts shared',
          values: [
            {
              main: 'GB123456789012345',
              additional: [
                'Account Information',
                'Account Balance'
              ]
            },
            {
              main: 'GB098765432109876',
              additional: [
                'Account Information',
                'Account Balance'
              ]
            }
          ]
        },
        {
          title: 'Date created',
          values: [
            {
              main: '08/11/2020'
            }
          ]
        },
        {
          title: 'Valid until',
          values: [
            {
              main: '10/12/2020'
            }
          ]
        },
      ],
    };
  },

  methods: {
    ...mapActions(["showModal", "hideModal"]),

    handleSave() {
      this.hideModal();
    },

    handleRevoke() {
      this.showModal({
        component: "RevokeRightsModal"
      });
    }
  }
};
</script>

<style scoped lang="scss">
.access-btn {
  &__container {
    padding: 8px;
  }

  @media screen and (max-width: $screen-sm-max) {
    & {
      width: 50%;
    }

    & + & {
      margin-left: 16px;
    }
  }

  @media screen and (min-width: $screen-lg-min) {
    &__container {
      padding: 16px;
    }
  }
}

</style>
