<template>
  <div :class="`tag tag-${elStyle}`">
    <span>{{ label }}</span>
    <button
      v-if="handler"
      class="tag__remove"
      @click="handler"
    >
      Remove
    </button>
  </div>
</template>

<script>
export default {
  name: "Tag",

  props: {
    label: { type: String, required: false, default: "" },
    handler: { type: Function, required: false, default: null },
    elStyle: {
      type: String,
      required: false,
      default: "default",
      validator: function(value) {
        return ["default", "white"].indexOf(value) !== -1;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.tag {
  display: inline-flex;
  padding: 0 12px;
  font-family: $font-regular;
  font-size: 14px;
  line-height: 34px;
  border-radius: 4px;
  transition: background-color 0.1s linear, border-color 0.1s linear;

  &-default {
    background-color: $accent-normal;
    color: $white;
    line-height: 34px;
  }

  &-white {
    border: 1px solid $grey-normal;
    line-height: 32px;
    color: $additional-brown;
  }

  &__remove {
    font-size: 0;
    margin-left: 8px;
    transition: color 0.1s linear;
  }

  &__remove:before {
    content: "\e934";
    @include inline-icon;
    font-size: 16px;
  }

  &-white &__remove {
    color: $grey-dark-02;
  }

  @media (hover: hover) {
    &-default:hover {
      background-color: $accent-light;
    }

    &-white:hover {
      background-color: $grey-light-02;
      border-color: $grey-light-02;
    }

    &-white:hover &__remove {
      color: $grey-dark-01;
    }
  }
}
</style>
