<template>
  <div class="statement">
    <div class="statement-wrapper">
      <div class="statement-header">
        <div class="statement-header__info">
          <div class="statement-header__company">
            {{ statementName }}
          </div>
          <div class="statement-header__title">
            Statement
          </div>
        </div>

        <div class="statement-header__toolbox">
          <div class="statement-header__toolbox-item">
            <ButtonCircle
              icon="icon-print"
              btn-style="light"
            />
          </div>

          <div class="statement-header__toolbox-item">
            <ButtonCircle
              icon="icon-save"
              btn-style="light"
            />
          </div>
        </div>
      </div>

      <div class="statement-filters">
        <div class="statement-filters__date">
          <FormDatepicker
            placeholder="Select date..."
            is-range
            is-double-paned
            el-style="filter"
            :value="dateRange"
            @change="dateRange = $event"
          />
        </div>

        <div class="statement-filters__search">
          <input
            v-model="search"
            placeholder="Search"
            class="statement-filters__search-input"
            role="search"
            @submit="handleSearch"
          >

          <button class="statement-filters__search-submit">
            Search
          </button>
        </div>

        <div class="statement-filters__group">
          <FormSelectGroup>
            <FormSelect
              placeholder="All accounts"
              el-style="filter"
              el-width="33.33%"
              :value="accountsFilter"
              :options="['All accounts', 'Account 1', 'Account 2', 'Account 3']"
              @change="accountsFilter = $event"
            />

            <FormSelect
              placeholder="All currencies"
              el-style="filter"
              el-width="33.33%"
              multiple-choice
              :value="currencyFilter"
              :options="['All currencies', 'EUR', 'USD', 'RUB', 'CAD']"
              @change="currencyFilter = $event"
            />

            <FormSelect
              placeholder="All types"
              el-style="filter"
              el-width="33.33%"
              :value="typeFilter"
              :options="['All types', 'Mastercard', 'Income', 'Outcome']"
              @change="typeFilter = $event"
            />
          </FormSelectGroup>
        </div>
      </div>

      <div class="statement-mob-filters">
        <div class="statement-mob-filters__search">
          <form
            class="statement-mob-filters__search-form"
            @submit="handleSearch"
          >
            <input
              class="statement-mob-filters__search-input"
              role="search"
              type="text"
              name="search"
              :model="search"
              placeholder="Search..."
            >
          </form>

          <button
            class="statement-mob-filters__filter-toggle"
            :class="{ 'is-active': mobileFiltersOpen }"
            @click="mobileFiltersOpen = !mobileFiltersOpen"
          >
            Filters
          </button>
        </div>

        <div
          class="statement-mob-filters__filter-group"
          :class="{ 'is-open': mobileFiltersOpen }"
        >
          <FormDatepicker
            placeholder="Select date..."
            is-range
            is-double-paned
            el-style="filter"
            el-width="100%"
            :value="dateRange"
            @change="dateRange = $event"
          />

          <FormSelect
            placeholder="All accounts"
            el-style="filter"
            el-width="100%"
            :value="accountsFilter"
            :options="['All accounts', 'Account 1', 'Account 2', 'Account 3']"
            @change="accountsFilter = $event"
          />

          <FormSelect
            placeholder="All currencies"
            el-style="filter"
            el-width="100%"
            multiple-choice
            :value="currencyFilter"
            :options="['All currencies', 'EUR', 'USD', 'RUB', 'CAD']"
            @change="currencyFilter = $event"
          />

          <FormSelect
            placeholder="All types"
            el-style="filter"
            el-width="100%"
            :value="typeFilter"
            :options="['All types', 'Mastercard', 'Income', 'Outcome']"
            @change="typeFilter = $event"
          />
        </div>
      </div>

      <div class="statement-table">
        <div class="statement-table__header">
          <div
            class="statement-table__header-cell statement-table__header-cell--type"
          >
            Type
          </div>

          <div
            class="statement-table__header-cell statement-table__header-cell--conterparty is-sortable"
            :class="{ 'is-sorted': sortBy === 'conterparty' }"
            @click="sortBy = 'conterparty'"
          >
            Counterparty
          </div>

          <div
            class="statement-table__header-cell statement-table__header-cell--date is-sortable"
            :class="{ 'is-sorted': sortBy === 'date' }"
            @click="sortBy = 'date'"
          >
            Date
          </div>

          <div
            class="statement-table__header-cell statement-table__header-cell--debit-credit"
          >
            Debit
          </div>

          <div
            class="statement-table__header-cell statement-table__header-cell--debit-credit"
          >
            Credit
          </div>

          <div
            class="statement-table__header-cell statement-table__header-cell--amount"
          >
            Amount
          </div>

          <div
            class="statement-table__header-cell statement-table__header-cell--balance is-sortable"
            :class="{ 'is-sorted': sortBy === 'balance' }"
            @click="sortBy = 'balance'"
          >
            Balance
          </div>
        </div>

        <div class="statement-table__body">
          <div
            v-for="(row, i) in transactions"
            :key="i"
            class="statement-table__row"
            @click="handleShowDetails(row.detailsData)"
          >
            <div class="statement-table__row-cell statement-table__icon-box">
              <div class="statement-table__icon">
                <svg v-if="row.icon">
                  <use
                    :xlink:href="
                      require('@/assets/images/svg-sprites.svg') +
                        '#' +
                        row.icon
                    "
                  />
                </svg>
              </div>
            </div>

            <div class="statement-table__row-cell statement-table__details">
              <b>{{ row.to }}</b>

              <div class="text-grey">
                <small>{{ row.account }}</small>
                <br>
                <small>{{ row.details }}</small>
              </div>
            </div>

            <div class="statement-table__row-cell statement-table__date">
              <div>{{ row.date }}</div>
              <small class="text-grey">{{ row.time }}</small>
            </div>

            <div
              class="statement-table__row-cell statement-table__amount"
              :class="{ 'is-empty': !row.debit }"
            >
              <span
                v-if="row.debit"
                class="text-red"
              >
                {{ row.debit | moneyformat }} {{ row.currency }}
              </span>
            </div>

            <div
              class="statement-table__row-cell statement-table__amount"
              :class="{ 'is-empty': !row.credit }"
            >
              <span
                v-if="row.credit"
                class="text-green"
              >
                {{ row.credit | moneyformat }} {{ row.currency }}
              </span>
            </div>

            <div class="statement-table__row-cell statement-table__balance">
              <span>{{ row.balance | moneyformat }} {{ row.currency }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import { statementTransactions } from "@/fixtures";

import ButtonCircle from "@/components/ButtonCircle";
import FormSelect from "@/components/Form/FormSelect";
import FormSelectGroup from "@/components/Form/FormSelectGroup";
import FormDatepicker from "@/components/Form/FormDatepicker";

export default {
  name: "StatementTable",

  components: {
    ButtonCircle,
    FormSelect,
    FormSelectGroup,
    FormDatepicker
  },

  data() {
    return {
      statementName: "Umbrella Inc",

      mobileFiltersOpen: false,

      accountsFilter: "All accounts",

      currencyFilter: "All currencies",

      typeFilter: "All types",

      search: "",

      sortBy: "date",

      dateRange: {
        start: new Date(2020, 0, 1),
        end: new Date(2020, 0, 4)
      }
    };
  },

  created() {
    this.transactions = statementTransactions;
  },

  methods: {
    ...mapActions(["showModal"]),

    handleSearch(e) {
      e.preventDefault();
    },

    handleShowDetails(detailsData) {
      this.showModal({
        component: "PaymentDetailsModal",
        data: detailsData
      });
    }
  }
};
</script>

<style scoped lang="scss">
.statement {
  width: 100%;
  margin: 0 auto;
}

.statement-wrapper {
  margin-bottom: 16px;

  @media screen and (min-width: $screen-md-min) {
    background-color: $white;
    border-radius: 8px;
    margin-bottom: 32px;
  }
}

.statement-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__company {
    font-size: 14px;
    line-height: 18px;
    color: $grey-dark-01;
  }

  &__title {
    font-family: $font-bold;
    font-size: 20px;
    line-height: 24px;
    color: $grey-dark;
  }

  &__toolbox {
    display: flex;
  }

  &__toolbox-item + &__toolbox-item {
    margin-left: 24px;
  }

  @media screen and (max-width: $screen-sm-max) {
    margin-bottom: 16px;

    &__company {
      display: none;
    }

    /deep/ &__toolbox .btn-circle__icon {
      width: 36px;
      height: 36px;
      background-color: $white;
      font-size: 16px;
    }
  }

  @media screen and (min-width: $screen-md-min) {
    padding: 20px 24px;
  }
}

.statement-filters {
  display: flex;
  flex-wrap: wrap;

  /deep/ & .form-select__dropdown-toggle {
    background-color: $bg-color;
  }

  &__group,
  &__date,
  &__search {
    display: flex;
    padding: 24px;
    border-top: 1px solid $grey-light-01;
  }

  &__group {
    width: 100%;
  }

  &__date {
    width: 280px;
  }

  &__search {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 0 24px;
  }

  /deep/ & .form-select-group {
    width: 100%;
  }

  &__search-input {
    min-width: 1%;
    flex: 1;
    text-align: right;
    font-size: 14px;
    line-height: 18px;
  }

  &__search-input::-webkit-input-placeholder {
    color: $grey-dark-02;
  }

  &__search-input:-ms-input-placeholder {
    color: $grey-dark-02;
  }

  &__search-input::placeholder {
    color: $grey-dark-02;
  }

  &__search-submit {
    display: inline-block;
    width: 24px;
    height: 24px;
    font-size: 0;
    color: $grey-dark-02;
    margin-left: 10px;
    transition: color 0.1s linear;
  }

  &__search-submit:before {
    content: "\e92d";
    @include inline-icon;
    font-size: 24px;
  }

  @media (hover: hover) {
    &__search-submit:hover {
      color: $accent-normal;
    }
  }

  @media screen and (max-width: $screen-sm-max) {
    display: none;
  }

  @media screen and (min-width: $screen-xlg-min) {
    &__date {
      width: 25%;
      order: 1;
      padding: 24px 12px 24px 24px;
    }

    &__group {
      width: 50%;
      order: 2;
      padding: 24px 12px;
    }

    &__search {
      width: 25%;
      order: 3;
    }
  }
}

.statement-mob-filters {
  margin-bottom: 16px;

  &__search {
    display: flex;
    margin-bottom: 4px;
  }

  &__search-form {
    flex: 1;
  }

  &__search-input {
    background-color: $white;
    border-radius: 4px 0px 0px 4px;
    min-width: 1%;
    width: 100%;
    padding: 10px 12px;
  }

  /deep/ & .form-datepicker__input,
  /deep/ &__filter-group .form-select__dropdown-toggle {
    background-color: $grey-light-01;
  }

  &__filter-toggle {
    display: inline-block;
    border-radius: 0 4px 4px 0;
    font-size: 0;
    width: 40px;
    min-width: 40px;
    background-color: rgba($additional-brown, 0.04);
    color: $additional-brown;
  }

  &__filter-toggle.is-active {
    color: $accent-normal;
  }

  &__filter-toggle:before {
    content: "\e917";
    @include inline-icon;
    font-size: 16px;
  }

  &__filter-group {
    overflow: hidden;
    height: 0;
    opacity: 0;
    transition: opacity 0.15s linear, height 0.15s linear,
      overflow 0s linear 0.15s;
  }

  &__filter-group.is-open {
    overflow: visible;
    height: 100%;
    opacity: 1;
    transition: opacity 0.15s linear, height 0.15s linear, overflow 0s linear 0s;
  }

  /deep/ &__filter-group .form-select {
    margin-top: 4px;
  }

  @media screen and (min-width: $screen-md-min) {
    display: none;
  }
}

.statement-table {
  &__header {
    display: flex;
    background-color: $grey-light-01;
    padding: 16px 20px;
  }

  &__header-cell {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: rgba($grey-dark, 0.6);
  }

  &__header-cell.is-sortable {
    cursor: pointer;
  }

  &__header-cell.is-sortable:after {
    content: "\e92f";
    @include inline-icon;
    color: $grey-dark-02;
    font-size: 16px;
    vertical-align: text-bottom;
  }

  &__header-cell.is-sorted:after {
    content: "\e930";
  }

  &__header-cell--type {
    width: 10%;
  }

  &__header-cell--conterparty {
    width: 30%;
  }

  &__header-cell--date {
    width: 16%;
  }

  &__header-cell--amount {
    width: 20%;
    text-align: right;
  }

  &__header-cell--balance {
    width: 24%;
    text-align: right;
  }

  &__row {
    position: relative;
    display: flex;
    padding: 16px 20px;
    cursor: pointer;
    transition: background-color 0.1s linear;
  }

  &__row + &__row {
    border-top: 1px solid $grey-light-01;
  }

  &__row-cell {
    font-size: 14px;
    line-height: 18px;
  }

  &__row-cell small {
    font-size: 14px;
    line-height: 18px;
  }

  &__row-cell b {
    font-family: $font-semibold;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    min-width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: $bg-color;
    margin: auto 0;
    transition: background-color 0.1s linear;
  }

  &__icon svg {
    max-width: 24px;
    max-height: 24px;
  }

  &__icon-box {
    display: flex;
    width: 10%;
  }

  &__details {
    width: 30%;
  }

  &__date {
    width: 16%;
  }

  &__amount,
  &__balance {
    text-align: right;
  }

  &__amount {
    width: 20%;
  }

  &__balance {
    width: 24%;
  }

  @media (hover: hover) {
    &__row:hover {
      background-color: rgba($grey-light-02, 0.5);

      .statement-table__icon {
        background-color: $additional-normal;
      }
    }
  }

  @media screen and (max-width: $screen-sm-max) {
    background-color: $white;
    border-radius: 8px;

    &__header {
      display: none;
    }

    &__row {
      padding: 36px 48px 16px 60px;
    }

    &__icon-box {
      position: absolute;
      left: 12px;
      top: 16px;
      min-width: 48px;
    }

    &__date {
      display: flex;
      position: absolute;
      top: 16px;
      left: 60px;
      min-width: 120px;
    }

    &__date > div {
      margin-right: 4px;
    }

    &__details {
      min-width: 100%;
    }

    &__amount {
      position: absolute;
      top: 16px;
      right: 16px;
      min-width: 30%;
      white-space: nowrap;
    }

    &__balance {
      display: none;
    }
  }

  @media screen and (min-width: $screen-md-min) {
    &__header-cell,
    &__row-cell {
      padding: 0 4px;
    }

    &__row-cell {
      font-size: 16px;
      line-height: 20px;
    }
  }

  @media screen and (max-width: $screen-lg-max) {
    &__header-cell--type {
      font-size: 0;
    }

    &__header-cell--debit-credit {
      display: none;
    }

    &__row-cell.is-empty {
      display: none;
    }
  }

  @media screen and (min-width: $screen-xlg-min) {
    &__header-cell--type {
      width: 6%;
    }

    &__header-cell--conterparty {
      width: 38%;
    }

    &__header-cell--date {
      width: 8%;
    }

    &__header-cell--debit-credit {
      width: 16%;
      text-align: right;
    }

    &__header-cell--amount {
      display: none;
    }

    &__header-cell--balance {
      width: 16%;
    }

    &__icon-box {
      width: 6%;
    }

    &__details {
      width: 38%;
    }

    &__date {
      width: 8%;
    }

    &__amount,
    &__balance {
      width: 16%;
    }
  }
}
</style>
