/*
  Mixin for setting body class from component pages;
*/

function getClassName(vm) {
  const { bodyClass } = vm.$options;

  if (bodyClass) {
    return typeof bodyClass === "function" ? bodyClass.call(vm) : bodyClass;
  }
}

export default {
  created() {
    const className = getClassName(this);

    if (className && !document.body.classList.contains(className)) {
      document.body.classList.add(className);
    }
  },
  destroyed() {
    const className = getClassName(this);

    if (className && document.body.classList.contains(className)) {
      document.body.classList.remove(className);
    }
  }
};
