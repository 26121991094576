<template>
  <div class="payout-modal-error">
    <div
      v-if="msg"
      class="payout-modal-error__msg"
    >
      {{ msg }}
    </div>

    <div
      v-if="details"
      class="payout-modal-error__details"
    >
      <template v-if="type === 'low_funds'">
        <div class="arrange arrange--wrap">
          <div
            v-for="(item, i) in details"
            :key="i"
            class="payout-modal-error__details-col"
          >
            <div class="payout-modal-error__details-line">
              <span>Payment: </span><span
                class="text-red"
              >{{ item.payment | moneyformat }} {{ item.currency }}</span>
            </div>

            <div class="payout-modal-error__details-line">
              <span>Available: </span><span
                class="text-green"
              >{{ item.available | moneyformat }} {{ item.currency }}</span>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "PayoutModalError",

  props: {
    type: {
      type: String,
      required: false,
      default: "",
      validator: value => {
        return ["low_funds"].indexOf(value) !== -1;
      }
    },

    msg: { type: String, required: false, default: "" },

    details: { type: [Object, Array], required: false, default: null }
  }
};
</script>

<style scoped lang="scss">
.payout-modal-error {
  background-color: rgba($red, 0.1);
  border-radius: 8px;

  &__msg {
    position: relative;
    padding: 12px 16px 12px 40px;
    color: $red;
    font-size: 16px;
    line-height: 20px;
  }

  &__msg:before {
    content: "\e93f";
    @include inline-icon;
    margin-right: 4px;
    position: absolute;
    left: 16px;
    top: 16px;
  }

  &__details {
    padding: 12px 16px;
    border-top: 1px solid $white;
    font-size: 14px;
    line-height: 18px;
  }

  &__details-col + &__details-col {
    margin-top: 12px;
  }

  &__details-line + &__details-line {
    margin-top: 4px;
  }

  @media screen and (max-width: $screen-sm-max) {
    &__details-col {
      width: 100%;
    }
  }

  @media screen and (min-width: $screen-md-min) {
    &__msg:before {
      top: 14px;
    }

    &__details-col + &__details-col {
      margin: 0 0 0 16px;
    }
  }
}
</style>
