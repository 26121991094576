<template>
  <DashboardLayout>
    <SavingsPlaceholder />
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "@/components/Layout/DashboardLayout";
import SavingsPlaceholder from "@/components/Blocks/SavingsPlaceholder";

export default {
  name: "Statement",

  components: {
    DashboardLayout,
    SavingsPlaceholder
  }
};
</script>
