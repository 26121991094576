<template>
  <footer class="dashboard-footer">
    <div class="dashboard-footer__item">
      <a
        href="/"
        class="dashboard-footer__link-item"
      >
        Terms and Conditions
      </a>

      <ul class="dashboard-footer__links">
        <li>
          <a
            href="tel:+442038079680"
            class="dashboard-footer__link-item"
          >
            +44 20380 79680
          </a>
        </li>

        <li>
          <a
            href="mailto:info@tpf.com"
            class="dashboard-footer__link-item"
          >
            info@tpf.com
          </a>
        </li>
      </ul>
    </div>

    <div
      class="dashboard-footer__item dashboard-footer__postfix text-grey text-sm"
    >
      <div class="dashboard-footer__postfix-item">
        Last login:
      </div>
      <div class="dashboard-footer__postfix-item">
        Today, 16:35, Latvia, 159.148.65.49
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "DashboardFooter"
};
</script>

<style scoped lang="scss">
.dashboard-footer {
  margin-top: auto;
  background-color: $white;
  padding: 16px;
  text-align: center;

  &__item + &__item {
    margin-top: 16px;
  }

  &__link-item {
    display: inline-block;
    font-size: 14px;
    line-height: 18px;
    color: $grey-dark-01;
    transition: color 0.1s linear;
    margin: 6px 0;
  }

  @media (hover: hover) {
    &__link-item:hover {
      color: $accent-light;
    }
  }

  @media screen and (min-width: $screen-md-min) {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    padding: 32px;
    text-align: left;

    &__item + &__item {
      margin-top: 0;
    }

    &__item:last-child {
      text-align: right;
    }

    &__link-item {
      margin: 0;
    }

    &__links {
      display: flex;
      margin-top: 12px;
    }

    &__links li + li {
      margin-left: 16px;
      padding-left: 16px;
      border-left: 1px solid $grey-dark-02;
    }

    &__postfix-item + &__postfix-item {
      margin-top: 12px;
    }
  }

  @media screen and (min-width: $screen-lg-min) {
    &__item {
      display: flex;
      align-items: center;
    }

    &__links {
      display: flex;
      margin: 0 0 0 16px;
      padding-left: 16px;
      border-left: 1px solid $grey-dark-02;
    }

    &__postfix-item + &__postfix-item {
      margin-top: 0;
      margin-left: 6px;
    }
  }
}
</style>
