<template>
  <div>
    <div
      class="modal"
      :class="[`is-${size}`, `is-${modalStyle}`, fixHeight && 'fix-height']"
    >
      <div
        class="modal__dialog"
        @click="handleCloseOutside"
      >
        <div
          ref="content"
          class="modal__content"
        >
          <div class="modal-header">
            <div
              v-if="hasTitle"
              class="modal-header__title"
            >
              <slot name="title" />
            </div>

            <div
              v-if="hasToolbox"
              class="modal-header__toolbox"
            >
              <slot name="toolbox" />
            </div>

            <button
              v-if="modalStyle !== 'success' && modalStyle !== 'info'"
              class="modal-header__close"
              @click="hideModal"
            >
              Close
            </button>
          </div>

          <div class="modal-body">
            <slot />
          </div>

          <div
            v-if="hasFooter"
            class="modal-footer"
          >
            <slot name="footer" />
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal__backdrop"
      @click="hideModal"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "ModalWrapper",

  props: {
    size: { type: String, required: false, default: "lg" },
    fixHeight: { type: Boolean, required: false, default: false },
    modalStyle: { type: String, required: false, default: "default" }
  },

  computed: {
    hasTitle() {
      return !!this.$slots.title;
    },

    hasToolbox() {
      return !!this.$slots.toolbox;
    },

    hasFooter() {
      return !!this.$slots.footer;
    }
  },

  created() {
    document.addEventListener("keyup", this.handleEsc);
  },

  destroyed() {
    document.removeEventListener("keyup", this.handleEsc);
  },

  methods: {
    ...mapActions(["hideModal"]),

    handleCloseOutside(e) {
      const content = this.$refs.content;
      const target = e.target;
      if (content && content !== target && !content.contains(target))
        this.hideModal();
    },

    handleEsc(e) {
      if (e.keyCode === 27) this.hideModal();
    }
  }
};
</script>

<style scoped lang="scss">
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;

  &__dialog {
    position: relative;
    width: auto;
    display: flex;
    align-items: center;
    height: 100%;
    overflow: auto;
    transform: translateY(0);
  }

  .fade-enter-active &__dialog,
  .fade-leave-active &__dialog {
    transition: transform 0.15s;
  }

  .fade-enter &__dialog,
  .fade-leave-to &__dialog {
    transform: translateY(10px);
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 100%;
    min-height: 100%;
    pointer-events: auto;
    background-color: $white;
    outline: 0;
  }

  &.fix-height &__content {
    max-height: 100%;
  }

  &__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba($grey-dark, 0.6);
    z-index: 1049;
  }

  @media screen and (min-width: $screen-md-min) {
    &__content {
      border-radius: 8px;
      min-height: 1%;
      height: auto;
      max-width: 100%;
    }

    &.is-lg &__content {
      width: 800px;
    }

    &.is-md &__content {
      width: 600px;
    }

    &.fix-height &__content {
      max-height: 90vh;
    }
  }
}

.modal-header {
  position: relative;
  display: flex;
  flex: 0 0 auto;

  .is-default & {
    align-items: center;
    min-height: 68px;
    padding: 16px 56px 16px 16px;
    border-bottom: 1px solid $grey-light-01;
  }

  .is-success &,
  .is-info & {
    position: relative;
    min-height: 112px;
    padding: 32px;
    color: $white;
    text-align: center;
    margin-bottom: 46px;
  }

  .is-success & {
    background-color: $green;
  }

  .is-info & {
    background-color: $additional-brown;
  }

  .is-success &::after,
  .is-info &::after {
    content: "\e946";
    @include inline-icon;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background-color: $white;
    position: absolute;
    bottom: -32px;
    left: 50%;
    transform: translateX(-50%);
    line-height: 64px;
  }

  .is-success &::after {
    content: "\e946";
    font-size: 16px;
    border: 4px solid $green;
    color: $green;
  }

  .is-info &::after {
    content: "\e948";
    font-size: 24px;
    border: 4px solid $additional-brown;
    color: $additional-brown;
  }

  &__title {
    font-family: $font-bold;
    font-size: 20px;
    line-height: 24px;
  }

  .is-success &__title,
  .is-info &__title {
    margin: 0 auto;
  }

  &__toolbox {
    display: flex;
    margin-left: auto;
  }

  &__close {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    width: 32px;
    height: 32px;
    font-size: 0;
    color: $grey-dark-02;
    transition: color 0.1s linear;
  }

  &__close:before {
    content: "\e934";
    @include inline-icon;
    font-size: 20px;
  }

  &__toolbox .btn-circle-md + .btn-circle-md {
    margin-left: 8px;
  }

  @media (hover: hover) {
    &__close:hover {
      color: $accent-normal;
    }
  }

  @media screen and (max-width: $screen-sm-max) {
    /deep/ &__toolbox .btn-circle-md .btn-circle__icon {
      width: 36px;
      height: 36px;
      font-size: 16px;
    }
  }

  @media screen and (min-width: $screen-md-min) {
    .is-default & {
      min-height: 88px;
      padding: 20px 72px 20px 24px;
    }

    .is-success &,
    .is-info & {
      min-height: 140px;
      padding: 32px;
      border-radius: 8px 8px 0 0;
      margin-bottom: 80px;
    }

    .is-success &::after,
    .is-info &::after {
      width: 100px;
      height: 100px;
      bottom: -56px;
      line-height: 100px;
    }

    .is-success &::after {
      font-size: 24px;
    }

    .is-info &::after {
      font-size: 44px;
    }

    .is-success &::after {
      border: 6px solid $green;
    }

    .is-info &::after {
      border: 6px solid $additional-brown;
    }

    .is-success &__title,
    .is-info &__title {
      font-size: 28px;
      line-height: 32px;
    }

    &__toolbox .btn-circle-md + .btn-circle-md {
      margin-left: 24px;
    }

    &__close:before {
      font-size: 24px;
    }
  }
}

.modal-body {
  padding: 0 16px;

  .fix-height & {
    overflow: auto;
  }

  @media screen and (min-width: $screen-md-min) {
    padding: 0 24px;
  }
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex: 0 0 auto;
  padding: 16px 8px;
  margin-top: auto;

  .fix-height & {
    border-top: 1px solid $grey-light-01;
  }

  .is-success &,
  .is-info & {
    padding: 16px;
    justify-content: center;
  }

  @media screen and (max-width: $screen-sm-max) {
    .is-success & .btn,
    .is-info & .btn {
      width: 100%;
    }
  }

  @media screen and (min-width: $screen-md-min) {
    padding: 12px;

    .is-success &,
    .is-info & {
      padding: 24px;
    }
  }
}
</style>
