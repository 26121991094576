<template>
  <div class="radio-group">
    <label
      v-for="(item, i) in items"
      :key="i"
    >
      <input
        v-model="thisModel"
        type="radio"
        :name="name"
        :value="item.value"
      >

      <span class="radio-group__item">
        <i
          v-if="item.icon"
          :class="`icon ${item.icon}`"
        />
        <span v-if="item.label">{{ item.label }}</span>
      </span>
    </label>
  </div>
</template>

<script>
export default {
  name: "RadioGroup",

  props: {
    model: { type: String, required: false, default: null },
    name: { type: String, required: false, default: "" },
    items: { type: Array, required: false, default: null }
  },

  data() {
    return {
      thisModel: this.model
    };
  },

  watch: {
    thisModel(val) {
      this.$emit("change", val);
    }
  }
};
</script>

<style scoped lang="scss">
.radio-group {
  display: inline-flex;

  & input {
    position: absolute;
    opacity: 0;
    visibility: 0;
  }

  &__item {
    display: block;
    padding: 0 16px;
    line-height: 48px;
    cursor: pointer;
    color: $white;
    background-color: rgba($additional-brown, 0.1);
    transition: background-color 0.1s linear;
  }

  &__item .icon {
    font-size: 22px;
    vertical-align: text-bottom;
  }

  & label:first-child &__item {
    border-radius: 8px 0px 0px 8px;
  }

  & label:last-child &__item {
    border-radius: 0px 8px 8px 0px;
  }

  input:checked + &__item {
    background-color: rgba($additional-brown, 0.3);
  }

  @media (hover: hover) {
    &__item:hover {
      background-color: rgba($additional-brown, 0.2);
    }
  }
}
</style>
