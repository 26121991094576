<template>
  <nav class="dashboard-nav">
    <ul class="dashboard-nav__primary-links">
      <li
        v-for="(link, i) in links"
        :key="i"
      >
        <Tooltip
          right
          :popover="link.title"
        >
          <router-link
            :to="link.url"
            class="dashboard-nav__links-item"
            active-class="is-current"
          >
            <i :class="`icon ${link.icon}`" />
            <span>{{ link.title }}</span>
          </router-link>
        </Tooltip>
      </li>
    </ul>

    <ul class="dashboard-nav__secondary-links">
      <li>
        <Tooltip
          right
          popover="Hide my info"
        >
          <button class="dashboard-nav__links-item">
            <i class="icon icon-eye" />
            <span>hide info</span>
          </button>
        </Tooltip>
      </li>

      <li
        v-for="(link, j) in secondaryLinks"
        :key="j"
      >
        <Tooltip
          right
          :popover="link.title"
        >
          <router-link
            :to="link.url"
            class="dashboard-nav__links-item"
            active-class="is-current"
          >
            <i :class="`icon ${link.icon}`" />
            <span>{{ link.title }}</span>
          </router-link>
        </Tooltip>
      </li>
    </ul>
  </nav>
</template>

<script>
import Tooltip from "@/components/Tooltip";

export default {
  name: "DashboardNav",

  components: {
    Tooltip
  },

  data() {
    return {
      links: [
        {
          icon: "icon-dashboard",
          title: "Dashboard",
          url: "/dashboard"
        },
        {
          icon: "icon-statement",
          title: "Statement",
          url: "/statement"
        },
        {
          icon: "icon-exchange",
          title: "Exchange",
          url: "/exchange"
        },
        {
          icon: "icon-saving",
          title: "Savings",
          url: "/savings"
        }
      ],

      secondaryLinks: [
        {
          icon: "icon-settings",
          title: "Settings",
          url: "/settings"
        }
      ]
    };
  }
};
</script>

<style scoped lang="scss">
.dashboard-nav {
  width: 72px;
  background-color: $additional-brown;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  display: flex;
  flex-direction: column;

  &__links-item {
    display: flex;
    width: 72px;
    height: 72px;
    color: $white;
    transition: background-color 0.1s linear, color 0.1s linear;
  }

  &__links-item.is-current {
    background-color: rgba($additional-brown-light, 0.3);
    color: $accent-light;
  }

  &__links-item span {
    font-size: 0;
  }

  &__links-item .icon {
    font-size: 24px;
    margin: auto;
  }

  &__secondary-links {
    margin-top: auto;
  }

  &__primary-links .tooltip:before,
  &__secondary-links .tooltip:before {
    white-space: nowrap;
  }

  @media (hover: hover) {
    &__links-item:hover {
      background-color: rgba($additional-brown-light, 0.3);
      color: $accent-light;
    }
  }

  @media screen and (max-width: $screen-sm-max) {
    display: none;
  }

  @media screen and (min-width: $screen-lg-min) {
    width: 88px;

    &__links-item {
      width: 88px;
      height: 88px;
    }
  }

  @media screen and (min-width: $screen-xlg-min) {
    width: 100px;

    &__links-item {
      width: 100px;
      height: 100px;
    }
  }
}
</style>
