<template>
  <div class="form-section">
    <div class="form-section__header">
      {{ title }}
    </div>

    <div class="form-section__body">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "FormSection",

  props: {
    title: { type: String, required: false, default: "" }
  }
};
</script>

<style scoped lang="scss">
.form-section {
  &__header {
    width: 218px;
    padding: 0 24px;
    font-family: $font-semibold;
    font-size: 16px;
    line-height: 20px;
  }

  &__body {
    flex: 1;
    padding: 0 24px;
  }

  @media screen and (min-width: $screen-lg-min) {
    display: flex;
  }
}
</style>
