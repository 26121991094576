var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ModalWrapper',{attrs:{"size":"md"}},[_c('template',{slot:"title"},[_vm._v(" Payment ")]),_c('ModalCallout',[_c('div',{staticClass:"payment-modal-header"},[_c('div',{staticClass:"text-sb"},[_vm._v(" Umbrella INC ")]),_c('div',{staticClass:"arrange arrange--wrap mt-32"},[_c('FormSelect',{staticClass:"payment-modal-header__account",attrs:{"id":"account","name":"account","label":"Account","el-style":"input-inverse","value":_vm.account,"options":['Account 1', 'Account 2', 'Account 3']},on:{"change":function($event){_vm.account = $event}}}),_c('div',{staticClass:"payment-modal-header__amount"},[_c('FormItemGroup',{attrs:{"label":"Amount","comment":"Available balance: 2,010.89"}},[_c('FormInput',{attrs:{"id":"amount","name":"amount","type":"number","el-style":"inverse","has-error":_vm.amountError,"value":_vm.amount},on:{"input":function($event){_vm.amount = $event}}}),_c('FormSelect',{attrs:{"id":"currency","name":"currency","el-width":"120px","el-style":"input-inverse","value":_vm.currency,"options":[
                { value: 'USD', title: 'USD', flag: 'us' },
                { value: 'EUR', title: 'EUR', flag: 'eu' },
                { value: 'GBP', title: 'GBP', flag: 'gb' }
              ]},on:{"change":function($event){_vm.currency = $event}}})],1)],1)],1)])]),_c('div',{staticClass:"arrange arrange--between text-sb mt-lg-24 mt-sm-16"},[_c('span',[_vm._v("Beneficiary")]),_c('ButtonLink',{attrs:{"icon":"icon-doc-sm","label":"Use template","btn-style":"dark","btn-size":"sm","click-handler":_vm.handleTemplate}})],1),_c('div',{staticClass:"mt-32"},[_c('FormInput',{attrs:{"id":"name","name":"name","type":"text","value":_vm.name,"label":"Name","has-error":_vm.nameError},on:{"input":function($event){_vm.name = $event}}})],1),_c('div',{staticClass:"mt-lg-40 mt-sm-32"},[_c('FormInput',{attrs:{"id":"address","name":"address","type":"text","value":_vm.address,"label":"Address","has-error":_vm.addressError},on:{"input":function($event){_vm.address = $event}}})],1),_c('div',{staticClass:"mt-lg-40 mt-sm-32"},[_c('FormInput',{attrs:{"id":"city","name":"city","type":"text","value":_vm.city,"label":"City","has-error":_vm.cityError},on:{"input":function($event){_vm.city = $event}}})],1),_c('div',{staticClass:"arrange mt-lg-40 mt-sm-32"},[_c('FormSelect',{staticClass:"payment-modal__country",attrs:{"id":"country","name":"country","label":"Country","value":_vm.country,"options":[
        { value: 'lt', title: 'Lithuania', flag: 'lt' },
        { value: 'lv', title: 'Latvia', flag: 'lv' },
        { value: 'ee', title: 'Estonia', flag: 'ee' }
      ]},on:{"change":function($event){_vm.country = $event}}}),_c('FormInput',{staticClass:"payment-modal__zip",attrs:{"id":"zip","name":"zip","type":"text","value":_vm.zip,"label":"Zip Code","has-error":_vm.zipError,"placeholder":"LV - 0000","mask":"LV - ####"},on:{"input":function($event){_vm.zip = $event}}})],1),_c('div',{staticClass:"text-sb mt-24"},[_vm._v(" Bank ")]),_c('div',{staticClass:"arrange mt-32"},[_c('FormInput',{staticClass:"payment-modal__bank-name",attrs:{"id":"bank_name","name":"bank-name","type":"text","value":_vm.bankName,"label":"Name","has-error":_vm.bankNameError},on:{"input":function($event){_vm.bankName = $event}}}),_c('FormInput',{staticClass:"payment-modal__swift",attrs:{"id":"swift","name":"swift","type":"text","value":_vm.swift,"label":"SWIFT","has-error":_vm.swiftError},on:{"input":function($event){_vm.swift = $event}}})],1),_c('div',{staticClass:"arrange arrange--wrap mt-lg-40 mt-sm-32"},[_c('FormSelect',{staticClass:"payment-modal__bank-country",attrs:{"id":"bank_country","name":"bank-country","label":"Country","value":_vm.bankCountry,"options":[
        { value: 'lt', title: 'Lithuania', flag: 'lt' },
        { value: 'lv', title: 'Latvia', flag: 'lv' },
        { value: 'ee', title: 'Estonia', flag: 'ee' }
      ]},on:{"change":function($event){_vm.bankCountry = $event}}}),_c('FormInput',{staticClass:"payment-modal__account-number",attrs:{"id":"account_number","name":"account-number","type":"text","value":_vm.accountNumber,"label":"Account number","has-error":_vm.accountNumberError},on:{"input":function($event){_vm.accountNumber = $event}}})],1),_c('div',{staticClass:"text-sb mt-24"},[_vm._v(" Payment details ")]),_c('div',{staticClass:"mt-32"},[_c('FormInput',{attrs:{"id":"information","name":"information","type":"text","value":_vm.information,"label":"Information","has-error":_vm.informationError},on:{"input":function($event){_vm.information = $event}}})],1),_c('div',{staticClass:"arrange arrange--align-center mt-24"},[_c('Toggle',{attrs:{"id":"corr_bank","name":"corr-bank","value":_vm.corrBank},on:{"change":function($event){_vm.corrBank = $event}}}),_c('span',{staticClass:"text-grey ml-8"},[_vm._v("Add correspondent bank")])],1),(_vm.corrBank)?_c('div',[_c('div',{staticClass:"arrange mt-40"},[_c('FormInput',{staticClass:"payment-modal__bank-name",attrs:{"id":"corr_bank_name","name":"corr-bank-name","type":"text","value":_vm.corrBankName,"label":"Name","has-error":_vm.corrBankNameError},on:{"input":function($event){_vm.corrBankName = $event}}}),_c('FormInput',{staticClass:"payment-modal__swift",attrs:{"id":"corr_swift","name":"corr-swift","type":"text","value":_vm.corrSwift,"label":"SWIFT","has-error":_vm.corrSwiftError},on:{"input":function($event){_vm.corrSwift = $event}}})],1),_c('div',{staticClass:"arrange arrange--wrap mt-lg-40 mt-sm-32"},[_c('FormSelect',{staticClass:"payment-modal__bank-country",attrs:{"id":"corr_bank_country","name":"corr-bank-country","label":"Country","value":_vm.corrBankCountry,"options":[
          { value: 'lt', title: 'Lithuania', flag: 'lt' },
          { value: 'lv', title: 'Latvia', flag: 'lv' },
          { value: 'ee', title: 'Estonia', flag: 'ee' }
        ]},on:{"change":function($event){_vm.corrBankCountry = $event}}}),_c('FormInput',{staticClass:"payment-modal__account-number",attrs:{"id":"corr_account_number","name":"corr-account-number","type":"text","value":_vm.corrAccountNumber,"label":"Account number","has-error":_vm.corrAccountNumberError},on:{"input":function($event){_vm.corrAccountNumber = $event}}})],1)]):_vm._e(),_c('div',{staticClass:"mt-24"},[_c('DocumentDropzone')],1),_c('template',{slot:"footer"},[_c('ModalActionWrapper',[_c('Button',{attrs:{"label":"Pay","btn-style":"primary","click-handler":_vm.handleSubmit}})],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }