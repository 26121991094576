<template>
  <AuthLayout :show-side-bg="false">
    <h1 class="h1-bold auth-title">
      Two-Factor Authentication
    </h1>

    <div class="mt-8 text-grey text-sm">
      We've sent a code to the phone number ending in <span class="text-black">64</span>.
      <br>
      Add it below to finish logging in.
    </div>

    <div class="mt-40">
      <FormInput
        id="verification"
        name="verification"
        type="number"
        label="Verification code"
        el-width="100%"
        :value="form.verification"
        :error="form.verificationError"
        @input="form.verification = $event"
      />
    </div>

    <div class="mt-16">
      <ButtonLink
        btn-style="grey"
        btn-size="sm"
        label="Send again"
      />
    </div>

    <div class="arrange mt-24">
      <Button
        icon="icon-back-mobile"
        label="Back"
        btn-style="transparent"
        btn-width="100px"
      />

      <div class="w-100 ml-24">
        <Button
          label="Log in"
          btn-style="primary"
          btn-width="100%"
        />
      </div>
    </div>
  </AuthLayout>
</template>

<script>
import AuthLayout from "@/components/Layout/AuthLayout";
import FormInput from "@/components/Form/FormInput";
import ButtonLink from "@/components/ButtonLink";
import Button from "@/components/Button";

export default {
  name: "Login",

  components: {
    AuthLayout,
    FormInput,
    ButtonLink,
    Button
  },

  data() {
    return {
      form: {
        verification: "",
        verificationError: "",
        remember: true
      }
    };
  }
};
</script>
