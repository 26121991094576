<template>
  <component
    :is="type"
    :to="to"
    :class="`btn btn-${btnStyle} btn-${btnSize} btn-${btnAlign}`"
    :style="{ minWidth: btnWidth }"
    @click="clickHandler"
  >
    <i
      v-if="icon"
      :class="`icon ${icon}`"
    />
    <span>{{ label }}</span>
  </component>
</template>

<script>
export default {
  name: "Button",

  props: {
    icon: { type: String, required: false, default: "" },
    label: { type: String, required: false, default: "" },
    btnStyle: { type: String, required: false, default: "" },
    btnSize: { type: String, required: false, default: "md" },
    btnAlign: { type: String, required: false, default: "center" },
    btnWidth: { type: String, required: false, default: "" },
    to: { type: String, required: false, default: "" },
    clickHandler: { type: Function, required: false, default: () => {} }
  },

  computed: {
    type() {
      if (this.to) {
        return "router-link";
      }

      return "button";
    }
  }
};
</script>

<style scoped lang="scss">
.btn {
  display: inline-flex;
  white-space: nowrap;
  align-items: center;
  border: 2px solid transparent;
  transition: background-color 0.1s linear, border-color 0.1s linear, box-shadow 0.1s linear;

  &:disabled {
    pointer-events: none;
  }

  &-sm,
  &-md {
    border-radius: 8px;
    font-family: $font-semibold;
    line-height: 44px;
    font-size: 16px;
  }

  &-sm {
    padding: 0 16px;
  }

  &-md {
    padding: 0 24px;
  }

  &-md .icon {
    font-size: 26px;
  }

  &-center {
    justify-content: center;
  }

  &-left {
    justify-content: flex-start;
  }

  & .icon {
    margin-right: 8px;
  }
}

.btn-primary {
  background-color: $accent-normal;
  color: $white;

  &:disabled {
    opacity: 0.3;
  }

  @media (hover: hover) {
    &:hover {
      background-color: $accent-light;
    }

    &:focus {
      background-color: $accent-normal;
      border-color: $accent-dark;
    }

    &:active {
      background-color: $accent-dark;
    }
  }
}

.btn-dark {
  background-color: rgba($accent-normal, 0.1);
  color: $additional-brown;

  &:disabled {
    opacity: 0.5;
  }

  @media (hover: hover) {
    &:hover {
      background-color: rgba($accent-normal, 0.3);
    }

    &:focus {
      background-color: rgba($accent-normal, 0.1);
      border: 2px solid rgba($additional-brown, 0.5);
    }

    &:active {
      background-color: rgba($accent-normal, 0.5);
    }
  }
}

.btn-dark-opacity {
  background-color: $grey-light-02;
  color: $additional-brown;
  border: none;

  &.btn-sm,
  &.btn-md {
    line-height: 48px;
  }

  &:disabled {
    opacity: 0.5;
  }

  @media (hover: hover) {
    &:hover {
      background-color: $additional-normal;
    }

    &:focus {
      background-color: rgba($grey-light-02, 0.04);
      box-shadow: inset 0 0 0 1px rgba($additional-brown, 0.75);
    }

    &:active {
      background-color: rgba($additional-normal, 0.5);
      box-shadow: none;
    }
  }
}

.btn-grey-light {
  font-family: $font-regular;
  color: $white;
  background-color: rgba($grey-light-02, 0.1);
  border: none;

  &.btn-sm,
  &.btn-md {
    line-height: 48px;
  }

  &:disabled {
    opacity: 0.5;
  }

  @media (hover: hover) {
    &:hover {
      background-color: rgba($grey-light-01, 0.2);
    }

    &:focus {
      background-color: rgba($grey-light-02, 0.1);
      box-shadow: inset 0 0 0 1px rgba($additional-brown, 0.8);
    }

    &:active {
      background-color: rgba($grey-dark-02, 0.3);
      box-shadow: none;
    }
  }
}

.btn-grey-light-opacity {
  font-family: $font-regular;
  background-color: $grey-light-02;
  color: $additional-brown;

  &:disabled {
    opacity: 0.5;
  }

  @media (hover: hover) {
    &:hover {
      background-color: $additional-normal;
    }

    &:focus {
      background-color: $grey-light-02;
      border-color: $additional-brown;
    }

    &:active {
      background-color: rgba($additional-normal, 0.5);
      border-color: transparent;
    }
  }
}

.btn-transparent {
  background-color: transparent;
  font-family: $font-regular;
  color: $additional-brown;
  transition: color 0.1s linear;

  &:disabled {
    opacity: 0.5;
  }

  @media (hover: hover) {
    &:hover,
    &:focus,
    &:active {
      color: $accent-light;
    }
  }
}

.btn-layout-more {
  width: 100%;

  @media screen and (min-width: $screen-md-min) {
    justify-content: flex-start;
  }
}

.btn-input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  & .icon {
    margin-right: 0;
  }

  @media (hover: hover) {
    &:hover {
      background-color: $grey-light-01;
    }

    &:focus {
      background-color: $grey-light-02;
      border: 2px solid $additional-brown;
    }

    &:active {
      background-color: $grey-normal;
    }
  }
}

.btn-dark-light {
  background-color: rgba($grey-light-02, 0.1);
  color: $white;

  &:disabled {
    opacity: 0.5;
  }

  @media (hover: hover) {
    &:hover {
      background-color: rgba($grey-light-01, 0.2);
    }

    &:focus {
      background-color: rgba($grey-light-02, 0.1);
      border: 2px solid $grey-dark-02;
    }

    &:active {
      background-color: rgba($grey-dark-02, 0.3);
    }
  }

  @media screen and (min-width: $screen-lg-min) {
    background-color: rgba($additional-brown, 0.04);
    color: $additional-brown;

    @media (hover: hover) {
      &:hover {
        background-color: rgba($additional-brown, 0.1);
      }

      &:focus {
        background-color: rgba($additional-brown, 0.04);
        border: 2px solid rgba($additional-brown, 0.5);
      }

      &:active {
        background-color: rgba($additional-brown, 0.15);
      }
    }
  }
}
</style>
