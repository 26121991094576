var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ModalWrapper',{attrs:{"size":"md"}},[_c('template',{slot:"title"},[_vm._v(" Currency Exchange ")]),_c('div',{staticClass:"exchange-modal-body"},[_c('div',{staticClass:"arrange"},[_c('TabButton',{attrs:{"label":"Buy","target":"buy","is-selected":_vm.currentTab === 'buy'},on:{"select":function($event){_vm.currentTab = $event}}}),_c('TabButton',{attrs:{"label":"Sell","target":"sell","is-selected":_vm.currentTab === 'sell'},on:{"select":function($event){_vm.currentTab = $event}}})],1),(_vm.currentTab === 'buy')?_c('div',{key:"buy"},[_c('div',{staticClass:"mt-40"},[_c('FormSelect',{attrs:{"label":"Account","el-style":"input","value":_vm.buy.account,"options":['Account 1', 'Account 2', 'Account 3']},on:{"change":function($event){_vm.buy.account = $event}}})],1),_c('div',{staticClass:"exchange-modal-body__main-row"},[_c('div',{staticClass:"exchange-modal-body__main-primary"},[_c('FormItemGroup',{attrs:{"label":"Amount (I'm buying)"}},[_c('FormInput',{attrs:{"type":"number","value":_vm.buy.amount},on:{"input":function($event){_vm.buy.amount = $event}}}),_c('FormSelect',{attrs:{"el-width":"120px","el-style":"input","value":_vm.buy.buyCurrency,"options":[
                { value: 'gbp', title: 'GBP', flag: 'gb' },
                { value: 'usd', title: 'USD', flag: 'us' },
                { value: 'eur', title: 'EUR', flag: 'eu' }
              ]},on:{"change":function($event){_vm.buy.buyCurrency = $event}}})],1)],1),_c('div',{staticClass:"exchange-modal-body__main-secondary"},[_c('FormSelect',{attrs:{"label":"For","el-style":"input","value":_vm.buy.sellCurrency,"options":[
              { value: 'gbp', title: 'GBP', flag: 'gb' },
              { value: 'usd', title: 'USD', flag: 'us' },
              { value: 'eur', title: 'EUR', flag: 'eu' }
            ]},on:{"change":function($event){_vm.buy.sellCurrency = $event}}})],1),_c('span',{staticClass:"exchange-modal-body__main-subscript"},[_vm._v(" Rate: 1 GBP = 1.31 USD ")])])]):_vm._e(),(_vm.currentTab === 'sell')?_c('div',{key:"sell"},[_c('div',{staticClass:"mt-40"},[_c('FormSelect',{attrs:{"label":"Account","el-style":"input","value":_vm.sell.account,"options":['Account 1', 'Account 2', 'Account 3']},on:{"change":function($event){_vm.sell.account = $event}}})],1),_c('div',{staticClass:"exchange-modal-body__main-row"},[_c('div',{staticClass:"exchange-modal-body__main-primary"},[_c('FormItemGroup',{attrs:{"label":"Amount (I'm selling)"}},[_c('FormInput',{attrs:{"el-width":"236px","type":"number","value":_vm.sell.amount},on:{"input":function($event){_vm.sell.amount = $event}}}),_c('FormSelect',{attrs:{"el-width":"120px","el-style":"input","value":_vm.sell.buyCurrency,"options":[
                { value: 'gbp', title: 'GBP', flag: 'gb' },
                { value: 'usd', title: 'USD', flag: 'us' },
                { value: 'eur', title: 'EUR', flag: 'eu' }
              ]},on:{"change":function($event){_vm.sell.buyCurrency = $event}}})],1)],1),_c('div',{staticClass:"exchange-modal-body__main-secondary"},[_c('FormSelect',{attrs:{"label":"For","el-style":"input","value":_vm.sell.sellCurrency,"options":[
              { value: 'gbp', title: 'GBP', flag: 'gb' },
              { value: 'usd', title: 'USD', flag: 'us' },
              { value: 'eur', title: 'EUR', flag: 'eu' }
            ]},on:{"change":function($event){_vm.sell.sellCurrency = $event}}})],1),_c('span',{staticClass:"exchange-modal-body__main-subscript"},[_vm._v(" Rate: 1 USD = 0.91 EUR ")])])]):_vm._e()]),_c('ModalCallout',[_c('div',{staticClass:"exchange-modal-info"},[_c('div',{staticClass:"exchange-modal-info__primary"},[_vm._v(" "+_vm._s(_vm.currentTab === "buy" ? "You will need" : "You will get")+" "),_c('span',{staticClass:"text-black"},[_vm._v("130.74")]),_vm._v(" USD ")]),_c('div',{staticClass:"exchange-modal-info__secondary"},[_vm._v(" Conversion will be performed at the market rate at the moment of transaction ")])])]),_c('template',{slot:"footer"},[_c('ModalActionWrapper',[_c('Button',{attrs:{"label":"Exchange","btn-style":"primary","click-handler":_vm.handleSubmit}})],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }