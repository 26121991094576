<template>
  <ModalWrapper size="md">
    <template slot="title">
      Order card
    </template>

    <ModalCallout>
      <StepsModal
        :current-step="currentStep"
        :steps="3"
      />
    </ModalCallout>

    <div v-if="currentStep === 1">
      <div class="text-sb mt-24">
        Currency of the card
      </div>

      <div class="new-card-modal-currencies__list">
        <div
          v-for="(item, i) in currencies"
          :key="i"
          :class="[i == 0 ? 'mt-12' : 'mt-24']"
        >
          <FormRadioBox
            name="currency"
            :value="selectedCurrency"
            :label="item.title"
            @change="selectedCurrency = $event"
          >
            <template slot="title">
              {{ item.title }}
            </template>

            <template slot="img">
              <img
                v-if="item.flag"
                class="new-card-modal-currencies__item-flag"
                :src="require(`@/assets/images/flags/${item.flag}.svg`)"
                :alt="item.title"
              >
            </template>
          </FormRadioBox>
        </div>
      </div>
    </div>

    <div v-if="currentStep === 2">
      <div class="text-sb mt-24">
        Address for card delivery
      </div>

      <div class="new-card-modal-form">
        <div class="mt-32">
          <FormInput
            id="address"
            name="address"
            type="text"
            :value="form.address"
            label="Address"
            :has-error="form.addressError"
            @input="form.address = $event"
          />
        </div>

        <div class="arrange mt-lg-40 mt-sm-32">
          <FormInput
            id="city"
            name="city"
            type="text"
            :value="form.city"
            label="City"
            :has-error="form.cityError"
            el-width="60%"
            @input="form.city = $event"
          />

          <FormInput
            id="zip"
            name="zip"
            type="text"
            :value="form.zip"
            label="Zip Code"
            :has-error="form.zipError"
            el-width="40%"
            placeholder="LV - 0000"
            mask="LV - ####"
            class="ml-lg-24 ml-sm-16"
            @input="form.zip = $event"
          />
        </div>

        <div class="arrange mt-lg-40 mt-sm-32">
          <FormSelect
            id="country"
            name="country"
            label="Country"
            el-width="60%"
            el-style="input"
            :value="form.country"
            :options="[
              { value: 'lt', title: 'Lithuania', flag: 'lt' },
              { value: 'lv', title: 'Latvia', flag: 'lv' },
              { value: 'ee', title: 'Estonia', flag: 'ee' }
            ]"
            @change="form.country = $event"
          />

          <FormInput
            id="state"
            name="state"
            type="text"
            :value="form.state"
            label="State"
            :has-error="form.stateError"
            el-width="40%"
            class="ml-lg-24 ml-sm-16"
            @input="form.state = $event"
          />
        </div>

        <div class="arrange mt-lg-40 mt-sm-32">
          <div>
            <FormSelect
              id="country"
              name="country"
              label="Country"
              el-width="130px"
              el-style="input"
              :value="form.phoneCode"
              :options="[
                { value: '+370', title: '+370', flag: 'lt' },
                { value: '+371', title: '+371', flag: 'lv' },
                { value: '+372', title: '+372', flag: 'ee' }
              ]"
              @change="form.phoneCode = $event"
            />
          </div>

          <FormInput
            id="phone"
            name="phone"
            type="tel"
            :value="form.phone"
            label="Phone number"
            :has-error="form.phoneError"
            placeholder="000 000 00 00"
            mask="### ### ## ##"
            class="ml-lg-24 ml-sm-16"
            @input="form.phone = $event"
          />
        </div>
      </div>
    </div>

    <div v-if="currentStep === 3">
      <div class="text-sb mt-24">
        Type of delivery
      </div>

      <div class="mt-lg-12 mt-sm-16">
        <FormRadioBox
          name="delivery-type"
          :value="deliveryType"
          label="express"
          @change="deliveryType = $event"
        >
          <template slot="title">
            Express
          </template>

          <template slot="comment">
            Price: 40–60 EUR. Takes around 2–3 days
          </template>
        </FormRadioBox>
      </div>

      <div class="mt-lg-24 mt-sm-16">
        <FormRadioBox
          name="delivery-type"
          :value="deliveryType"
          label="ordinary"
          @change="deliveryType = $event"
        >
          <template slot="title">
            Ordinary delivery
          </template>

          <template slot="comment">
            Price: FREE. Takes around 10–14 days
          </template>
        </FormRadioBox>
      </div>
    </div>

    <template slot="footer">
      <ModalActionWrapper>
        <Button
          :label="currentStep < 3 ? 'Continue' : 'Order payment card'"
          btn-style="primary"
          :click-handler="handleNextStep"
        />
      </ModalActionWrapper>
    </template>
  </ModalWrapper>
</template>

<script>
import { mapActions } from "vuex";

import ModalWrapper from "@/components/Modal/ModalWrapper";
import ModalCallout from "@/components/Modal/ModalCallout";
import ModalActionWrapper from "@/components/Modal/ModalActionWrapper";
import StepsModal from "@/components/Modal/StepsModal";
import Button from "@/components/Button";
import FormInput from "@/components/Form/FormInput";
import FormSelect from "@/components/Form/FormSelect";
import FormRadioBox from "@/components/Form/FormRadioBox";

export default {
  name: "NewCardModal",

  components: {
    ModalWrapper,
    ModalCallout,
    ModalActionWrapper,
    StepsModal,
    Button,
    FormInput,
    FormSelect,
    FormRadioBox
  },

  data() {
    return {
      currentStep: 1,

      selectedCurrency: null,

      currencies: [
        { title: "EUR", flag: "eu" },
        { title: "GBP", flag: "gb" },
        { title: "USD", flag: "us" }
      ],

      form: {
        address: "",
        city: "",
        zip: "",
        country: "lv",
        phoneCode: "+371",
        phone: ""
      },

      deliveryType: "express"
    };
  },

  methods: {
    ...mapActions(["pushNotification", "hideModal"]),

    handleCurrencySelect(currency) {
      if (this.selectedCurrencies.includes(currency)) {
        const index = this.selectedCurrencies.indexOf(currency);

        if (index > -1) this.selectedCurrencies.splice(index, 1);
      } else {
        this.selectedCurrencies.push(currency);
      }
    },

    handleNextStep() {
      if (this.currentStep + 1 > 3) {
        this.hideModal();

        const notification = {
          type: "success",
          title: "Card has been successfully ordered",
          message: "Please wait for our specialist to contact you"
        };

        this.pushNotification(notification);

        return;
      }

      this.currentStep = this.currentStep + 1;
    }
  }
};
</script>
