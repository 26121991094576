<template>
  <div
    ref="selectRef"
    class="form-select form-item"
    :class="{
      'is-open': isOpen,
      'to-top': top,
      'has-error': hasError,
      'is-disabled': disabled,
      'input-style': elStyle === 'input',
      'input-black-style': elStyle === 'input-black',
      'input-inverse-style': elStyle === 'input-inverse',
      'filter-style': elStyle === 'filter',
      'plain-style': elStyle === 'plain',
      'no-value': !value
    }"
    :style="{
      width: elWidth,
      minWidth: elMinWidth
    }"
  >
    <select
      :id="id"
      v-model="selectedOptions"
      :name="name"
      :multiple="multipleChoice"
    >
      <option
        v-for="(option, i) in options"
        :key="i"
        :value="option.value || option"
      >
        {{ option.title || option }}
      </option>
    </select>

    <label
      v-if="label"
      class="form-select__label"
    >{{ label }}</label>

    <div class="form-select__dropdown">
      <button
        class="form-select__dropdown-toggle"
        @click="handleDrop"
      >
        <img
          v-if="selectedFlag"
          :src="require(`@/assets/images/flags/${selectedFlag}.svg`)"
          :alt="title"
          class="flag"
        >

        <span
          class="form-select__dropdown-toggle-title"
          :title="title"
        >
          {{ title }}
        </span>
        <span class="form-select__dropdown-toggle-arrow" />
      </button>

      <div class="form-select__dropdown-container">
        <ul>
          <li
            v-for="(option, j) in options"
            :key="j"
          >
            <button
              :class="{
                'is-selected': isOptionSelected(option.value || option)
              }"
              :disabled="option.disabled"
              @click="handleSelect(option.value || option)"
            >
              <img
                v-if="option.flag"
                :src="require(`@/assets/images/flags/${option.flag}.svg`)"
                :alt="option.title"
                class="flag"
              >

              <span
                v-if="phoneCode && option.country"
                class="mr-4"
              >
                {{ option.country }}
              </span>

              <span
                v-if="phoneCode && option.country"
                class="text-grey"
              >
                ({{ option.title || option }})
              </span>

              <span
                v-else
              >
                {{ option.title || option }}
              </span>

            </button>
          </li>
        </ul>
      </div>
    </div>

    <span
      v-if="hasError"
      class="form-select__error"
    >{{ hasError }}</span>
  </div>
</template>

<script>
export default {
  name: "FormSelect",

  props: {
    label: { type: String, required: false, default: "" },
    elWidth: { type: String, required: false, default: "" },
    elMinWidth: { type: String, required: false, default: "" },
    elStyle: {
      type: String,
      required: false,
      default: "input",
      validator: function(value) {
        return (
          ["input", "input-black", "input-inverse", "filter", "plain"].indexOf(value) !== -1
        );
      }
    },
    id: { type: String, required: false, default: "" },
    name: { type: String, required: false, default: "" },
    value: { type: [String, Number, Array], required: false, default: "" },
    placeholder: { type: String, required: false, default: "" },
    options: { type: Array, required: false, default: null },
    disabled: { type: Boolean, required: false, default: false },
    hasError: { type: String, required: false, default: "" },
    multipleChoice: { type: Boolean, required: false, default: false },
    phoneCode: { type: Boolean, required: false, default: false },
  },

  data() {
    return {
      selectedOptions: this.multipleChoice ? [this.value] : this.value,
      isOpen: false,
      top: false
    };
  },

  computed: {
    title() {
      if (this.multipleChoice) {
        const isArray = Array.isArray(this.selectedOptions);
        if (!isArray) return this.placeholder;

        const joinedValues = this.selectedOptions.join(', ');
        return joinedValues;
      }

      const selected = this.options.find(item => {
        if (item.value) return item.value === this.selectedOptions;
        return item === this.selectedOptions;
      });

      if (!selected) return this.placeholder;

      return selected.title ? selected.title : selected;
    },

    selectedFlag() {
      const selected = this.options.find(item => {
        if (item.value) return item.value === this.selectedOptions;

        return item === this.selectedOptions;
      });

      return selected && selected.flag ? selected.flag : "";
    }
  },

  watch: {
    selectedOptions(val) {
      this.$emit("change", val);
    }
  },

  destroyed() {
    document.removeEventListener("mousedown", this.handleCloseAll);
  },

  mounted() {
    this.top = this.toTop();
  },

  methods: {
    handleDrop() {
      this.isOpen = !this.isOpen;

      if (this.isOpen) {
        document.addEventListener("mousedown", this.handleCloseAll);

        this.top = this.toTop();
      } else {
        document.removeEventListener("mousedown", this.handleCloseAll);
      }
    },

    handleCloseAll(e) {
      const el = this.$refs.selectRef;
      const target = e.target;
      if (el !== target && !el.contains(target)) {
        this.isOpen = false;
        document.removeEventListener("mousedown", this.handleCloseAll);
      }
    },

    toTop() {
      if (!this.$refs.selectRef) return false;

      const selectRect = this.$refs.selectRef.getBoundingClientRect();
      const availableSpace =
        window.innerHeight - selectRect.y - selectRect.height;

      return availableSpace < 250;
    },

    handleSelect(val) {
      let selectedOptions = this.selectedOptions;
      const firstOption = this.options[0];
      const isFirstOptionSelected = firstOption === val;
      const isFirstOptionInArray = this.multipleChoice ? selectedOptions.indexOf(firstOption) !== -1 : selectedOptions === firstOption;
      const selectedOptionIndex = option => selectedOptions.indexOf(option);
      const addOption = option => selectedOptions.push(option);
      const removeOption = option => selectedOptions.splice(selectedOptionIndex(option), 1);

      if (!this.multipleChoice) {
        selectedOptions = val;
        this.selectedOptions = selectedOptions;
        this.isOpen = false;

        return;
      }

      if (isFirstOptionSelected) {
        if (isFirstOptionInArray) {
          this.isOpen = false;
          return;
        }

        selectedOptions = [];
        addOption(firstOption);
        this.selectedOptions = selectedOptions;
        this.isOpen = false;

      } else {
        isFirstOptionInArray ? removeOption(firstOption) : false;
        selectedOptionIndex(val) === -1 ? addOption(val) : removeOption(val);
        !selectedOptions.length ? addOption(firstOption) : false;
        this.selectedOptions = selectedOptions;
      }
    },

    isOptionSelected(option) {
      return this.multipleChoice ? this.selectedOptions.indexOf(option) !== -1 : this.selectedOptions === option;
    },
  }
};
</script>

<style scoped lang="scss">
.form-select {
  position: relative;

  & select {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }

  & select:focus ~ &__dropdown &__dropdown-toggle {
    border-color: $accent-normal;
  }

  &__label {
    position: absolute;
    top: -22px;
    left: 0;
    display: inline-block;
    font-size: 14px;
    line-height: 18px;
    color: $grey-dark-01;
  }

  &__dropdown {
    position: relative;
    width: 100%;
  }

  &__dropdown-toggle {
    font-family: $font-regular;
    background-color: $grey-light-02;
    width: 100%;
    border: 1px solid transparent;
    display: inline-flex;
    align-items: center;
    transition: background-color 0.1s linear;

    &:hover {
      background-color: $additional-normal;
    }
  }

  &.input-inverse-style &__dropdown-toggle {
    background-color: $white;
  }

  &__dropdown-toggle-title {
    flex: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    display: block;
    overflow: hidden;
  }

  &.input-style &__dropdown-toggle,
  &.input-black-style &__dropdown-toggle,
  &.input-inverse-style &__dropdown-toggle {
    font-size: 16px;
    line-height: 48px;
    padding: 0 12px 0 16px;
    border-radius: 8px;
    text-align: left;
  }

  &.input-style &__dropdown-toggle,
  &.input-inverse-style &__dropdown-toggle {
    color: $grey-dark;
  }

  &.input-black-style &__dropdown-toggle {
    color: black;
  }

  &.filter-style &__dropdown-toggle {
    font-size: 14px;
    line-height: 38px;
    padding: 0 32px;
    border-radius: 4px;
    color: $additional-brown;
    text-align: center;
  }

  &.plain-style &__dropdown-toggle {
    text-align: left;
  }

  .has-error &__dropdown-toggle {
    border-color: $red;
  }

  .form-select-group &.filter-style &__dropdown-toggle {
    border-radius: 0;
  }

  .form-select-group &.filter-style:first-child &__dropdown-toggle {
    border-radius: 4px 0px 0px 4px;
  }

  .form-select-group &.filter-style:last-child &__dropdown-toggle {
    border-radius: 0px 4px 4px 0px;
  }

  .form-select-group & + & &__dropdown-toggle,
  .form-item-group .form-item + & &__dropdown-toggle {
    border-left: 1px solid $grey-dark-02;
  }

  .form-item-group .form-item + &.input-inverse-style &__dropdown-toggle {
    border-left: 1px solid $grey-normal;
  }

  .form-item-group & &__dropdown-toggle {
    border-radius: 0;
  }

  .form-item-group &:first-child &__dropdown-toggle {
    border-radius: 8px 0 0 8px;
  }

  .form-item-group &:last-child &__dropdown-toggle {
    border-radius: 0 8px 8px 0;
  }

  &__dropdown-toggle-arrow:before {
    content: "\e911";
    @include inline-icon;
    line-height: 16px;
  }

  &.input-style &__dropdown-toggle-arrow:before,
  &.input-inverse-style &__dropdown-toggle-arrow:before,
  &.plain-style &__dropdown-toggle-arrow:before {
    margin-left: auto;
    color: $grey-dark-01;
    padding-left: 4px;
  }

  &.input-style.no-value &__dropdown-toggle {
    color: $grey-dark-01;
  }

  &.filter-style &__dropdown-toggle-arrow:before {
    margin-left: 4px;
  }

  &.is-open &__dropdown-toggle-arrow:before {
    content: "\e912";
  }

  &__dropdown-toggle .flag,
  &__dropdown-container .flag {
    width: 28px;
    margin-right: 8px;
    vertical-align: text-bottom;
  }

  &__dropdown-container {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 99;
    min-width: 100%;
    max-width: 80vw;
    color: $grey-dark;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-4px);
    transition: opacity 0.1s linear, transform 0.1s linear,
      visibility 0s linear 0.2s;
  }

  &__dropdown-container li + li {
    border-top: 1px solid $grey-light-01;
  }

  &.is-open &__dropdown-container {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: opacity 0.1s linear, transform 0.1s linear,
      visibility 0s linear 0s;
  }

  &.to-top &__dropdown-container {
    top: auto;
    bottom: 100%;
    transform: translateY(4px);
  }

  &.is-open.to-top &__dropdown-container {
    transform: translateY(0);
  }

  &__dropdown-container ul {
    background-color: $white;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08),
      0px 8px 12px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    margin: 4px 0;
    max-height: 250px;
    overflow: auto;
  }

  &__dropdown-container ul button {
    position: relative;
    display: block;
    width: 100%;
    padding: 16px 36px 16px 12px;
    text-align: left;
    white-space: nowrap;
    transition: background-color 0.1s linear;
  }

  &__dropdown-container ul button span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 100%;
  }

  &__dropdown-container button.is-selected:after {
    content: "\e919";
    @include inline-icon;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: $accent-normal;
  }

  &__error {
    display: inline-block;
    font-size: 12px;
    line-height: 16px;
    color: $red;
    margin-top: 4px;
  }

  &.is-disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  @media (hover: hover) {
    &__dropdown-container ul button:hover {
      background-color: $grey-light-02;
    }
  }
}
</style>
