<template>
  <AuthLayout
    :show-side-bg="false"
    :increased-width="true"
  >
    <h1 class="h1-bold auth-title">
      Access Rights to Accounts in TPF Ltd
    </h1>

    <div class="mt-32 text-sm">
      <span class="text-sb">Swedbank Lithuania</span> is requesting:
    </div>

    <div
      v-for="(item, i) in items"
      :key="i"
      class="mt-8"
    >
      <FormCheckboxBox
        :value="true"
        @change="handleSelect('title', $event)"
      >
        <template slot="title">
          {{ item.title }}
        </template>

        <template slot="description">
          <div
            v-for="(paragpraph, j) in item.description"
            :key="j"
            class="form-checkbox-box__description-paragraph"
          >
            {{ paragpraph }}
          </div>
        </template>
      </FormCheckboxBox>
    </div>

    <div class="arrange arrange--align-center mt-40">
      <div class="arrange-item--no-shrink">
        <FormDatepicker
          id="expiration"
          name="expiration"
          placeholder="MM/DD/YYYY"
          label="Consent expiration date:"
          el-width="154px"
          el-style="input"
          :value="form.expiration"
          :has-error="form.expirationError"
          class="mr-sm-8 mr-lg-24"
          input-format="DD/MM/YYYY"
          @change="form.expiration = $event"
        />
      </div>

      <div class="text-sm text-grey">
        Maximum validity of consent is 90 days
      </div>
    </div>

    <div class="mt-20">
      <div class="text-sm text-grey">
        Accounts:
      </div>

      <div class="mt-4">
        <FormSelect
          placeholder="Choose accounts"
          el-style="input-black"
          el-width="100%"
          multiple-choice
          :value="accounts[0]"
          :options="accounts"
          @change="accountSelect = $event"
        />
      </div>
    </div>

    <div class="mt-32">
      <Button
        label="Confirm to proceed"
        btn-style="primary"
        btn-width="184px"
      />
    </div>
  </AuthLayout>
</template>

<script>
import AuthLayout from "@/components/Layout/AuthLayout";
import FormCheckboxBox from "@/components/Form/FormCheckboxBox";
import FormDatepicker from "@/components/Form/FormDatepicker";
import FormSelect from "@/components/Form/FormSelect";
import Button from "@/components/Button";

export default {
  name: "Login",

  components: {
    AuthLayout,
    FormCheckboxBox,
    FormDatepicker,
    FormSelect,
    Button
  },

  data() {
    return {
      selected: [],

      items: [
        {
          title: 'Account Balance',
          description: [
            'Allows sharing your actual account balance for the respective account.'
          ],
        },
        {
          title: 'List of Transactions',
          description: [
            'Allows requesting a list of transactions and transaction details for the resperctive accounts.',
            'Transaction details are covering payment description, date, currency, amount, name of the counterparty, address of the counterparty, name of the counterparty’s bank and other information available in transaction details.'
          ],
        },
        {
          title: 'Account Information',
          description: [
            'Your account number will be retrieved by the account servicing bank.'
          ],
        },
        {
          title: 'Payment Initiation',
          description: [
            'Allows initiation of outgoing transactions from the respective account(s) by the account servicing bank.'
          ],
        },
      ],

      form: {
        expiration: '',
        expirationError: '',
      },

      accounts: [
        'All accounts',
        'GB123456789012345',
        'GB098765432109876',
        'GB123456789012341',
        'GB098765432109410'
      ],

      selectedAccounts: [],
    };
  },

  methods: {
    handleSelect(title) {
      if (this.selected.includes(title)) {
        const index = this.selected.indexOf(title);

        if (index > -1) this.selected.splice(index, 1);
      } else {
        this.selected.push(title);
      }
    },
  }
};
</script>

<style scoped lang="scss">
  .form-checkbox-box {
    &__description-paragraph + &__description-paragraph {
      margin-top: 20px;
    }
  }
</style>
