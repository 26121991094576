<template>
  <OnboardingBusinessLayout
    :current-step="currentStep"
    :side-msg="sideMsg"
  >
    <Header>
      <template slot="title">
        Corporate documents
      </template>

      <template slot="intro">
        Attach the following corporate documents
      </template>
    </Header>

    <div class="grey-box mt-lg-32 mt-sm-24">
      <ul class="grey-box__list">
        <li>
          Registration certificate
          <br>
          <span> if exists</span>
        </li>
        <li>A document representing actual list of shareholders </li>
        <li>A document representing actual list of directors</li>
        <li>A document confirming actual legal address</li>
        <li>
          Power of Attorney
          <br>
          <span>if position in Company was chosen as Attorney!</span>
        </li>
        <li>
          Copies of passport for XXX, XXXX, XXX
          <br>
          <span>for all ultimate beneficial owners</span>
        </li>
        <li>Copies of utility bills for XXX, XXXX, XXX</li>
      </ul>
    </div>

    <div class="mt-lg-32 mt-sm-24">
      <Dropzone
        id="state"
        name="state"
        :has-error="form.fileError"
        @fileChange="form.files = $event"
      />
    </div>

    <div class="mt-lg-32 mt-sm-24">
      <Button
        label="Provide documents later"
        btn-style="grey-light-opacity"
        btn-width="100%"
      />
    </div>

    <div class="arrange mt-lg-32 mt-sm-24">
      <Button
        icon="icon-back-mobile"
        label="Return"
        btn-style="transparent"
        btn-width="92px"
      />

      <Button
        label="Continue"
        btn-style="primary"
        class="w-100 ml-24"
        :disabled="!form.files || form.fileError ? true : false"
      />
    </div>
  </OnboardingBusinessLayout>
</template>

<script>
import OnboardingBusinessLayout from "@/components/Layout/OnboardingBusinessLayout";
import Header from "@/components/Onboarding/Header";
import Dropzone from "@/components/Onboarding/Dropzone";
import Button from "@/components/Button";

export default {
  components: {
    OnboardingBusinessLayout,
    Header,
    Dropzone,
    Button
  },

  data() {
    return {
      currentStep: 8,

      sideMsg:
        "We need your utility bill to confirm your residence. All your privacy data will be stored safely.",

      form: {
        files: null,
        fileError: ""
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.grey-box {
  background-color: $grey-light-02;
  border-radius: 8px;
  font-size: 14px;
  padding: 24px 22px;

  &__list li {
    position: relative;
    padding-left: 20px;
  }

  &__list li::before {
    content: "";
    position: absolute;
    left: 0;
    top: 6px;
    width: 6px;
    height: 6px;
    background-color: $accent-normal;
    border-radius: 50%;
  }

  &__list li + li {
    margin-top: 12px;
  }

  &__list span {
    color: $grey-dark-01;
  }
}
</style>
