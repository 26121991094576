<template>
  <div>
    <h1
      class="h1-bold"
      style="margin: 52px 32px;"
    >
      UI kit
    </h1>

    <section class="mt-32 mb-64">
      <div style="background-color: #F5F6F9; padding: 32px;">
        <h2 class="h2-bold">
          Typography
        </h2>
      </div>

      <div style="padding: 32px;">
        <div
          v-for="(textBlock, i) in textStyles"
          :key="i"
          class="arrange mt-16"
          style="border-bottom: 1px solid #EDEFF2; padding: 16px 0;"
        >
          <div style="flex: 0 0 200px;">
            {{ textBlock.title }}
          </div>
          <p :class="textBlock.className">
            {{ sampleText }}
          </p>
        </div>
      </div>
    </section>

    <section>
      <div style="background-color: #F5F6F9; padding: 32px;">
        <h2 class="h2-bold">
          Icons
        </h2>
      </div>

      <div
        class="arrange arrange--wrap"
        style="padding: 32px;justify-content: flex-start;"
      >
        <div
          v-for="(icon, i) in icons"
          :key="i"
          style="width:48px;height:48px;display:flex;align-items:center;justify-content:center;font-size:24px;color:#57140F;"
        >
          <i :class="`icon ${icon}`" />
        </div>
      </div>
    </section>

    <section>
      <div style="background-color: #F5F6F9; padding: 32px;">
        <h2 class="h2-bold">
          Buttons
        </h2>
      </div>

      <div>
        <div
          class="arrange arrange--wrap"
          style="padding: 32px;"
        >
          <div class="mr-8">
            <Button
              label="Primary"
              btn-style="primary"
              btn-width="168px"
            />
          </div>

          <div class="mr-8">
            <Button
              icon="icon-add"
              label="Primary"
              btn-style="primary"
              btn-width="168px"
            />
          </div>

          <div class="mr-8">
            <Button
              label="Primary left"
              btn-style="primary"
              btn-width="168px"
              btn-align="left"
            />
          </div>

          <div class="mr-8">
            <Button
              label="Primary Disabled"
              btn-style="primary"
              btn-width="168px"
              disabled
            />
          </div>
        </div>

        <div
          class="arrange arrange--wrap"
          style="background-color: #FFE5DD; padding: 32px;"
        >
          <div class="mr-8">
            <Button
              label="Dark"
              btn-style="dark"
              btn-width="168px"
            />
          </div>

          <div class="mr-8">
            <Button
              icon="icon-add"
              label="Dark"
              btn-style="dark"
              btn-width="168px"
            />
          </div>

          <div class="mr-8">
            <Button
              label="Dark left"
              btn-style="dark"
              btn-width="168px"
              btn-align="left"
            />
          </div>

          <div class="mr-8">
            <Button
              label="Dark Disabled"
              btn-style="dark"
              btn-width="168px"
              disabled
            />
          </div>
        </div>

        <div
          class="arrange arrange--wrap"
          style="background-color: #57140F; padding: 32px;"
        >
          <div class="mr-8">
            <Button
              label="Grey-light"
              btn-style="grey-light"
              btn-width="168px"
            />
          </div>

          <div class="mr-8">
            <Button
              icon="icon-add"
              label="Grey-light"
              btn-style="grey-light"
              btn-width="168px"
            />
          </div>

          <div class="mr-8">
            <Button
              label="Grey-light left"
              btn-style="grey-light"
              btn-width="168px"
              btn-align="left"
            />
          </div>

          <div class="mr-8">
            <Button
              label="Grey-light Disabled"
              btn-style="grey-light"
              btn-width="168px"
              disabled
            />
          </div>
        </div>

        <div
          class="arrange arrange--wrap"
          style="padding: 32px; border-bottom: 1px solid #EDEFF2;"
        >
          <div class="mr-8">
            <Button
              label="Dark-opacity"
              btn-style="dark-opacity"
              btn-width="168px"
            />
          </div>

          <div class="mr-8">
            <Button
              icon="icon-add"
              label="Dark-opacity"
              btn-style="dark-opacity"
              btn-width="168px"
            />
          </div>

          <div class="mr-8">
            <Button
              label="Dark-opacity left"
              btn-style="dark-opacity"
              btn-width="168px"
              btn-align="left"
            />
          </div>

          <div class="mr-8">
            <Button
              label="Dark-opacity Disabled"
              btn-style="dark-opacity"
              btn-width="168px"
              disabled
            />
          </div>
        </div>

        <div
          class="arrange arrange--wrap"
          style="padding: 32px; border-bottom: 1px solid #EDEFF2;"
        >
          <div class="mr-8">
            <Button
              label="Grey-light-opacity"
              btn-style="grey-light-opacity"
              btn-width="168px"
            />
          </div>

          <div class="mr-8">
            <Button
              icon="icon-add"
              label="Grey-light-opacity"
              btn-style="grey-light-opacity"
              btn-width="168px"
            />
          </div>

          <div class="mr-8">
            <Button
              label="Grey-light-opacity left"
              btn-style="grey-light-opacity"
              btn-width="168px"
              btn-align="left"
            />
          </div>

          <div class="mr-8">
            <Button
              label="Grey-light-opacity Disabled"
              btn-style="grey-light-opacity"
              btn-width="168px"
              disabled
            />
          </div>
        </div>

        <div
          class="arrange arrange--wrap"
          style="padding: 32px;"
        >
          <div class="mr-8">
            <Button
              label="Transparent"
              btn-style="transparent"
              btn-width="168px"
            />
          </div>

          <div class="mr-8">
            <Button
              icon="icon-add"
              label="Transparent"
              btn-style="transparent"
              btn-width="168px"
            />
          </div>

          <div class="mr-8">
            <Button
              label="Transparent left"
              btn-style="transparent"
              btn-width="200px"
              btn-align="left"
            />
          </div>

          <div class="mr-8">
            <Button
              label="Transparent Disabled"
              btn-style="transparent"
              btn-width="168px"
              disabled
            />
          </div>
        </div>

        <div
          class="arrange arrange--wrap"
          style="padding: 32px; background-color: #FFE5DD;"
        >
          <div class="mr-32">
            <ButtonCircle
              icon="icon-add"
              label="Label"
              btn-style="dark"
            />
          </div>

          <div class="mr-32">
            <ButtonCircle
              icon="icon-add"
              label="Disabled"
              btn-style="dark"
              disabled
            />
          </div>

          <div class="mr-32">
            <ButtonCircle
              icon="icon-add"
              btn-style="dark"
              btn-size="sm"
            />
          </div>

          <div class="mr-32">
            <ButtonCircle
              icon="icon-add"
              btn-style="dark"
              btn-size="sm"
              disabled
            />
          </div>
        </div>

        <div
          class="arrange arrange--wrap"
          style="padding: 32px;"
        >
          <div class="mr-32">
            <ButtonCircle
              icon="icon-add"
              label="Label"
              btn-style="light"
            />
          </div>

          <div class="mr-32">
            <ButtonCircle
              icon="icon-add"
              label="Disabled"
              btn-style="light"
              disabled
            />
          </div>

          <div class="mr-32">
            <ButtonCircle
              icon="icon-add"
              btn-style="light"
              btn-size="sm"
            />
          </div>

          <div class="mr-32">
            <ButtonCircle
              icon="icon-add"
              btn-style="light"
              btn-size="sm"
              disabled
            />
          </div>
        </div>

        <div class="arrange arrange--wrap">
          <div
            class="arrange arrange--wrap"
            style="background-color: #57140F; padding: 32px; width: 400px; min-width: 50%;"
          >
            <div class="mr-32">
              <ButtonLink
                icon="icon-eye"
                label="Show details"
                btn-style="light"
              />
            </div>

            <div class="mr-32">
              <ButtonLink
                icon="icon-eye"
                label="Disabled"
                btn-style="light"
                disabled
              />
            </div>

            <div class="mr-32">
              <ButtonLink
                icon="icon-clear"
                label="Show details sm"
                btn-style="light"
                btn-size="sm"
              />
            </div>

            <div class="mr-32">
              <ButtonLink
                label="Underlined"
                btn-style="underline"
                btn-size="sm"
              />
            </div>
          </div>

          <div
            class="arrange arrange--wrap"
            style="padding: 32px;"
          >
            <div class="mr-32">
              <ButtonLink
                icon="icon-eye"
                label="Show details"
              />
            </div>

            <div class="mr-32">
              <ButtonLink
                icon="icon-eye"
                label="Disabled"
                disabled
              />
            </div>

            <div class="mr-32">
              <ButtonLink
                icon="icon-clear"
                label="Show details sm"
                btn-size="sm"
              />
            </div>

            <div class="mr-32">
              <ButtonLink
                label="Underlined"
                btn-style="underline"
                btn-size="sm"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div style="background-color: #F5F6F9; padding: 32px;">
        <h2 class="h2-bold">
          Radio Group
        </h2>
      </div>

      <div style="background-color: #FFE5DD; padding: 32px;">
        <RadioGroup
          :model="radioGroup"
          name="radio-group"
          :items="[
            { icon: 'icon-card-tile', label: '', value: '1', id: 'radio_one' },
            { icon: 'icon-mail', label: '', value: '2', id: 'radio_one' },
            {
              icon: 'icon-list-details',
              label: '',
              value: '3',
              id: 'radio_three'
            }
          ]"
          @change="radioGroup = $event"
        />
      </div>
    </section>

    <section>
      <div style="background-color: #F5F6F9; padding: 32px;">
        <h2 class="h2-bold">
          Checkbox
        </h2>
      </div>

      <div
        class="arrange"
        style="padding: 32px;"
      >
        <div class="mr-8">
          <FormCheckbox
            id="checkbox_id"
            name="checkbox-name"
            :value="checkbox"
            el-width="300px"
            @change="checkbox = $event"
          >
            Label text. Lorem ipsum dolor sit amet, consectetur adipisicing
            elit, sed do eiusmod tempor incididunt ut labore et dolore magna
            aliqua.
          </FormCheckbox>
        </div>

        <div class="mr-8">
          <FormCheckbox
            id="checkbox_id_2"
            name="checkbox-name-2"
            el-width="200px"
            disabled
          >
            Disabled
          </FormCheckbox>
        </div>

        <div class="mr-8">
          <FormCheckbox
            id="checkbox_id_3"
            name="checkbox-name-3"
            :value="checkbox"
            el-width="200px"
            has-error="Some error"
          >
            With error
          </FormCheckbox>
        </div>

        <div class="mr-8">
          <FormCheckbox
            id="checkbox_id_4"
            name="checkbox-name-4"
            :value="checkbox"
            el-width="200px"
            indeterminate
          >
            Indeterminate
          </FormCheckbox>
        </div>
      </div>

      <div
        class="arrange"
        style="padding: 32px;"
      >
        <div class="mr-8">
          <FormCheckboxBox
            id="checkbox_box_id"
            name="checkbox-box-name"
            :value="checkboxBox"
            el-width="300px"
            @change="checkboxBox = $event"
          >
            <template slot="title">
              Ordinary
            </template>

            <template slot="comment">
              Price: FREE
            </template>
          </FormCheckboxBox>
        </div>

        <div class="mr-8">
          <FormCheckboxBox
            id="checkbox_box_id_2"
            name="checkbox-box-name"
            :value="checkboxBox_2"
            @change="checkboxBox_2 = $event"
          >
            <template slot="title">
              Ordinary
            </template>
          </FormCheckboxBox>
        </div>

        <div class="mr-8">
          <FormCheckboxBox
            id="checkbox_box_id_3"
            name="checkbox-box-name"
            :value="checkboxBox_3"
            @change="checkboxBox_3 = $event"
          >
            <template slot="title">
              Ordinary
            </template>

            <template slot="img">
              <img
                class="new-card-modal-currencies__item-flag"
                :src="require(`@/assets/images/flags/eu.svg`)"
                alt="eu"
              >
            </template>
          </FormCheckboxBox>
        </div>

        <div class="mr-8">
          <FormCheckboxBox
            id="checkbox_box_id_4"
            name="checkbox-box-name"
            :value="checkboxBox_4"
            indeterminate
            @change="checkboxBox_4 = $event"
          >
            <template slot="title">
              Ordinary
            </template>

            <template slot="img">
              <img
                class="new-card-modal-currencies__item-flag"
                :src="require(`@/assets/images/flags/eu.svg`)"
                alt="eu"
              >
            </template>
          </FormCheckboxBox>
        </div>

        <div class="mr-8">
          <FormCheckboxBox
            :value="false"
            disabled
          >
            <template slot="title">
              Disabled
            </template>

            <template slot="img">
              <img
                class="new-card-modal-currencies__item-flag"
                :src="require(`@/assets/images/flags/eu.svg`)"
                alt="eu"
              >
            </template>
          </FormCheckboxBox>
        </div>
      </div>

      <div
        class="arrange"
        style="padding: 32px;"
      >
        <div class="mr-8">
          <FormRadio
            id="radio_1"
            name="radio-1"
            :value="radio"
            label="1"
            el-width="300px"
            @change="radio = $event"
          >
            Radio Label text.
          </FormRadio>
        </div>

        <div class="mr-8">
          <FormRadio
            id="radio_2"
            name="radio-1"
            :value="radio"
            label="2"
            el-width="300px"
            @change="radio = $event"
          >
            Radio Label text.
          </FormRadio>
        </div>

        <div class="mr-8">
          <FormRadio
            id="radio_3"
            name="radio-3"
            :value="radio"
            label="3"
            el-width="300px"
            disabled
            @change="radio = $event"
          >
            Radio Disabled
          </FormRadio>
        </div>
      </div>

      <div
        class="arrange"
        style="padding: 32px;"
        role="radiogroup"
      >
        <div class="mr-8">
          <FormRadioBox
            id="radio_1"
            name="radio-2"
            :value="radio_2"
            label="1"
            el-width="300px"
            @change="radio_2 = $event"
          >
            <template slot="title">
              Ordinary
            </template>
          </FormRadioBox>
        </div>

        <div class="mr-8">
          <FormRadioBox
            id="radio_2"
            name="radio-2"
            :value="radio_2"
            label="2"
            @change="radio_2 = $event"
          >
            <template slot="title">
              Ordinary
            </template>

            <template slot="comment">
              Price: FREE
            </template>
          </FormRadioBox>
        </div>

        <div class="mr-8">
          <FormRadioBox
            id="radio_3"
            name="radio-2"
            :value="radio_2"
            label="3"
            @change="radio_2 = $event"
          >
            <template slot="title">
              Ordinary
            </template>

            <template slot="comment">
              Price: FREE
            </template>

            <template slot="img">
              <img
                class="new-card-modal-currencies__item-flag"
                :src="require(`@/assets/images/flags/eu.svg`)"
                alt="eu"
              >
            </template>
          </FormRadioBox>
        </div>

        <div class="mr-8">
          <FormRadioBox
            id="radio_4"
            name="radio-2"
            :value="radio_2"
            label="4"
            disabled
            @change="radio_2 = $event"
          >
            <template slot="title">
              Ordinary
            </template>

            <template slot="comment">
              Price: FREE
            </template>

            <template slot="img">
              <img
                class="new-card-modal-currencies__item-flag"
                :src="require(`@/assets/images/flags/eu.svg`)"
                alt="eu"
              >
            </template>
          </FormRadioBox>
        </div>
      </div>
    </section>

    <section>
      <div style="background-color: #F5F6F9; padding: 32px;">
        <h2 class="h2-bold">
          Toggle switch
        </h2>
      </div>

      <div
        class="arrange"
        style="padding: 32px;"
      >
        <div class="mr-8">
          <Toggle
            id="toggle_1"
            name="toggle-1"
            :value="toggle_1"
            @change="toggle_1 = $event"
          />
        </div>

        <div class="mr-8">
          <Toggle
            id="toggle_2"
            name="toggle-2"
            :value="toggle_2"
            @change="toggle_2 = $event"
          />
        </div>

        <div class="mr-8">
          <Toggle
            id="toggle_3"
            name="toggle-3"
            :value="true"
            disabled
          />
        </div>
      </div>
    </section>

    <section>
      <div style="background-color: #F5F6F9; padding: 32px;">
        <h2 class="h2-bold">
          Tag
        </h2>
      </div>

      <div
        class="arrange"
        style="padding: 32px;"
      >
        <div class="mr-8">
          <Tag label="Label" />
        </div>

        <div class="mr-8">
          <Tag
            label="Label"
            :handler="handler"
          />
        </div>
      </div>
    </section>

    <section>
      <div style="background-color: #F5F6F9; padding: 32px;">
        <h2 class="h2-bold">
          Form input
        </h2>
      </div>

      <div
        class="arrange"
        style="padding: 32px;"
      >
        <div class="mr-8">
          <FormInput
            id="input_id"
            name="input-name"
            type="text"
            :value="input_1"
            placeholder="Placeholder"
            label="Label"
            el-width="180px"
            @input="input_1 = $event"
          />
        </div>

        <div class="mr-8">
          <FormInput
            id="input_id_2"
            name="input-name-2"
            type="email"
            :value="input_2"
            placeholder="email"
            label="email"
            el-width="180px"
            @input="input_2 = $event"
          />
        </div>

        <div class="mr-8">
          <FormInput
            id="input_id_3"
            name="input-name-3"
            type="email"
            :value="input_3"
            placeholder="email"
            label="Disabled"
            el-width="180px"
            disabled
            @input="input_3 = $event"
          />
        </div>

        <div class="mr-8">
          <FormInput
            id="input_id_4"
            name="input-name-4"
            type="number"
            :value="input_4"
            placeholder="Placeholder"
            label="With error"
            el-width="180px"
            has-error="Some error"
            @input="input_4 = $event"
          />
        </div>
      </div>

      <div
        style="padding: 32px;"
      >
        <FormInput
          id="website"
          name="website"
          type="list"
          :value="input_5"
          label="Input with list"
          el-width="320px"
          placeholder="http://site.com/"
          @input="input_5 = $event"
        />
      </div>
    </section>

    <section>
      <div style="background-color: #F5F6F9; padding: 32px;">
        <h2 class="h2-bold">
          Form select
        </h2>
      </div>

      <div
        class="arrange arrange--wrap"
        style="padding: 32px;"
      >
        <div class="mr-8 mb-24">
          <FormSelect
            id="form_select"
            name="form-select"
            label="Label"
            placeholder="All types"
            el-width="240px"
            el-style="input"
            :value="select_7"
            :options="[
              { value: '1', title: 'Option 1' },
              { value: '2', title: 'Option 2' },
              { value: '3', title: 'Option 3' }
            ]"
            @change="select_7 = $event"
          />
        </div>

        <div class="mr-8 mb-24">
          <FormSelect
            id="form_select"
            name="form-select"
            label="Label"
            placeholder="All types"
            el-width="240px"
            el-style="input"
            :value="select_8"
            :options="[
              'Option 1',
              'Option 2',
              'Option 3',
              'Option 4',
              'Option 5',
              'Option 6',
              'Option 7',
              'Option 8',
              'Option 9'
            ]"
            @change="select_8 = $event"
          />
        </div>

        <div class="mr-8 mb-24">
          <FormSelect
            id="form_select"
            name="form-select"
            label="Label"
            placeholder="All types"
            el-width="240px"
            el-style="input"
            :options="['Option 1', 'Option 2']"
            disabled
          />
        </div>

        <div class="mr-8 mb-24">
          <FormSelect
            id="form_select"
            name="form-select"
            label="Label"
            placeholder="All types"
            el-width="240px"
            el-style="input"
            :value="select_9"
            :options="['Option 1', 'Option 2']"
            has-error="Some error"
            @change="select_9 = $event"
          />
        </div>

        <div class="mr-8 mb-24">
          <FormSelect
            id="form_select"
            name="form_select"
            label="Label (phone)"
            el-style="input"
            :value="select_11"
            :options="[
              { value: '+370', title: '+370', country: 'Lithuania', flag: 'lt' },
              { value: '+371', title: '+371', country: 'Latvia', flag: 'lv' },
              { value: '+372', title: '+372', country: 'Estonia', flag: 'ee' }
            ]"
            phone-code
            @change="select_11 = $event"
          />
        </div>

        <div class="mr-8 mb-24">
          <FormSelect
            id="form_select"
            name="form-select"
            label="Label (multiple)"
            placeholder="All types"
            el-width="240px"
            el-style="input"
            :value="select_10"
            :options="[
              'All options',
              'Option 1',
              'Option 2',
              'Option 3',
              'Option 4',
              'Option 5'
            ]"
            multiple-choice
            @change="select_10 = $event"
          />
        </div>
      </div>

      <div
        class="arrange"
        style="padding: 32px;"
      >
        <div class="mr-8">
          <FormSelect
            id="form_select"
            name="form-select"
            label="Label"
            placeholder="All types"
            el-style="filter"
            :value="select_1"
            :options="[
              { value: '1', title: 'Option 1' },
              { value: '2', title: 'Option 2' },
              { value: '3', title: 'Option 3' }
            ]"
            @change="select_1 = $event"
          />
        </div>

        <div class="mr-8">
          <FormSelect
            id="form_select"
            name="form-select"
            label="Label"
            placeholder="All types"
            el-style="filter"
            :value="select_2"
            :options="[
              'Option 1',
              'Option 2',
              'Option 3',
              'Option 4',
              'Option 5',
              'Option 6',
              'Option 7',
              'Option 8',
              'Option 9'
            ]"
            @change="select_2 = $event"
          />
        </div>

        <div class="mr-8">
          <FormSelect
            id="form_select"
            name="form-select"
            label="Label"
            placeholder="All types"
            el-style="filter"
            :options="['Option 1', 'Option 2']"
            disabled
          />
        </div>

        <div class="mr-8">
          <FormSelect
            id="form_select"
            name="form-select"
            label="Label"
            placeholder="All types"
            el-style="filter"
            :value="select_3"
            :options="['Option 1', 'Option 2']"
            has-error="Some error"
            @change="select_3 = $event"
          />
        </div>

        <div class="mr-8">
          <FormSelect
            id="form_select"
            name="form-select"
            label="Label (multiple)"
            placeholder="All types"
            el-style="filter"
            :value="select_10"
            :options="[
              'All options',
              'Option 1',
              'Option 2',
              'Option 3',
              'Option 4',
              'Option 5',
            ]"
            multiple-choice
            @change="select_10 = $event"
          />
        </div>
      </div>

      <div style="padding: 32px;">
        <FormSelectGroup el-width="560px">
          <FormSelect
            id="form_select"
            name="form-select"
            placeholder="All types"
            el-style="filter"
            :value="select_4"
            :options="['Option 1', 'Option 2', 'Option 3']"
            @change="select_4 = $event"
          />
          <FormSelect
            id="form_select"
            name="form-select"
            placeholder="All types"
            el-style="filter"
            :value="select_5"
            :options="['Option 1', 'Option 2']"
            @change="select_5 = $event"
          />
          <FormSelect
            id="form_select"
            name="form-select"
            placeholder="All types"
            el-style="filter"
            :value="select_6"
            :options="['Option 1', 'Option 2']"
            @change="select_6 = $event"
          />
          <FormSelect
            id="form_select"
            name="form-select"
            placeholder="All types"
            el-style="filter"
            :value="select_10"
            :options="['All options', 'Option 1', 'Option 2', 'Option 3', 'Option 4', 'Option 5']"
            multiple-choice
            @change="select_10 = $event"
          />
        </FormSelectGroup>
      </div>
    </section>

    <section>
      <div style="background-color: #F5F6F9; padding: 32px;">
        <h2 class="h2-bold">
          Datepicker
        </h2>
      </div>

      <div
        class="arrange"
        style="padding: 32px;"
      >
        <div class="mr-32">
          <FormDatepicker
            id="date_1"
            name="date-1"
            placeholder="Select date..."
            label="Label"
            is-range
            is-double-paned
            el-width="220px"
            el-style="input"
            :value="dateRange_1"
            @change="dateRange_1 = $event"
          />
        </div>

        <div class="mr-32">
          <FormDatepicker
            id="date_2"
            name="date-2"
            placeholder="Select date..."
            label="Label"
            is-range
            is-double-paned
            el-width="220px"
            el-style="input"
            has-error="Some error"
            :value="dateRange_2"
            @change="dateRange_2 = $event"
          />
        </div>

        <div class="mr-32">
          <FormDatepicker
            id="date_3"
            name="date-3"
            placeholder="Select date..."
            label="Label"
            el-width="220px"
            el-style="input"
            :value="date_3"
            @change="date_3 = $event"
          />
        </div>

        <div class="mr-32">
          <FormDatepicker
            id="date_4"
            name="date-4"
            placeholder="Select date..."
            label="Disabled"
            el-width="220px"
            el-style="input"
            :value="null"
            disabled
          />
        </div>
      </div>

      <div
        class="arrange"
        style="padding: 32px;"
      >
        <div class="mr-32">
          <FormDatepicker
            id="date_5"
            name="date-5"
            placeholder="Select date..."
            label="Label"
            is-range
            is-double-paned
            el-width="240px"
            el-style="filter"
            :value="dateRange_4"
            @change="dateRange_4 = $event"
          />
        </div>

        <div class="mr-32">
          <FormDatepicker
            id="date_6"
            name="date-6"
            placeholder="Select date..."
            label="Label"
            is-range
            is-double-paned
            el-width="240px"
            el-style="filter"
            has-error="Some error"
            :value="dateRange_5"
            @change="dateRange_5 = $event"
          />
        </div>

        <div class="mr-32">
          <FormDatepicker
            id="date_7"
            name="date-7"
            placeholder="Select date..."
            label="Label"
            el-width="240px"
            el-style="filter"
            :value="date_6"
            @change="date_6 = $event"
          />
        </div>

        <div class="mr-32">
          <FormDatepicker
            id="date_8"
            name="date-8"
            placeholder="Select date..."
            label="Disabled"
            el-width="240px"
            el-style="filter"
            :value="null"
            disabled
          />
        </div>
      </div>
    </section>

    <section>
      <div style="background-color: #F5F6F9; padding: 32px;">
        <h2 class="h2-bold">
          Tooltip
        </h2>
      </div>

      <div
        class="arrange"
        style="padding: 32px 32px 32px 100px;"
      >
        <div class="mr-32">
          <Tooltip
            left
            popover="Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod."
          >
            <span>Hover me (left)</span>
          </Tooltip>
        </div>

        <div class="mr-32">
          <Tooltip
            top
            popover="Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod."
          >
            <span>Hover me (top)</span>
          </Tooltip>
        </div>

        <div class="mr-32">
          <Tooltip
            bottom
            popover="Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod."
          >
            <span>Hover me (bottom)</span>
          </Tooltip>
        </div>

        <div class="mr-32">
          <Tooltip
            right
            popover="Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod."
          >
            <span>Hover me (right)</span>
          </Tooltip>
        </div>
      </div>
    </section>

    <section>
      <div style="background-color: #F5F6F9; padding: 32px;">
        <h2 class="h2-bold">
          Dropdown
        </h2>
      </div>

      <div
        class="arrange"
        style="padding: 32px;"
      >
        <Dropdown>
          <template slot="toggle">
            <Button
              label="Dropdown"
              btn-style="primary"
              btn-width="168px"
            />
          </template>

          <template slot="menu">
            <div style="padding: 16px;">
              <ul>
                <li>Item</li>
                <li>Item</li>
                <li>Item</li>
                <li>Item</li>
                <li>Item</li>
              </ul>
            </div>
          </template>
        </Dropdown>
      </div>
    </section>

    <section>
      <div style="background-color: #F5F6F9; padding: 32px;">
        <h2 class="h2-bold">
          Payment Cards
        </h2>
      </div>

      <div
        class="arrange"
        style="padding: 32px;"
      >
        <div class="mr-32">
          <PaymentCard
            card-style="green"
            issuer="mastercard"
            type-label="Main card"
            card-number="**** **** **** 1240"
            primary-label="3.755,95 EUR"
            secondary-label="12,95 EUR on hold"
            valid="12/23"
            holder="John Dow"
          />
        </div>

        <div class="mr-32">
          <PaymentCard
            card-style="black"
            issuer="visa"
            :logo="require('@/assets/images/logo.svg')"
            primary-label="Black Card"
            primary-number="0000 0000 0000 0000"
            valid="12/23"
            holder="Your Name"
          />
        </div>

        <div class="mr-32">
          <PaymentCard
            card-style="white"
            issuer="mastercard"
            type-label="Second card"
            card-number="**** **** **** 1240"
            primary-label="3.755,95 EUR"
            secondary-label="12,95 EUR on hold"
            valid="12/23"
            holder="John Dow"
          />
        </div>
      </div>
    </section>

    <section>
      <div style="background-color: #F5F6F9; padding: 32px;">
        <h2 class="h2-bold">
          Badges
        </h2>
      </div>

      <div
        class="arrange"
        style="padding: 32px;"
      >
        <div class="mr-32">
          <Badge
            label="Neutral"
            el-style="neutral"
          />
        </div>

        <div class="mr-32">
          <Badge
            label="Danger"
            el-style="danger"
          />
        </div>

        <div class="mr-32">
          <Badge
            label="Warning"
            el-style="warning"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Button from "@/components/Button";
import ButtonCircle from "@/components/ButtonCircle";
import ButtonLink from "@/components/ButtonLink";
import RadioGroup from "@/components/RadioGroup";
import FormCheckbox from "@/components/Form/FormCheckbox";
import FormCheckboxBox from "@/components/Form/FormCheckboxBox";
import FormRadio from "@/components/Form/FormRadio";
import FormRadioBox from "@/components/Form/FormRadioBox";
import Toggle from "@/components/Toggle";
import Tag from "@/components/Tag";
import FormInput from "@/components/Form/FormInput";
import FormSelect from "@/components/Form/FormSelect";
import FormSelectGroup from "@/components/Form/FormSelectGroup";
import FormDatepicker from "@/components/Form/FormDatepicker";
import Tooltip from "@/components/Tooltip";
import Dropdown from "@/components/Dropdown";
import PaymentCard from "@/components/Blocks/PaymentCard";
import Badge from "@/components/Badge";

export default {
  name: "Ui",

  title: "TPF | UI-kit",

  components: {
    Button,
    ButtonCircle,
    ButtonLink,
    RadioGroup,
    FormCheckbox,
    FormCheckboxBox,
    FormRadio,
    FormRadioBox,
    Toggle,
    Tag,
    FormInput,
    FormSelect,
    FormSelectGroup,
    FormDatepicker,
    Tooltip,
    Dropdown,
    PaymentCard,
    Badge
  },

  data() {
    return {
      textStyles: [
        { title: "Title_B", className: "h1-bold" },
        { title: "Title_R", className: "h1" },
        { title: "Headline_B", className: "h2-bold" },
        { title: "Headline_R", className: "h2" },
        { title: "Subtitle_R", className: "h3" },
        { title: "Body_R_01", className: "text-regular" },
        { title: "Body_Sb_01", className: "text-sb" },
        { title: "Body_R_02", className: "text-sm" },
        { title: "Body_Sb_02", className: "text-sm-sb" },
        { title: "Description_R_01", className: "text-dscr" },
        { title: "Description_R_02", className: "text-dscr-sm" },
        { title: "Caps_R_01", className: "text-caps" },
        { title: "Caps_Sb_01", className: "text-caps-sb" },
        { title: "Caps_R_02", className: "text-caps-sm" },
        { title: "Button_Sb", className: "text-btn-sb" },
        { title: "Button_R", className: "text-btn" }
      ],

      sampleText:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",

      icons: [
        "icon-accounts",
        "icon-add",
        "icon-arrow-left",
        "icon-arrow-long-down",
        "icon-arrow-long-up",
        "icon-arrow-right",
        "icon-back-mobile",
        "icon-burger",
        "icon-calendar",
        "icon-card-details",
        "icon-case",
        "icon-check",
        "icon-clear",
        "icon-clock",
        "icon-clothes",
        "icon-completed",
        "icon-dashboard",
        "icon-dropdown-down",
        "icon-dropdown-up",
        "icon-events",
        "icon-exchange-old",
        "icon-exchange",
        "icon-eye",
        "icon-filter",
        "icon-food",
        "icon-info-request",
        "icon-info",
        "icon-limits",
        "icon-list-details",
        "icon-lock-alt",
        "icon-lock",
        "icon-logout",
        "icon-mail",
        "icon-more-thin",
        "icon-more",
        "icon-notifiations",
        "icon-payments",
        "icon-pencil",
        "icon-pin",
        "icon-print",
        "icon-profile",
        "icon-refresh",
        "icon-right",
        "icon-save",
        "icon-saving",
        "icon-search",
        "icon-settings",
        "icon-sort-down",
        "icon-sort-up",
        "icon-statement",
        "icon-transactions",
        "icon-card-tile",
        "icon-close",
        "icon-doc-add",
        "icon-doc-xls",
        "icon-wrong",
        "icon-success",
        "icon-doc-sm",
        "icon-trash",
        "icon-trash-m",
        "icon-outbox",
        "icon-inbox",
        "icon-attach",
        "icon-attention",
        "icon-get-to",
        "icon-reply",
        "icon-info-f",
        "icon-error-f",
        "icon-check-f",
        "icon-attention-f",
        "icon-done",
        "icon-limits-v",
        "icon-info",
        "icon-external-access"
      ],

      btns: [
        { style: "primary", label: "Primary", size: "md" },
        { style: "dark", label: "Dark", size: "md" },
        { style: "dark-opacity", label: "Dark opacity", size: "md" },
        { style: "grey-light", label: "Grey light", size: "md" },
        { style: "grey-light-opacity", label: "Grey light opacity", size: "md" }
      ],

      radioGroup: "1",

      checkbox: false,

      checkboxBox: false,

      checkboxBox_2: true,

      checkboxBox_3: false,

      checkboxBox_4: false,

      radio: "1",

      radio_2: 1,

      toggle_1: false,

      toggle_2: true,

      input_1: "",

      input_2: "foo@bar.com",

      input_3: "foo@bar.com",

      input_4: "42",

      input_5: "",

      select_1: "2",

      select_2: "Option 3",

      select_3: "",

      select_4: "",

      select_5: "",

      select_6: "",

      select_7: "2",

      select_8: "Option 3",

      select_9: "",

      select_10: "All options",

      select_11: "+371",

      select_flag: "+371",

      dateRange_1: {
        start: new Date(2020, 0, 1),
        end: new Date(2020, 0, 4)
      },

      dateRange_2: null,

      date_3: new Date(2020, 0, 4),

      dateRange_4: {
        start: new Date(2020, 0, 1),
        end: new Date(2020, 0, 4)
      },

      dateRange_5: null,

      date_6: new Date(2020, 0, 4)
    };
  },

  methods: {
    handler() {
      // eslint-disable-next-line no-console
      console.log("event");
    }
  }
};
</script>

<style lang="scss" scoped>
.changing-bg {
  background-color: #57140F;

  @media screen and (min-width: $screen-lg-min) {
    background-color: #ffffff;
  }
}
</style>
