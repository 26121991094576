<template>
  <div
    class="form-datepicker"
    :class="{
      'has-error': hasError,
      'is-disabled': disabled,
      'input-style': elStyle === 'input',
      'filter-style': elStyle === 'filter'
    }"
    :style="{ width: elWidth }"
  >
    <label
      v-if="label"
      :for="id"
    >{{ label }}</label>

    <div class="form-datepicker__wrapper">
      <v-date-picker
        v-model="range"
        :mode="isRange ? 'range' : 'single'"
        :input-props="{
          class: 'form-datepicker__input',
          placeholder: placeholder,
          readonly: false,
          id: id,
          name: name,
          style: null
        }"
        :is-double-paned="isDoublePaned"
        :show-day-popover="false"
        :popover-visibility="'focus'"
        :is-inline="isInline"
        :theme-styles="themeStyles"
        :select-attribute="selectAttribute"
        :drag-attribute="dragAttribute"
        :attributes="attrs"
        title-position="left"
        :pane-width="280"
        :formats="formats"
        @dayclick="dayclick"
      />
    </div>

    <span
      v-if="hasError"
      class="form-datepicker__error"
    >{{ hasError }}</span>
  </div>
</template>

<script>
export default {
  name: "Datepicker",

  props: {
    value: {
      type: [Date, Object],
      required: false,
      default() {
        return null;
      }
    },
    isRange: { type: Boolean, required: false, default: false },
    isDoublePaned: { type: Boolean, required: false, default: false },
    id: { type: String, required: false, default: "" },
    name: { type: String, required: false, default: "" },
    placeholder: { type: String, required: false, default: "" },
    label: { type: String, required: false, default: "" },
    hasError: { type: String, required: false, default: "" },
    disabled: { type: Boolean, required: false, default: false },
    elWidth: { type: String, required: false, default: "" },
    elStyle: {
      type: String,
      required: false,
      default: "input",
      validator: function(value) {
        return ["input", "filter"].indexOf(value) !== -1;
      }
    },
    inputFormat: { type: String, required: false, default: "DD.MM.YYYY" }
  },

  data() {
    return {
      isInline: false,

      range: this.value,

      formats: {
        title: "MMMM YYYY",
        weekdays: "WWW",
        navMonths: "MMM",
        input: [this.inputFormat, this.inputFormat]
      },

      attrs: [
        {
          dates: this.value ? [this.value.start, this.value.end] : [],

          highlight: {
            backgroundColor: "#16BF99",
            borderColor: "#16BF99",
            borderWidth: "2px",
            height: "30px"
          },

          contentStyle: {
            color: "white"
          }
        }
      ],
      selectAttribute: {
        highlight: {
          backgroundColor: "rgba(22, 191, 153, 0.3)",
          borderRadius: "30px"
        },
        contentStyle: {
          color: "#12997B"
        }
      },
      dragAttribute: {
        highlight: {
          backgroundColor: "#16BF99",
          borderRadius: "30px"
        },
        contentStyle: {
          color: "#12997B"
        }
      },
      themeStyles: {
        wrapper: {
          background: "",
          color: "",
          border: "",
          borderRadius: "",
          boxShadow: ""
        },
        dayContent({ isHovered }) {
          return {
            backgroundColor: (isHovered && "#EDEFF2") || ""
          };
        },
        dayCellNotInMonth: {
          color: "#C8C9CC"
        },
        verticalDivider: {
          width: "24px"
        }
      }
    };
  },

  watch: {
    range(val) {
      this.attrs[0].dates = [val.start, val.end];

      this.$emit("change", val);
    }
  },

  methods: {
    dayclick() {
      this.attrs[0].dates = [];
    }
  }
};
</script>

<style scoped lang="scss">
.form-datepicker {
  position: relative;
  display: inline-block;
  font-family: $font-regular;

  & label {
    position: absolute;
    top: -22px;
    left: 0;
    display: inline-block;
    font-size: 14px;
    line-height: 18px;
    color: $grey-dark-01;
  }

  &__wrapper {
    position: relative;
  }

  &.filter-style &__wrapper:before {
    content: "\e908";
    @include inline-icon;
    position: absolute;
    top: 12px;
    left: 16px;
    z-index: 2;
    color: $additional-brown;
  }

  &__error {
    display: inline-block;
    font-size: 12px;
    line-height: 16px;
    color: $red;
    margin-top: 4px;
  }

  &.is-disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
</style>

<style lang="scss">
@import "@/assets/scss/lib/calendar";

.form-datepicker {
  &__input {
    width: 100%;
    min-width: 1%;
    transition: border-color 0.1s linear;
  }

  &.filter-style &__input {
    text-align: center;
    color: $additional-brown;
    padding: 0 32px;
  }

  &.input-style &__input {
    font-size: 16px;
    background-color: $grey-light-02;
    border: 1px solid transparent;
    padding: 14px 16px;
    border-radius: 8px;
  }

  &.input-style &__input:focus {
    border-color: $accent-normal;
  }

  &.filter-style &__input {
    font-size: 14px;
    line-height: 38px;
    background-color: $grey-light-02;
    color: $additional-brown;
    border: 1px solid transparent;
    border-radius: 4px;
  }

  &.has-error &__input {
    border-color: $red;
  }

  &__input::-webkit-input-placeholder {
    color: $grey-dark-01;
  }

  &__input:-ms-input-placeholder {
    color: $grey-dark-01;
  }

  &__input::placeholder {
    color: $grey-dark-01;
  }

  & .c-day-content {
    font-family: $font-regular;
    font-size: 14px;
  }

  & .c-pane-container {
    padding: 16px;
  }

  & .c-pane > .c-header {
    position: relative;
    margin-bottom: 20px;
  }

  & .c-header .c-title,
  & .popover-content .c-title {
    font-size: 16px;
    line-height: 20px;
    font-family: $font-semibold;
  }

  & .c-table tr td[data-v-81948efe] {
    width: 60px;
    height: 30px;
  }

  & .c-nav {
    padding: 6px 0;
  }

  & .c-nav .c-header {
    padding: 0 6px;
    margin-bottom: 8px;
  }

  & .c-nav .c-table-cell {
    font-size: 14px;
    line-height: 18px;
  }

  & .c-weekdays {
    border-bottom: 1px solid $grey-normal;
    font-family: $font-regular;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: $grey-dark-01;
    margin-bottom: 8px;
    padding-bottom: 6px;
    text-transform: uppercase;
  }

  & .svg-icon path[data-v-12e91ab4] {
    fill: $grey-dark-02;
    transition: fill 0.1s linear;
  }

  & .c-arrow-layout:hover .svg-icon path[data-v-12e91ab4] {
    fill: $grey-dark-01;
  }

  & .popover-origin .popover-content {
    position: relative;
    outline: none;
    background-color: $white;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08),
      0px 8px 12px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
  }
}
</style>
