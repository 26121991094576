<template>
  <div
    class="form-radio"
    :class="{ 'is-disabled': disabled }"
    :style="{ width: elWidth }"
  >
    <label class="form-radio__wrapper">
      <input
        :id="id"
        v-model="model"
        :name="name"
        type="radio"
        :value="label"
        :disabled="disabled"
      >
      <span class="form-radio__check" />

      <span class="form-radio__label">
        <slot />
      </span>
    </label>
  </div>
</template>

<script>
export default {
  name: "FormRadio",

  props: {
    id: { type: String, required: false, default: "" },
    name: { type: String, required: false, default: "" },
    value: { type: [String, Number], required: false, default: null },
    label: { type: String, required: false, default: "" },
    elWidth: { type: String, required: false, default: "" },
    disabled: { type: Boolean, required: false, default: false }
  },

  computed: {
    model: {
      get() {
        return this.value;
      },
      set() {
        this.$emit("change", this.label);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.form-radio {
  display: inline-block;
  min-height: 24px;

  &__wrapper {
    position: relative;
    display: flex;
    padding-left: 24px;
    cursor: pointer;
  }

  &__wrapper input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }

  &__check {
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -9px;
    display: block;
    width: 18px;
    height: 18px;
    min-width: 18px;
    box-shadow: inset 0 0 0 1.5px $grey-dark-02;
    border-radius: 50%;
    transition: box-shadow 0.1s linear;
  }

  &__check:before {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -4px 0 0 -4px;
    display: block;
    width: 8px;
    height: 8px;
    content: "";
    border-radius: 50%;
    background-color: transparent;
    transition: background-color 0.15s linear;
  }

  & input:checked ~ &__check {
    box-shadow: inset 0 0 0 1.5px $accent-normal;
  }

  & input:checked ~ &__check:before {
    background-color: $accent-normal;
  }

  &.has-error &__check {
    box-shadow: inset 0 0 0 1.5px $red;
  }

  & input:disabled ~ span {
    opacity: 0.5;
  }

  &__label {
    display: block;
    padding-left: 8px;
    color: $additional-brown;
  }

  &__error {
    font-family: $font-regular;
    font-size: 12px;
    line-height: 1.33;
    margin: 4px 0;
    color: $red;
  }

  &.is-disabled {
    pointer-events: none;
  }

  @media (hover: hover) {
    &__wrapper:hover &__check {
      box-shadow: inset 0 0 0 1.5px $accent-normal;
    }
  }
}
</style>
