<template>
  <div>
    <div
      v-for="(field, i) in fields"
      :key="i"
      class="field"
    >
      <div class="field__title">
        {{ field.title }}:
      </div>

      <div class="field__values">
        <div
          v-for="(value, j) in field.values"
          :key="j"
          class="field__value"
        >
          <div class="field__value-main">
            {{ value.main }}
          </div>

          <div v-if="value.additional && value.additional.length">
            <div
              v-for="(item, l) in value.additional"
              :key="l"
              class="field__value-additional"
            >
              <i class="icon icon-info-request mr-8"></i>
              {{ item }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AccessRightsTable",

  props: {
    fields: {
      type: Array, Object,
      required: true,
      default: () => []
    }
  }
};
</script>

<style scoped lang="scss">
.field {
  display: flex;
  width: 100%;
  padding: 16px 0;
  border-bottom: 1px solid $grey-light-01;

  &__title {
    color: $grey-dark-01;
  }

  &__values {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__value + &__value {
    border-top: 1px solid $grey-light-01;
    padding-top: 16px;
    margin-top: 16px;
  }

  &__value-additional {
    display: flex;
    align-items: center;
    color: $accent-normal;
    margin-top: 10px;
  }

  @media screen and (max-width: $screen-md-max) {
    flex-direction: column;

    &__values {
      margin-top: 4px;
    }
  }

  @media screen and (min-width: $screen-lg-min) {
    &__title {
      width: 234px;
      padding-right: 26px;
    }
  }
}
</style>
