<template>
  <div class="payment-card__wrapper">
    <div
      class="payment-card"
      :class="`payment-card--${cardStyle}`"
    >
      <div class="payment-card__header">
        <img
          v-if="logo"
          :src="logo"
          alt="logo"
          class="payment-card__header-logo"
        >
        <div
          v-if="typeLabel"
          class="payment-card__header-item"
        >
          {{ typeLabel }}
        </div>
        <div
          v-if="cardNumber"
          class="payment-card__header-item"
        >
          {{ cardNumber }}
        </div>
      </div>

      <div class="payment-card__main">
        <div
          v-if="primaryLabel"
          class="payment-card__label-primary"
        >
          {{ primaryLabel }}
        </div>
        <div
          v-if="primaryNumber"
          class="payment-card__number-primary"
        >
          {{ primaryNumber }}
        </div>
        <div
          v-if="secondaryLabel"
          class="payment-card__label-secondary"
        >
          {{ secondaryLabel }}
        </div>
      </div>

      <div class="payment-card__footer">
        <div class="payment-card__footer-item">
          <div class="payment-card__info-label">
            valid thru
          </div>
          <div class="payment-card__info">
            {{ valid }}
          </div>
        </div>

        <div class="payment-card__footer-item">
          <div class="payment-card__info-label">
            Cardholder
          </div>
          <div class="payment-card__info">
            {{ holder }}
          </div>
        </div>

        <svg
          v-if="issuer"
          class="payment-card__issuer"
        >
          <use
            :xlink:href="
              require('@/assets/images/svg-sprites.svg') + '#' + issuer
            "
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaymentCard",

  props: {
    cardStyle: {
      type: String,
      required: false,
      default: "green",
      validator: function(value) {
        return ["green", "black", "white"].indexOf(value) !== -1;
      }
    },
    logo: { type: String, required: false, default: "" },
    issuer: { type: String, required: false, default: "" },
    typeLabel: { type: String, required: false, default: "" },
    cardNumber: { type: String, required: false, default: "" },
    primaryLabel: { type: String, required: false, default: "" },
    primaryNumber: { type: String, required: false, default: "" },
    secondaryLabel: { type: String, required: false, default: "" },
    valid: { type: String, required: false, default: "" },
    holder: { type: String, required: false, default: "" }
  }
};
</script>

<style scoped lang="scss">
.payment-card {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 8px;
  box-shadow: 0px 4px 16px rgba(0, 3, 31, 0.15),
    0px 2px 6px rgba(0, 3, 31, 0.15);
  font-family: $font-regular;
  padding: 8px 10px;

  &__wrapper {
    position: relative;
    width: 420px;
    max-width: 100%;
    padding-top: 59%;
    max-height: 248px;
  }

  &--green {
    background-color: $accent-normal;
    color: $white;
  }

  &--black {
    background-color: $grey-dark;
    color: $white;
  }

  &--white {
    background-color: $white;
    color: $black;
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__header-logo {
    display: block;
    max-height: 32px;
  }

  &__header-item {
    font-size: 14px;
    line-height: 18px;
  }

  &__label-primary {
    font-size: 28px;
    line-height: 32px;
  }

  &__number-primary {
    font-size: 18px;
    line-height: 24px;
    color: $grey-dark-01;
    margin-top: 4px;
  }

  &__label-secondary {
    font-size: 12px;
    line-height: 16px;
    margin-top: 2px;
  }

  &__footer {
    display: flex;
  }

  &__footer-item + &__footer-item {
    margin-left: 22px;
  }

  &__info-label {
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    opacity: 0.5;
  }

  &__info {
    margin-top: 2px;
    font-size: 14px;
    line-height: 18px;
  }

  &__issuer {
    position: absolute;
    right: 2px;
    bottom: 2px;
    width: 32px;
    height: 32px;
  }

  @media screen and (min-width: $screen-md-min) {
    padding: 16px;

    &__header-item {
      font-size: 18px;
      line-height: 24px;
    }

    &__label-secondary {
      font-size: 16px;
      line-height: 20px;
      margin-top: 4px;
    }

    &__info-label {
      font-size: 12px;
      line-height: 16px;
    }

    &__issuer {
      right: 12px;
      bottom: 8px;
      width: 48px;
      height: 48px;
    }
  }
}
</style>
