<template>
  <ModalWrapper
    size="md"
    fix-height
  >
    <template slot="title">
      Add account
    </template>

    <ModalCallout>
      <div class="add-account-modal__header-label">
        Account Name
      </div>
    </ModalCallout>

    <div class="add-account-modal__body">
      <div class="add-account-modal__body-title">
        Currency
      </div>

      <div class="add-account-modal__list">
        <div class="add-account-modal__chunk">
          <div
            v-for="(item, i) in currencies.slice(0, 6)"
            :key="i"
            class="add-account-modal__cell"
          >
            <FormCheckboxBox @change="handleSelect(item.title, $event)">
              <template slot="title">
                {{ item.title }}
              </template>

              <template slot="img">
                <img
                  v-if="item.flag"
                  :src="require(`@/assets/images/flags/${item.flag}.svg`)"
                  :alt="item.title"
                >
              </template>
            </FormCheckboxBox>
          </div>
        </div>

        <transition name="add-account-fade">
          <div
            v-if="!isCollapsed"
            class="add-account-modal__chunk collapsable"
          >
            <div
              v-for="(item, j) in currencies.slice(6)"
              :key="j"
              class="add-account-modal__cell"
            >
              <FormCheckboxBox @change="handleSelect(item.title, $event)">
                <template slot="title">
                  {{ item.title }}
                </template>

                <template slot="img">
                  <img
                    v-if="item.flag"
                    :src="require(`@/assets/images/flags/${item.flag}.svg`)"
                    :alt="item.title"
                  >
                </template>
              </FormCheckboxBox>
            </div>
          </div>
        </transition>

        <button
          class="add-account-modal__collapse-toggle"
          @click="isCollapsed = false"
        >
          Show more
        </button>
      </div>
    </div>

    <template slot="footer">
      <div class="add-account-modal-footer">
        <Button
          label="Add account"
          btn-style="primary"
          :click-handler="handleSubmit"
        />

        <div class="add-account-modal-footer__note">
          I request TPF Ltd. to open a new account for Umbrella Inc.
        </div>
      </div>
    </template>
  </ModalWrapper>
</template>

<script>
import { mapActions } from "vuex";

import ModalWrapper from "@/components/Modal/ModalWrapper";
import ModalCallout from "@/components/Modal/ModalCallout";
import Button from "@/components/Button";
import FormCheckboxBox from "@/components/Form/FormCheckboxBox";

export default {
  name: "AddAccountModal",

  components: {
    ModalWrapper,
    ModalCallout,
    Button,
    FormCheckboxBox
  },

  data() {
    return {
      isCollapsed: true,

      selected: [],

      currencies: [
        { title: "EUR", flag: "eu" },
        { title: "GBP", flag: "gb" },
        { title: "USD", flag: "us" },
        { title: "CHF", flag: "ch" },
        { title: "NOK", flag: "no" },
        { title: "RUR", flag: "ru" },
        { title: "DKK", flag: "dk" },
        { title: "SEK", flag: "se" },
        { title: "CAN", flag: "ca" },
        { title: "AUD", flag: "au" },
        { title: "JPY", flag: "jp" },
        { title: "HUF", flag: "hu" },
        { title: "UAH", flag: "ua" },
        { title: "PLN", flag: "pl" },
        { title: "BTC", flag: "btc" },
        { title: "HKD", flag: "hk" },
        { title: "CZK", flag: "cz" },
        { title: "CNY", flag: "cn" },
        { title: "BRL", flag: "br" },
        { title: "NIS", flag: "il" },
        { title: "KRW", flag: "kr" }
      ]
    };
  },

  methods: {
    ...mapActions(["showModal"]),

    handleSelect(title) {
      if (this.selected.includes(title)) {
        const index = this.selected.indexOf(title);

        if (index > -1) this.selected.splice(index, 1);
      } else {
        this.selected.push(title);
      }
    },

    handleSubmit() {
      this.showModal({
        component: "SuccessAccountModal"
      });
    }
  }
};
</script>

<style scoped lang="scss">
.add-account-modal {
  &__header-label {
    font-size: 16px;
    line-height: 20px;
    color: $grey-dark;
    padding: 16px 0;
  }

  &__body {
    padding: 16px 0;
  }

  &__body-title {
    font-family: $font-semibold;
    font-size: 16px;
    line-height: 20px;
  }

  &__chunk {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -12px;
  }

  &__chunk.is-collapsed {
    height: 0;
  }

  &__cell {
    width: 50%;
    padding: 6px;
  }

  &__collapse-toggle {
    font-size: 14px;
    line-height: 18px;
    color: $additional-brown;
    transition: color 0.1s linear;
  }

  &__collapse-toggle:after {
    content: "\e911";
    @include inline-icon;
    line-height: 18px;
    vertical-align: bottom;
  }

  .collapsable ~ &__collapse-toggle {
    display: none;
  }

  @media (hover: hover) {
    &__collapse-toggle:hover {
      color: $accent-light;
    }
  }

  @media screen and (min-width: $screen-md-min) {
    &__header-label {
      padding: 24px 0;
    }

    &__body {
      padding: 24px 0;
    }

    &__cell {
      width: 33.3333%;
      padding: 12px;
    }
  }
}

.add-account-modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 8px;
  width: 100%;

  &__note {
    font-size: 16px;
    line-height: 20px;
    color: $grey-dark-01;
    width: 100%;
    margin-top: 12px;
  }

  @media screen and (max-width: $screen-sm-max) {
    & .btn {
      min-width: 100%;
    }
  }

  @media screen and (min-width: $screen-md-min) {
    padding: 12px;

    &__note {
      width: 324px;
      margin-left: 16px;
      margin-top: 0;
    }
  }
}
</style>

<style>
.add-account-fade-enter-active,
.add-account-fade-leave-active {
  transition: opacity 0.5s;
}
.add-account-fade-enter,
.add-account-fade-leave-to {
  opacity: 0;
}
</style>
