<template>
  <div class="card-selector">
    <h2 class="card-selector__title h2-bold">
      Cards
    </h2>

    <div class="card-selector__inner">
      <div class="card-selector__card-box">
        <div class="card-selector-carousel">
          <hooper
            ref="hooper"
            :settings="hooperSettings"
            @slide="handleSlide"
          >
            <slide
              v-for="(card, i) in cards"
              :key="i"
            >
              <div class="card-selector-carousel__slide">
                <PaymentCard
                  :card-style="card.style"
                  :issuer="card.issuer"
                  :type-label="card.name"
                  :card-number="card.number"
                  :primary-label="card.balance"
                  :secondary-label="card.onHold"
                  :valid="card.valid"
                  :holder="card.holder"
                />
              </div>
            </slide>
            <hooper-pagination slot="hooper-addons" />
          </hooper>
        </div>

        <div
          v-if="cards.length > 1"
          class="card-selector-carousel__nav"
        >
          <ButtonCircle
            v-if="cards.length > 4"
            icon="icon-arrow-left"
            btn-style="dark"
            btn-size="sm"
            :click-handler="handlePrevPage"
            :disabled="currentPage === 1"
          />

          <div class="card-selector-carousel__nav-list">
            <div
              v-for="p in Math.ceil(cards.length / 4)"
              :key="p"
              class="card-selector-carousel__nav-page"
              :class="{ 'is-selected': p === currentPage }"
            >
              <div
                v-for="(item, j) in cards.slice((p - 1) * 4, p * 4)"
                :key="j"
                class="card-selector-carousel__nav-item-wrapper"
              >
                <button
                  class="card-selector-carousel__nav-item"
                  :class="[
                    `card-selector-carousel__nav-item--${item.style}`,
                    {
                      'is-selected': cards.indexOf(item) === currentCard
                    }
                  ]"
                  @click="handleSelect(cards.indexOf(item))"
                >
                  <svg>
                    <use
                      :xlink:href="
                        require('@/assets/images/svg-sprites.svg') +
                          '#' +
                          item.issuer
                      "
                    />
                  </svg>

                  <span class="card-selector-carousel__nav-item-label">
                    {{ item.name }}
                  </span>
                </button>
              </div>
            </div>
          </div>

          <ButtonCircle
            v-if="cards.length > 4"
            icon="icon-right"
            btn-style="dark"
            btn-size="sm"
            :click-handler="handleNextPage"
            :disabled="currentPage === Math.ceil(cards.length / 4)"
          />
        </div>
      </div>

      <div class="card-selector__action-box">
        <ul class="card-selector__action-list">
          <li>
            <ButtonCircle
              icon="icon-add"
              label="Top-up"
              btn-style="dark"
            />
          </li>
          <li>
            <ButtonCircle
              icon="icon-statement"
              label="Statement"
              btn-style="dark"
            />
          </li>
          <li>
            <ButtonCircle
              icon="icon-lock"
              label="Block"
              btn-style="dark"
            />
          </li>
          <li>
            <ButtonCircle
              icon="icon-pin"
              label="View PIN"
              btn-style="dark"
            />
          </li>
          <li>
            <ButtonCircle
              icon="icon-more"
              label="More"
              btn-style="dark"
            />
          </li>
        </ul>

        <div class="card-selector__action-footer">
          <Button
            label="Add card"
            btn-style="dark"
            btn-width="100%"
            :click-handler="handleNewCardPopup"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import { cards } from "@/fixtures";

import Button from "@/components/Button";
import ButtonCircle from "@/components/ButtonCircle";
import PaymentCard from "@/components/Blocks/PaymentCard";

import { Hooper, Slide, Pagination as HooperPagination } from "hooper";
import "hooper/dist/hooper.css";

export default {
  name: "CardSelector",

  components: {
    ButtonCircle,
    PaymentCard,
    Button,
    Hooper,
    Slide,
    HooperPagination
  },

  data() {
    return {
      currentCard: 0,

      currentPage: 1,

      hooperSettings: {
        centerMode: true,
        autoPlay: false,
        breakpoints: {
          768: {
            itemsToShow: 1
          },
          0: {
            itemsToShow: 1.3
          }
        }
      }
    };
  },

  created() {
    this.cards = cards;
  },

  methods: {
    ...mapActions(["showModal"]),

    handleSelect(id) {
      this.currentCard = id;

      this.$refs.hooper.slideTo(id);
    },

    handlePrevPage() {
      this.currentPage = this.currentPage - 1;
    },

    handleNextPage() {
      this.currentPage = this.currentPage + 1;
    },

    handleSlide(id) {
      this.currentCard = id.currentSlide;
    },

    handleNewCardPopup() {
      this.showModal({
        component: "NewCardModal"
      });
    }
  }
};
</script>

<style scoped lang="scss">
.card-selector {
  width: 100%;
  background-color: $additional-normal;
  border-radius: 8px;
  padding: 16px 16px 24px 16px;

  &__title {
    margin-bottom: 28px;
  }

  &__card-box {
    width: 100%;
    position: relative;
  }

  &__action-list {
    display: flex;
    flex-wrap: wrap;
    margin: -12px;
  }

  &__action-list li {
    padding: 12px;
    width: 33.33%;
    text-align: center;
  }

  &__action-footer {
    margin-top: 24px;
  }

  @media screen and (max-width: $screen-sm-max) {
    &__title {
      display: none;
    }
  }

  @media screen and (min-width: $screen-md-min) {
    padding: 24px;

    &__inner {
      display: flex;
    }

    &__card-box {
      width: 50%;
      min-width: 340px;
      max-width: 420px;
    }

    &__action-box {
      margin-left: 32px;
      width: 50%;
    }
  }

  @media screen and (min-width: $screen-lg-min) {
    padding: 24px 24px 48px 24px;
  }
}

.card-selector-carousel {
  margin: -16px;

  &__slide {
    width: 420px;
    max-width: 100%;
    margin: auto;
    padding: 16px 0;
  }

  &__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
    padding: 0 16px;
  }

  &__nav-list {
    position: relative;
    height: 40px;
    width: 280px;
  }

  &__nav-page {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    transition: opacity 0.1s linear, z-index 0s linear 0s,
      visibility 0s linear 0s;
    display: inline-flex;
  }

  &__nav-page.is-selected {
    opacity: 1;
    visibility: visible;
    z-index: 1;
  }

  &__nav-item-wrapper {
    display: inline-flex;
    width: 25%;
  }

  &__nav-item {
    position: relative;
    width: 58px;
    height: 40px;
    border-radius: 4px;
    color: $additional-brown;
    margin: 0 auto;
  }

  &__nav-item.is-selected:before {
    content: "";
    width: 8px;
    height: 8px;
    position: absolute;
    z-index: 1;
    top: -4px;
    left: 50%;
    margin-left: -4px;
    transform: rotate(45deg);
  }

  &__nav-item--green,
  &__nav-item--green:before {
    background-color: $accent-normal;
  }

  &__nav-item--black,
  &__nav-item--black:before {
    background-color: $grey-dark;
  }

  &__nav-item--white,
  &__nav-item--white:before {
    background-color: $white;
  }

  &__nav-item svg {
    width: 40px;
    height: 40px;
  }

  &__nav-item-label {
    position: absolute;
    bottom: -18px;
    left: 0;
    z-index: 1;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
    font-size: 12px;
    line-height: 16px;
  }

  @media screen and (max-width: $screen-sm-max) {
    &__nav {
      display: none;
    }
  }

  @media screen and (min-width: $screen-md-min) {
    &__slide {
      padding: 16px;
    }
  }

  @media screen and (min-width: $screen-lg-min) {
    &__nav-item {
      // margin: 0 12px;
    }

    &__nav-list {
      width: 328px;
    }
  }
}
</style>

<style lang="scss">
.card-selector-carousel {
  & .hooper {
    height: auto;
    padding-bottom: 32px;
  }

  & .hooper:focus {
    outline: none;
  }

  & .payment-card__wrapper {
    transform: scale(0.9);
    transition: transform 0.1s linear;
  }

  & .hooper-slide.is-current .payment-card__wrapper {
    transform: scale(1);
  }

  & .hooper-pagination {
    bottom: 16px;
  }

  & .hooper-indicator {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin: 0 4px;
    background-color: rgba($white, 0.25);
  }

  & .hooper-indicator.is-active {
    background-color: $white;
  }

  @media screen and (min-width: $screen-md-min) {
    & .hooper {
      padding-bottom: 0;
    }

    & .hooper-pagination {
      display: none;
    }
  }
}
</style>
