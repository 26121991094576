<template>
  <ModalWrapper
    size="md"
    modal-style="success"
  >
    <template slot="title">
      Successfully opened
    </template>

    <div class="text-center text-sb mb-16">
      Account has been successfully opened
    </div>

    <template slot="footer">
      <Button
        label="Ok"
        btn-style="primary"
        btn-width="110px"
        :click-handler="hideModal"
      />
    </template>
  </ModalWrapper>
</template>

<script>
import { mapActions } from "vuex";
import ModalWrapper from "@/components/Modal/ModalWrapper";
import Button from "@/components/Button";

export default {
  name: "SuccessAccountModal",

  components: {
    ModalWrapper,
    Button
  },

  methods: {
    ...mapActions(["hideModal"])
  }
};
</script>
