<template>
  <DashboardLayout>
    <StatementTable />

    <StatementCurrencyTable />

    <div class="mb-lg-32 mb-sm-24">
      <Pagination
        :current-page="pagination.currentPage"
        :pages="pagination.pages"
        :per-page="pagination.perPage"
        @selectPage="pagination.currentPage = $event"
        @perPageChange="pagination.perPage = $event"
      />
    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "@/components/Layout/DashboardLayout";
import StatementTable from "@/components/Blocks/StatementTable";
import StatementCurrencyTable from "@/components/Blocks/StatementCurrencyTable";
import Pagination from "@/components/Blocks/Pagination";

export default {
  name: "Statement",

  components: {
    DashboardLayout,
    StatementTable,
    StatementCurrencyTable,
    Pagination
  },

  data() {
    return {
      pagination: {
        currentPage: 1,
        pages: 36,
        perPage: 10
      }
    };
  }
};
</script>
