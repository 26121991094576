<template>
  <div
    class="mass-payout-modal-dropzone"
    :class="{
      'has-dragover': hasDragover,
      'has-error': hasError
    }"
    @dragover="handleDragover"
    @dragenter="handleDragover"
    @dragleave="handleDragend"
    @dragend="handleDragend"
    @drop="handleDrop"
  >
    <div class="mass-payout-modal-dropzone__inner">
      <span class="mass-payout-modal-dropzone__icon" />

      <div class="mass-payout-modal-dropzone__title">
        {{
          !hasError ? "Drag and drop the bill here" : `${hasError} Try again`
        }}
      </div>

      <div class="mass-payout-modal-dropzone__info">
        You can upload XLS file with your transactions and them pay all of you
        transactions by one click.
        <br>
        <a
          href="#"
          class="text-link"
        >View XLS example</a>
      </div>

      <label class="mass-payout-modal-dropzone__label">
        <input
          ref="input"
          type="file"
          @change="handleChange"
        >

        <Button
          label="Upload file"
          btn-style="primary"
        />
      </label>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button";

export default {
  name: "MassPayoutDropzone",

  components: {
    Button
  },

  props: {
    hasError: { type: String, required: false, default: "" }
  },

  data() {
    return {
      hasDragover: false,
      files: []
    };
  },

  methods: {
    handleDragover(e) {
      e.preventDefault();
      if (this.hasDragover) return;
      this.hasDragover = true;
    },

    handleDragend(e) {
      e.preventDefault();
      this.hasDragover = false;
    },

    handleDrop(e) {
      e.preventDefault();

      const droppedFiles = e.dataTransfer.files;
      const input = this.$refs.input;

      input.files = droppedFiles;

      this.hasDragover = false;
    },

    handleChange(e) {
      e.preventDefault();

      const files = e.target.files;

      this.hasFile = true;
      this.fileName = files[0].name;

      this.$emit("change", files);
    }
  }
};
</script>

<style scoped lang="scss">
.mass-payout-modal-dropzone {
  position: relative;
  width: 100%;
  border: 1px dashed $grey-normal;
  border-radius: 4px;
  text-align: center;
  transition: background-color 0.1s linear;

  &.has-dragover {
    background-color: rgba($accent-normal, 0.1);
  }

  & input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 32px 16px 24px 16px;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: $grey-light-02;
  }

  &__icon:before {
    content: "\e936";
    @include inline-icon;
    font-size: 40px;
    color: $grey-dark-02;
    transform: translateX(1px);
  }

  &.has-error &__icon {
    border: 4px solid $red;
    background-color: transparent;
  }

  &.has-error &__icon:before {
    content: "\e934";
    color: $red;
  }

  &__title {
    margin-top: 40px;
    font-family: $font-semibold;
    font-size: 16px;
    line-height: 20px;
    color: $additional-brown;
  }

  &.has-error &__title {
    color: $red;
  }

  &__info {
    margin-top: 16px;
    font-size: 14px;
    line-height: 18px;
    color: rgba($grey-dark-01, 0.8);
  }

  &__label {
    width: 100%;
    margin-top: 24px;
  }

  @media screen and (max-width: $screen-sm-max) {
    &__label .btn {
      width: 100%;
    }

    &__title {
      display: none;
    }

    &.has-error &__title {
      display: block;
    }
  }

  @media screen and (min-width: $screen-md-min) {
    &__inner {
      padding: 32px;
    }

    &__info {
      margin-top: 8px;
    }

    &__label {
      margin-top: 40px;
    }
  }
}
</style>
