var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ModalWrapper',{attrs:{"size":"md"}},[_c('template',{slot:"title"},[_vm._v(" Distribute funds ")]),_c('div',{staticClass:"distribute-funds-modal__row"},[_c('div',{staticClass:"distribute-funds-modal__row-item"},[_c('FormSelect',{attrs:{"label":"From account","value":_vm.accountFrom,"options":[
          'Savings account 1',
          'Savings account 2',
          'Savings account 3'
        ]},on:{"change":function($event){_vm.accountFrom = $event}}})],1),_c('div',{staticClass:"distribute-funds-modal__row-item"},[_c('FormSelect',{attrs:{"label":"To account","value":_vm.accountTo,"options":['Account 1', 'Account 2', 'Account 3']},on:{"change":function($event){_vm.accountTo = $event}}})],1)]),_c('FormItemGroup',{staticClass:"mt-lg-40 mt-sm-32",attrs:{"label":"Goal amount (sum)"}},[_c('FormInput',{attrs:{"type":"number","has-error":_vm.goalError,"value":_vm.goal},on:{"input":function($event){_vm.goal = $event}}}),_c('FormSelect',{attrs:{"el-width":"120px","value":_vm.currency,"options":[
        { value: 'usd', title: 'USD', flag: 'us' },
        { value: 'eur', title: 'EUR', flag: 'eu' },
        { value: 'gbp', title: 'GBP', flag: 'gb' }
      ]},on:{"change":function($event){_vm.currency = $event}}})],1),_c('ButtonLink',{staticClass:"mt-12",attrs:{"label":("Transfer all: " + _vm.availableFunds),"btn-style":"dark","btn-size":"sm","click-handler":_vm.handleTransferAll}}),_c('template',{slot:"footer"},[_c('ModalActionWrapper',[_c('Button',{attrs:{"label":"Transfer","btn-style":"primary","click-handler":_vm.handleSubmit}})],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }