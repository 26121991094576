<template>
  <ModalWrapper size="md">
    <template slot="title">
      Payment
    </template>

    <ModalCallout>
      <div class="payment-modal-header">
        <div class="text-sb">
          Umbrella INC
        </div>

        <div class="arrange arrange--wrap mt-32">
          <FormSelect
            id="account"
            name="account"
            label="Account"
            class="payment-modal-header__account"
            el-style="input-inverse"
            :value="account"
            :options="['Account 1', 'Account 2', 'Account 3']"
            @change="account = $event"
          />

          <div class="payment-modal-header__amount">
            <FormItemGroup
              label="Amount"
              comment="Available balance: 2,010.89"
            >
              <FormInput
                id="amount"
                name="amount"
                type="number"
                el-style="inverse"
                :has-error="amountError"
                :value="amount"
                @input="amount = $event"
              />

              <FormSelect
                id="currency"
                name="currency"
                el-width="120px"
                el-style="input-inverse"
                :value="currency"
                :options="[
                  { value: 'USD', title: 'USD', flag: 'us' },
                  { value: 'EUR', title: 'EUR', flag: 'eu' },
                  { value: 'GBP', title: 'GBP', flag: 'gb' }
                ]"
                @change="currency = $event"
              />
            </FormItemGroup>
          </div>
        </div>
      </div>
    </ModalCallout>

    <div class="arrange arrange--between text-sb mt-lg-24 mt-sm-16">
      <span>Beneficiary</span>

      <ButtonLink
        icon="icon-doc-sm"
        label="Use template"
        btn-style="dark"
        btn-size="sm"
        :click-handler="handleTemplate"
      />
    </div>

    <div class="mt-32">
      <FormInput
        id="name"
        name="name"
        type="text"
        :value="name"
        label="Name"
        :has-error="nameError"
        @input="name = $event"
      />
    </div>

    <div class="mt-lg-40 mt-sm-32">
      <FormInput
        id="address"
        name="address"
        type="text"
        :value="address"
        label="Address"
        :has-error="addressError"
        @input="address = $event"
      />
    </div>

    <div class="mt-lg-40 mt-sm-32">
      <FormInput
        id="city"
        name="city"
        type="text"
        :value="city"
        label="City"
        :has-error="cityError"
        @input="city = $event"
      />
    </div>

    <div class="arrange mt-lg-40 mt-sm-32">
      <FormSelect
        id="country"
        name="country"
        label="Country"
        class="payment-modal__country"
        :value="country"
        :options="[
          { value: 'lt', title: 'Lithuania', flag: 'lt' },
          { value: 'lv', title: 'Latvia', flag: 'lv' },
          { value: 'ee', title: 'Estonia', flag: 'ee' }
        ]"
        @change="country = $event"
      />

      <FormInput
        id="zip"
        name="zip"
        type="text"
        class="payment-modal__zip"
        :value="zip"
        label="Zip Code"
        :has-error="zipError"
        placeholder="LV - 0000"
        mask="LV - ####"
        @input="zip = $event"
      />
    </div>

    <div class="text-sb mt-24">
      Bank
    </div>

    <div class="arrange mt-32">
      <FormInput
        id="bank_name"
        name="bank-name"
        type="text"
        :value="bankName"
        label="Name"
        class="payment-modal__bank-name"
        :has-error="bankNameError"
        @input="bankName = $event"
      />

      <FormInput
        id="swift"
        name="swift"
        type="text"
        :value="swift"
        label="SWIFT"
        class="payment-modal__swift"
        :has-error="swiftError"
        @input="swift = $event"
      />
    </div>

    <div class="arrange arrange--wrap mt-lg-40 mt-sm-32">
      <FormSelect
        id="bank_country"
        name="bank-country"
        label="Country"
        class="payment-modal__bank-country"
        :value="bankCountry"
        :options="[
          { value: 'lt', title: 'Lithuania', flag: 'lt' },
          { value: 'lv', title: 'Latvia', flag: 'lv' },
          { value: 'ee', title: 'Estonia', flag: 'ee' }
        ]"
        @change="bankCountry = $event"
      />

      <FormInput
        id="account_number"
        name="account-number"
        type="text"
        class="payment-modal__account-number"
        :value="accountNumber"
        label="Account number"
        :has-error="accountNumberError"
        @input="accountNumber = $event"
      />
    </div>

    <div class="text-sb mt-24">
      Payment details
    </div>

    <div class="mt-32">
      <FormInput
        id="information"
        name="information"
        type="text"
        :value="information"
        label="Information"
        :has-error="informationError"
        @input="information = $event"
      />
    </div>

    <div class="arrange arrange--align-center mt-24">
      <Toggle
        id="corr_bank"
        name="corr-bank"
        :value="corrBank"
        @change="corrBank = $event"
      />

      <span class="text-grey ml-8">Add correspondent bank</span>
    </div>

    <div v-if="corrBank">
      <div class="arrange mt-40">
        <FormInput
          id="corr_bank_name"
          name="corr-bank-name"
          type="text"
          :value="corrBankName"
          label="Name"
          class="payment-modal__bank-name"
          :has-error="corrBankNameError"
          @input="corrBankName = $event"
        />

        <FormInput
          id="corr_swift"
          name="corr-swift"
          type="text"
          :value="corrSwift"
          label="SWIFT"
          class="payment-modal__swift"
          :has-error="corrSwiftError"
          @input="corrSwift = $event"
        />
      </div>

      <div class="arrange arrange--wrap mt-lg-40 mt-sm-32">
        <FormSelect
          id="corr_bank_country"
          name="corr-bank-country"
          label="Country"
          class="payment-modal__bank-country"
          :value="corrBankCountry"
          :options="[
            { value: 'lt', title: 'Lithuania', flag: 'lt' },
            { value: 'lv', title: 'Latvia', flag: 'lv' },
            { value: 'ee', title: 'Estonia', flag: 'ee' }
          ]"
          @change="corrBankCountry = $event"
        />

        <FormInput
          id="corr_account_number"
          name="corr-account-number"
          type="text"
          class="payment-modal__account-number"
          :value="corrAccountNumber"
          label="Account number"
          :has-error="corrAccountNumberError"
          @input="corrAccountNumber = $event"
        />
      </div>
    </div>

    <div class="mt-24">
      <DocumentDropzone />
    </div>

    <template slot="footer">
      <ModalActionWrapper>
        <Button
          label="Pay"
          btn-style="primary"
          :click-handler="handleSubmit"
        />
      </ModalActionWrapper>
    </template>
  </ModalWrapper>
</template>

<script>
import { mapActions } from "vuex";

import ModalWrapper from "@/components/Modal/ModalWrapper";
import ModalCallout from "@/components/Modal/ModalCallout";
import ModalActionWrapper from "@/components/Modal/ModalActionWrapper";
import Button from "@/components/Button";
import ButtonLink from "@/components/ButtonLink";
import FormSelect from "@/components/Form/FormSelect";
import FormInput from "@/components/Form/FormInput";
import FormItemGroup from "@/components/Form/FormItemGroup";
import Toggle from "@/components/Toggle";
import DocumentDropzone from "@/components/Blocks/DocumentDropzone";

export default {
  name: "NewPaymentModal",

  components: {
    ModalWrapper,
    ModalCallout,
    ModalActionWrapper,
    Button,
    ButtonLink,
    FormSelect,
    FormInput,
    FormItemGroup,
    Toggle,
    DocumentDropzone
  },

  data() {
    return {
      account: "Account 1",
      currency: "USD",
      amount: null,
      amountError: "",
      name: "",
      nameError: "",
      address: "",
      addressError: "",
      country: "lv",
      countryError: "",
      city: "",
      cityError: "",
      zip: "",
      zipError: "",
      bankName: "",
      bankNameError: "",
      swift: "",
      swiftError: "",
      bankCountry: "lv",
      accountNumber: "",
      accountNumberError: "",
      information: "",
      informationError: "",
      corrBank: false,
      corrBankName: "",
      corrBankNameError: "",
      corrSwift: "",
      corrSwiftError: "",
      corrBankCountry: "lv",
      corrAccountNumber: "",
      corrAccountNumberError: ""
    };
  },

  methods: {
    ...mapActions(["showModal"]),

    handleSubmit() {
      this.showModal({
        component: "NewPaymentSuccessModal"
      });
    },

    handleTemplate() {
      this.showModal({
        component: "PaymentTemplatesModal"
      });
    }
  }
};
</script>

<style scoped lang="scss">
.payment-modal-header {
  padding: 16px 0 38px 0;

  &__title {
    font-family: $font-semibold;
    font-size: 16px;
    line-height: 20px;
  }

  &__account {
    width: 100%;
  }

  &__amount {
    width: 100%;
    margin-top: 32px;
  }

  @media screen and (min-width: $screen-md-min) {
    padding: 24px 0 40px 0;

    &__account {
      width: 220px;
    }

    &__amount {
      margin-left: 24px;
      margin-top: 0;
      max-width: 308px;
    }
  }
}

.payment-modal {
  &__country,
  &__bank-country {
    width: 100%;
  }

  &__country-row {
    width: 100%;
    display: flex;
    margin-top: 32px;
  }

  &__zip,
  &__swift {
    width: 168px;
    min-width: 110px;
    margin-left: 16px;
  }

  &__account-number {
    margin-top: 32px;
  }

  @media screen and (min-width: $screen-md-min) {
    &__city-row {
      width: auto;
      margin-top: 0;
    }

    &__city {
      width: 116px;
      margin-left: 24px;
      margin-top: 0;
    }

    &__country,
    &__bank-name {
      width: 360px;
    }

    &__zip,
    &__swift {
      margin-left: 24px;
    }

    &__bank-country {
      width: 220px;
    }

    &__account-number {
      width: 308px;
      margin-left: 24px;
      margin-top: 0;
    }
  }
}
</style>
