<template>
  <div>
    <h1>Homepage</h1>
  </div>
</template>

<script>
export default {
  name: "Home",

  components: {}
};
</script>
