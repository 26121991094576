<template>
  <div>
    <div style="background-color: #F5F6F9; padding: 32px;">
      <h1 class="h1-bold">
        TPF UI
      </h1>
    </div>

    <div
      class="mt-32"
      style="padding: 32px;"
    >
      <h2 class="h2-bold">
        Template list
      </h2>

      <ul class="mt-32">
        <li
          v-for="(page, i) in pages"
          :key="i"
          class="mt-16"
        >
          <router-link :to="{ name: page.route }">
            {{ page.title }}
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",

  data() {
    return {
      pages: [
        { title: "UI-kit", route: "uikit" },
        { title: "Modals", route: "modals" },
        { title: "Login", route: "login" },
        { title: "Two-Factor Authentication", route: "login-two-factor" },
        { title: "Dashboard", route: "dashboard" },
        { title: "Dashboard - No data", route: "dashboard-alt" },
        { title: "Statement", route: "statement" },
        { title: "Onboarding - Sign up", route: "onboarding-signup" },
        {
          title: "Onboarding - Create password",
          route: "onboarding-password"
        },
        {
          title: "Onboarding - Personal information",
          route: "onboarding-info"
        },
        {
          title: "Onboarding - Attach utility bill",
          route: "onboarding-bill"
        },
        {
          title: "Onboarding - Professional profile",
          route: "onboarding-profile"
        },
        {
          title: "Onboarding - Verification",
          route: "onboarding-verification"
        },
        {
          title: "Onboarding - Verification successful",
          route: "onboarding-success"
        },
        { title: "Messages", route: "messages" },
        { title: "Notifications", route: "notifications" },
        { title: "Savings", route: "savings" },
        { title: "Savings Empty", route: "savings-empty" },
        { title: "Settings", route: "settings" },
        { title: "Settings - External Access", route: "settings-external-access" },
        {
          title: "Onboarding Business - Sign up",
          route: "onboarding-business-signup"
        },
        {
          title: "Onboarding Business - Email verification",
          route: "onboarding-business-email-verification"
        },
        {
          title: "Onboarding Business - Create password",
          route: "onboarding-business-password"
        },
        {
          title: "Onboarding Business - Password recovery",
          route: "onboarding-business-password-recovery"
        },
        {
          title: "Onboarding Business - Contact Information",
          route: "onboarding-business-info"
        },
        {
          title: "Onboarding Business - Attach utility bill",
          route: "onboarding-business-bill"
        },
        {
          title: "Onboarding Business - Company profile",
          route: "onboarding-business-profile"
        },
        {
          title: "Onboarding Business - Company representative",
          route: "onboarding-business-representative"
        },
        {
          title: "Onboarding Business - Beneficial owners",
          route: "onboarding-business-owners"
        },
        {
          title: "Onboarding Business - Corporate documents",
          route: "onboarding-business-documents"
        },
        {
          title: "Onboarding Business - Business information",
          route: "onboarding-business-info-business"
        },
        {
          title: "Onboarding Business - Verification",
          route: "onboarding-business-verification"
        },
        {
          title: "Onboarding Business - Success",
          route: "onboarding-business-success"
        },
        {
          title: "Access Rights - Login",
          route: "access-rights-login"
        },
        {
          title: "Access Rights - Two Factor Authentication",
          route: "access-rights-two-factor-auth"
        },
        {
          title: "Access Rights - Details",
          route: "access-rights-details"
        },
        {
          title: "Access Rights - Confirmation",
          route: "access-rights-confirmation"
        },
      ]
    };
  }
};
</script>
