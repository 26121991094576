<template>
  <ModalWrapper size="md">
    <template slot="title">
      Add savings account
    </template>

    <div class="mt-lg-40 mt-sm-32">
      <FormInput
        type="text"
        :value="name"
        label="Account name"
        @input="name = $event"
      />

      <FormItemGroup
        label="Goal amount (sum)"
        class="mt-lg-40 mt-sm-32"
      >
        <FormInput
          type="number"
          :has-error="goalError"
          :value="goal"
          @input="goal = $event"
        />

        <FormSelect
          el-width="120px"
          :value="currency"
          :options="[
            { value: 'usd', title: 'USD', flag: 'us' },
            { value: 'eur', title: 'EUR', flag: 'eu' },
            { value: 'gbp', title: 'GBP', flag: 'gb' }
          ]"
          @change="currency = $event"
        />
      </FormItemGroup>
    </div>

    <template slot="footer">
      <ModalActionWrapper>
        <Button
          label="Add savings account"
          btn-style="primary"
          :click-handler="handleSubmit"
        />
      </ModalActionWrapper>
    </template>
  </ModalWrapper>
</template>

<script>
import { mapActions } from "vuex";

import ModalWrapper from "@/components/Modal/ModalWrapper";
import ModalActionWrapper from "@/components/Modal/ModalActionWrapper";
import Button from "@/components/Button";
import FormInput from "@/components/Form/FormInput";
import FormSelect from "@/components/Form/FormSelect";
import FormItemGroup from "@/components/Form/FormItemGroup";

export default {
  name: "NewCardModal",

  components: {
    ModalWrapper,
    ModalActionWrapper,
    Button,
    FormInput,
    FormSelect,
    FormItemGroup
  },

  data() {
    return {
      name: "",
      goal: 1000.0,
      goalError: "",
      currency: "gbp"
    };
  },

  methods: {
    ...mapActions(["showModal"]),

    handleSubmit() {
      this.showModal({
        component: "NewSavingsAccountSuccessModal"
      });
    }
  }
};
</script>
