<template>
  <DashboardLayout>
    <div>
      <h1 class="h1-bold">
        Modals
      </h1>

      <section>
        <div
          v-for="(modal, i) in modals"
          :key="i"
          class="mt-24"
        >
          <Button
            :label="modal.name"
            btn-style="primary"
            :click-handler="
              () => {
                handleModal(modal.component, modal.data);
              }
            "
          />
        </div>
      </section>
    </div>
  </DashboardLayout>
</template>

<script>
import { mapActions } from "vuex";

import { paymentDetails, payout, accountDetails } from "@/fixtures";

import DashboardLayout from "@/components/Layout/DashboardLayout";
import Button from "@/components/Button";

export default {
  name: "Modals",

  title: "TPF | Modals",

  components: {
    DashboardLayout,
    Button
  },

  data() {
    return {
      modals: [
        { name: "New message modal", component: "NewMessageModal" },
        { name: "Messaging modal", component: "MessageModal" },
        { name: "Add account modal", component: "AddAccountModal" },
        { name: "Account added modal", component: "SuccessAccountModal" },
        { name: "Add card modal", component: "NewCardModal" },
        { name: "New payment modal", component: "NewPaymentModal" },
        { name: "Payment templates modal", component: "PaymentTemplatesModal" },
        {
          name: "Payment template list modal",
          component: "PaymentTemplateListModal"
        },
        {
          name: "New payment success modal",
          component: "NewPaymentSuccessModal"
        },
        { name: "Currency Exchange modal", component: "CurrencyExchangeModal" },
        {
          name: "Payment Details modal | Income",
          component: "PaymentDetailsModal",
          data: paymentDetails.paymentIncome
        },
        {
          name: "Payment Details modal | Expense",
          component: "PaymentDetailsModal",
          data: paymentDetails.paymentExpense
        },
        {
          name: "Add savings account modal",
          component: "NewSavingsAccountModal"
        },
        {
          name: "Add savings account success modal",
          component: "NewSavingsAccountSuccessModal"
        },
        {
          name: "Distribute funds modal",
          component: "DistributeFundsModal"
        },
        {
          name: "Distribute funds success modal",
          component: "DistributeFundsSuccessModal"
        },
        {
          name: "Mass payout upload",
          component: "MassPayoutUploadModal"
        },
        {
          name: "Mass payout upload (error)",
          component: "MassPayoutUploadModal",
          data: {
            error: "Wrong file type"
          }
        },
        {
          name: "Mass payout | step #2",
          component: "MassPayoutModal",
          data: {
            payout: payout
          }
        },
        {
          name: "Add money",
          component: "AddMoneyModal",
          data: {
            details: accountDetails
          }
        },
        {
          name: "New request",
          component: "NewRequestModal"
        },
        {
          name: "Show access rights",
          component: "ExternalAccessModal"
        },
        {
          name: "Revoke rights",
          component: "RevokeRightsModal"
        },
      ]
    };
  },

  methods: {
    ...mapActions(["showModal"]),

    handleModal(component, data = {}) {
      this.showModal({
        component: component,
        data: data
      });
    }
  }
};
</script>
