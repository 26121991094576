<template>
  <OnboardingLayout
    :current-step="currentStep"
    :side-msg="sideMsg"
  >
    <Header>
      <template slot="title">
        Attach utility bill
      </template>

      <template slot="intro">
        Upload your utility bill to confirm your residence
      </template>
    </Header>

    <div class="mt-lg-32 mt-sm-24">
      <Dropzone
        id="state"
        name="state"
        :has-error="form.fileError"
        @fileChange="form.files = $event"
      />
    </div>

    <div class="mt-lg-32 mt-sm-24">
      <Button
        label="Next"
        btn-style="primary"
        btn-width="100%"
        :disabled="!form.files || form.fileError ? true : false"
      />
    </div>
  </OnboardingLayout>
</template>

<script>
import OnboardingLayout from "@/components/Layout/OnboardingLayout";
import Header from "@/components/Onboarding/Header";
import Dropzone from "@/components/Onboarding/Dropzone";
import Button from "@/components/Button";

export default {
  components: {
    OnboardingLayout,
    Header,
    Dropzone,
    Button
  },

  data() {
    return {
      currentStep: 4,

      sideMsg:
        "We need your utility bill to confirm your residence. All your privacy data will be able to safety.",

      form: {
        files: null,
        fileError: ""
      }
    };
  }
};
</script>
