export const currentUser = {
  id: "1",
  avatar: "https://via.placeholder.com/80x80.png/ffffff/002060",
  primary: "Umbrella Inc",
  name: "John D.",
  balance: 5090.56,
  currency: "EUR",
  notifications: 2
};

export const availableUsers = [
  {
    id: "1",
    avatar: "https://via.placeholder.com/80x80.png/ffffff/002060",
    type: "company",
    primary: "ABC International Ltd",
    name: "John D.",
    balance: 5090.56,
    currency: "EUR",
    notifications: 2
  },
  {
    id: "2",
    avatar: "https://via.placeholder.com/80x80.png/ffffff/002060",
    type: "company",
    primary: "Transport Counsulting",
    name: "John D.",
    balance: 5090.56,
    currency: "EUR",
    notifications: 2
  },
  {
    id: "3",
    avatar: "https://via.placeholder.com/80x80.png/ffffff/002060",
    type: "private",
    primary: "John Doe",
    balance: 5090.56,
    currency: "EUR",
    notifications: 2
  },
  {
    id: "4",
    avatar: "https://via.placeholder.com/80x80.png/ffffff/002060",
    type: "private",
    primary: "Michael Jordan",
    name: "John D.",
    balance: 5090.56,
    currency: "EUR",
    notifications: 2
  }
];

export const paymentDetails = {
  paymentIncome: {
    details: {
      type: "income",
      title: "External Payment",
      icon: "income",
      name: "UAB Vilniaus Prekyba",
      account: "LT12657401408033217001",
      bank: "Handelsbank",
      amount: 56.0,
      currency: "USD",
      isProcessing: true
    },

    rows: [
      { title: "Sender:", value: "ABC Company LTD" },
      { title: "Sender Bank:", value: "Handelsbank" },
      { title: "SWIFT:", value: "DEKTDE7G" },
      { title: "Account of the sender:", value: "DE654467654321" },
      { title: "Receiver:", value: "Umbrella Inc" },
      { title: "Receiver Bank:", value: "TPF Ltd" },
      { title: "Account of the receiver:", value: "GB654378965432" },
      { title: "Amount:", value: "56,875.65 EUR" },
      { title: "Credited on:", value: "01.12.2019 / 12:34" },
      {
        title: "Payment details:",
        value:
          "Payment for contract FD6785, delivery of sea food, FOB.\nInvoive number: 643A1"
      }
    ]
  },

  paymentExpense: {
    details: {
      type: "expense",
      title: "Outgoing Payment",
      icon: "mastercard",
      name: "Mc Donald’s",
      account: "LT3256 2345 2345 2321",
      bank: "Online purchase",
      amount: -10.99,
      currency: "USD"
    },

    rows: [
      { title: "Sender:", value: "ABC Company LTD" },
      { title: "Sender Bank:", value: "Handelsbank" },
      { title: "SWIFT:", value: "DEKTDE7G" },
      { title: "Account of the sender:", value: "DE654467654321" },
      { title: "Receiver:", value: "Umbrella Inc" },
      { title: "Receiver Bank:", value: "TPF Ltd" },
      { title: "Account of the receiver:", value: "GB654378965432" },
      { title: "Amount:", value: "56,875.65 EUR" },
      { title: "Credited on:", value: "01.12.2019 / 12:34" },
      {
        title: "Payment details:",
        value:
          "Payment for contract FD6785, delivery of sea food, FOB.\nInvoive number: 643A1"
      }
    ]
  }
};

export const payout = [
  {
    currency: "USD",
    total: 2,
    amount: 107500.9,
    payments: [
      {
        conterparty: '"Alphabet Inc"',
        details: "Donation to Apple Inc",
        swift: "MLSCUS31",
        amount: 7000.45,
        info: {
          address: "5 New Street Square London, EC4A 3TW United Kingdom",
          countryCode: "GB, BLACKPOOL",
          bank: "MisterTango Lithuania",
          bankCountry: "LT",
          account: "LT12657401408033217001",
          corrAccount: "EE212657401438034217007"
        }
      },
      {
        conterparty: 'LLC "BAE Systems"',
        details: "Lease agreement with Boeing. Agreement N: 4542",
        swift: "CHASUS33",
        amount: 100500.45,
        info: {
          address: "5 New Street Square London, EC4A 3TW United Kingdom",
          countryCode: "GB, BLACKPOOL",
          bank: "MisterTango Lithuania",
          bankCountry: "LT",
          account: "LT12657401408033217001",
          corrAccount: "EE212657401438034217007"
        }
      }
    ]
  },

  {
    currency: "EUR",
    total: 43,
    amount: 1000028.0,
    payments: [
      {
        conterparty: 'UAB "Vilniaus Prekyba"',
        details: "Rental of office, Invoice N34N573",
        swift: "EVIULT21",
        amount: 18000,
        info: {
          address: "5 New Street Square London, EC4A 3TW United Kingdom",
          countryCode: "GB, BLACKPOOL",
          bank: "MisterTango Lithuania",
          bankCountry: "LT",
          account: "LT12657401408033217001",
          corrAccount: "EE212657401438034217007"
        }
      },
      {
        conterparty: 'LTD "BitStamp"',
        details: "Purchase of BTC",
        swift: "MIEGLT21",
        amount: 1000000,
        info: {
          address: "5 New Street Square London, EC4A 3TW United Kingdom",
          countryCode: "GB, BLACKPOOL",
          bank: "MisterTango Lithuania",
          bankCountry: "LT",
          account: "LT12657401408033217001",
          corrAccount: "EE212657401438034217007"
        }
      },
      {
        conterparty: 'AS "Bolt"',
        details: "Taxi ride for 10.05.2020",
        swift: "LHVBEE22",
        amount: 10,
        info: {
          address: "5 New Street Square London, EC4A 3TW United Kingdom",
          countryCode: "GB, BLACKPOOL",
          bank: "MisterTango Lithuania",
          bankCountry: "LT",
          account: "LT12657401408033217001",
          corrAccount: "EE212657401438034217007"
        }
      },
      {
        conterparty: 'UAB "Vilniaus Prekyba"',
        details: "Rental of office, Invoice N34N573",
        swift: "EVIULT21",
        amount: 18000,
        info: {
          address: "5 New Street Square London, EC4A 3TW United Kingdom",
          countryCode: "GB, BLACKPOOL",
          bank: "MisterTango Lithuania",
          bankCountry: "LT",
          account: "LT12657401408033217001",
          corrAccount: "EE212657401438034217007"
        }
      },
      {
        conterparty: 'LTD "BitStamp"',
        details: "Purchase of BTC",
        swift: "MIEGLT21",
        amount: 1000000,
        info: {
          address: "5 New Street Square London, EC4A 3TW United Kingdom",
          countryCode: "GB, BLACKPOOL",
          bank: "MisterTango Lithuania",
          bankCountry: "LT",
          account: "LT12657401408033217001",
          corrAccount: "EE212657401438034217007"
        }
      },
      {
        conterparty: 'AS "Bolt"',
        details: "Taxi ride for 10.05.2020",
        swift: "LHVBEE22",
        amount: 10,
        info: {
          address: "5 New Street Square London, EC4A 3TW United Kingdom",
          countryCode: "GB, BLACKPOOL",
          bank: "MisterTango Lithuania",
          bankCountry: "LT",
          account: "LT12657401408033217001",
          corrAccount: "EE212657401438034217007"
        }
      },
      {
        conterparty: 'UAB "Vilniaus Prekyba"',
        details: "Rental of office, Invoice N34N573",
        swift: "EVIULT21",
        amount: 18000,
        info: {
          address: "5 New Street Square London, EC4A 3TW United Kingdom",
          countryCode: "GB, BLACKPOOL",
          bank: "MisterTango Lithuania",
          bankCountry: "LT",
          account: "LT12657401408033217001",
          corrAccount: "EE212657401438034217007"
        }
      },
      {
        conterparty: 'LTD "BitStamp"',
        details: "Purchase of BTC",
        swift: "MIEGLT21",
        amount: 1000000,
        info: {
          address: "5 New Street Square London, EC4A 3TW United Kingdom",
          countryCode: "GB, BLACKPOOL",
          bank: "MisterTango Lithuania",
          bankCountry: "LT",
          account: "LT12657401408033217001",
          corrAccount: "EE212657401438034217007"
        }
      },
      {
        conterparty: 'AS "Bolt"',
        details: "Taxi ride for 10.05.2020",
        swift: "LHVBEE22",
        amount: 10,
        info: {
          address: "5 New Street Square London, EC4A 3TW United Kingdom",
          countryCode: "GB, BLACKPOOL",
          bank: "MisterTango Lithuania",
          bankCountry: "LT",
          account: "LT12657401408033217001",
          corrAccount: "EE212657401438034217007"
        }
      },
      {
        conterparty: 'UAB "Vilniaus Prekyba"',
        details: "Rental of office, Invoice N34N573",
        swift: "EVIULT21",
        amount: 18000,
        info: {
          address: "5 New Street Square London, EC4A 3TW United Kingdom",
          countryCode: "GB, BLACKPOOL",
          bank: "MisterTango Lithuania",
          bankCountry: "LT",
          account: "LT12657401408033217001",
          corrAccount: "EE212657401438034217007"
        }
      },
      {
        conterparty: 'LTD "BitStamp"',
        details: "Purchase of BTC",
        swift: "MIEGLT21",
        amount: 1000000,
        info: {
          address: "5 New Street Square London, EC4A 3TW United Kingdom",
          countryCode: "GB, BLACKPOOL",
          bank: "MisterTango Lithuania",
          bankCountry: "LT",
          account: "LT12657401408033217001",
          corrAccount: "EE212657401438034217007"
        }
      },
      {
        conterparty: 'AS "Bolt"',
        details: "Taxi ride for 10.05.2020",
        swift: "LHVBEE22",
        amount: 10,
        info: {
          address: "5 New Street Square London, EC4A 3TW United Kingdom",
          countryCode: "GB, BLACKPOOL",
          bank: "MisterTango Lithuania",
          bankCountry: "LT",
          account: "LT12657401408033217001",
          corrAccount: "EE212657401438034217007"
        }
      }
    ]
  },

  {
    currency: "GBP",
    total: 27,
    amount: 1794620.64,
    rejected: 5,
    payments: [
      {
        conterparty: "LTD Harrod's",
        details: "Purchase of a bow tie, cheque 58454543",
        swift: "CLRBGB22",
        amount: 180.64,
        info: {
          address: "5 New Street Square London, EC4A 3TW United Kingdom",
          countryCode: "GB, BLACKPOOL",
          bank: "MisterTango Lithuania",
          bankCountry: "LT",
          account: "LT12657401408033217001",
          corrAccount: "EE212657401438034217007"
        }
      },
      {
        conterparty: "LTD Rainbow and Sons",
        details: "Loan agreement N.346",
        swift: "",
        amount: 1794440,
        rejected: true,
        info: {
          address: "5 New Street Square London, EC4A 3TW United Kingdom",
          countryCode: "GB, BLACKPOOL",
          bank: "MisterTango Lithuania",
          bankCountry: "LT",
          account: "LT12657401408033217001",
          corrAccount: "EE212657401438034217007"
        }
      },
      {
        conterparty: "LTD Harrod's",
        details: "Purchase of a bow tie, cheque 58454543",
        swift: "CLRBGB22",
        amount: 180.64,
        info: {
          address: "5 New Street Square London, EC4A 3TW United Kingdom",
          countryCode: "GB, BLACKPOOL",
          bank: "MisterTango Lithuania",
          bankCountry: "LT",
          account: "LT12657401408033217001",
          corrAccount: "EE212657401438034217007"
        }
      },
      {
        conterparty: "LTD Rainbow and Sons",
        details: "Loan agreement N.346",
        swift: "",
        amount: 1794440,
        rejected: true,
        info: {
          address: "5 New Street Square London, EC4A 3TW United Kingdom",
          countryCode: "GB, BLACKPOOL",
          bank: "MisterTango Lithuania",
          bankCountry: "LT",
          account: "LT12657401408033217001",
          corrAccount: "EE212657401438034217007"
        }
      },
      {
        conterparty: "LTD Harrod's",
        details: "Purchase of a bow tie, cheque 58454543",
        swift: "CLRBGB22",
        amount: 180.64,
        info: {
          address: "5 New Street Square London, EC4A 3TW United Kingdom",
          countryCode: "GB, BLACKPOOL",
          bank: "MisterTango Lithuania",
          bankCountry: "LT",
          account: "LT12657401408033217001",
          corrAccount: "EE212657401438034217007"
        }
      },
      {
        conterparty: "LTD Rainbow and Sons",
        details: "Loan agreement N.346",
        swift: "",
        amount: 1794440,
        rejected: true,
        info: {
          address: "5 New Street Square London, EC4A 3TW United Kingdom",
          countryCode: "GB, BLACKPOOL",
          bank: "MisterTango Lithuania",
          bankCountry: "LT",
          account: "LT12657401408033217001",
          corrAccount: "EE212657401438034217007"
        }
      }
    ]
  }
];

export const savings = [
  {
    title: "New car",
    progress: 0.43,
    balance: 1054.0,
    currency: "USD",
    goal: 5000.0,
    transactions: [
      {
        icon: "mastercard",
        title: "Main card",
        fromAccount: "3256 2345 2345 2321, ALFARUMM",
        details: "Between my accounts",
        date: "10/25/2019",
        time: "13:19",
        amount: -10.0,
        balance: 1054.0,
        currency: "USD"
      },
      {
        icon: "mastercard",
        title: "Main card",
        fromAccount: "3256 2345 2345 2321, ALFARUMM",
        details: "Between my accounts",
        date: "09/21/2019",
        time: "01:27",
        amount: 100.0,
        balance: 1600.0,
        currency: "USD"
      },
      {
        icon: "mastercard",
        title: "Main card",
        fromAccount: "3256 2345 2345 2321, ALFARUMM",
        details: "Between my accounts",
        date: "08/12/2019",
        time: "07:18",
        amount: 500.0,
        balance: 1500.0,
        currency: "USD"
      },
      {
        icon: "mastercard",
        title: "Main card",
        fromAccount: "3256 2345 2345 2321, ALFARUMM",
        details: "Between my accounts",
        date: "07/23/2019",
        time: "08:20",
        amount: 1000.0,
        balance: 1000.0,
        currency: "USD"
      }
    ]
  },

  {
    title: "House",
    progress: 0.62,
    balance: 5120.0,
    currency: "USD",
    goal: 2000000.0,
    transactions: [
      {
        icon: "mastercard",
        title: "Main card",
        fromAccount: "3256 2345 2345 2321, ALFARUMM",
        details: "Between my accounts",
        date: "10/25/2019",
        time: "13:19",
        amount: -10.0,
        balance: 1054.0,
        currency: "USD"
      },
      {
        icon: "mastercard",
        title: "Main card",
        fromAccount: "3256 2345 2345 2321, ALFARUMM",
        details: "Between my accounts",
        date: "09/21/2019",
        time: "01:27",
        amount: 100.0,
        balance: 1600.0,
        currency: "USD"
      },
      {
        icon: "mastercard",
        title: "Main card",
        fromAccount: "3256 2345 2345 2321, ALFARUMM",
        details: "Between my accounts",
        date: "08/12/2019",
        time: "07:18",
        amount: 500.0,
        balance: 1500.0,
        currency: "USD"
      },
      {
        icon: "mastercard",
        title: "Main card",
        fromAccount: "3256 2345 2345 2321, ALFARUMM",
        details: "Between my accounts",
        date: "07/23/2019",
        time: "08:20",
        amount: 1000.0,
        balance: 1000.0,
        currency: "USD"
      }
    ]
  },

  {
    title: "Travel",
    progress: 0.25,
    balance: 500.0,
    currency: "USD",
    goal: 3500.0,
    transactions: [
      {
        icon: "mastercard",
        title: "Main card",
        fromAccount: "3256 2345 2345 2321, ALFARUMM",
        details: "Between my accounts",
        date: "10/25/2019",
        time: "13:19",
        amount: -10.0,
        balance: 1054.0,
        currency: "USD"
      },
      {
        icon: "mastercard",
        title: "Main card",
        fromAccount: "3256 2345 2345 2321, ALFARUMM",
        details: "Between my accounts",
        date: "09/21/2019",
        time: "01:27",
        amount: 100.0,
        balance: 1600.0,
        currency: "USD"
      },
      {
        icon: "mastercard",
        title: "Main card",
        fromAccount: "3256 2345 2345 2321, ALFARUMM",
        details: "Between my accounts",
        date: "08/12/2019",
        time: "07:18",
        amount: 500.0,
        balance: 1500.0,
        currency: "USD"
      },
      {
        icon: "mastercard",
        title: "Main card",
        fromAccount: "3256 2345 2345 2321, ALFARUMM",
        details: "Between my accounts",
        date: "07/23/2019",
        time: "08:20",
        amount: 1000.0,
        balance: 1000.0,
        currency: "USD"
      }
    ]
  }
];

export const dashboardAccounts = [
  {
    title: "Account 1",
    balance: 21130.43,
    balance_currency: "EUR",
    currencies: [
      {
        id: "1",
        name: "EUR",
        flag: "eu",
        amount: 1900.45,
        color: "#5D61D0"
      },
      {
        id: "2",
        name: "USD",
        flag: "us",
        amount: 2010.89,
        color: "#52EAC8"
      },
      {
        id: "3",
        name: "GBP",
        flag: "gb",
        amount: 492.45,
        color: "#E2900F"
      },
      {
        id: "4",
        name: "RUB",
        flag: "ru",
        amount: 2004.0,
        color: "#002060"
      },
      {
        id: "5",
        name: "CAD",
        flag: "ca",
        amount: 149.01,
        color: "#C3212C"
      }
    ],

    chart: true
  },

  {
    title: "Account 2",
    balance: 4030.43,
    balance_currency: "EUR",
    currencies: [
      {
        id: "1",
        name: "EUR",
        flag: "eu",
        amount: 19000.45,
        color: "#5D61D0"
      }
    ]
  }
];

export const transactions = [
  {
    date: "Today",
    records: [
      {
        icon: "mastercard",
        details: "Between my accounts",
        target: "Main card",
        amount: -13.47,
        currency: "USD",
        isProcessing: true,
        detailsData: paymentDetails.paymentIncome
      },
      {
        icon: "income",
        details: "Between my accounts",
        target: "ABC Company Ltd.",
        amount: 200.0,
        currency: "USD",
        detailsData: paymentDetails.paymentIncome
      }
    ]
  },
  {
    date: "Yesterday",
    records: [
      {
        icon: "mastercard",
        details: "Asos",
        target: "Main card",
        amount: -53.32,
        currency: "USD",
        isRejected: true,
        detailsData: paymentDetails.paymentExpense
      }
    ]
  },
  {
    date: "11/25/2019",
    records: [
      {
        icon: "income",
        details: "Shawn Edwards",
        target: "7393847392035567",
        amount: 130.55,
        currency: "USD",
        detailsData: paymentDetails.paymentIncome
      },
      {
        icon: "mastercard",
        details: "Mc Donald's",
        target: "Main card",
        amount: -10.99,
        currency: "USD",
        isPostponed: true,
        detailsData: paymentDetails.paymentExpense
      }
    ]
  },
  {
    date: "10/25/2019",
    records: [
      {
        icon: "income",
        details: "Shawn Edwards",
        target: "7393847392035567",
        amount: 130.55,
        currency: "USD",
        detailsData: paymentDetails.paymentIncome
      },
      {
        icon: "outcome",
        details: "Mc Donald's",
        target: "Main card",
        amount: -10.99,
        currency: "USD",
        isCanceled: true,
        detailsData: paymentDetails.paymentExpense
      },
      {
        icon: "outcome",
        details: "Between my accounts",
        target: "Main card",
        amount: -13.47,
        currency: "USD",
        detailsData: paymentDetails.paymentExpense
      },
      {
        icon: "income",
        details: "Between my accounts",
        target: "ABC Company Ltd.",
        amount: 200.0,
        currency: "USD",
        detailsData: paymentDetails.paymentIncome
      }
    ]
  }
];

export const statementTransactions = [
  {
    icon: "mastercard",
    to: "Main card",
    account: "3256 2345 2345 2321, ALFARUMM",
    details: "Between my accounts",
    date: "10/25/2019",
    time: "18:37",
    debit: -13.47,
    balance: 6120.75,
    currency: "USD",
    detailsData: paymentDetails.paymentExpense
  },
  {
    icon: "income",
    to: "Account 1",
    account: "3256 2345 2345 2321, SABRRUMM",
    details: "Between my accounts",
    date: "10/25/2019",
    time: "18:37",
    credit: 200.0,
    balance: 6320.75,
    currency: "USD",
    detailsData: paymentDetails.paymentIncome
  },
  {
    icon: "outcome",
    to: "Account 1",
    account: "3256 2345 2345 2321, SABRRUMM",
    details:
      "Payment for export. Contract number NF6542A, Invoice number: 18, FOB. Custom clearance: DF8653323S",
    date: "10/25/2019",
    time: "18:37",
    debit: -30.0,
    balance: 6320.75,
    currency: "USD",
    detailsData: paymentDetails.paymentExpense
  },
  {
    icon: "mastercard",
    to: "Main card",
    account: "3256 2345 2345 2321, ALFARUMM",
    details: "Between my accounts",
    date: "10/25/2019",
    time: "18:37",
    debit: -13.47,
    balance: 6120.75,
    currency: "USD",
    detailsData: paymentDetails.paymentExpense
  },
  {
    icon: "income",
    to: "Account 1",
    account: "3256 2345 2345 2321, SABRRUMM",
    details: "Between my accounts",
    date: "10/25/2019",
    time: "18:37",
    credit: 200.0,
    balance: 6320.75,
    currency: "USD",
    detailsData: paymentDetails.paymentIncome
  },
  {
    icon: "outcome",
    to: "Account 1",
    account: "3256 2345 2345 2321, SABRRUMM",
    details:
      "Payment for export. Contract number NF6542A, Invoice number: 18, FOB. Custom clearance: DF8653323S",
    date: "10/25/2019",
    time: "18:37",
    debit: -30.0,
    balance: 6320.75,
    currency: "USD",
    detailsData: paymentDetails.paymentExpense
  }
];

export const statementCurrencyBalance = [
  {
    currency: "EUR",
    opening: 2045.3,
    closing: 1623.9,
    credit: 421.4,
    debit: 0.0
  },
  {
    currency: "USD",
    opening: 5440.75,
    closing: 6435.65,
    credit: 325.43,
    debit: 934.15
  },
  {
    currency: "GBP",
    opening: 0.0,
    closing: 0.0,
    credit: 0.0,
    debit: 0.0
  },
  {
    currency: "RUB",
    opening: 0.0,
    closing: 0.0,
    credit: 0.0,
    debit: 0.0
  },
  {
    currency: "CAD",
    opening: 25.0,
    closing: 25.0,
    credit: 0.0,
    debit: 0.0
  }
];

export const messages = [
  {
    id: "1",
    date: "02/10/2020",
    time: "17:56",
    count: "14",
    subject: "Call For Research Studies",
    abstract:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    to: "John Doe",
    isUnread: true
  },

  {
    id: "2",
    date: "01/25/2020",
    time: "14:30",
    count: "1",
    subject: "Final reminder: Upgrade to Pro",
    abstract:
      "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    to: "John Doe",
    isUnread: true,
    isImportant: true
  },

  {
    id: "3",
    date: "01/23/2020",
    time: "07:09",
    count: "10",
    subject: "Need to chat",
    abstract:
      "They stayed on top of the project from start to finish with constant.",
    to: "Umbrella Inc",
    hasAttach: true
  },

  {
    id: "4",
    date: "01/21/2020",
    time: "22:55",
    count: "1",
    subject: "Reminder about your Appointment",
    abstract: "Be sure to ask for Jenny!",
    to: "Umbrella Inc"
  },

  {
    id: "5",
    date: "02/10/2020",
    time: "17:56",
    count: "14",
    subject: "Call For Research Studies",
    abstract:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    to: "John Doe"
  },

  {
    id: "6",
    date: "01/25/2020",
    time: "14:30",
    count: "1",
    subject: "Final reminder: Upgrade to Pro",
    abstract:
      "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    to: "John Doe",
    isImportant: true
  },

  {
    id: "7",
    date: "01/23/2020",
    time: "07:09",
    count: "10",
    subject: "Need to chat",
    abstract:
      "They stayed on top of the project from start to finish with constant.",
    to: "Umbrella Inc",
    hasAttach: true
  },

  {
    id: "8",
    date: "01/21/2020",
    time: "22:55",
    count: "1",
    subject: "Reminder about your Appointment",
    abstract: "Be sure to ask for Jenny!",
    to: "Umbrella Inc"
  }
];

export const messageBranch = {
  subject: "Reminder about your Appointment",

  items: [
    {
      from: "Wade Fisher",
      for: "John Doe",
      date: "22:53, 01/21/2020",
      body:
        "Customer Service was top notch.\n\nIt was truly like nothing I have experienced with any other company. They stayed on top of the project from start to finish with constant updates, hit the target arrival date, and were friendly.\n\nWe highly recommend. Be sure to ask for Jenny!",
      attachments: [
        {
          name: "Image001.jpg",
          size: "62.83 KB",
          link: "#"
        },

        {
          name: "Document_0123.jpdf",
          size: "1.43 MB",
          link: "#"
        }
      ]
    },

    {
      from: "Wade Fisher",
      for: "John Doe",
      date: "09:41, 01/21/2020",
      body:
        "Excellent all around!\n\nWhat stood out most was Jake's excellent service. He made sure our order was placed in time, even though we were ordering on a holiday. He followed through at every step of the process."
    },

    {
      from: "Wade Fisher",
      for: "John Doe",
      date: "09:41, 01/21/2020",
      body:
        "Excellent all around!\n\nWhat stood out most was Jake's excellent service. He made sure our order was placed in time, even though we were ordering on a holiday. He followed through at every step of the process."
    },

    {
      from: "Wade Fisher",
      for: "John Doe",
      date: "09:41, 01/21/2020",
      body:
        "Excellent all around!\n\nWhat stood out most was Jake's excellent service. He made sure our order was placed in time, even though we were ordering on a holiday. He followed through at every step of the process."
    }
  ]
};

export const cards = [
  {
    id: "1",
    style: "green",
    name: "Main card",
    number: "**** **** **** 1240",
    issuer: "mastercard",
    balance: "3,755.95 EUR",
    onHold: "12.95 EUR on hold",
    valid: "12/23",
    holder: "John Dow"
  },
  {
    id: "2",
    style: "black",
    name: "Secondary card",
    number: "**** **** **** 9999",
    issuer: "visa",
    balance: "3,755.95 EUR",
    valid: "12/22",
    holder: "John Dow"
  },
  {
    id: "3",
    style: "white",
    name: "My card",
    number: "**** **** **** 9999",
    issuer: "mastercard",
    balance: "3,755.95 EUR",
    valid: "12/22",
    holder: "John Dow"
  },
  {
    id: "4",
    style: "black",
    name: "One more card",
    number: "**** **** **** 9999",
    issuer: "visa",
    balance: "3,755.95 EUR",
    valid: "12/22",
    holder: "John Dow"
  },
  {
    id: "5",
    style: "black",
    name: "Black card",
    number: "**** **** **** 9999",
    issuer: "visa",
    balance: "3,755.95 EUR",
    valid: "12/22",
    holder: "John Dow"
  },
  {
    id: "6",
    style: "white",
    name: "My white card",
    number: "**** **** **** 9999",
    issuer: "mastercard",
    balance: "3,755.95 EUR",
    valid: "12/22",
    holder: "John Dow"
  }
];

export const accountDetails = [
  {
    id: "1",
    flag: "eu",
    title: "EUR",
    details:
      "Beneficiary: Umbrella Inc\n Account number: GB12IORY1234512345678\n Bank: TPF Ltd\n Bank country: United Kingdom\n SWIFT/BIC: IORYGB22"
  },
  {
    id: "2",
    flag: "gb",
    title: "GBP (within the UK)",
    details:
      "Beneficiary: Umbrella Inc\nAccount number: 12345678\nSort code: 12345\nBank: TPF Ltd\nBank Country: United Kingdom"
  },
  {
    id: "3",
    flag: "gb",
    title: "GBP (from outside the UK)",
    details:
      "Beneficiary: Umbrella Inc\nAccount number: GB12IORY12345987654321\nBank: TPF Ltd\nBank country: United Kingdom\nSWIFT/BIC: IORYGB22"
  },
  {
    id: "4",
    flag: "us",
    title: "USD",
    details:
      "Beneficiary: EarthPort Plc\nAccount number: FR59BNPA1234512345678\nBank: BNP-PARIBAS SA\nBank country: France\nSWIFT: BNPAFRPP",
    comment: "Obligatory payment details: in favour of TPF/12345"
  },
  {
    id: "5",
    title: "Other currencies",
    details:
      "Beneficiary: TPF Ltd\nAccount number: DE10DEKT1234512345678\nBank: Deutsche HandelsBank AG\nBank country: Germany\nSWIFT: DEKTDE7G",
    comment: "Obligatory payment details: in favour of TPF/12345"
  }
];
