<template>
  <div class="pagination">
    <div class="pagination-pages pagination-pages--desktop">
      <button
        class="pagination-pages__prev"
        :disabled="currentPage === 1"
        @click="handlePageSelect(currentPage - 1)"
      >
        Prev
      </button>

      <div class="pagination-pages__list">
        <template v-for="(page, i) in pagesArr">
          <button
            v-if="page"
            :key="i"
            class="pagination-pages__page"
            :class="{ 'is-current': currentPage === page }"
            @click="handlePageSelect(page)"
          >
            {{ page }}
          </button>

          <span
            v-if="!page"
            :key="i"
            class="pagination-pages__page"
          >...</span>
        </template>
      </div>

      <button
        class="pagination-pages__next"
        :disabled="currentPage === pages"
        @click="handlePageSelect(currentPage + 1)"
      >
        Next
      </button>
    </div>

    <div class="pagination-pages pagination-pages--mob">
      <button
        class="pagination-pages__prev"
        :disabled="currentPage === 1"
        @click="handlePageSelect(currentPage - 1)"
      >
        Prev
      </button>

      <div class="pagination-pages__list">
        <template v-for="(page, i) in pagesArrMob">
          <button
            v-if="page"
            :key="i"
            class="pagination-pages__page"
            :class="{ 'is-current': currentPage === page }"
            @click="handlePageSelect(page)"
          >
            {{ page }}
          </button>

          <span
            v-if="!page"
            :key="i"
            class="pagination-pages__page"
          >...</span>
        </template>
      </div>

      <button
        class="pagination-pages__next"
        :disabled="currentPage === pages"
        @click="handlePageSelect(currentPage + 1)"
      >
        Next
      </button>
    </div>

    <form
      class="pagination-jump"
      @submit="handleJump"
    >
      <label
        class="pagination-jump__label"
        for="pagination_jump"
      >
        Jump to
      </label>
      <input
        v-model="jumpTo"
        class="pagination-jump__input"
        placeholder="0"
        type="number"
      >
    </form>

    <div class="pagination-perpage">
      <FormSelect
        :value="perPageModel"
        :options="[
          { value: 10, title: 'Show 10' },
          { value: 25, title: 'Show 25' },
          { value: 50, title: 'Show 50' },
          { value: 100, title: 'Show 100' }
        ]"
        @change="perPageModel = $event"
      />
    </div>
  </div>
</template>

<script>
import FormSelect from "@/components/Form/FormSelect";

export default {
  name: "Pagination",

  components: {
    FormSelect
  },

  props: {
    currentPage: { type: Number, reqired: false, default: 1 },
    pages: { type: Number, reqired: false, default: 1 },
    perPage: { type: Number, reqired: false, default: 10 }
  },

  data() {
    return {
      perPageModel: this.perPage,

      jumpTo: null
    };
  },

  computed: {
    pagesArr() {
      let arr = [];

      if (this.pages > 11) {
        let first_chunk = this.currentPage < 7 ? [...this.chunk(7)] : [1];

        let middle_chunk =
          this.currentPage >= 7 && this.currentPage <= this.pages - 6
            ? [null, ...this.chunk(5, this.currentPage - 3), null]
            : [null];

        let last_chunk =
          this.currentPage > this.pages - 6
            ? [...this.chunk(7, this.pages - 7)]
            : [this.pages];

        arr = [...first_chunk, ...middle_chunk, ...last_chunk];
      } else {
        arr = [...this.chunk(this.pages)];
      }

      return arr;
    },

    pagesArrMob() {
      let arr = [];

      if (this.pages > 5) {
        let first_chunk = this.currentPage < 3 ? [...this.chunk(3)] : [1];

        let middle_chunk =
          this.currentPage >= 3 && this.currentPage <= this.pages - 2
            ? [null, this.currentPage, null]
            : [null];

        let last_chunk =
          this.currentPage > this.pages - 2
            ? [...this.chunk(3, this.pages - 3)]
            : [this.pages];

        arr = [...first_chunk, ...middle_chunk, ...last_chunk];
      } else {
        arr = [...this.chunk(this.pages)];
      }

      return arr;
    }
  },

  watch: {
    perPageModel(val) {
      this.$emit("perPageChange", val);
    }
  },

  methods: {
    chunk(length, start = 0) {
      return [...Array.from({ length: length }, (v, k) => start + k + 1)];
    },

    handlePageSelect(page) {
      this.$emit("selectPage", page);
    },

    handleJump(e) {
      e.preventDefault();

      this.$emit("selectPage", parseInt(this.jumpTo));
    }
  }
};
</script>

<style scoped lang="scss">
.pagination {
  display: flex;
  align-items: center;
  background-color: $white;
  border-radius: 8px;
  height: 72px;
}

.pagination-pages {
  display: flex;
  align-items: center;

  &--mob {
    width: 100%;
  }

  &__prev,
  &__next {
    width: 72px;
    height: 72px;
    font-size: 0;
    color: $grey-dark-02;
    transition: color 0.1s linear;
  }

  &__prev:disabled,
  &__next:disabled {
    pointer-events: none;
  }

  &__prev:before,
  &__next:before {
    @include inline-icon;
    font-size: 24px;
  }

  &__prev {
    border-right: 1px solid $grey-light-01;
    margin-right: 20px;
  }

  &__prev:before {
    content: "\e902";
  }

  &__next {
    border-left: 1px solid $grey-light-01;
    margin-left: 20px;
  }

  &--desktop &__next {
    border-right: 1px solid $grey-light-01;
  }

  &__next:before {
    content: "\e905";
  }

  &__list {
    display: flex;
    align-items: center;
  }

  &--mob &__list {
    flex: 1;
    justify-content: center;
  }

  &__page {
    display: inline-block;
    width: 32px;
    height: 32px;
    font-size: 14px;
    line-height: 32px;
    color: $grey-dark-01;
    text-align: center;
    transition: color 0.1s linear;
  }

  &__page.is-current {
    font-family: $font-semibold;
    color: $accent-normal;
  }

  @media (hover: hover) {
    &__prev:hover,
    &__next:hover,
    &__page:hover {
      color: $accent-normal;
    }
  }

  @media screen and (max-width: $screen-md-max) {
    &--desktop {
      display: none;
    }
  }

  @media screen and (min-width: $screen-lg-min) {
    &--mob {
      display: none;
    }
  }
}

.pagination-jump {
  display: flex;
  align-items: center;
  border-right: 1px solid $grey-light-01;
  padding: 0 32px;
  height: 100%;
  font-size: 14px;
  line-height: 18px;
  color: $grey-dark-01;

  &__label {
    margin-right: 4px;
  }

  &__input {
    min-width: 1%;
    width: 2ch;
    line-height: 18px;
    -moz-appearance: textfield;
    color: $grey-dark-01;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  @media screen and (max-width: $screen-md-max) {
    display: none;
  }
}

.pagination-perpage {
  margin-left: auto;
  border-left: 1px solid $grey-light-01;
  display: flex;
  align-items: center;
  padding: 0 32px;
  height: 100%;

  /deep/ .form-select.input-style .form-select__dropdown-toggle {
    font-size: 14px;
    line-height: 18px;
    color: $grey-dark-01;
    background-color: transparent;
    padding: 0;
  }

  @media screen and (max-width: $screen-md-max) {
    display: none;
  }
}
</style>
