<template>
  <div class="modal-steps">
    <div
      v-for="(step, s) in stepArray"
      :key="s"
      class="modal-steps__item"
      :class="{
        'is-current': step === currentStep,
        'is-done': step < currentStep
      }"
    >
      <div class="modal-steps__circle">
        {{ step }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StepsModal",

  props: {
    currentStep: { type: Number, required: false, default: 1 },
    steps: { type: Number, required: false, default: 1 }
  },

  computed: {
    stepArray() {
      const arr = [...Array(this.steps).keys()];

      arr.shift(1);
      arr.push(this.steps);

      return arr;
    }
  }
};
</script>

<style scoped lang="scss">
.modal-steps {
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 0;
  flex: 0 0 auto;

  &__item {
    position: relative;
    flex: 1;
  }

  &__circle {
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: rgba($additional-brown, 0.1);
    color: rgba($additional-brown, 0.3);
    font-family: $font-semibold;
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    margin: auto;
  }

  &__item + &__item:before {
    content: "";
    width: calc(100% - 46px);
    height: 4px;
    background-color: rgba($additional-brown, 0.1);
    margin: 0 24px;
    display: block;
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translateY(-50%);
    border-radius: 4px;
  }

  &__item.is-current &__circle {
    background-color: $white;
    color: $additional-brown;
  }

  &__item.is-current:before,
  &__item.is-done:before {
    background-color: $accent-normal;
  }

  &__item.is-done &__circle {
    color: transparent;
    background-color: $white;
  }

  &__item.is-done &__circle:after {
    content: "\e944";
    @include inline-icon;
    color: $accent-normal;
    font-size: 30px;
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>
