<template>
  <ModalWrapper
    size="md"
    fix-height
  >
    <template slot="title">
      Payment templates
    </template>

    <div class="payment-templates-modal-table">
      <div class="payment-templates-modal-table__head">
        <div
          class="payment-templates-modal-table__head-cell payment-templates-modal-table__head-cell--name"
        >
          Beneficiary
        </div>
        <div
          class="payment-templates-modal-table__head-cell payment-templates-modal-table__head-cell--date"
        >
          Creation Date
        </div>
      </div>

      <div class="payment-templates-modal-table__body">
        <div
          v-for="item in templates"
          :key="item.id"
          class="payment-templates-modal-table__item"
        >
          <div class="payment-templates-modal-table__item-main">
            <div class="payment-templates-modal-table__item-name">
              {{ item.name }}
            </div>
            <div
              class="payment-templates-modal-table__item-date"
              data-header="Created on "
            >
              {{ item.date }}
            </div>
          </div>

          <div class="payment-templates-modal-table__item-toolbox">
            <button
              class="payment-templates-modal-table__toolbox-btn payment-templates-modal-table__toolbox-btn--edit"
              @click="handleEdit"
            >
              Edit
            </button>

            <button
              class="payment-templates-modal-table__toolbox-btn payment-templates-modal-table__toolbox-btn--delete"
              @click="e => handleDelete(e, item.id)"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>

    <template slot="footer">
      <ModalActionWrapper>
        <Button
          label="Cancel"
          btn-style="grey-light-opacity"
          :click-handler="hideModal"
        />
      </ModalActionWrapper>
    </template>
  </ModalWrapper>
</template>

<script>
import { mapActions } from "vuex";

import ModalWrapper from "@/components/Modal/ModalWrapper";
import ModalActionWrapper from "@/components/Modal/ModalActionWrapper";
import Button from "@/components/Button";

export default {
  name: "NewCardModal",

  components: {
    ModalWrapper,
    ModalActionWrapper,
    Button
  },

  data() {
    return {
      templates: [
        { id: "1", name: "Umbrella Inc", date: "01.01.2020" },
        { id: "2", name: "Grace Hopper", date: "03.03.2019" },
        { id: "3", name: "Margaret Hamilton", date: "02.03.2019" },
        { id: "4", name: "Joan Clarke", date: "24.06.2011" }
      ]
    };
  },

  methods: {
    ...mapActions(["showModal", "hideModal"]),

    handleEdit() {
      this.showModal({
        component: "NewPaymentModal"
      });
    },

    handleDelete(e, id) {
      e.stopPropagation();

      const prompt = window.confirm(
        "Are you sure you want to delete this template?"
      );

      if (prompt) {
        this.templates = this.templates.filter(item => item.id !== id);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.payment-templates-modal-table {
  &__head {
    background-color: rgba($grey-light-02, 0.88);
    display: flex;
    margin: 0 -24px;
  }

  &__head-cell {
    padding: 16px 24px;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: rgba($grey-dark, 0.6);
  }

  &__head-cell--name {
    width: 266px;
  }

  &__head-cell--date {
    flex: 1;
  }

  &__body {
    margin: 0 -24px;
  }

  &__item {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 18px;
    transition: background-color 0.1s linear;
  }

  &__item + &__item {
    border-top: 1px solid $grey-light-01;
  }

  &__item-main {
    padding: 16px;
  }

  &__item-name {
    font-family: $font-semibold;
  }

  &__item-toolbox {
    display: flex;
    align-items: center;
    margin-left: auto;
    padding-right: 16px;
  }

  &__toolbox-btn {
    display: inline-block;
    width: 24px;
    height: 24px;
    color: $additional-brown;
    font-size: 0;
    transition: color 0.1s linear;
  }

  &__toolbox-btn:before {
    @include inline-icon;
    font-size: 16px;
  }

  &__toolbox-btn + &__toolbox-btn {
    margin-left: 12px;
  }

  &__toolbox-btn--edit:before {
    content: "\e925";
  }

  &__toolbox-btn--delete:before {
    content: "\e93b";
  }

  @media (hover: hover) {
    &__item:hover {
      background-color: rgba($grey-light-02, 0.5);
    }

    &__toolbox-btn:hover {
      color: $accent-normal;
    }
  }

  @media screen and (max-width: $screen-sm-max) {
    &__head {
      display: none;
    }

    &__item-date {
      margin-top: 2px;
    }

    &__item-date:before {
      content: attr(data-header);
    }
  }

  @media screen and (min-width: $screen-md-min) {
    &__item-main {
      display: flex;
      padding: 18px 28px;
    }

    &__item-name {
      width: 266px;
    }

    &__item-toolbox {
      padding-right: 24px;
    }

    &__toolbox-btn:before {
      font-size: 24px;
    }

    &__toolbox-btn + &__toolbox-btn {
      margin-left: 16px;
    }
  }
}
</style>
