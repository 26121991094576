<template>
  <ModalWrapper size="md">
    <template slot="title">
      Templates
    </template>

    <div class="mt-lg-40 mt-sm-32">
      <FormSelect
        label="Beneficiary"
        :value="template"
        :options="['Template 1', 'Template 2', 'Template 3']"
        placeholder="Search name"
        @change="template = $event"
      />
    </div>

    <template slot="footer">
      <ModalActionWrapper>
        <Button
          label="Use template"
          btn-style="primary"
          :click-handler="handleSubmit"
        />
      </ModalActionWrapper>

      <ModalActionWrapper is-right>
        <ButtonLink
          label="Templates"
          btn-style="underline"
          btn-size="sm"
          :click-handler="showTemplates"
        />
      </ModalActionWrapper>
    </template>
  </ModalWrapper>
</template>

<script>
import { mapActions } from "vuex";

import ModalWrapper from "@/components/Modal/ModalWrapper";
import ModalActionWrapper from "@/components/Modal/ModalActionWrapper";
import Button from "@/components/Button";
import ButtonLink from "@/components/ButtonLink";
import FormSelect from "@/components/Form/FormSelect";

export default {
  name: "NewCardModal",

  components: {
    ModalWrapper,
    ModalActionWrapper,
    Button,
    ButtonLink,
    FormSelect
  },

  data() {
    return {
      template: ""
    };
  },

  methods: {
    ...mapActions(["showModal"]),

    handleSubmit() {
      this.showModal({
        component: "NewPaymentModal"
      });
    },

    showTemplates() {
      this.showModal({
        component: "PaymentTemplateListModal"
      });
    }
  }
};
</script>
