<template>
  <DashboardLayout>
    <template slot="sidebar">
      <div class="mb-16">
        <MessagesTabs
          :current-tab="currentTab"
          @select="currentTab = $event"
        />
      </div>
    </template>

    <template>
      <div class="mb-lg-32 mb-sm-24">
        <MessagesTable />
      </div>

      <div class="mb-lg-32 mb-sm-24">
        <Pagination
          :current-page="pagination.currentPage"
          :pages="pagination.pages"
          :per-page="pagination.perPage"
          @selectPage="pagination.currentPage = $event"
          @perPageChange="pagination.perPage = $event"
        />
      </div>
    </template>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "@/components/Layout/DashboardLayout";
import MessagesTabs from "@/components/Blocks/MessagesTabs";
import MessagesTable from "@/components/Blocks/MessagesTable";
import Pagination from "@/components/Blocks/Pagination";

export default {
  components: {
    DashboardLayout,
    MessagesTabs,
    MessagesTable,
    Pagination
  },

  data() {
    return {
      currentTab: "Inbox",

      pagination: {
        currentPage: 1,
        pages: 36,
        perPage: 10
      }
    };
  }
};
</script>
