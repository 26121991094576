<template>
  <div
    ref="dropdownRef"
    class="dropdown"
    :class="{
      'is-open': isOpen,
      'to-top': top,
      'is-left': isLeft && !isRight,
      'is-right': isRight,
      'mob-overlay': mobOverlay
    }"
  >
    <div
      class="dropdown__toggle"
      @click="handleDrop"
    >
      <slot name="toggle" />
    </div>

    <div class="dropdown__menu">
      <div class="dropdown__menu-inner">
        <slot name="menu" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Dropdown",

  props: {
    isLeft: { type: Boolean, required: false, default: true },
    isRight: { type: Boolean, required: false, default: false },
    mobOverlay: { type: Boolean, required: false, default: false }
  },

  data() {
    return {
      isOpen: false,
      top: false
    };
  },

  destroyed() {
    document.removeEventListener("mousedown", this.handleCloseAll);
  },

  mounted() {
    this.top = this.toTop();
  },

  methods: {
    handleDrop() {
      this.isOpen = !this.isOpen;

      if (this.isOpen) {
        document.addEventListener("mousedown", this.handleCloseAll);

        this.top = this.toTop();
      } else {
        document.removeEventListener("mousedown", this.handleCloseAll);
      }
    },

    handleCloseAll(e) {
      const el = this.$refs.dropdownRef;
      const target = e.target;
      if (el !== target && !el.contains(target)) {
        this.isOpen = false;
        document.removeEventListener("mousedown", this.handleCloseAll);
      }
    },

    toTop() {
      if (!this.$refs.dropdownRef) return false;
      const dropdownRect = this.$refs.dropdownRef.getBoundingClientRect();
      const availableSpace =
        window.innerHeight - dropdownRect.y - dropdownRect.height;
      return availableSpace < 250;
    }
  }
};
</script>

<style scoped lang="scss">
.dropdown {
  position: relative;
  display: inline-block;

  &__toggle {
    cursor: pointer;
  }

  &__menu {
    position: absolute;
    top: 100%;
    z-index: 99;
    min-width: 100%;
    color: $grey-dark;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.1s linear, transform 0.1s linear,
      visibility 0s linear 0.2s;
  }

  &.is-right &__menu {
    right: 0;
  }

  &.is-left &__menu {
    left: 0;
  }

  &.to-top &__menu {
    top: auto;
    bottom: 100%;
  }

  &.is-open &__menu {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.1s linear, transform 0.1s linear,
      visibility 0s linear 0s;
  }

  &__menu-inner {
    background-color: $white;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08),
      0px 8px 12px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    margin: 4px 0;
    max-height: 255px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  @media screen and (max-width: $screen-sm-max) {
    &.mob-overlay &__menu {
      position: fixed;
      top: auto;
      bottom: 0;
      left: 0;
      right: 0;
      max-height: 80vh;
    }

    &.mob-overlay &__menu:before {
      content: "";
      background-color: rgba($grey-dark, 0.6);
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 98;
      pointer-events: none;
    }

    &.mob-overlay &__menu-inner {
      max-height: 80vh;
      margin: 0;
      border-radius: 4px 4px 0px 0px;
      z-index: 99;
      position: relative;
    }
  }
}
</style>
