var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-datepicker",class:{
    'has-error': _vm.hasError,
    'is-disabled': _vm.disabled,
    'input-style': _vm.elStyle === 'input',
    'filter-style': _vm.elStyle === 'filter'
  },style:({ width: _vm.elWidth })},[(_vm.label)?_c('label',{attrs:{"for":_vm.id}},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('div',{staticClass:"form-datepicker__wrapper"},[_c('v-date-picker',{attrs:{"mode":_vm.isRange ? 'range' : 'single',"input-props":{
        class: 'form-datepicker__input',
        placeholder: _vm.placeholder,
        readonly: false,
        id: _vm.id,
        name: _vm.name,
        style: null
      },"is-double-paned":_vm.isDoublePaned,"show-day-popover":false,"popover-visibility":'focus',"is-inline":_vm.isInline,"theme-styles":_vm.themeStyles,"select-attribute":_vm.selectAttribute,"drag-attribute":_vm.dragAttribute,"attributes":_vm.attrs,"title-position":"left","pane-width":280,"formats":_vm.formats},on:{"dayclick":_vm.dayclick},model:{value:(_vm.range),callback:function ($$v) {_vm.range=$$v},expression:"range"}})],1),(_vm.hasError)?_c('span',{staticClass:"form-datepicker__error"},[_vm._v(_vm._s(_vm.hasError))]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }