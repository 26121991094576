<template>
  <div class="form-select-group">
    <slot />
  </div>
</template>

<script>
export default {
  name: "FormSelectGroup"
};
</script>

<style scoped lang="scss">
.form-select-group {
  display: flex;
}
</style>
