<template>
  <div
    class="account-preview"
    :class="{ 'is-collapsed': isCollapsed }"
  >
    <div class="account-preview__header">
      <div class="account-preview__header-info">
        <div class="account-preview__header-info-main">
          <h2 class="account-preview__header-title">
            {{ account.title }}
          </h2>

          <div
            v-if="account.currencies.length > 1 && isCollapsed"
            class="account-preview__header-multicurrency">
            <span class="account-preview__header-multicurrency--mobile">
              {{ account.title.length > 7 ? 'mc' : 'multi-currency' }}
            </span>
            <span class="account-preview__header-multicurrency--desktop">
              multi-currency
            </span>
          </div>
        </div>

        <div class="account-preview__header-balance">
          {{ account.balance | moneyformat }} {{ account.balance_currency }}
        </div>
      </div>

      <div class="account-preview__header-toolbox">
        <button
          class="account-preview__header-toggle"
          @click="isCollapsed = !isCollapsed"
        >
          Toggle collapse
        </button>

        <div class="account-preview-options">
          <Dropdown is-right>
            <template slot="toggle">
              <button class="account-preview-options__toggle">
                Show options
              </button>
            </template>

            <template slot="menu">
              <ul class="account-preview-options__list">
                <li>
                  <button class="account-preview-options__item">
                    <span>Rename</span> <i class="icon icon-pencil" />
                  </button>
                </li>

                <li>
                  <button class="account-preview-options__item">
                    <span>Statement</span> <i class="icon icon-statement" />
                  </button>
                </li>
              </ul>
            </template>
          </Dropdown>
        </div>
      </div>
    </div>

    <div class="account-preview__collapsable">
      <div class="account-preview__body">
        <div class="account-preview__graph-box">
          <div
            v-if="account.chart"
            class="account-preview__graph"
          >
            <div class="account-preview__graph-inner">
              <chart
                class="account-preview__chart js-chart"
                :data="account.currencies"
              />

              <div
                v-if="!chartHover"
                class="account-preview__graph-total"
              >
                <div class="account-preview__graph-total-amount">
                  {{ account.balance }}
                </div>
                <div class="account-preview__graph-total-currency">
                  {{ account.balance_currency }}
                </div>
              </div>
            </div>
          </div>

          <div class="account-preview__actions">
            <div class="account-preview__actions-item">
              <ButtonCircle
                icon="icon-add"
                label="Add money"
                btn-style="light"
                :click-handler="handleAddMoneyModal"
              />
            </div>

            <div class="account-preview__actions-item">
              <ButtonCircle
                icon="icon-payments"
                label="Payment"
                btn-style="light"
                :click-handler="handlePaymentModal"
              />
            </div>

            <div class="account-preview__actions-item">
              <ButtonCircle
                icon="icon-exchange"
                label="Exchange"
                btn-style="light"
                :click-handler="handleExchangeModal"
              />
            </div>
          </div>
        </div>

        <ul class="account-preview__currencies">
          <li
            v-for="(item, i) in account.currencies"
            :key="i"
            class="account-preview__currencie"
          >
            <span
              class="account-preview__currencie-color"
              :style="{ backgroundColor: item.color }"
            />

            <img
              v-if="item.flag"
              :src="require(`@/assets/images/flags/${item.flag}.svg`)"
              :alt="item.name"
              class="account-preview__currencie-flag"
            >

            <div class="account-preview__currencie-amount h3">
              {{ item.amount | moneyformat }}
            </div>

            <div class="account-preview__currencie-label text-dscr">
              {{ item.name }}
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import { accountDetails } from "@/fixtures";

import Chart from "@/components/Chart";
import Dropdown from "@/components/Dropdown";
import ButtonCircle from "@/components/ButtonCircle";

export default {
  name: "AccountPreview",

  components: {
    Chart,
    Dropdown,
    ButtonCircle
  },

  props: {
    account: { type: Object, required: false, default: null }
  },

  data() {
    return {
      isCollapsed: false,
      chartHover: false
    };
  },

  methods: {
    ...mapActions(["showModal"]),

    handlePaymentModal() {
      this.showModal({
        component: "NewPaymentModal"
      });
    },

    handleExchangeModal() {
      this.showModal({
        component: "CurrencyExchangeModal"
      });
    },

    handleAddMoneyModal() {
      this.showModal({
        component: "AddMoneyModal",
        data: {
          details: accountDetails
        }
      });
    },
  }
};
</script>

<style scoped lang="scss">
.account-preview {
  background-color: $white;
  border-radius: 8px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0 16px 16px;
  }

  &__header-title {
    font-family: $font-bold;
    font-size: 18px;
    line-height: 22px;
  }

  &__header-multicurrency {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 20px;
    border-radius: 2px;
    color: $grey-dark-01;
    background-color: $grey-light-01;
    padding: 0 4px;
    margin-left: 10px;
  }

  &__header-toolbox {
    display: flex;
    align-items: center;
  }

  &__header-info {
    flex: 1;
  }

  &__header-info-main {
    display: flex;
  }

  &__header-balance {
    font-size: 14px;
    line-height: 18px;
    color: $grey-dark-01;
    margin-top: 2px;
    margin-left: auto;
  }

  &__header-toggle {
    margin-left: 16px;
    width: 24px;
    height: 24px;
    font-size: 0;
    color: $grey-dark-02;
  }

  &__header-toggle:before {
    content: "\e912";
    @include inline-icon;
    font-size: 24px;
  }

  &.is-collapsed &__header-toggle:before {
    content: "\e911";
  }

  &__collapsable {
    overflow: hidden;
    height: 100%;
    transition: opacity 0.1s linear, height 0.1s linear;
  }

  &.is-collapsed &__collapsable {
    height: 0;
    opacity: 0;
    transition: opacity 0.1s linear, height 0.1s linear;
  }

  &__body {
    overflow: hidden;
    border-radius: 0 0 8px 8px;
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 32px 16px;
  }

  &__actions-item {
    padding: 0 8px;
  }

  &__graph-box {
    display: flex;
    flex-direction: column;
  }

  &__graph {
    padding: 8px 16px 0;
    margin: 0 auto;
  }

  &__graph-inner {
    position: relative;
  }

  &__chart {
    position: relative;
    border-radius: 50%;
  }

  &__chart:hover ~ &__graph-total {
    opacity: 0;
  }

  &__graph-total {
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
    height: auto;
    width: auto;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: opacity 0.1s linear;
  }

  &__graph-total-amount {
    font-size: 28px;
  }

  &__graph-total-currency {
    color: #A3A4A5;
  }

  &__currencies {
    border-top: 1px solid $grey-light-01;
  }

  &__currencie {
    position: relative;
    display: flex;
    align-items: center;
    padding: 16px;
    height: 62px;
  }

  &__currencie + &__currencie {
    border-top: 1px solid $grey-light-01;
  }

  &__currencie-color {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 3px;
  }

  &__currencie-flag {
    display: block;
    width: 100%;
    max-width: 40px;
    max-height: 28px;
  }

  &__currencie-flag + &__currencie-amount {
    margin-left: 12px;
  }

  &__currencie-label {
    font-size: 12px;
    line-height: 16px;
    margin: 0 0 4px 4px;
  }

  @media screen and (max-width: $screen-sm-max) {
    &__header-multicurrency--desktop {
      display: none;
    }

    /deep/ &__actions-item .btn-circle__label {
      font-size: 14px;
    }
  }

  @media screen and (min-width: $screen-md-min) {
    &__header {
      padding: 32px 0 32px 24px;
    }

    &__header-info {
      display: flex;
      align-items: center;
    }

    &__header-title {
      font-size: 20px;
      line-height: 1.2;
    }

    &__header-multicurrency--mobile {
      display: none;
    }

    &__header-balance {
      font-size: 20px;
      line-height: 1.2;
      color: $black;
      margin-top: 0;
    }

    &__body {
      display: flex;
      border-top: 1px solid $grey-light-01;
    }

    &__currencies {
      order: 1;
      width: 310px;
      border: none;
    }

    &__currencie {
      padding: 24px;
      height: 80px;
    }

    &__graph-box {
      order: 2;
      flex: 1;
      border-left: 1px solid $grey-light-01;
    }

    &__graph {
      order: 2;
      padding: 24px 0 56px 0;
    }

    &__actions {
      order: 1;
      padding: 32px 24px;
    }
  }

  @media screen and (min-width: $screen-lg-min) {
    &__currencies {
      width: 420px;
    }
  }

  @media screen and (min-width: $screen-xlg-min) {
    &__currencies {
      width: 360px;
    }
  }
}

.account-preview-options {
  &__toggle {
    position: relative;
    width: 40px;
    height: 36px;
    border-radius: 4px 4px 0px 0px;
    font-size: 0;
    color: $grey-dark-02;
    background-color: $white;
  }

  .is-open &__toggle {
    z-index: 101;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08),
      0px -4px 12px rgba(0, 0, 0, 0.08);
  }

  .is-open.to-top &__toggle {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08),
      0px 8px 12px rgba(0, 0, 0, 0.08);
  }

  .to-top &__toggle {
    border-radius: 0px 0px 4px 4px;
  }

  &__toggle:before {
    content: "\e922";
    @include inline-icon;
    font-size: 24px;
  }

  &__toggle:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -5px;
    height: 6px;
    background-color: $white;
  }

  .to-top &__toggle:after {
    top: -5px;
    bottom: unset;
  }

  &__list {
    min-width: 180px;
  }

  &__list li + li {
    border-top: 1px solid $grey-light-01;
  }

  &__item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 12px;
    font-size: 14px;
    line-height: 18px;
    color: $additional-brown;
    transition: background-color 0.1s linear;
  }

  &__item:hover {
    background-color: $grey-light-02;
  }

  &__item .icon {
    font-size: 22px;
    color: $grey-dark-02;
  }
}
</style>
