<template>
  <AuthLayout
    :show-side-bg="false"
    :increased-width="true"
  >
    <h1 class="h1-bold auth-title">
      Signing  the Access Rights
    </h1>

    <div class="mt-40">
      <AccessRightsTable :fields="fields" />
    </div>

    <div class="mt-32 text-grey">
      <div>
        At any time you may view and cancel previously given consents for access to your accounts. Go to Settings → External Access to Accounts.
      </div>
      <div class="mt-24">
        I authorise TPF Ltd. to provide above mentioned access rights to AS SEB Bank.
      </div>
    </div>

    <div class="arrange mt-24">
      <Button
        label="Confirm to proceed"
        btn-style="primary"
        btn-size="sm"
      />

      <Button
        class="ml-sm-16 ml-lg-20"
        label="Back"
        btn-style="dark-opacity"
      />
    </div>
  </AuthLayout>
</template>

<script>
import AuthLayout from "@/components/Layout/AuthLayout";
import AccessRightsTable from "@/components/Blocks/AccessRightsTable";
import Button from "@/components/Button";

export default {
  name: "Login",

  components: {
    AuthLayout,
    AccessRightsTable,
    Button
  },

  data() {
    return {
      fields: [
        {
          title: 'Requesting bank / payment provider',
          values: [
            {
              main: 'Swedbank Lithuania'
            }
          ]
        },
        {
          title: 'Registration number',
          values: [
            {
              main: 'FI1234567890'
            }
          ]
        },
        {
          title: 'Access(es) valid until',
          values: [
            {
              main: '10/12/2020'
            }
          ]
        },
        {
          title: 'Permitted Activities',
          values: [
            {
              main: 'GB123456789012345',
              additional: [
                'Account Information',
                'Account Balance'
              ]
            },
            {
              main: 'GB098765432109876',
              additional: [
                'Account Information',
                'Account Balance'
              ]
            }
          ]
        },
      ],
    };
  }
};
</script>

<style scoped lang="scss">

</style>
