<template>
  <OnboardingBusinessLayout
    :current-step="currentStep"
    :side-msg="sideMsg"
  >
    <Header>
      <template slot="title">
        <div class="text-center">
          Verification successful
        </div>
      </template>
    </Header>

    <div class="mt-lg-32 mt-sm-16 arrange arrange--center">
      <img
        :src="require('@/assets/images/signup-success.svg')"
        alt="illustration"
        class=""
      >
    </div>

    <div class="mt-lg-32 mt-sm-24">
      <h5 class="text-center text-sb">
        Thank you!
      </h5>

      <p class="text-center text-grey mt-4">
        We will revert to you within 3 business days with the status of your
        account.
      </p>
    </div>
  </OnboardingBusinessLayout>
</template>

<script>
import OnboardingBusinessLayout from "@/components/Layout/OnboardingBusinessLayout";
import Header from "@/components/Onboarding/Header";

export default {
  components: {
    OnboardingBusinessLayout,
    Header
  },

  data() {
    return {
      currentStep: 11,

      sideMsg:
        "Thank you! We will revert to you within 3 business days with the status of your account."
    };
  }
};
</script>
