<template>
  <ModalWrapper size="lg">
    <template slot="title">
      Mass payout
    </template>

    <FormSelect
      class="mt-lg-32 mt-sm-16"
      :value="account"
      :options="['Account 1', 'Account 2', 'Account 3']"
      @change="account = $event"
    />

    <div class="mt-lg-32 mt-sm-16 mb-24">
      <MassPayoutDropzone
        :has-error="hasFileError"
        @change="files = $event"
      />
    </div>
  </ModalWrapper>
</template>

<script>
import { mapActions, mapState } from "vuex";

import ModalWrapper from "@/components/Modal/ModalWrapper";
import FormSelect from "@/components/Form/FormSelect";
import MassPayoutDropzone from "@/components/Blocks/MassPayoutDropzone";

export default {
  name: "MassPayoutUploadModal",

  components: {
    ModalWrapper,
    FormSelect,
    MassPayoutDropzone
  },

  data() {
    return {
      account: "Account 1",
      files: []
    };
  },

  computed: {
    ...mapState(["modal"]),

    hasFileError() {
      return this.modal.data.error || "";
    }
  },

  watch: {
    files(val) {
      if (val && !this.hasFileError) {
        this.handleUpload();
      }
    }
  },

  methods: {
    ...mapActions(["showModal"]),

    handleUpload() {
      this.showModal({
        component: "MassPayoutModal"
      });
    }
  }
};
</script>
