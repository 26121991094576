<template>
  <DashboardLayout>
    <template slot="sidebar">
      <SettingsNav
        :current="currentSection"
        class="mb-16"
        @select="currentSection = $event"
      />
    </template>

    <template>
      <SettingsExternalAccessTable
        class="mb-lg-32 mb-sm-24"
      />
    </template>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "@/components/Layout/DashboardLayout";
import SettingsNav from "@/components/Blocks/SettingsNav";
import SettingsExternalAccessTable from "@/components/Blocks/SettingsExternalAccessTable";

export default {
  name: "Settings",

  components: {
    DashboardLayout,
    SettingsNav,
    SettingsExternalAccessTable
  },

  data() {
    return {
      currentSection: "External Access",
    };
  }
};
</script>

<style scoped lang="scss">

</style>
