<template>
  <transition name="fade">
    <component
      :is="modal.component"
      v-if="isModalVisible"
    />
  </transition>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { freezeBody } from "@/utilities/FreezeBody.js";

import NewMessageModal from "@/components/Modal/NewMessageModal";
import MessageModal from "@/components/Modal/MessageModal";
import AddAccountModal from "@/components/Modal/AddAccountModal";
import SuccessAccountModal from "@/components/Modal/SuccessAccountModal";
import NewCardModal from "@/components/Modal/NewCardModal";
import NewPaymentModal from "@/components/Modal/NewPaymentModal";
import NewPaymentSuccessModal from "@/components/Modal/NewPaymentSuccessModal";
import CurrencyExchangeModal from "@/components/Modal/CurrencyExchangeModal";
import PaymentDetailsModal from "@/components/Modal/PaymentDetailsModal";
import NewSavingsAccountModal from "@/components/Modal/NewSavingsAccountModal";
import NewSavingsAccountSuccessModal from "@/components/Modal/NewSavingsAccountSuccessModal";
import DistributeFundsModal from "@/components/Modal/DistributeFundsModal";
import DistributeFundsSuccessModal from "@/components/Modal/DistributeFundsSuccessModal";
import MassPayoutUploadModal from "@/components/Modal/MassPayoutUploadModal";
import MassPayoutModal from "@/components/Modal/MassPayoutModal";
import PaymentTemplatesModal from "@/components/Modal/PaymentTemplatesModal";
import PaymentTemplateListModal from "@/components/Modal/PaymentTemplateListModal";
import AddMoneyModal from "@/components/Modal/AddMoneyModal";
import NewRequestModal from "@/components/Modal/NewRequestModal";
import ExternalAccessModal from "@/components/Modal/ExternalAccessModal";
import RevokeRightsModal from "@/components/Modal/RevokeRightsModal";

export default {
  name: "ModalContainer",

  components: {
    NewMessageModal,
    MessageModal,
    AddAccountModal,
    SuccessAccountModal,
    NewCardModal,
    NewPaymentModal,
    NewPaymentSuccessModal,
    CurrencyExchangeModal,
    PaymentDetailsModal,
    NewSavingsAccountModal,
    NewSavingsAccountSuccessModal,
    DistributeFundsModal,
    DistributeFundsSuccessModal,
    MassPayoutUploadModal,
    MassPayoutModal,
    PaymentTemplatesModal,
    PaymentTemplateListModal,
    AddMoneyModal,
    NewRequestModal,
    ExternalAccessModal,
    RevokeRightsModal,
  },

  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters(["isModalVisible"]),
    ...mapState(["modal"])
  },

  watch: {
    isModalVisible(val) {
      freezeBody(val);
    }
  }
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active,
.fade-enter,
.fade-leave-to {
  position: relative;
  z-index: 999;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
