<template>
  <div class="message-table">
    <div class="message-table-header">
      <div class="message-table-header__title">
        Inbox Messages
      </div>

      <div class="message-table-header__toolbox">
        <div class="message-table-header__toolbox-item">
          <ButtonCircle
            icon="icon-pencil"
            btn-style="light"
            :click-handler="handleNewMessage"
          />
        </div>
      </div>
    </div>

    <div class="message-table-head">
      <div
        class="message-table-head__cell message-table-head__cell--date is-sortable"
        :class="{ 'is-sorted': sortBy === 'date' }"
        @click="handleSort('date')"
      >
        Date
      </div>

      <div class="message-table-head__cell message-table-head__cell--subject">
        Subject
      </div>

      <div class="message-table-head__cell message-table-head__cell--to">
        For
      </div>
    </div>

    <div class="message-table-body">
      <div
        v-for="(message, i) in messages"
        :key="i"
        class="message-table-item"
        :class="{ 'is-unread': message.isUnread }"
        @click="handleMessageModal"
      >
        <div class="message-table-item__date">
          <div class="message-table-item__status" />
          <span>{{ message.date }}</span>
          <span class="message-table-item__time">{{ message.time }}</span>
        </div>

        <div class="message-table-item__count">
          <div
            v-if="message.count && message.count > 1"
            class="message-table-item__count-val"
          >
            {{ message.count }}
          </div>
        </div>

        <div class="message-table-item__info">
          <div
            class="message-table-item__subject"
            :class="{ 'has-attach': message.hasAttach }"
          >
            {{ message.subject }}
          </div>
          <div class="message-table-item__abstract">
            {{ message.abstract }}
          </div>
        </div>

        <div class="message-table-item__to">
          {{ message.to }}
        </div>

        <div class="message-table-item__accessory">
          <i
            v-if="message.isImportant"
            class="icon icon-attention text-red"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import { messages } from "@/fixtures";

import ButtonCircle from "@/components/ButtonCircle";

export default {
  name: "MessagingTable",

  components: {
    ButtonCircle
  },

  data() {
    return {
      sortBy: null
    };
  },

  created() {
    this.messages = messages;
  },

  methods: {
    ...mapActions(["showModal"]),

    handleSort(target) {
      this.sortBy = this.sortBy !== target ? target : null;
    },

    handleNewMessage() {
      this.showModal({
        component: "NewMessageModal"
      });
    },

    handleMessageModal() {
      this.showModal({
        component: "MessageModal"
      });
    }
  }
};
</script>

<style scoped lang="scss">
.message-table {
  @media screen and (min-width: $screen-md-min) {
    background-color: $white;
    border-radius: 8px;
  }
}

.message-table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__title {
    font-family: $font-bold;
    font-size: 20px;
    line-height: 24px;
  }

  &__toolbox {
    display: flex;
  }

  &__toolbox-item + &__toolbox-item {
    margin-left: 24px;
  }

  @media screen and (max-width: $screen-sm-max) {
    margin-bottom: 16px;

    /deep/ &__toolbox .btn-circle__icon {
      width: 36px;
      height: 36px;
      background-color: $white;
      font-size: 16px;
    }
  }

  @media screen and (min-width: $screen-md-min) {
    padding: 20px 24px;
  }
}

.message-table-head {
  display: flex;
  background-color: $grey-light-01;
  padding: 16px 20px;

  &__cell {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: rgba($grey-dark, 0.6);
  }

  &__cell.is-sortable {
    cursor: pointer;
  }

  &__cell.is-sortable:after {
    content: "\e92f";
    @include inline-icon;
    color: $grey-dark-02;
    font-size: 16px;
    vertical-align: text-bottom;
  }

  &__cell.is-sorted:after {
    content: "\e930";
  }

  &__cell--date {
    padding-left: 16px;
    width: 162px;
  }

  &__cell--subject {
    flex: 1;
  }

  &__cell--to {
    width: 192px;
  }

  @media screen and (max-width: $screen-sm-max) {
    display: none;
  }
}

.message-table-body {
  @media screen and (max-width: $screen-sm-max) {
    background-color: $white;
    border-radius: 8px;
  }
}

.message-table-item {
  position: relative;
  padding: 16px 52px 16px 16px;
  cursor: pointer;
  transition: background-color 0.1s linear;

  & + & {
    border-top: 1px solid $grey-light-01;
  }

  &__count-val {
    line-height: 22px;
    border-radius: 4px;
    background-color: $grey-light-02;
    color: $grey-dark-01;
    font-family: $font-semibold;
    text-align: center;
    font-size: 14px;
    padding: 0 4px;
  }

  &.is-unread &__status:before {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: $accent-normal;
  }

  &__subject {
    line-height: 20px;
    padding-right: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__subject.has-attach:after {
    content: "\e93e";
    @include inline-icon;
    color: $grey-dark-01;
    font-size: 16px;
  }

  &__abstract {
    font-size: 14px;
    color: $grey-dark-01;
    margin-top: 2px;
  }

  &.is-unread &__subject {
    font-family: $font-semibold;
  }

  &__accessory .icon {
    font-size: 24px;
  }

  @media (hover: hover) {
    &:hover {
      background-color: rgba($grey-light-02, 0.5);
    }
  }

  @media screen and (max-width: $screen-sm-max) {
    &.is-unread &__status {
      width: 16px;
    }

    &.is-unread &__status:before {
      margin-top: 5px;
    }

    &__date {
      display: flex;
      max-width: 50%;
      font-size: 14px;
      line-height: 18px;
      color: $grey-dark-01;
    }

    &__to {
      position: absolute;
      top: 16px;
      right: 16px;
      text-align: right;
      width: 40%;
      font-size: 14px;
      line-height: 18px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__count {
      position: absolute;
      top: 36px;
      right: 16px;
    }

    &__subject {
      margin-top: 2px;
    }

    &__abstract {
      display: block;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__accessory {
      position: absolute;
      bottom: 16px;
      right: 16px;
    }
  }

  @media screen and (min-width: $screen-md-min) {
    display: flex;
    padding: 16px;

    &__date {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 128px;
      padding-left: 16px;
      font-size: 16px;
      line-height: 20px;
    }

    &__time {
      margin-top: 2px;
      font-size: 14px;
      line-height: 18px;
      color: $grey-dark-01;
    }

    &__status {
      position: absolute;
      top: 6px;
      left: 0;
      width: 16px;
      height: 16px;
    }

    &__count {
      width: 36px;
      padding-right: 8px;
    }

    &__count-val {
      line-height: 22px;
      border-radius: 4px;
      background-color: $grey-light-02;
      color: $grey-dark-01;
      font-family: $font-semibold;
      text-align: center;
      font-size: 14px;
      padding: 0 4px;
    }

    &__info {
      flex: 1;
      margin-right: 16px;
    }

    &.is-unread &__to {
      font-family: $font-semibold;
    }

    &__abstract {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__subject,
    &__abstract {
      max-width: 220px;
    }

    &__to {
      width: 154px;
      font-size: 16px;
      line-height: 20px;
    }

    &__accessory {
      width: 42px;
      align-self: center;
    }
  }

  @media screen and (min-width: $screen-lg-min) {
    &__subject,
    &__abstract {
      max-width: 454px;
    }
  }
}
</style>
