<template>
  <OnboardingLayout
    :current-step="currentStep"
    :side-msg="sideMsg"
  >
    <Header>
      <template slot="title">
        Hello, please sign up
      </template>
    </Header>

    <div class="mt-40">
      <FormInput
        id="name"
        name="name"
        type="text"
        :value="form.name"
        label="Name"
        :has-error="form.nameError"
        @input="form.name = $event"
      />
    </div>

    <div class="mt-lg-40 mt-sm-32">
      <FormInput
        id="surname"
        name="surname"
        type="text"
        :value="form.surname"
        label="Surname"
        :has-error="form.surnameError"
        @input="form.surname = $event"
      />
    </div>

    <div class="arrange mt-lg-40 mt-sm-32">
      <div>
        <FormSelect
          id="country"
          name="country"
          label="Country"
          el-width="130px"
          el-style="input"
          :value="form.country"
          :options="[
            { value: '+370', title: '+370', country: 'Lithuania', flag: 'lt' },
            { value: '+371', title: '+371', country: 'Latvia', flag: 'lv' },
            { value: '+372', title: '+372', country: 'Estonia', flag: 'ee' }
          ]"
          phone-code
          @change="form.country = $event"
        />
      </div>

      <FormInput
        id="phone"
        name="phone"
        type="tel"
        :value="form.phone"
        label="Phone number"
        :has-error="form.phoneError"
        placeholder="000 000 00 00"
        mask="### ### ## ##"
        class="ml-16"
        @input="form.phone = $event"
      />
    </div>

    <div class="mt-lg-40 mt-sm-32">
      <FormInput
        id="email"
        name="email"
        type="email"
        :value="form.email"
        label="Email"
        :has-error="form.emailError"
        @input="form.email = $event"
      />
    </div>

    <div class="mt-lg-32 mt-sm-24">
      <Button
        label="Submit"
        btn-style="primary"
        btn-width="100%"
      />
    </div>

    <div class="mt-16">
      <FormCheckbox
        id="terms"
        name="terms"
        :value="form.terms"
        :has-error="form.termsError"
        @change="form.terms = $event"
      >
        <span class="text-grey text-sm">
          I confirm <a
            href="#"
            class="text-link"
          >Terms & Conditions</a>
        </span>
      </FormCheckbox>
    </div>
  </OnboardingLayout>
</template>

<script>
import OnboardingLayout from "@/components/Layout/OnboardingLayout";
import Header from "@/components/Onboarding/Header";
import FormInput from "@/components/Form/FormInput";
import FormSelect from "@/components/Form/FormSelect";
import Button from "@/components/Button";
import FormCheckbox from "@/components/Form/FormCheckbox";

export default {
  components: {
    OnboardingLayout,
    Header,
    FormInput,
    FormSelect,
    Button,
    FormCheckbox
  },

  data() {
    return {
      currentStep: 1,

      sideMsg: "You get a confirmation email",

      form: {
        name: "",
        nameError: "",
        surname: "",
        surnameError: "",
        country: "+371",
        phone: "",
        phoneError: "",
        email: "",
        emailError: "",
        terms: true,
        termsError: ""
      }
    };
  }
};
</script>
