<template>
  <OnboardingLayout
    :current-step="currentStep"
    :side-msg="sideMsg"
  >
    <Header>
      <template slot="title">
        Professional profile
      </template>
    </Header>

    <div class="mt-40">
      <FormInput
        id="worplace"
        name="worplace"
        type="text"
        :value="form.workplace"
        label="Place of work"
        :has-error="form.workplaceError"
        @input="form.workplace = $event"
      />
    </div>

    <div class="mt-lg-40 mt-sm-32">
      <FormInput
        id="income"
        name="income"
        type="number"
        :value="form.income"
        label="Planned monthly income (EUR)"
        :has-error="form.incomeError"
        @input="form.income = $event"
      />
    </div>

    <div class="mt-lg-40 mt-sm-32">
      <FormInput
        id="linkedin"
        name="linkedin"
        type="text"
        :value="form.linkedin"
        label="LinkedIn profile"
        :has-error="form.linkedinError"
        placeholder="@user"
        @input="form.income = $event"
      />
    </div>

    <div class="mt-lg-32 mt-sm-24">
      <Button
        label="Continue"
        btn-style="primary"
        btn-width="100%"
      />
    </div>
  </OnboardingLayout>
</template>

<script>
import OnboardingLayout from "@/components/Layout/OnboardingLayout";
import Header from "@/components/Onboarding/Header";
import FormInput from "@/components/Form/FormInput";
import Button from "@/components/Button";

export default {
  components: {
    OnboardingLayout,
    Header,
    FormInput,
    Button
  },

  data() {
    return {
      currentStep: 5,

      sideMsg:
        "Some short tooltip. Why do we need to know about this info about user",

      form: {
        workplace: "",
        workplaceError: "",
        income: "",
        incomeError: "",
        linkedin: "",
        linkedinError: ""
      }
    };
  }
};
</script>
