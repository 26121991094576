<template>
  <transition name="slide-fade">
    <div
      v-if="notification"
      class="notification-bar"
      :class="[`notification-bar--${notification.type}`]"
    >
      <div
        class="notification-bar__msg"
        v-html="notification.message"
      />

      <button
        class="notification-bar__close"
        @click="hideNotification"
      >
        Close
      </button>
    </div>
  </transition>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "NotificationBar",

  computed: {
    ...mapState(["notification"])
  },

  methods: {
    ...mapActions(["hideNotification"])
  }
};
</script>

<style scoped lang="scss">
.notification-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 101;
  min-height: 32px;

  &--success {
    background-color: $green;
    color: $white;
  }

  &--info {
    background-color: $additional-brown;
    color: $white;
  }

  &--warning {
    background-color: $accent-light;
    color: $white;
  }

  &--danger {
    background-color: $red;
    color: $white;
  }

  &__msg {
    width: 100%;
    text-align: center;
    padding: 6px 32px;
    font-size: 16px;
    line-height: 20px;
  }

  /deep/ &__msg a,
  /deep/ &__msg button {
    font-family: $font-semibold;
    text-decoration: underline;
  }

  &__close {
    position: absolute;
    right: 4px;
    top: 4px;
    width: 24px;
    height: 24px;
    font-size: 0;
    transition: opacity 0.1s linear;
  }

  &__close:before {
    content: "\e934";
    @include inline-icon;
    font-size: 22px;
  }

  @media (hover: hover) {
    &__close:hover {
      opacity: 0.6;
    }
  }

  @media screen and (min-width: $screen-md-min) {
    left: 72px;
  }

  @media screen and (min-width: $screen-lg-min) {
    left: 88px;
  }

  @media screen and (min-width: $screen-xlg-min) {
    left: 100px;
  }
}
</style>

<style lang="scss">
.slide-fade-enter-active {
  transition: all 0.15s ease-in-out;
}
.slide-fade-leave-active {
  transition: all 0.15s ease-in-out;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-100%);
  opacity: 0;
}
</style>
