<template>
  <ModalWrapper size="lg">
    <template slot="title">
      {{ modalData.details.title }}
    </template>

    <template slot="toolbox">
      <ButtonCircle
        icon="icon-print"
        btn-style="light"
        title="Print"
      />
      <ButtonCircle
        icon="icon-save"
        btn-style="light"
        title="Save"
      />
    </template>

    <ModalCallout>
      <div class="payment-details-modal-header">
        <div class="payment-details-modal-header__icon">
          <svg>
            <use
              :xlink:href="
                require('@/assets/images/svg-sprites.svg') +
                  '#' +
                  modalData.details.icon
              "
            />
          </svg>
        </div>

        <div class="payment-details-modal-header__info">
          <div class="payment-details-modal-header__info-primary">
            {{ modalData.details.name }}
          </div>
          <div class="payment-details-modal-header__info-secondary">
            {{ modalData.details.account }}
            <br>
            {{ modalData.details.bank }}
          </div>
        </div>

        <div class="payment-details-modal-header__amount">
          <div
            class="mb-4"
            :class="{
              'text-green': modalData.details.amount > 0,
              'text-red': modalData.details.amount < 0
            }"
          >
            {{ modalData.details.amount | moneyformat }}
            {{ modalData.details.currency }}
          </div>
          <Badge
            v-if="modalData.details.isProcessing"
            label="Processing"
            el-style="neutral"
          />
        </div>
      </div>
    </ModalCallout>

    <div
      v-for="(row, i) in modalData.rows"
      :key="i"
      class="payment-details-modal-table__row"
    >
      <div class="payment-details-modal-table__row-title">
        {{ row.title }}
      </div>

      <div class="payment-details-modal-table__row-value">
        {{ row.value }}
      </div>
    </div>

    <template slot="footer">
      <ModalActionWrapper v-if="modalData.details.type === 'expense'">
        <Button
          label="Pay again"
          btn-style="primary"
          :click-handler="handleNewPayment"
        />
      </ModalActionWrapper>

      <ModalActionWrapper>
        <Button
          label="Save as a template"
          btn-style="grey-light-opacity"
          :click-handler="handleSaveTemplate"
        />
      </ModalActionWrapper>
    </template>
  </ModalWrapper>
</template>

<script>
import { mapState, mapActions } from "vuex";

import ModalWrapper from "@/components/Modal/ModalWrapper";
import ModalCallout from "@/components/Modal/ModalCallout";
import ModalActionWrapper from "@/components/Modal/ModalActionWrapper";
import ButtonCircle from "@/components/ButtonCircle";
import Button from "@/components/Button";
import Badge from "@/components/Badge";

export default {
  name: "PaymentDetailsModal",

  components: {
    ModalWrapper,
    ModalCallout,
    ModalActionWrapper,
    ButtonCircle,
    Button,
    Badge
  },

  computed: {
    ...mapState(["modal"]),

    modalData() {
      return this.modal.data || {};
    }
  },

  methods: {
    ...mapActions(["hideModal", "showModal", "pushNotification"]),

    handleNewPayment() {
      this.showModal({
        component: "NewPaymentModal"
      });
    },

    handleSaveTemplate() {
      this.hideModal();

      const notification = {
        type: "success",
        title: "New payment template has been successfully created."
      };

      this.pushNotification(notification);
    }
  }
};
</script>

<style scoped lang="scss">
.payment-details-modal-header {
  display: flex;
  padding: 24px 0 16px 0;

  &__icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    margin: 0 auto 8px auto;
    background-color: $white;
  }

  &__icon svg {
    max-width: 38px;
    max-height: 38px;
  }

  &__info-primary {
    font-family: $font-semibold;
    font-size: 16px;
    line-height: 20px;
  }

  &__info-secondary {
    font-size: 14px;
    line-height: 18px;
    margin-top: 4px;
    color: $grey-dark-01;
  }

  &__amount {
    text-align: center;
  }

  &__info {
    margin-top: 16px;
    text-align: center;
  }

  @media screen and (max-width: $screen-sm-max) {
    flex-direction: column;
    align-items: center;

    &__icon {
      order: 1;
    }

    &__amount {
      order: 2;
    }

    &__info {
      order: 3;
    }
  }

  @media screen and (min-width: $screen-md-min) {
    padding: 21px 0;

    &__icon {
      width: 40px;
      height: 40px;
      margin: auto 16px auto 0;
    }

    &__icon svg {
      max-width: 24px;
      max-height: 24px;
    }

    &__amount {
      text-align: right;
      margin-left: auto;
    }

    &__info {
      text-align: left;
      margin-top: 0;
    }

    &__info-secondary {
      font-size: 16px;
      line-height: 20px;
      margin-top: 2px;
    }
  }
}

.payment-details-modal-table {
  &__row {
    border-bottom: 1px solid $grey-light-01;
    padding: 16px 0;
  }

  &__row-title,
  &__row-value {
    font-size: 14px;
    line-height: 18px;
    white-space: pre-line;
  }

  &__row-title {
    color: $grey-dark-01;
  }

  &__row-value {
    margin-top: 2px;
  }

  @media screen and (min-width: $screen-md-min) {
    &__row {
      display: flex;
      align-items: center;
    }

    &__row-title,
    &__row-value {
      font-size: 16px;
      line-height: 20px;
      padding-right: 60px;
    }

    &__row-title {
      width: 34%;
    }

    &__row-value {
      width: 66%;
      margin-top: 0;
    }
  }
}

.payment-details-modal-footer {
  margin: -6px -8px;

  & .btn {
    margin: 6px 8px;
  }

  @media screen and (min-width: $screen-md-min) {
    margin: -6px -12px;

    & .btn {
      margin: 6px 12px;
    }
  }
}
</style>
