<script>
import { Doughnut } from "vue-chartjs";

export default {
  extends: Doughnut,

  props: {
    data: {
      type: [Object, Array],
      required: true,
      default: () => {}
    }
  },

  data() {
    return {
      options: {
        legend: {
          display: false
        },
        hover: {
          mode: 'label',
          animationDuration: 100,
        },
        cutoutPercentage: 80,
        tooltips: {
          enabled: false,
          custom: function(tooltipModel) {
                // Tooltip Element
                var tooltipEl = document.getElementById('chartjs-tooltip');
                var tooltipContainer = document.querySelector('.js-chart');

                // Create element on first render
                if (!tooltipEl) {
                  tooltipEl = document.createElement('div');
                  tooltipEl.id = 'chartjs-tooltip';
                  tooltipEl.innerHTML = '<div data-tooltip></div>';
                  tooltipContainer.append(tooltipEl);
                }

                // Hide if no tooltip
                if (tooltipModel.opacity === 0) {
                  tooltipEl.style.opacity = 0;
                  return;
                }

                // Set caret Position
                tooltipEl.classList.remove('above', 'below', 'no-transform');
                if (tooltipModel.yAlign) {
                  tooltipEl.classList.add(tooltipModel.yAlign);
                } else {
                  tooltipEl.classList.add('no-transform');
                }

                function getBody(bodyItem) {
                  return bodyItem.lines;
                }

                // Set Text
                if (tooltipModel.body) {
                  var bodyLines = tooltipModel.body.map(getBody);
                  tooltipEl.innerHTML = '';

                  bodyLines.forEach(function(body) {
                    var bodyTextsArray = body[0].split(': ');

                    var getFontSize = function(text) {
                      var textLength = text.length;
                      var baseTextLength = 8;
                      var maxFontSize = 28;
                      var minFontSize = 14;
                      var newFontSize;

                      if (textLength > baseTextLength) {
                        newFontSize = maxFontSize - (Math.abs(baseTextLength - textLength) * 2);

                        return newFontSize < minFontSize ? minFontSize : newFontSize;
                      }

                      return maxFontSize;
                    }

                    var bodyTextCurrency = document.createElement('div');
                    bodyTextCurrency.innerHTML = bodyTextsArray[0];
                    bodyTextCurrency.style.color = '#A3A4A5';

                    var bodyTextAmount = document.createElement('div');
                    bodyTextAmount.innerHTML = bodyTextsArray[1].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    bodyTextAmount.style.fontSize = getFontSize(bodyTextAmount.innerHTML).toString() + 'px';
                    bodyTextAmount.style.whiteSpace = 'nowrap';
                    bodyTextAmount.style.overflow = 'hidden';
                    bodyTextAmount.style.textOverflow = 'ellipsis';
                    bodyTextAmount.style.width = '100%';

                    tooltipEl.appendChild(bodyTextAmount);
                    tooltipEl.appendChild(bodyTextCurrency);
                  });
                }

                // `this` will be the overall tooltip
                // var position = this._chart.canvas.getBoundingClientRect();

                // Display, position, and set styles for font
                tooltipEl.style.opacity = 1;
                tooltipEl.style.position = 'absolute';
                tooltipEl.style.top = 0;
                tooltipEl.style.left = '15%';
                tooltipEl.style.right = '15%';
                tooltipEl.style.height = '100%';
                tooltipEl.style.display = 'flex';
                tooltipEl.style.flexDirection = 'column';
                tooltipEl.style.justifyContent = 'center';
                tooltipEl.style.alignItems = 'center';
                tooltipEl.style.textAlign = 'center';
                tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                tooltipEl.style.padding = 0;
                tooltipEl.style.overflow = 'hidden';
                tooltipEl.style.pointerEvents = 'none';
                tooltipEl.style.transition = 'opacity 0.1s linear';
            }
        },
      },
    };
  },

  computed: {
    currencies() {
      const currencies = [];
      this.data.forEach(item => currencies.push(item.name));

      return currencies;
    },

    amounts() {
      const amounts = [];
      this.data.forEach(item => amounts.push(item.amount));

      return amounts;
    },

    colors() {
      const colors = [];
      this.data.forEach(item => colors.push(item.color));

      return colors;
    },

    allData() {
      return {
        labels: this.currencies || [],
        datasets: [
          {
            data: this.amounts || [],
            backgroundColor: this.colors || [],
            hoverBackgroundColor: this.colors || [],
            borderWidth: 1,
            borderColor: this.colors || [],
            hoverBorderColor: this.colors || [],
          },
          {
            data: this.amounts || [],
            backgroundColor: '#fff',
            hoverBackgroundColor: this.colors || [],
            borderWidth: 1,
            borderColor: '#fff',
            hoverBorderColor: this.colors || [],
          }
        ],
      }
    }
  },

  mounted() {
    this.renderChart(this.allData, this.options);
  }
};
</script>

<style lang="scss" scoped>
.chartjs-render-monitor {
  max-width: 200px;
  max-height: 200px;
  margin: 0 auto;
  border-radius: 50%;
}
</style>
