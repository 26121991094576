<template>
  <OnboardingBusinessLayout
    :current-step="currentStep"
    :side-msg="sideMsg"
  >
    <Header>
      <template slot="title">
        Please provide ultimate beneficial owners of ABC Ltd
      </template>
    </Header>

    <div class="mt-40">
      <div
        v-if="form.addedOwners.length"
        class="form__list mb-lg-32 mb-lg-24"
      >
        <div
          v-for="(item, i) in form.addedOwners"
          :key="i"
          class="form__list-item"
        >
          <div>
            <div>
              <span class="mr-4 text-grey">Name:</span>
              <span>{{ item.fullName }}</span>
            </div>

            <div class="mt-lg-8 mt-sm-4">
              <span class="mr-4 text-grey">Date of birth:</span>
              <span>{{ item.dateOfBirth }}</span>
            </div>

            <div class="mt-lg-8 mt-sm-4">
              <span class="mr-4 text-grey">Country:</span>
              <span>{{ item.country }}</span>
            </div>
          </div>

          <button
            class="form__list-btn text-grey"
            type="button"
            @click="editEntry(item.id)"
          >
            Edit
          </button>
        </div>
      </div>
    </div>

    <div class="mt-lg-40 mt-sm-32">
      <div class="arrange">
        <FormInput
          id="name"
          name="name"
          type="text"
          :value="form.name"
          label="Name"
          el-width="auto"
          :has-error="form.nameError"
          @input="form.name = $event"
        />

        <Datepicker
          id="dob"
          name="dob"
          placeholder="DD/MM/YYYY"
          label="Date of birth"
          el-width="154px"
          el-style="input"
          :value="form.dob"
          :has-error="form.dobError"
          class="ml-24"
          input-format="DD/MM/YYYY"
          @change="form.dob = $event"
        />
      </div>

      <div class="mt-lg-40 mt-sm-32">
        <FormInput
          id="surname"
          name="surname"
          type="text"
          :value="form.surname"
          label="Surname"
          :has-error="form.surnameError"
          @input="form.surname = $event"
        />
      </div>

      <div class="mt-lg-40 mt-sm-32">
        <FormSelect
          id="country"
          name="country"
          label="Сountry of residence"
          el-width="100%"
          el-style="input"
          :value="form.country"
          :options="[
            { value: 'Lithuania', title: 'Lithuania', flag: 'lt' },
            { value: 'Latvia', title: 'Latvia', flag: 'lv' },
            { value: 'Estonia', title: 'Estonia', flag: 'ee' }
          ]"
          @change="form.country = $event"
        />
      </div>

      <div class="mt-lg-16 mt-sm-12">
        <Button
          icon="icon-add"
          label="Add ultimate beneficial owners of ABC Ltd"
          btn-style="transparent"
          btn-width="138px"
          btn-size="sm"
          :click-handler="addEntry"
        />
      </div>
    </div>

    <div class="arrange mt-lg-32 mt-sm-24">
      <Button
        icon="icon-back-mobile"
        label="Return"
        btn-style="transparent"
        btn-width="92px"
      />

      <Button
        label="Continue"
        btn-style="primary"
        class="w-100 ml-24"
      />
    </div>
  </OnboardingBusinessLayout>
</template>

<script>
import OnboardingBusinessLayout from "@/components/Layout/OnboardingBusinessLayout";
import Header from "@/components/Onboarding/Header";
import FormInput from "@/components/Form/FormInput";
import Button from "@/components/Button";
import FormSelect from "@/components/Form/FormSelect";
import Datepicker from "@/components/Form/FormDatepicker";

export default {
  components: {
    OnboardingBusinessLayout,
    Header,
    FormInput,
    Button,
    FormSelect,
    Datepicker
  },

  data() {
    return {
      currentStep: 7,

      sideMsg:
        "Some short tooltip. Why do we need to know about this info about user",

      form: {
        name: "",
        nameError: "",
        surname: "",
        surnameError: "",
        dob: null,
        dobError: '',
        country: "Latvia",

        addedOwners: [],
        addedItemId: 0,
      }
    };
  },

  methods: {
    addEntry() {
      const data = {
        id: this.form.addedItemId,
        name: this.form.name,
        surname: this.form.surname,
        fullName: this.form.name + ' ' + this.form.surname,
        dob: this.form.dob,
        dateOfBirth: this.getBirthday(this.form.dob),
        country: this.form.country,
      };

      this.form.addedOwners.push(data);
      this.form.addedItemId++;

      this.form.name = '';
      this.form.surname = '';
      this.form.dob = null;
    },

    editEntry(id) {
      const itemsArray = this.form.addedOwners;
      const item = itemsArray.find(item => item.id === id);
      const itemIndex = itemsArray.indexOf(item);

      this.form.name = item.name;
      this.form.surname = item.surname;
      this.form.dob = item.dob;
      this.form.country = item.country;

      if (itemIndex !== -1) itemsArray.splice(itemIndex, 1);
    },

    getBirthday(date) {
      const day = () => {
        const d = new Date(date).getDate();

        return d >= 10 ? d : `0${d}`;
      };

      const month = () => {
        const m = new Date(date).getMonth() + 1;

        return m >= 10 ? m : `0${m}`;
      };

      const year = new Date(date).getFullYear();

      return `${day()}.${month()}.${year}`;
    },
  }
};
</script>

<style lang="scss" scoped>
.form {
  &__list-item {
    position: relative;
    padding: 16px;
    background-color: $grey-light-02;
    border-radius: 8px;
    font-size: 14px;
  }

  &__list-item + &__list-item {
    margin-top: 24px;
  }

  &__list-btn {
    position: absolute;
    top: 16px;
    right: 16px;
  }
}
</style>
