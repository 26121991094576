<template>
  <div class="card-offer">
    <h2 class="card-offer__title h2-bold">
      TPF Multi-Currency Debit Card
    </h2>

    <div class="card-offer__inner">
      <div class="card-offer__card-box">
        <div class="card-offer__card-wrapper">
          <PaymentCard
            card-style="black"
            issuer="mastercard"
            :logo="require('@/assets/images/logo.svg')"
            primary-label="Black Card"
            primary-number="0000 0000 0000 0000"
            valid="12/23"
            holder="Your Name"
          />
        </div>

        <div class="card-offer__card-footer">
          <a
            href="#"
            class="card-offer__link"
          >
            About Multi-Currency Debit Card
          </a>
        </div>
      </div>

      <div class="card-offer__info-box">
        <ul class="card-offer__info-item">
          <li>
            Card issue free of charge
          </li>

          <li>
            High volume POS transactions
          </li>

          <li>
            Secure online payments
          </li>

          <li>
            Ready for ApplePay and AndroidPay
          </li>
        </ul>

        <p class="card-offer__info-item">
          The greatest tool for instant access to your funds around the globe.
        </p>

        <div class="card-offer__info-footer">
          <Button
            label="Order a card"
            btn-style="dark"
            btn-width="186px"
            :click-handler="handleNewCardPopup"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import PaymentCard from "@/components/Blocks/PaymentCard";
import Button from "@/components/Button";

export default {
  name: "CardOffer",

  components: {
    PaymentCard,
    Button
  },

  methods: {
    ...mapActions(["showModal"]),

    handleNewCardPopup() {
      this.showModal({
        component: "NewCardModal"
      });
    }
  }
};
</script>

<style scoped lang="scss">
.card-offer {
  width: 100%;
  background-color: $additional-normal;
  border-radius: 8px;
  padding: 16px 16px 24px 16px;

  &__title {
    margin-bottom: 28px;
  }

  &__card-box {
    width: 100%;
    position: relative;
  }

  &__card-wrapper {
    max-width: 420px;
    margin: auto;
  }

  &__card-footer {
    margin-top: 16px;
  }

  &__link {
    font-size: 14px;
    line-height: 18px;
    color: rgba($additional-brown, 0.7);
    transition: color 0.1s linear;
  }

  &__link:after {
    content: "\e92a";
    @include inline-icon;
    vertical-align: middle;
  }

  &__info-footer {
    margin-top: 24px;
  }

  &__info-box {
    color: $additional-brown;
    font-size: 14px;
    line-height: 18px;
    margin-top: 32px;
  }

  &__info-box ul li {
    position: relative;
    padding-left: 34px;
  }

  &__info-box ul li + li {
    margin-top: 24px;
  }

  &__info-box ul li:before {
    content: "\e90b";
    @include inline-icon;
    position: absolute;
    left: 0;
    top: -1px;
    font-size: 24px;
  }

  &__info-item + &__info-item {
    margin-top: 24px;
  }

  &__info-item {
    font-size: 14px;
    line-height: 18px;
  }

  @media (hover: hover) {
    &__link:hover {
      color: $accent-light;
    }
  }

  @media screen and (max-width: $screen-sm-max) {
    &__info-footer .btn {
      width: 100%;
    }
  }

  @media screen and (min-width: $screen-md-min) {
    padding: 24px;

    &__inner {
      display: flex;
    }

    &__card-box {
      width: 50%;
      min-width: 340px;
      max-width: 420px;
    }

    &__card-footer {
      margin-top: 62px;
    }

    &__info-box {
      margin: 0 0 0 32px;
      width: 50%;
      padding-top: 12px;
      font-size: 16px;
      line-height: 20px;
    }

    &__info-item {
      font-size: 16px;
      line-height: 20px;
    }
  }

  @media screen and (min-width: $screen-lg-min) {
    padding: 24px 24px 32px 24px;
  }
}
</style>
