<template>
  <ModalWrapper
    size="md"
    modal-style="success"
  >
    <template slot="title">
      Transfer successful
    </template>

    <div class="text-center text-sb">
      Transaction number: LT3256 2345 2345 2321
    </div>

    <div class="mt-24">
      <ModalTableRow
        v-for="(row, i) in tableRows"
        :key="i"
      >
        <template slot="title">
          {{ row.title }}
        </template>

        <template slot="value">
          {{ row.value }}
        </template>
      </ModalTableRow>
    </div>

    <template slot="footer">
      <Button
        label="Ok"
        btn-style="primary"
        btn-width="110px"
        :click-handler="hideModal"
      />
    </template>
  </ModalWrapper>
</template>

<script>
import { mapActions } from "vuex";

import ModalWrapper from "@/components/Modal/ModalWrapper";
import Button from "@/components/Button";
import ModalTableRow from "@/components/Modal/ModalTableRow";

export default {
  name: "DistributeFundsSuccessModal",

  components: {
    ModalWrapper,
    Button,
    ModalTableRow
  },

  data() {
    return {
      tableRows: [
        { title: "From saving account", value: "Saving account 1" },
        { title: "To account", value: "Account 2" },
        { title: "Amount", value: "1,000.00  GBP" }
      ]
    };
  },

  methods: {
    ...mapActions(["hideModal"])
  }
};
</script>
