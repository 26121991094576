<template>
  <div class="login-layout">
    <div
      v-if="showSideBg"
      class="login-layout__bg"
      :style="{
        backgroundImage: 'url(' + require('@/assets/images/login-bg.svg') + ')'
      }"
    />

    <div
      class="login-layout__content"
      :class="{ 'color-change': showSideBg }"
    >
      <header class="login-layout__header">
        <img
          :src="require('@/assets/images/logo.svg')"
          alt="logo"
          class="login-layout__header-logo"
        >

        <LangSelect
          :selected="lang"
          :options="['eng', 'est']"
          @change="lang = $event"
        />
      </header>

      <main class="login-layout__main">
        <div
          v-if="hasBackSlot"
          class="login-layout__back"
        >
          <slot name="backSlot" />
        </div>

        <div
          class="login-layout__main-wrapper"
          :class="{ 'increased-width': increasedWidth }"
        >
          <div class="login-layout__main-mob-head">
            <div
              v-if="hasMobBackSlot"
              class="login-layout__mob-back"
            >
              <slot name="mobBackSlot" />
            </div>

            <img
              :src="require('@/assets/images/logo.svg')"
              alt="logo"
              class="login-layout__main-mob-logo"
            >
          </div>

          <slot />
        </div>
      </main>

      <footer class="login-layout__footer">
        <ul class="login-layout__footer-links">
          <li>
            <a
              href="/"
              class="login-layout__footer-link"
            >
              Terms and Conditions
            </a>
          </li>

          <li>
            <a
              href="tel:+442038079680"
              class="login-layout__footer-link"
            >
              +44 20380 79680
            </a>
          </li>

          <li>
            <a
              href="mailto:info@tpf.com"
              class="login-layout__footer-link"
            >
              info@tpf.com
            </a>
          </li>
        </ul>
      </footer>
    </div>
  </div>
</template>

<script>
import LangSelect from "@/components/Auth/LangSelect";

export default {
  name: "AuthLayout",

  components: {
    LangSelect
  },

  props: {
    showSideBg: {
      type: Boolean,
      required: false,
      default: true
    },
    increasedWidth: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      lang: "eng"
    };
  },

  computed: {
    hasBackSlot() {
      return !!this.$slots.backSlot;
    },

    hasMobBackSlot() {
      return !!this.$slots.mobBackSlot;
    }
  }
};
</script>

<style scoped lang="scss">
.login-layout {
  position: relative;
  display: flex;
  min-height: 100vh;
  background-color: $bg-color;

  &__bg {
    width: 100%;
    min-height: 100%;
    background-position: bottom right;
    background-size: cover;
  }

  &__content {
    position: relative;
    z-index: 2;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 32px;
  }

  &__header-logo {
    max-width: 80px;
    max-height: 40px;
  }

  &__main {
    margin: auto;
    padding: 0 16px;
  }

  &__back {
    margin-bottom: 16px;
  }

  &__main-wrapper {
    background-color: $white;
    border-radius: 8px;
    padding: 32px;
    width: 100%;
    max-width: 514px;
  }

  &__main-mob-head {
    position: relative;
    display: flex;
    margin-bottom: 24px;
  }

  &__main-mob-logo {
    width: 41px;
    height: 32px;
    margin: auto;
  }

  &__footer {
    margin-top: auto;
    padding: 32px 16px;
  }

  &__footer-links li {
    padding: 0 16px;
  }

  &__footer-link {
    font-size: 14px;
    line-height: 18px;
    color: $grey-dark-01;
    transition: color 0.1s linear;
  }

  .color-change &__footer-link {
    color: $white;
  }

  &__mob-back {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  @media (hover: hover) {
    &__footer-link:hover {
      color: $accent-light;
    }
  }

  @media screen and (max-width: $screen-md-max) {
    &__bg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1;
    }

    &__header-logo {
      display: none;
    }

    &__back {
      display: none;
    }

    &__footer-links li {
      text-align: center;
    }

    &__footer-links li + li {
      margin-top: 12px;
    }

    & .auth-title,
    & .auth-text {
      text-align: center;
    }
  }

  @media screen and (min-width: $screen-lg-min) {
    &__bg {
      width: 645px;
      max-width: 40%;
    }

    &__main-mob-head {
      display: none;
    }

    &__main-wrapper.increased-width {
      width: 802px;
      max-width: none;
    }

    &__footer-links {
      display: flex;
    }

    &__footer-links li + li {
      border-left: 1px solid $grey-dark-02;
    }

    &__footer-link {
      color: $grey-dark-01;
    }

    .color-change &__footer-link {
      color: $grey-dark-01;
    }
  }
}
</style>
