<template>
  <div class="statement-currency-table">
    <div class="statement-currency-table__header">
      <div
        class="statement-currency-table__header-cell statement-currency-table__header-cell--main"
      >
        Currency
      </div>

      <div
        class="statement-currency-table__header-cell statement-currency-table__header-cell--secondary"
      >
        Opening balance
      </div>

      <div
        class="statement-currency-table__header-cell statement-currency-table__header-cell--secondary"
      >
        closing balance
      </div>

      <div
        class="statement-currency-table__header-cell statement-currency-table__header-cell--secondary"
      >
        credit turnover
      </div>

      <div
        class="statement-currency-table__header-cell statement-currency-table__header-cell--secondary"
      >
        debit turnover
      </div>
    </div>

    <div class="statement-currency-table__body">
      <div
        v-for="(row, i) in balance"
        :key="i"
        class="statement-currency-table__row"
      >
        <div
          class="statement-currency-table__cell statement-currency-table__cell--main"
        >
          <span>{{ row.currency }}</span>
        </div>

        <div
          class="statement-currency-table__cell statement-currency-table__cell--secondary"
        >
          <span class="statement-currency-table__mob-title">
            Opening balance
          </span>
          <span>{{ row.opening | moneyformat }}</span>
        </div>

        <div
          class="statement-currency-table__cell statement-currency-table__cell--secondary"
        >
          <span class="statement-currency-table__mob-title">
            Closing balance
          </span>
          <span>{{ row.closing | moneyformat }}</span>
        </div>

        <div
          class="statement-currency-table__cell statement-currency-table__cell--secondary"
        >
          <span class="statement-currency-table__mob-title">
            Debit turnover
          </span>
          <span class="text-green">{{ row.credit | moneyformat }}</span>
        </div>

        <div
          class="statement-currency-table__cell statement-currency-table__cell--secondary"
        >
          <span class="statement-currency-table__mob-title">
            Credit turnover
          </span>
          <span class="text-red">{{ row.debit | moneyformat }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { statementCurrencyBalance } from "@/fixtures";

export default {
  name: "StatementCurrencyTable",

  created() {
    this.balance = statementCurrencyBalance;
  }
};
</script>

<style scoped lang="scss">
.statement-currency-table {
  width: 100%;
  margin: 0 auto 16px auto;

  &__header {
    display: flex;
    background-color: rgba($grey-light-01, 0.88);
    border-radius: 8px 8px 0 0;
    padding: 16px 12px;
  }

  &__header-cell {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: rgba($grey-dark, 0.6);
    padding: 0 12px;
  }

  &__header-cell--main {
    width: 36%;
  }

  &__header-cell--secondary {
    width: 16%;
    text-align: right;
  }

  &__body {
    background-color: $white;
    border-radius: 8px;
  }

  &__row {
    padding: 16px;
  }

  &__row + &__row {
    border-top: 1px solid $grey-light-01;
  }

  &__cell--secondary {
    padding: 4px 0;
  }

  &__cell.is-right {
    text-align: right;
  }

  &__mob-title {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: rgba($grey-dark, 0.6);
  }

  @media screen and (max-width: $screen-sm-max) {
    &__header {
      display: none;
    }

    &__cell--main {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 8px;
    }

    &__cell--secondary {
      font-size: 14px;
      line-height: 18px;
      display: flex;
      justify-content: space-between;
    }
  }

  @media screen and (min-width: $screen-md-min) {
    margin: 0 auto 32px auto;

    &__body {
      border-radius: 0 0 8px 8px;
    }

    &__row {
      display: flex;
      align-items: center;
      padding: 22px 12px;
    }

    &__cell {
      padding: 0 12px;
    }

    &__cell--main {
      width: 36%;
    }

    &__cell--secondary {
      width: 16%;
      text-align: right;
    }

    &__mob-title {
      display: none;
    }
  }
}
</style>
