<template>
  <div
    class="form-checkbox"
    :class="{ 'has-error': hasError, 'is-disabled': disabled }"
    :style="{ width: elWidth }"
  >
    <label class="form-checkbox__wrapper">
      <input
        :id="id"
        :name="name"
        type="checkbox"
        :disabled="disabled"
        :indeterminate.prop="indeterminate"
        :checked="value"
        @change="$emit('change', $event.target.checked)"
      >
      <span class="form-checkbox__check" />

      <span class="form-checkbox__label">
        <slot />
      </span>
    </label>

    <span
      v-if="hasError"
      class="form-checkbox__error"
    >{{ hasError }}</span>
  </div>
</template>

<script>
export default {
  name: "FormCheckbox",

  props: {
    id: { type: String, required: false, default: "" },
    name: { type: String, required: false, default: "" },
    value: { type: Boolean, required: false, default: false },
    indeterminate: { type: Boolean, required: false, default: false },
    elWidth: { type: String, required: false, default: "" },
    disabled: { type: Boolean, required: false, default: false },
    hasError: { type: String, required: false, default: "" }
  }
};
</script>

<style scoped lang="scss">
.form-checkbox {
  display: inline-block;

  &__wrapper {
    position: relative;
    display: flex;
    padding-left: 16px;
    cursor: pointer;
  }

  &__wrapper input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }

  &__check {
    position: absolute;
    top: 2px;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    box-shadow: inset 0 0 0 1.5px $grey-dark-02;
    border-radius: 2px;
    background-color: $white;
    color: $white;
    transition: background-color 0.1s linear, box-shadow 0.1s linear;
  }

  & input:checked + &__check:after {
    @include inline-icon;

    content: "\e919";
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 13px;
    transform: translate(-48%, -48%);
  }

  & input:indeterminate + &__check:after {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -1px 0 0 -3px;
    width: 6px;
    height: 2px;
    content: "";
    background-color: $white;
    border-radius: 1px;
  }

  &__wrapper:focus &__check,
  & input:focus + &__check {
    box-shadow: inset 0 0 0 2px $additional-brown;
  }

  & input:checked + &__check,
  & input:indeterminate + &__check {
    background-color: $accent-normal;
    box-shadow: none;
  }

  &.has-error &__check {
    box-shadow: inset 0 0 0 1.5px $red;
  }

  & input:disabled ~ span {
    opacity: 0.5;
  }

  &__label {
    display: block;
    padding-left: 8px;
    color: $additional-brown;
  }

  &__error {
    font-family: $font-regular;
    font-size: 12px;
    line-height: 1.33;
    margin: 4px 0;
    color: $red;
  }

  &.is-disabled {
    pointer-events: none;
  }

  @media (hover: hover) {
    &__wrapper:hover &__check {
      border-color: $accent-normal;
    }
  }
}
</style>
