<template>
  <span :class="`badge badge-${elStyle}`">{{ label }}</span>
</template>

<script>
export default {
  name: "Badge",

  props: {
    label: { type: String, required: false, default: "" },
    elStyle: {
      type: String,
      required: false,
      default: "neutral",
      validator: function(value) {
        return ["neutral", "danger", "warning"].indexOf(value) !== -1;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.badge {
  display: inline-block;
  border-radius: 2px;
  font-family: $font-regular;
  font-size: 12px;
  line-height: 20px;
  padding: 0 4px;
}

.badge-neutral {
  color: $grey-dark-01;
  background-color: $grey-light-01;
}

.badge-danger {
  color: $white;
  background-color: $red;
}

.badge-warning {
  color: $white;
  background-color: $accent-light;
}
</style>
