<template>
  <svg
    class="progress-ring"
    :width="size"
    :height="size"
  >
    <circle
      stroke="#EDEFF2"
      :stroke-width="stroke"
      fill="transparent"
      :r="radiusOuter"
      :cx="center"
      :cy="center"
    />
    <circle
      class="progress-ring-circle"
      stroke="#16BF99"
      :stroke-width="strokeProgress"
      fill="transparent"
      :r="radiusProgress"
      :cx="center"
      :cy="center"
      :style="radialProgress"
    />
  </svg>
</template>

<script>
export default {
  name: "SavingsProgressGraph",

  props: {
    progress: { type: Number, required: false, default: 0 },
    size: { type: Number, required: false, default: 16 },
    stroke: { type: Number, required: false, default: 2 },
    strokeProgress: { type: Number, required: false, default: 4 }
  },

  computed: {
    radiusOuter() {
      return this.size / 2 - this.stroke;
    },

    radiusProgress() {
      return (this.size - this.stroke - this.strokeProgress) / 2;
    },

    center() {
      return this.size / 2;
    },

    radialProgress() {
      const radius = this.radiusProgress;
      const circumference = radius * 2 * Math.PI;
      const strokeDasharray = `${circumference}px, ${circumference}px`;
      const offset = circumference - (this.progress / 100) * circumference;
      const strokeDashoffset = offset;

      return `stroke-dasharray: ${strokeDasharray}; stroke-dashoffset: ${strokeDashoffset}px`;
    }
  }
};
</script>

<style scoped lang="scss">
.progress-ring-circle {
  transition: 0.35s stroke-dashoffset;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}
</style>
