<template>
  <OnboardingLayout
    :current-step="currentStep"
    :side-msg="sideMsg"
  >
    <Header>
      <template slot="title">
        Personal information
      </template>
    </Header>

    <div class="mt-lg-24 mt-sm-16">
      <h4 class="text-sb">
        Personal info
      </h4>

      <div class="mt-32 arrange arrange--wrap arrange--align-center">
        <FormDatepicker
          id="birthsDate"
          name="births-date"
          placeholder="MM/DD/YYYY"
          label="Date of birth"
          el-width="154px"
          el-style="input"
          :value="form.birthsDate"
          :has-error="form.birthsDateError"
          class="mr-24"
          input-format="DD/MM/YYYY"
          @change="form.birthsDate = $event"
        />

        <PersonalInfo>
          <div class="text-sm">
            <span class="text-grey">Name:</span> John Doe
          </div>
          <div class="text-sm mt-4">
            <span class="text-grey">Phone:</span> +331 632 435 42 12
          </div>
        </PersonalInfo>
      </div>
    </div>

    <div class="mt-lg-24 mt-sm-16">
      <h4 class="text-sb">
        Your residence
      </h4>

      <div class="mt-32">
        <FormInput
          id="address"
          name="address"
          type="text"
          :value="form.address"
          label="Address"
          :has-error="form.addressError"
          @input="form.address = $event"
        />
      </div>

      <div class="arrange mt-lg-40 mt-sm-32">
        <FormInput
          id="city"
          name="city"
          type="text"
          :value="form.city"
          label="City"
          :has-error="form.cityError"
          el-width="60%"
          @input="form.city = $event"
        />

        <FormInput
          id="zip"
          name="zip"
          type="text"
          :value="form.zip"
          label="Zip Code"
          :has-error="form.zipError"
          el-width="40%"
          placeholder="LV - 0000"
          mask="LV - ####"
          class="ml-lg-24 ml-sm-16"
          @input="form.zip = $event"
        />
      </div>

      <div class="arrange mt-lg-40 mt-sm-32">
        <FormSelect
          id="country"
          name="country"
          label="Country"
          el-width="60%"
          el-style="input"
          :value="form.country"
          :options="[
            { value: 'lt', title: 'Lithuania', flag: 'lt' },
            { value: 'lv', title: 'Latvia', flag: 'lv' },
            { value: 'ee', title: 'Estonia', flag: 'ee' }
          ]"
          @change="form.country = $event"
        />

        <FormInput
          id="state"
          name="state"
          type="text"
          :value="form.state"
          label="State"
          :has-error="form.stateError"
          el-width="40%"
          class="ml-lg-24 ml-sm-16"
          @input="form.state = $event"
        />
      </div>

      <div class="mt-lg-32 mt-sm-24">
        <ToggleBox>
          <template slot="label">
            <div class="text-dscr text-grey">
              Are you resident or citizen of the Unated States of America?
            </div>
          </template>

          <template slot="control">
            <Toggle
              id="us_resident"
              name="us-resident"
              :labels="{
                true: 'Yes',
                false: 'No'
              }"
              :value="form.usResident"
              @change="form.usResident = $event"
            />
          </template>
        </ToggleBox>
      </div>

      <div class="mt-lg-32 mt-sm-24">
        <Button
          label="Next"
          btn-style="primary"
          btn-width="100%"
        />
      </div>
    </div>
  </OnboardingLayout>
</template>

<script>
import OnboardingLayout from "@/components/Layout/OnboardingLayout";
import Header from "@/components/Onboarding/Header";
import FormDatepicker from "@/components/Form/FormDatepicker";
import FormInput from "@/components/Form/FormInput";
import FormSelect from "@/components/Form/FormSelect";
import Button from "@/components/Button";
import ToggleBox from "@/components/ToggleBox";
import Toggle from "@/components/Toggle";
import PersonalInfo from "@/components/Onboarding/PersonalInfo";

export default {
  components: {
    OnboardingLayout,
    Header,
    FormDatepicker,
    FormInput,
    FormSelect,
    Button,
    ToggleBox,
    Toggle,
    PersonalInfo
  },

  data() {
    return {
      currentStep: 3,

      sideMsg:
        "Some short tooltip. Why do we need to know about this info about user",

      form: {
        birthsDate: null,
        birthsDateError: "",
        address: "",
        addressError: "",
        city: "",
        cityError: "",
        zip: "",
        zipError: "",
        country: "lv",
        countryError: "",
        state: "",
        stateError: "",
        usResident: false
      }
    };
  }
};
</script>
