<template>
  <ModalWrapper
    size="md"
    modal-style="info"
  >
    <template slot="title">
      Your request is accepted
    </template>

    <div class="text-center text-sb mb-8">
      Thank you!
    </div>

    <div class="text-center mb-16">
      We have sent you an email to
      <a
        class="text-blue text-link"
        href="#"
      >
        xxx@xxx.com
      </a>.
      Please change the password within 15 minutes.
    </div>

    <template slot="footer">
      <Button
        label="Ok"
        btn-style="dark-opacity"
        btn-width="110px"
        :click-handler="closeModal"
      />
    </template>
  </ModalWrapper>
</template>

<script>
import { mapActions } from "vuex";
import ModalWrapper from "@/components/Modal/ModalWrapper";
import Button from "@/components/Button";

export default {
  name: "NewRequestModal",

  components: {
    ModalWrapper,
    Button
  },

  methods: {
    ...mapActions(["hideModal"]),

    closeModal() {
      this.hideModal();
    }
  }
};
</script>
