<template>
  <div
    class="tooltip"
    :class="{
      top: top,
      right: right,
      bottom: bottom,
      left: left,
      show: show
    }"
    :data-tooltip="popover"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: "Tooltip",

  props: {
    popover: { type: String, required: false, default: "" },
    top: { type: Boolean, required: false, default: false },
    right: { type: Boolean, required: false, default: false },
    bottom: { type: Boolean, required: false, default: false },
    left: { type: Boolean, required: false, default: false },
    show: { type: Boolean, required: false, default: false }
  }
};
</script>

<style scoped lang="scss">
.tooltip {
  display: inline-block;
  position: relative;
  cursor: help;

  &:before {
    content: attr(data-tooltip);
    position: absolute;
    max-width: 250px;
    background-color: rgba($grey-dark, 0.85);
    color: $white;
    padding: 8px;
    border-radius: 2px;
    font-family: $font-regular;
    font-size: 12px;
    line-height: 1.33;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.1s linear, visibility 0s linear 0.2s;
  }

  &.top:before {
    bottom: calc(100% + 5px);
    left: 50%;
    transform: translateX(-50%);
  }

  &.right:before {
    bottom: 50%;
    left: calc(100% + 10px);
    transform: translateY(50%);
  }

  &.bottom:before {
    top: calc(100% + 5px);
    left: 50%;
    transform: translateX(-50%);
  }

  &.left:before {
    bottom: 50%;
    right: calc(100% + 10px);
    transform: translateY(50%);
  }

  &:after {
    content: "";
    width: 10px;
    height: 10px;
    background-image: url("data:image/svg+xml;utf8,<svg width='5' height='10' viewBox='0 0 5 10' fill='none' xmlns='http://www.w3.org/2000/svg'><path opacity='0.85' d='M5 0L0 5L5 10V0Z' fill='%23000817'/></svg>");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: 3px;
    position: absolute;
    transform-origin: center center;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.1s linear, visibility 0s linear 0.2s;
  }

  &.top:after {
    top: -5px;
    left: 50%;
    transform: rotate(-90deg) translateY(-50%);
  }

  &.right:after {
    right: -10px;
    top: 50%;
    transform: translateY(-50%);
  }

  &.bottom:after {
    bottom: -5px;
    left: 50%;
    transform: rotate(90deg) translateY(50%);
  }

  &.left:after {
    left: -10px;
    top: 50%;
    transform: rotate(180deg) translateY(50%);
  }

  &:hover:before,
  &:hover:after,
  &.show:before,
  &.show:after {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.1s linear, visibility 0s linear 0s;
  }
}
</style>
