<template>
  <DashboardLayout>
    <SavingsHeader class="dashboard-layout__item" />

    <SavingsAccountPreview
      v-for="(account, i) in savingsAccounts"
      :key="i"
      :data="account"
      class="dashboard-layout__item"
    />

    <div class="dashboard-layout__item">
      <Button
        label="Add account"
        btn-style="dark-opacity"
        :click-handler="handleNewAccount"
        class="btn-layout-more"
      />
    </div>
  </DashboardLayout>
</template>

<script>
import { mapActions } from "vuex";

import { savings } from "@/fixtures";

import DashboardLayout from "@/components/Layout/DashboardLayout";
import SavingsHeader from "@/components/Blocks/SavingsHeader";
import Button from "@/components/Button";
import SavingsAccountPreview from "@/components/Blocks/SavingsAccountPreview";

export default {
  name: "Statement",

  components: {
    DashboardLayout,
    SavingsHeader,
    Button,
    SavingsAccountPreview
  },

  created() {
    this.savingsAccounts = savings;
  },

  methods: {
    ...mapActions(["showModal"]),

    handleNewAccount() {
      this.showModal({
        component: "NewSavingsAccountModal"
      });
    }
  }
};
</script>
