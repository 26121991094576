<template>
  <header class="dashboard-header-mob">
    <img
      :src="require('@/assets/images/logo.svg')"
      alt="logo"
      class="dashboard-header-mob__logo"
    >

    <div class="dashboard-header-mob__toolbox">
      <div
        class="dashboard-header-mob__toolbox-item dashboard-header-mob__selected"
      >
        {{ user.primary }}
      </div>

      <router-link
        :to="{ name: 'messages' }"
        :data-count="user.notifications"
        class="dashboard-header-mob__toolbox-item dashboard-header-mob__notifications has-notifications"
      >
        <i class="icon icon-mail" />
        <span>Notifications</span>
      </router-link>

      <div class="dashboard-header-mob__toolbox-item">
        <DashboardNavMob />
      </div>
    </div>
  </header>
</template>

<script>
import DashboardNavMob from "@/components/Dashboard/DashboardNavMob";

export default {
  name: "DashboardHeaderMob",

  components: {
    DashboardNavMob
  },

  computed: {
    user() {
      return this.$store.state.currentUser;
    }
  }
};
</script>

<style scoped lang="scss">
.dashboard-header-mob {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  height: 64px;

  &__logo {
    display: block;
    width: 40px;
    height: 30px;
  }

  &__toolbox {
    display: flex;
    align-items: center;
  }

  &__toolbox-item + &__toolbox-item {
    margin-left: 24px;
  }

  &__selected {
    font-size: 14px;
    line-height: 18px;
    color: $grey-dark-01;
  }

  &__notifications {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    color: $additional-brown;
    transition: color 0.1s linear;
  }

  &__notifications:hover {
    color: $accent-light;
  }

  &__notifications span {
    font-size: 0;
  }

  &__notifications .icon {
    font-size: 22px;
  }

  &__notifications.has-notifications:before {
    content: attr(data-count);
    position: absolute;
    top: -4px;
    right: -4px;
    min-width: 14px;
    height: 18px;
    line-height: 18px;
    font-size: 12px;
    font-family: $font-semibold;
    border-radius: 13px;
    padding: 0 2px;
    background-color: $accent-normal;
    color: $white;
    border: 2px solid $grey-light-02;
    text-align: center;
  }

  @media screen and (min-width: $screen-md-min) {
    display: none;
  }
}
</style>
