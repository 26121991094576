<template>
  <div class="onboarding-header">
    <div class="onboarding-header__title">
      <slot name="title" />
    </div>

    <div
      v-if="hasIntro"
      class="onboarding-header__intro"
    >
      <slot name="intro" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Title",

  computed: {
    hasIntro() {
      return !!this.$slots.intro;
    }
  }
};
</script>

<style scoped lang="scss">
.onboarding-header {
  &__title {
    font-family: $font-bold;
    font-size: 26px;
    line-height: 1.15;
    text-align: center;
  }

  &__intro {
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    margin-top: 8px;
    color: $grey-dark-01;
  }

  @media screen and (min-width: $screen-lg-min) {
    &__title {
      font-size: 28px;
      line-height: 1.14;
      text-align: left;
    }

    &__intro {
      text-align: left;
    }
  }
}
</style>
