var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"payment-doc-upload"},[_c('transition-group',{attrs:{"name":"file"}},_vm._l((_vm.files),function(file){return _c('div',{key:file.uid,staticClass:"payment-doc-item"},[_c('div',{staticClass:"payment-doc-item__row"},[_c('div',{staticClass:"payment-doc-item__type"},[_c('FormSelect',{attrs:{"label":"Document type","el-style":"input-inverse","value":file.docType,"options":[
              { value: '1', title: 'Invoice' },
              { value: '2', title: 'Agreement' },
              { value: '3', title: 'Certificate of completed work' },
              { value: '4', title: 'Transportation document' },
              { value: '5', title: 'Customs/Tax declaration' } ]},on:{"change":function($event){file.docType = $event}}})],1),_c('div',{staticClass:"payment-doc-item__file"},[_c('span',{staticClass:"payment-doc-item__name"},[_vm._v(_vm._s(file.file.name .split(".") .slice(0, -1) .join(".")))]),_c('span',{staticClass:"payment-doc-item__ext"},[_vm._v("."+_vm._s(file.file.name.split(".").pop()))]),_c('button',{staticClass:"payment-doc-item__remove",on:{"click":function($event){return _vm.handleRemove(file.uid)}}},[_vm._v(" Remove ")])])]),_c('FormInput',{staticClass:"mt-lg-40 mt-sm-32",attrs:{"type":"text","el-style":"inverse","label":"Note","value":file.note},on:{"input":function($event){file.note = $event}}})],1)}),0),_c('div',{staticClass:"payment-doc-dropzone",class:{
      'has-dragover': _vm.hasDragover,
      'has-error': _vm.hasError
    },on:{"dragover":_vm.handleDragover,"dragenter":_vm.handleDragover,"dragleave":_vm.handleDragend,"dragend":_vm.handleDragend,"drop":_vm.handleDrop}},[_c('label',{staticClass:"payment-doc-dropzone__label"},[(!_vm.hasError)?[_vm._m(0),_vm._m(1)]:_vm._e(),(_vm.hasError)?_c('span',{staticClass:"payment-doc-dropzone__placeholder"},[_vm._v("Something went wrong. "),_c('span',{staticClass:"text-link"},[_vm._v("Try again")])]):_vm._e(),_c('input',{ref:"input",attrs:{"id":_vm.id,"name":_vm.name,"type":"file","multiple":""},on:{"change":_vm.handleChange}})],2)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"payment-doc-dropzone__placeholder payment-doc-dropzone__placeholder--desktop"},[_vm._v("Drop your file here or "),_c('span',{staticClass:"text-link"},[_vm._v("Choose")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"payment-doc-dropzone__placeholder payment-doc-dropzone__placeholder--mob"},[_c('span',{staticClass:"text-link"},[_vm._v("Choose file")])])}]

export { render, staticRenderFns }