<template>
  <div class="sidebar-banner">
    <hooper
      ref="hooper"
      :settings="hooperSettings"
    >
      <slide
        v-for="(slide, i) in slides"
        :key="i"
      >
        <div
          class="sidebar-banner__slide"
          :class="`sidebar-banner__slide--${slide.style}`"
        >
          <div
            class="sidebar-banner__img"
            :style="{
              backgroundImage:
                'url(' + require(`@/assets/images/${slide.img}`) + ')'
            }"
          />
          <div class="sidebar-banner__inner">
            <div class="sidebar-banner__title">
              {{ slide.title }}
            </div>
            <div class="sidebar-banner__subtitle">
              {{ slide.subtitle }}
            </div>
            <div class="sidebar-banner__cta">
              <Button
                :to="slide.link"
                :label="slide.linkLabel"
                :btn-style="buttonStyle(slide.style)"
                btn-width="168px"
              />
            </div>
          </div>
        </div>
      </slide>

      <hooper-pagination slot="hooper-addons" />
    </hooper>
  </div>
</template>

<script>
import { Hooper, Slide, Pagination as HooperPagination } from "hooper";
import "hooper/dist/hooper.css";
import Button from "@/components/Button";

export default {
  name: "SidebarBanner",

  components: {
    Hooper,
    Slide,
    HooperPagination,
    Button
  },

  props: {
    slides: { type: Array, required: false, default: null }
  },

  data() {
    return {
      hooperSettings: {
        itemsToShow: 1,
        centerMode: true,
        autoPlay: false
      }
    };
  },

  methods: {
    buttonStyle(style) {
      let btnStyle;

      switch (style) {
        case "white":
          btnStyle = "grey-light-opacity";
          break;
        default:
          btnStyle = "grey-light";
      }

      return btnStyle;
    }
  }
};
</script>

<style scoped lang="scss">
.sidebar-banner {
  position: relative;
  width: 100%;
  margin-bottom: 32px;

  &__slide {
    position: relative;
    border-radius: 8px;
    height: 304px;
    padding: 32px 24px;
    overflow: hidden;
  }

  &__slide--dark {
    background-color: $additional-brown;
    color: $white;
  }

  &__slide--white {
    background-color: $white;
  }

  &__img {
    position: absolute;
    left: 206px;
    right: 0;
    top: 0;
    bottom: 20px;
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: cover;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 170px;
  }

  &__title {
    font-family: $font-bold;
    font-size: 20px;
    line-height: 24px;
  }

  &__subtitle {
    font-size: 16px;
    line-height: 20px;
    margin-top: 16px;
    opacity: 0.6;
  }

  &__cta {
    margin-top: auto;
  }
}
</style>

<style lang="scss">
.sidebar-banner {
  & .hooper {
    height: auto;
  }

  & .hooper:focus {
    outline: none;
  }

  & .hooper-pagination {
    bottom: -32px;
    right: 0;
    padding: 0;
    transform: none;
    height: 32px;
  }

  & .hooper-indicator {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: rgba($grey-dark-01, 0.3);
  }

  & .hooper-indicator.is-active {
    background-color: $additional-brown;
  }
}
</style>
