<template>
  <div class="settings-nav">
    <div class="settings-nav-mob">
      <Dropdown>
        <template slot="toggle">
          <button class="settings-nav-mob__toggle">
            <i
              class="icon"
              :class="`icon-${selected.icon}`"
            />
            {{ selected.label }}
          </button>
        </template>

        <template slot="menu">
          <ul class="settings-nav-mob__list">
            <li
              v-for="(section, i) in sections"
              :key="i"
            >
              <button
                class="settings-nav-mob__item"
                :class="{ 'is-selected': section.label === current }"
                @click="handleSelect(section.label)"
              >
                <i
                  class="icon"
                  :class="`icon-${section.icon}`"
                />
                {{ section.label }}
              </button>
            </li>
          </ul>
        </template>
      </Dropdown>
    </div>

    <div class="settings-nav-desktop">
      <ul class="settings-nav-desktop__list">
        <li
          v-for="(section, i) in sections"
          :key="i"
        >
          <button
            class="settings-nav-desktop__item"
            :class="{ 'is-selected': section.label === current }"
            @click="handleSelect(section.label)"
          >
            <i
              class="icon"
              :class="`icon-${section.icon}`"
            />
            {{ section.label }}
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Dropdown from "@/components/Dropdown";

export default {
  name: "SettingsNav",

  components: {
    Dropdown
  },

  props: {
    current: { type: String, required: false, default: "" }
  },

  data() {
    return {
      sections: [
        { icon: "profile", label: "Profile settings" },
        { icon: "lock", label: "Login and password" },
        { icon: "notifiations", label: "Notifications" },
        { icon: "limits-v", label: "My limits" },
        { icon: "accounts", label: "Services and tariffs" },
        { icon: "external-access", label: "External Access" }
      ]
    };
  },

  computed: {
    selected() {
      const selectedSection = this.sections.find(item => {
        return item.label === this.current;
      });

      return selectedSection;
    }
  },

  methods: {
    handleSelect(label) {
      this.$emit("select", label);
    }
  }
};
</script>

<style scoped lang="scss">
.settings-nav-mob {
  & .dropdown {
    width: 100%;
  }

  &__toggle {
    display: flex;
    align-items: center;
    background-color: $white;
    border-radius: 8px;
    padding: 12px 16px;
    color: $additional-brown;
    width: 100%;
    font-family: $font-semibold;
    font-size: 16px;
    line-height: 24px;
  }

  &__toggle:after {
    content: "\e911";
    @include inline-icon;
    margin-left: auto;
    font-size: 24px;
  }

  &__toggle .icon {
    font-size: 24px;
    width: 24px;
    margin-right: 8px;
    text-align: center;
  }

  & .dropdown.is-open &__toggle:after {
    content: "\e912";
  }

  &__list li + li {
    border-top: 1px solid $grey-light-01;
  }

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    padding: 16px 32px 16px 16px;
    white-space: nowrap;
    font-size: 14px;
    width: 100%;
    color: $additional-brown;
    transition: background-color 0.1s linear;
  }

  &__item .icon {
    font-size: 16px;
    margin-right: 8px;
  }

  &__item.is-selected:after {
    content: "\e919";
    @include inline-icon;
    position: absolute;
    right: 16px;
    top: 16px;
    font-size: 16px;
    color: $accent-normal;
  }

  @media (hover: hover) {
    &__item:hover {
      background-color: $grey-light-02;
    }
  }

  @media screen and (min-width: $screen-xlg-min) {
    display: none;
  }
}

.settings-nav-desktop {
  background-color: $white;
  border-radius: 8px;
  padding: 16px 24px;

  &__list li {
    padding: 16px 0;
  }

  &__item {
    display: flex;
    align-items: center;
    font-family: $font-semibold;
    font-size: 16px;
    line-height: 24px;
    color: $additional-brown;
    transition: color 0.1s linear;
  }

  &__item.is-selected {
    color: $accent-normal;
  }

  &__item .icon {
    font-size: 24px;
    width: 24px;
    margin-right: 8px;
    text-align: center;
  }

  @media (hover: hover) {
    &__item:hover {
      color: $accent-light;
    }
  }

  @media screen and (max-width: $screen-lg-max) {
    display: none;
  }
}
</style>
