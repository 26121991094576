<template>
  <DashboardLayout>
    <template slot="sidebar">
      <SettingsNav
        :current="currentSection"
        class="mb-16"
        @select="currentSection = $event"
      />
    </template>

    <template>
      <SettingsWrapper
        title="Settings"
        class="mb-lg-32 mb-sm-24"
      >
        <FormSection title="General">
          <div class="settings-form-column settings-form-column--general">
            <FormInput
              id="email"
              name="email"
              type="email"
              placeholder="example@gmail.com"
              label="Email"
              el-width="100%"
              :value="general.email"
              :error="general.emailError"
              @input="general.email = $event"
            />

            <div class="settings-form-column__row mt-lg-48 mt-sm-32">
              <div class="settings-form-column__row-item">
                <FormSelect
                  id="default_currency"
                  name="default-currency"
                  label="Default Currency"
                  :value="general.currency"
                  :options="['USD', 'EUR', 'CAD']"
                  @change="general.currency = $event"
                />
              </div>

              <div class="settings-form-column__row-item">
                <FormSelect
                  id="time_zone"
                  name="time-zone"
                  label="Time zone"
                  :value="general.timezone"
                  :options="['GMT 6', 'GMT 7', 'GMT 8', 'GMT 9']"
                  @change="general.timezone = $event"
                />
              </div>
            </div>
          </div>
        </FormSection>

        <FormSection
          title="Notifications"
          class="mt-lg-48 mt-sm-32"
        >
          <div class="settings-form-column">
            <div class="mt-sm-16">
              <FormCheckbox
                id="product_info"
                name="product-info"
                :value="notifications.productInfo"
                @change="notifications.productInfo = $event"
              >
                <span class="text-sm">New & Product information</span>
              </FormCheckbox>
            </div>

            <div class="mt-lg-16 mt-sm-12">
              <FormCheckbox
                id="transactions"
                name="transactions"
                :value="notifications.transactions"
                @change="notifications.transactions = $event"
              >
                <span class="text-sm">Transaction notifications</span>
              </FormCheckbox>
            </div>

            <div class="mt-lg-16 mt-sm-12">
              <FormCheckbox
                id="payment"
                name="payment"
                :value="notifications.payment"
                @change="notifications.payment = $event"
              >
                <span class="text-sm">Payment received</span>
              </FormCheckbox>
            </div>

            <div class="mt-lg-16 mt-sm-12">
              <FormCheckbox
                id="card_expire"
                name="card-expire"
                :value="notifications.cardExpire"
                @change="notifications.cardExpire = $event"
              >
                <span class="text-sm">Сard expiration</span>
              </FormCheckbox>
            </div>
          </div>
        </FormSection>

        <FormSection
          title="Security"
          class="mt-lg-48 mt-sm-32"
        >
          <div class="settings-form-column settings-form-column--security">
            <ToggleBox>
              <template slot="label">
                <div>
                  Two-factor authentifications
                </div>

                <div class="text-dscr text-grey mt-4">
                  Phone: {{ twoFactor.phone }}
                </div>
              </template>

              <template slot="control">
                <Toggle
                  id="two_factor"
                  name="two-factor"
                  :value="twoFactor.isOn"
                  @change="twoFactor.isOn = $event"
                />
              </template>
            </ToggleBox>

            <div
              class="settings-form__actions arrange--between mt-lg-20 mt-sm-12"
            >
              <div class="settings-form__actions-item">
                <ButtonLink
                  icon="icon-lock-alt"
                  label="Change password"
                  btn-size="sm"
                />
              </div>

              <div class="settings-form__actions-item">
                <ButtonLink
                  icon="icon-close"
                  label="Delete account"
                  btn-style="red"
                  btn-size="sm"
                />
              </div>
            </div>
          </div>
        </FormSection>

        <FormSection class="mt-lg-40 mt-sm-24">
          <div class="settings-form-column">
            <div class="settings-form__actions">
              <div class="settings-form__actions-item">
                <Button
                  label="Save changes"
                  btn-style="primary"
                />
              </div>

              <div class="settings-form__actions-item">
                <Button
                  label="Cancel"
                  btn-style="grey-light-opacity"
                />
              </div>
            </div>
          </div>
        </FormSection>
      </SettingsWrapper>
    </template>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "@/components/Layout/DashboardLayout";
import SettingsNav from "@/components/Blocks/SettingsNav";
import SettingsWrapper from "@/components/Blocks/SettingsWrapper";
import FormSection from "@/components/Form/FormSection";
import FormInput from "@/components/Form/FormInput";
import FormSelect from "@/components/Form/FormSelect";
import FormCheckbox from "@/components/Form/FormCheckbox";
import ToggleBox from "@/components/ToggleBox";
import Toggle from "@/components/Toggle";
import ButtonLink from "@/components/ButtonLink";
import Button from "@/components/Button";

export default {
  name: "Settings",

  components: {
    DashboardLayout,
    SettingsNav,
    SettingsWrapper,
    FormSection,
    FormInput,
    FormSelect,
    FormCheckbox,
    ToggleBox,
    Toggle,
    ButtonLink,
    Button
  },

  data() {
    return {
      currentSection: "Profile settings",

      general: {
        email: "johnd@gmail.com",
        emailError: "",
        currency: "USD",
        timezone: "GMT 8"
      },

      notifications: {
        productInfo: false,
        transactions: true,
        payment: true,
        cardExpire: true
      },

      twoFactor: {
        phone: "+7 *** *** ** 85",
        isOn: true
      }
    };
  }
};
</script>

<style scoped lang="scss">
.settings-form {
  &__actions {
    display: flex;
    flex-wrap: wrap;
    margin-left: -8px;
    margin-right: -8px;
  }

  &__actions-item {
    padding: 4px 8px;
  }

  @media screen and (min-width: $screen-lg-min) {
    &__actions-item {
      padding: 0 12px;
    }
  }
}

.settings-form-column {
  &__row {
    display: flex;
    margin-left: -8px;
    margin-right: -8px;
  }

  &__row-item {
    width: 50%;
    padding: 0 8px;
  }

  @media screen and (max-width: $screen-md-max) {
    &--general {
      padding-top: 32px;
    }

    &--security {
      padding-top: 16px;
    }
  }

  @media screen and (min-width: $screen-lg-min) {
    width: 514px;

    &__row {
      margin-left: -16px;
      margin-right: -16px;
    }

    &__row-item {
      padding: 0 16px;
    }
  }
}
</style>
