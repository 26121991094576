<template>
  <div class="dashboard-main-split">
    <div class="dashboard-main-split-tabs">
      <button
        class="dashboard-main-split-tabs__item"
        :class="{ 'is-selected': currentTab === 'accounts' }"
        @click="currentTab = 'accounts'"
      >
        Accounts
      </button>
      <button
        class="dashboard-main-split-tabs__item"
        :class="{ 'is-selected': currentTab === 'cards' }"
        @click="currentTab = 'cards'"
      >
        Cards
      </button>
      <button
        class="dashboard-main-split-tabs__item"
        :class="{ 'is-selected': currentTab === 'history' }"
        @click="currentTab = 'history'"
      >
        History
      </button>
    </div>

    <div class="dashboard-main-split__main">
      <div
        class="dashboard-main-split__main-item dashboard-main-split__tab-content"
        :class="{ 'is-hidden': currentTab !== 'cards' }"
      >
        <slot name="cards" />
      </div>

      <div
        class="dashboard-main-split__main-item dashboard-main-split__tab-content"
        :class="{ 'is-hidden': currentTab !== 'accounts' }"
      >
        <slot name="accounts" />
      </div>
    </div>

    <aside class="dashboard-main-split__aside">
      <div class="dashboard-main-split__aside-banner">
        <slot name="banner" />
      </div>

      <div
        class="dashboard-main-split__aside-content dashboard-main-split__tab-content"
        :class="{ 'is-hidden': currentTab !== 'history' }"
      >
        <slot name="transactions" />
      </div>
    </aside>
  </div>
</template>

<script>
export default {
  name: "DashboardMainScreen",

  data() {
    return {
      currentTab: "accounts"
    };
  }
};
</script>

<style scoped lang="scss">
.dashboard-main-split {
  display: flex;
  flex-wrap: wrap;
  margin: auto -16px;

  &__main,
  &__aside {
    width: 100%;
    padding: 0 16px;
  }

  @media screen and (max-width: $screen-sm-max) {
    &__aside-banner {
      display: none;
    }

    &__tab-content.is-hidden {
      opacity: 0;
      max-height: 0;
      overflow: hidden;
    }
  }

  @media screen and (min-width: $screen-md-min) and (max-width: $screen-lg-max) {
    &__aside {
      display: flex;
      padding: 0 4px;
    }

    &__aside-banner {
      order: 2;
    }

    &__aside-content {
      order: 1;
    }

    &__aside-banner,
    &__aside-content {
      width: 50%;
      padding: 0 12px;
    }
  }

  @media screen and (min-width: $screen-xlg-min) {
    &__main {
      flex: 1;
    }

    &__aside {
      width: 436px;
    }
  }
}

.dashboard-main-split-tabs {
  display: flex;
  width: 100%;
  margin: 8px 16px 16px 16px;

  &__item {
    flex: 1;
    font-family: $font-semibold;
    font-size: 14px;
    line-height: 38px;
    text-align: center;
    white-space: nowrap;
    color: $additional-brown;
    border: 1px solid rgba($additional-brown, 0.04);
    transition: color 0.1s linear, background-color 0.1s linear, border-color 0.1s linear;
  }

  &__item + &__item {
    border-left: none;
  }

  &__item:first-child {
    border-radius: 4px 0 0 4px;
  }

  &__item:last-child {
    border-radius: 0 4px 4px 0;
  }

  &__item.is-selected {
    background-color: $grey-light-01;
    color: rgba($grey-dark, 0.5);
    border-color: transparent;
  }

  @media screen and (min-width: $screen-md-min) {
    display: none;
  }
}
</style>
