<template>
  <ul class="messages-tabs">
    <li>
      <button
        class="messages-tabs__item"
        :class="{ 'is-selected': currentTab === 'Inbox' }"
        @click="handleSelect('Inbox')"
      >
        <i class="icon icon-inbox" /><span>Inbox</span><span class="messages-tabs__count">2</span>
      </button>
    </li>

    <li>
      <button
        class="messages-tabs__item"
        :class="{ 'is-selected': currentTab === 'Sent' }"
        @click="handleSelect('Sent')"
      >
        <i class="icon icon-outbox" /><span>Sent</span>
      </button>
    </li>

    <li>
      <button
        class="messages-tabs__item"
        :class="{ 'is-selected': currentTab === 'Deleted' }"
        @click="handleSelect('Deleted')"
      >
        <i class="icon icon-trash-m" /><span>Deleted</span>
      </button>
    </li>
  </ul>
</template>

<script>
export default {
  name: "MessagingTabs",

  props: {
    currentTab: { type: String, required: false, default: "" }
  },

  methods: {
    handleSelect(tab) {
      this.$emit("select", tab);
    }
  }
};
</script>

<style scoped lang="scss">
.messages-tabs {
  @media screen and (max-width: $screen-sm-max) {
    display: flex;
    width: 100%;
    border: 1px solid rgba($additional-brown, 0.04);
    border-radius: 3px;

    & li {
      flex: 1;
    }

    & li + li {
      border-left: 1px solid rgba($additional-brown, 0.04);
    }

    &__item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 38px;
      padding: 0 8px;
      font-family: $font-semibold;
      font-size: 14px;
      line-height: 24px;
      color: $additional-brown;
      transition: background-color 0.1s linear;
    }

    &__item.is-selected {
      background-color: $grey-light-01;
      color: rgba($grey-dark, 0.5);
      border-color: transparent;
    }

    &__item .icon {
      display: none;
    }

    &__count {
      min-width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: $white;
      color: $grey-dark-01;
      font-size: 12px;
      line-height: 24px;
      margin: 0 8px;
    }
  }

  @media screen and (min-width: $screen-md-min) {
    background-color: $white;
    border-radius: 8px;
    padding: 8px 24px;
    margin-bottom: 24px;

    &__item {
      display: flex;
      width: 100%;
      align-items: center;
      color: $additional-brown;
      padding: 16px 0;
      font-family: $font-semibold;
      font-size: 16px;
      line-height: 24px;
      transition: color 0.1s linear;
    }

    &__item.is-selected {
      color: $accent-normal;
    }

    &__item .icon {
      width: 24px;
      font-size: 24px;
      text-align: center;
      margin-right: 8px;
    }

    &__count {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: $grey-light-01;
      color: $grey-dark-01;
      font-family: $font-semibold;
      font-size: 12px;
      line-height: 24px;
      margin-left: auto;
    }

    @media (hover: hover) {
      &__item:hover {
        color: $accent-light;
      }
    }
  }
}
</style>
