<template>
  <OnboardingBusinessLayout
    :current-step="currentStep"
    :side-msg="sideMsg"
  >
    <Header>
      <template slot="title">
        <div class="text-center">
          Email Verification
        </div>
      </template>
    </Header>

    <div class="mt-lg-32 mt-sm-16 arrange arrange--center">
      <img
        :src="require('@/assets/images/email-verification.svg')"
        alt="illustration"
      >
    </div>

    <div class="mt-lg-32 mt-sm-24">
      <h5 class="text-center text-sb">
        Thank you!
      </h5>

      <p class="text-center text-grey mt-4">
        We have sent you a confirmation link to
        <a
          class="text-blue text-link"
          href="#"
        >
          xxx@xxx.com</a>.
        Please click on the link and finish the application form.
      </p>
    </div>
  </OnboardingBusinessLayout>
</template>

<script>
import OnboardingBusinessLayout from "@/components/Layout/OnboardingBusinessLayout";
import Header from "@/components/Onboarding/Header";

export default {
  components: {
    OnboardingBusinessLayout,
    Header
  },

  data() {
    return {
      currentStep: 2,

      sideMsg:
        "Some short tooltip. Why do we need to know this info about user"
    };
  }
};
</script>
