<template>
  <div class="savings-placeholder text-center">
    <h1 class="h1-bold text-center">
      Saving accounts
    </h1>

    <img
      :src="require('@/assets/images/piggy_bank.svg')"
      alt="illustration"
      class="savings-placeholder__illustration mt-lg-24 mt-sm-20"
    >

    <div class="text-center text-sb mt-lg-32 mt-sm-16">
      You don't have any saving account
    </div>

    <div class="mt-lg-32 mt-sm-24">
      <Button
        label="Add saving account"
        btn-style="primary"
        btn-width="100%"
        :click-handler="handleNewAccount"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import Button from "@/components/Button";

export default {
  name: "SavingsPlaceholder",

  components: {
    Button
  },

  methods: {
    ...mapActions(["showModal"]),

    handleNewAccount() {
      this.showModal({
        component: "NewSavingsAccountModal"
      });
    }
  }
};
</script>

<style scoped lang="scss">
.savings-placeholder {
  background-color: $white;
  margin: 0 auto;
  width: 100%;
  padding: 24px;
  border-radius: 8px;

  &__illustration {
    width: 164px;
    height: 164px;
  }

  @media screen and (min-width: $screen-md-min) {
    margin: 100px auto;
    width: 514px;
    padding: 32px;

    &__illustration {
      width: 200px;
      height: 200px;
    }
  }
}
</style>
