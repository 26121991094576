import Vue from "vue";
import Router from "vue-router";

import Index from "@/components/views/Index.vue";
import Home from "@/components/views/Home.vue";
import Ui from "@/components/views/Ui.vue";
import Modals from "@/components/views/Modals.vue";
import Login from "@/components/views/Login.vue";
import LoginTwoFactor from "@/components/views/LoginTwoFactor.vue";
import Dashboard from "@/components/views/Dashboard";
import DashboardAlt from "@/components/views/DashboardAlt";
import Statement from "@/components/views/Statement";
import Messages from "@/components/views/Messages";
import Notifications from "@/components/views/Notifications";
import Savings from "@/components/views/Savings";
import SavingsEmpty from "@/components/views/SavingsEmpty";
import Settings from "@/components/views/Settings";
import SettingsExternalAccess from "@/components/views/SettingsExternalAccess";

import OnboardingSignup from "@/components/views/Onboarding/Signup";
import OnboardingPassword from "@/components/views/Onboarding/Password";
import OnboardingInfo from "@/components/views/Onboarding/Info";
import OnboardingBill from "@/components/views/Onboarding/Bill";
import OnboardingProfile from "@/components/views/Onboarding/Profile";
import OnboardingVerification from "@/components/views/Onboarding/Verification";
import OnboardingSuccess from "@/components/views/Onboarding/Success";

import OnboardingBusinessSignup from "@/components/views/OnboardingBusiness/Signup";
import OnboardingBusinessEmailVerification from "@/components/views/OnboardingBusiness/EmailVerification";
import OnboardingBusinessPassword from "@/components/views/OnboardingBusiness/Password";
import OnboardingBusinessPasswordRecovery from "@/components/views/OnboardingBusiness/PasswordRecovery";
import OnboardingBusinessInfo from "@/components/views/OnboardingBusiness/Info";
import OnboardingBusinessBill from "@/components/views/OnboardingBusiness/Bill";
import OnboardingBusinessProfile from "@/components/views/OnboardingBusiness/Profile";
import OnboardingBusinessRepresentative from "@/components/views/OnboardingBusiness/Representative";
import OnboardingBusinessOwners from "@/components/views/OnboardingBusiness/Owners";
import OnboardingBusinessDocuments from "@/components/views/OnboardingBusiness/Documents";
import OnboardingBusinessInfoBusiness from "@/components/views/OnboardingBusiness/InfoBusiness";
import OnboardingBusinessVerification from "@/components/views/OnboardingBusiness/Verification";
import OnboardingBusinessSuccess from "@/components/views/OnboardingBusiness/Success";

import AccessRightsLogin from "@/components/views/AccessRights/Login";
import AccessRightsTwoFactorAuth from "@/components/views/AccessRights/TwoFactorAuth";
import AccessRightsDetails from "@/components/views/AccessRights/Details";
import AccessRightsConfirmation from "@/components/views/AccessRights/Confirmation";

Vue.use(Router);

export default new Router({
  mode: "history",

  routes: [
    {
      path: "/",
      name: "index",
      component: Index
    },
    {
      path: "/ui",
      name: "uikit",
      component: Ui
    },
    {
      path: "/modals",
      name: "modals",
      component: Modals
    },
    {
      path: "/homepage",
      name: "home",
      component: Home
    },
    {
      path: "/login",
      name: "login",
      component: Login
    },
    {
      path: "/login-two-factor",
      name: "login-two-factor",
      component: LoginTwoFactor
    },
    {
      path: "/dashboard",
      name: "dashboard",
      component: Dashboard
    },
    {
      path: "/dashboard-alt",
      name: "dashboard-alt",
      component: DashboardAlt
    },
    {
      path: "/statement",
      name: "statement",
      component: Statement
    },
    {
      path: "/onboarding/signup",
      name: "onboarding-signup",
      component: OnboardingSignup
    },
    {
      path: "/onboarding/password",
      name: "onboarding-password",
      component: OnboardingPassword
    },
    {
      path: "/onboarding/info",
      name: "onboarding-info",
      component: OnboardingInfo
    },
    {
      path: "/onboarding/bill",
      name: "onboarding-bill",
      component: OnboardingBill
    },
    {
      path: "/onboarding/profile",
      name: "onboarding-profile",
      component: OnboardingProfile
    },
    {
      path: "/onboarding/verification",
      name: "onboarding-verification",
      component: OnboardingVerification
    },
    {
      path: "/onboarding/success",
      name: "onboarding-success",
      component: OnboardingSuccess
    },
    {
      path: "/messages",
      name: "messages",
      component: Messages
    },
    {
      path: "/notifications",
      name: "notifications",
      component: Notifications
    },
    {
      path: "/savings",
      name: "savings",
      component: Savings
    },
    {
      path: "/savings-empty",
      name: "savings-empty",
      component: SavingsEmpty
    },
    {
      path: "/settings",
      name: "settings",
      component: Settings
    },
    {
      path: "/settings-external-access",
      name: "settings-external-access",
      component: SettingsExternalAccess
    },
    {
      path: "/onboarding-business/signup",
      name: "onboarding-business-signup",
      component: OnboardingBusinessSignup
    },
    {
      path: "/onboarding-business/email-verification",
      name: "onboarding-business-email-verification",
      component: OnboardingBusinessEmailVerification
    },
    {
      path: "/onboarding-business/password",
      name: "onboarding-business-password",
      component: OnboardingBusinessPassword
    },
    {
      path: "/onboarding-business/password-recovery",
      name: "onboarding-business-password-recovery",
      component: OnboardingBusinessPasswordRecovery
    },
    {
      path: "/onboarding-business/info",
      name: "onboarding-business-info",
      component: OnboardingBusinessInfo
    },
    {
      path: "/onboarding-business/bill",
      name: "onboarding-business-bill",
      component: OnboardingBusinessBill
    },
    {
      path: "/onboarding-business/profile",
      name: "onboarding-business-profile",
      component: OnboardingBusinessProfile
    },
    {
      path: "/onboarding-business/representative",
      name: "onboarding-business-representative",
      component: OnboardingBusinessRepresentative
    },
    {
      path: "/onboarding-business/owners",
      name: "onboarding-business-owners",
      component: OnboardingBusinessOwners
    },
    {
      path: "/onboarding-business/documents",
      name: "onboarding-business-documents",
      component: OnboardingBusinessDocuments
    },
    {
      path: "/onboarding-business/info-business",
      name: "onboarding-business-info-business",
      component: OnboardingBusinessInfoBusiness
    },
    {
      path: "/onboarding-business/verification",
      name: "onboarding-business-verification",
      component: OnboardingBusinessVerification
    },
    {
      path: "/onboarding-business/success",
      name: "onboarding-business-success",
      component: OnboardingBusinessSuccess
    },
    {
      path: "/access-rights/login",
      name: "access-rights-login",
      component: AccessRightsLogin
    },
    {
      path: "/access-rights/two-factor-auth",
      name: "access-rights-two-factor-auth",
      component: AccessRightsTwoFactorAuth
    },
    {
      path: "/access-rights/details",
      name: "access-rights-details",
      component: AccessRightsDetails
    },
    {
      path: "/access-rights/confirmation",
      name: "access-rights-confirmation",
      component: AccessRightsConfirmation
    },
  ]
});
