<template>
  <div class="toggle-box">
    <div class="toggle-box__label">
      <slot name="label" />
    </div>

    <div class="toggle-box__control">
      <slot name="control" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ToggleBox"
};
</script>

<style scoped lang="scss">
.toggle-box {
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 16px;
  border: 1px solid $grey-light-01;

  &__label {
    max-width: 240px;
    padding-right: 16px;
  }

  &__control {
    margin-left: auto;
  }
}
</style>
