import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import titleMixin from "./mixins/titleMixin";
import bodyMixin from "./mixins/bodyMixin";

import VCalendar from "v-calendar";

import { VueMaskDirective } from "v-mask";

import { store } from "@/store/store";

Vue.mixin(titleMixin);
Vue.mixin(bodyMixin);

Vue.use(VCalendar, {
  firstDayOfWeek: 2
});

Vue.directive("mask", function(el, binding) {
  if (!binding.value) return;

  VueMaskDirective.bind(el, binding);
});

Vue.config.productionTip = false;

Vue.filter("moneyformat", value => {
  return value
    ? Number(value).toLocaleString(undefined, {
        minimumFractionDigits: 2
      })
    : 0.0;
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
