<template>
  <div
    class="notification-msg"
    :class="[`notification-msg--${notification.type}`]"
  >
    <i class="icon" />

    <div class="notification-msg__body">
      <div class="notification-msg__title">
        {{ notification.title }}
      </div>
      <div class="notification-msg__text">
        {{ notification.message }}
      </div>

      <div
        v-if="notification.confirmAction || notification.cancelAction"
        class="notification-msg__actions"
      >
        <button
          v-if="notification.confirmAction"
          class="notification-msg__actions-item notification-msg__confirm"
          @click="notification.confirmAction"
        >
          {{ notification.confirmLabel || "Accept" }}
        </button>

        <button
          v-if="notification.cancelAction"
          class="notification-msg__actions-item notification-msg__cancel"
          @click="notification.cancelAction"
        >
          {{ notification.cancelLabel || "Cancel" }}
        </button>
      </div>
    </div>

    <button
      class="notification-msg__close"
      @click="removePushNotification(notification.id)"
    >
      Close
    </button>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "NotificationPushCard",

  props: {
    notification: { type: Object, required: true }
  },

  created() {
    setTimeout(() => this.removePushNotification(this.notification.id), 6000);
  },

  methods: {
    ...mapActions(["removePushNotification"])
  }
};
</script>

<style scoped lang="scss">
.notification-msg {
  position: relative;
  width: 424px;
  max-width: 100%;
  background-color: $white;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08), 0px 8px 12px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  overflow: hidden;
  padding: 16px 34px 16px 16px;
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  &:first-child {
    margin-top: 62px;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
  }

  &--success:before {
    background-color: $green;
  }

  &--info:before {
    background-color: $additional-brown;
  }

  &--warning:before {
    background-color: $accent-light;
  }

  &--danger:before {
    background-color: $red;
  }

  & .icon {
    display: inline-flex;
    width: 24px;
    height: 24px;
    color: $white;
    margin-right: 22px;
  }

  & .icon:before {
    font-size: 24px;
    line-height: 24px;
    @include inline-icon;
  }

  &--success .icon:before {
    content: "\e944";
    color: $green;
  }

  &--info .icon:before {
    content: "\e942";
    color: $additional-brown;
  }

  &--warning .icon:before {
    content: "\e945";
    color: $accent-light;
  }

  &--danger .icon:before {
    content: "\e943";
    color: $red;
  }

  &__title {
    font-family: $font-semibold;
    font-size: 16px;
    line-height: 20px;
    color: $grey-dark;
  }

  &__text {
    font-size: 16px;
    line-height: 20px;
    margin-top: 6px;
    color: $grey-dark-01;
  }

  &__actions {
    margin: 10px -8px -6px -8px;
  }

  &__actions-item {
    display: inline-block;
    padding: 0 16px;
    line-height: 32px;
    border-radius: 4px;
    font-size: 14px;
    margin: 6px 8px;
    transition: background-color 0.1s linear;
  }

  &__confirm {
    background-color: $accent-normal;
    color: $white;
  }

  &__cancel {
    background-color: $grey-light-01;
    color: $additional-brown;
  }

  &__close {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 24px;
    height: 24px;
    font-size: 0;
    color: $grey-dark-02;
    transition: opacity 0.1s linear;
  }

  &__close:before {
    content: "\e934";
    @include inline-icon;
    font-size: 16px;
  }

  @media (hover: hover) {
    &__confirm:hover {
      background-color: $accent-light;
    }

    &__cancel:hover {
      background-color: rgba($additional-brown, 0.1);
    }

    &__close:hover {
      opacity: 0.6;
    }
  }

  @media screen and (min-width: $screen-md-min) {
    padding: 24px 34px 24px 24px;
  }
}
</style>
