<template>
  <div class="modal-table-row">
    <div class="modal-table-row__title">
      <slot name="title" />
    </div>

    <div class="modal-table-row__value">
      <slot name="value" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalTableRow"
};
</script>

<style scoped lang="scss">
.modal-table-row {
  padding: 16px 0;
  border-bottom: 1px solid $grey-light-01;

  &__title,
  &__value {
    font-size: 14px;
    line-height: 18px;
  }

  &__title {
    color: $grey-dark-01;
    margin-bottom: 2px;
  }

  @media screen and (max-width: $screen-sm-max) {
    &:first-child {
      border-top: 1px solid $grey-light-01;
      border-bottom: 1px solid $grey-light-01;
    }
  }

  @media screen and (min-width: $screen-md-min) {
    display: flex;
    align-items: center;

    &__title,
    &__value {
      width: 50%;
      font-size: 16px;
      line-height: 20px;
      padding-right: 62px;
    }

    &__title {
      margin-bottom: 0;
    }
  }
}
</style>
