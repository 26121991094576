<template>
  <div
    class="form-checkbox-box"
    :class="{ 'is-disabled': disabled, 'has-focus': hasFocus }"
    :style="{ width: elWidth }"
  >
    <label
      class="form-checkbox-box__wrapper"
      :class="{ 'has-description': hasDescription }"
    >
      <input
        :id="id"
        v-model="model"
        :name="name"
        type="checkbox"
        :disabled="disabled"
        :indeterminate.prop="indeterminate"
        @focus="hasFocus = true"
        @focusout="hasFocus = false"
      >

      <span class="form-checkbox-box__check" />

      <span class="form-checkbox-box__label">
        <span
          v-if="hasTitle"
          class="form-checkbox-box__title"
        >
          <slot name="title" />
        </span>

        <span
          v-if="hasComment"
          class="form-checkbox-box__comment"
        >
          <slot name="comment" />
        </span>
      </span>

      <span
        v-if="hasImg"
        class="form-checkbox-box__img"
      >
        <slot name="img" />
      </span>

      <span
        v-if="hasDescription"
        class="form-checkbox-box__description"
      >
        <slot name="description" />
      </span>
    </label>
  </div>
</template>

<script>
export default {
  name: "FormCheckboxBox",

  props: {
    id: { type: String, required: false, default: "" },
    name: { type: String, required: false, default: "" },
    value: { type: Boolean, required: false, default: false },
    indeterminate: { type: Boolean, required: false, default: false },
    label: { type: String, required: false, default: "" },
    elWidth: { type: String, required: false, default: "" },
    disabled: { type: Boolean, required: false, default: false }
  },

  data() {
    return {
      hasFocus: false
    };
  },

  computed: {
    hasTitle() {
      return !!this.$slots.title;
    },

    hasComment() {
      return !!this.$slots.comment;
    },

    hasImg() {
      return !!this.$slots.img;
    },

    hasDescription() {
      return !!this.$slots.description;
    },

    model: {
      get() {
        return this.value;
      },
      set() {
        this.$emit("change", this.label);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.form-checkbox-box {
  &__wrapper {
    display: inline-flex;
    align-items: center;
    width: 100%;
    border-radius: 8px;
    padding: 12px 16px;
    cursor: pointer;
    box-shadow: inset 0 0 0 1px $grey-light-01;
    transition: box-shadow 0.1s linear;
  }

  &__wrapper.has-description {
    align-items: flex-start;
  }

  &.is-disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &__wrapper input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }

  &__check {
    position: relative;
    display: block;
    margin-right: 10px;
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    box-shadow: inset 0 0 0 1.5px $grey-dark-02;
    border-radius: 2px;
    background-color: $white;
    color: $white;
    transition: background-color 0.1s linear, box-shadow 0.1s linear;
  }

  &__wrapper input:checked + &__check:after {
    @include inline-icon;

    content: "\e919";
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 13px;
    transform: translate(-48%, -48%);
  }

  &__wrapper input:indeterminate + &__check:after {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -1px 0 0 -3px;
    width: 6px;
    height: 2px;
    content: "";
    background-color: $white;
    border-radius: 1px;
  }

  &__wrapper:focus &__check,
  &__wrapper input:focus + &__check {
    box-shadow: inset 0 0 0 2px $additional-brown;
  }

  &__wrapper input:checked + &__check,
  &__wrapper input:indeterminate + &__check {
    background-color: $accent-normal;
    box-shadow: none;
  }

  &__title {
    display: block;
    font-size: 16px;
    line-height: 20px;
    color: $additional-brown;
  }

  .has-description &__title {
    color: $grey-dark;
    font-size: 14px;
    line-height: 18px;
  }

  &__comment {
    display: block;
    font-size: 12px;
    line-height: 16px;
    margin-top: 4px;
    color: $grey-dark-01;
  }

  &__img {
    display: block;
    width: 48px;
    height: 28px;
    padding-left: 8px;
    margin-left: auto;
  }

  &__img img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
  }

  &__description {
    display: block;
    font-size: 16px;
    line-height: 18px;
    width: 100%;
    color: $grey-dark-01;
  }

  .has-description &__description {
    font-size: 14px;
    line-height: 18px;
  }

  @media screen and (max-width: $screen-md-max) {
    &__wrapper.has-description {
      flex-wrap: wrap;
    }

    &__description {
      margin-top: 8px;
    }
  }

  @media screen and (min-width: $screen-lg-min) {
    .has-description &__title {
      min-width: 178px;
    }

    &__description {
      width: auto;
    }
  }

  @media (hover: hover) {
    &__wrapper:hover {
      box-shadow: inset 0 0 0 1px $accent-normal;
    }

    &.has-focus &__wrapper {
      box-shadow: inset 0 0 0 2px $additional-brown;
    }
  }
}
</style>
