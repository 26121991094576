<template>
  <div
    class="dashboard-nav-mob"
    :class="{ 'is-open': isOpen }"
  >
    <button
      class="dashboard-nav-mob__toggle"
      @click="handleOpen"
    >
      <i class="icon icon-burger" />
      <span>Menu</span>
    </button>

    <div class="dashboard-nav-mob__menu">
      <div class="dashboard-nav-mob__menu-inner">
        <div class="dashboard-nav-mob__top">
          <div class="dashboard-nav-mob__header">
            <img
              :src="require('@/assets/images/logo.svg')"
              alt="logo"
              class="dashboard-nav-mob__logo"
            >

            <button
              class="dashboard-nav-mob__close-btn"
              @click="handleOpen"
            >
              <i class="icon icon-close" />
              <span>Close</span>
            </button>
          </div>

          <ul class="dashboard-nav-mob__links">
            <li
              v-for="(link, i) in links"
              :key="i"
            >
              <router-link
                :to="link.url"
                class="dashboard-nav-mob__links-item"
                active-class="is-current"
              >
                <i :class="`icon ${link.icon}`" />
                <span>{{ link.title }}</span>
              </router-link>
            </li>
          </ul>
        </div>

        <div class="dashboard-nav-mob__footer">
          <div class="dashboard-nav-mob__footer-item">
            <div
              class="dashboard-nav-mob__user"
              @click="handleUserOpen"
            >
              <div class="dashboard-nav-mob__user-main">
                <img
                  :src="user.avatar"
                  alt="avatar"
                  class="dashboard-nav-mob__user-avatar"
                >
                <div class="dashboard-nav-mob__user-info">
                  <div class="dashboard-nav-mob__user-primary">
                    {{ user.primary }}
                  </div>
                  <div class="dashboard-nav-mob__user-secondary">
                    {{ user.name }}
                  </div>
                </div>
              </div>

              <div class="dashboard-nav-mob__user-balance">
                Total balance: {{ user.balance | moneyformat }}
                {{ user.currency }}
              </div>
            </div>
          </div>

          <div class="dashboard-nav-mob__footer-item">
            <div class="dashboard-nav-mob__log">
              Last login: Today, 16:35, Latvia, 159.148.65.49
            </div>
          </div>

          <div class="dashboard-nav-mob__footer-item">
            <ul class="dashboard-nav-mob__contacts">
              <li>
                <a
                  href="/"
                  class="dashboard-nav-mob__contact"
                >
                  Terms and Conditions
                </a>
              </li>

              <li>
                <a
                  href="tel:+442038079680"
                  class="dashboard-nav-mob__contact"
                >
                  +44 20380 79680
                </a>
              </li>

              <li>
                <a
                  href="mailto:info@tpf.com"
                  class="dashboard-nav-mob__contact"
                >
                  info@tpf.com
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <DashboardNavUserMob
      :is-open="userIsOpen"
      :close-handler="handleUserOpen"
    />
  </div>
</template>

<script>
import DashboardNavUserMob from "@/components/Dashboard/DashboardNavUserMob";

export default {
  name: "DashboardNavMob",

  components: {
    DashboardNavUserMob
  },

  data() {
    return {
      isOpen: false,

      userIsOpen: false,

      links: [
        {
          icon: "icon-dashboard",
          title: "Dashboard",
          url: "/dashboard"
        },
        {
          icon: "icon-statement",
          title: "Statement",
          url: "/statement"
        },
        {
          icon: "icon-exchange",
          title: "Exchange",
          url: "/exchange"
        },
        {
          icon: "icon-saving",
          title: "Savings",
          url: "/savings"
        },
        {
          icon: "icon-settings",
          title: "Settings",
          url: "/settings"
        }
      ]
    };
  },

  computed: {
    user() {
      return this.$store.state.currentUser;
    }
  },

  methods: {
    handleOpen() {
      this.isOpen = !this.isOpen;
    },

    handleUserOpen() {
      this.userIsOpen = !this.userIsOpen;
    }
  }
};
</script>

<style scoped lang="scss">
.dashboard-nav-mob {
  &__toggle {
    display: flex;
    justify-content: center;
    width: 32px;
    height: 32px;
    transition: color 0.1s linear;
  }

  &__toggle span {
    font-size: 0;
  }

  &__toggle .icon {
    font-size: 24px;
  }

  &__menu {
    position: fixed;
    top: 100%;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    background-color: $additional-brown;
    color: $white;
    transition: top 0.2s ease-in-out;
  }

  &.is-open &__menu {
    top: 0;
  }

  &__menu-inner {
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
  }

  &__top {
    flex: 1;
    background-color: rgba($additional-brown-light, 0.3);
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    height: 64px;
    flex: 0 0 64px;
  }

  &__logo {
    width: 40px;
    height: 30px;
  }

  &__close-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
  }

  &__close-btn span {
    font-size: 0;
  }

  &__close-btn .icon {
    font-size: 24px;
  }

  &__links {
    padding-bottom: 24px;
  }

  &__links-item {
    display: flex;
    align-items: center;
    padding: 16px;
    font-family: $font-semibold;
  }

  &__links-item.is-current {
    color: $accent-light;
  }

  &__links-item .icon {
    font-size: 24px;
    margin-right: 16px;
  }

  &__footer {
    margin-top: auto;
  }

  &__footer-item + &__footer-item {
    border-top: 1px solid rgba($white, 0.2);
  }

  &__user {
    position: relative;
    padding: 16px 36px 16px 16px;
  }

  &__user:after {
    content: "\e92a";
    @include inline-icon;
    font-size: 18px;
    position: absolute;
    top: 36px;
    right: 16px;
  }

  &__user-main {
    display: flex;
    align-items: center;
  }

  &__user-avatar {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 12px;
  }

  &__user-primary {
    font-size: 16px;
    line-height: 20px;
  }

  &__user-secondary {
    font-size: 12px;
    line-height: 16px;
    margin-top: 2px;
  }

  &__user-balance {
    margin-top: 12px;
    font-size: 14px;
    line-height: 18px;
  }

  &__log {
    padding: 16px;
    font-size: 14px;
    line-height: 18px;
    color: rgba($white, 0.5);
  }

  &__contacts {
    padding: 18px 16px;
  }

  &__contacts li {
    padding: 6px 0;
  }

  &__contact {
    font-size: 14px;
    line-height: 18px;
    color: rgba($white, 0.5);
  }
}
</style>
