<template>
  <OnboardingBusinessLayout
    :current-step="currentStep"
    :side-msg="sideMsg"
  >
    <Header>
      <template slot="title">
        Create password
      </template>
    </Header>

    <div class="mt-40">
      <FormInput
        id="email"
        name="email"
        type="email"
        :value="form.email"
        label="Email"
        :has-error="form.emailError"
        @input="form.email = $event"
      />
    </div>

    <div class="mt-lg-40 mt-sm-32">
      <FormInput
        id="pass"
        name="pass"
        type="password"
        :value="form.pass"
        label="Create password"
        :has-error="form.passError"
        @input="form.pass = $event"
      />
    </div>

    <div class="mt-lg-40 mt-sm-32">
      <FormInput
        id="repass"
        name="repass"
        type="password"
        :value="form.rePass"
        label="Confirm password"
        :has-error="form.rePassError"
        @input="form.rePass = $event"
      />
    </div>

    <div class="mt-lg-32 mt-sm-24">
      <Button
        label="Continue"
        btn-style="primary"
        btn-width="100%"
      />
    </div>
  </OnboardingBusinessLayout>
</template>

<script>
import OnboardingBusinessLayout from "@/components/Layout/OnboardingBusinessLayout";
import Header from "@/components/Onboarding/Header";
import FormInput from "@/components/Form/FormInput";
import Button from "@/components/Button";

export default {
  components: {
    OnboardingBusinessLayout,
    Header,
    FormInput,
    Button
  },

  data() {
    return {
      currentStep: 3,

      sideMsg:
        "Password has to be at least 6 characters long. Must contain at least one lower case letter, one digit and one of these special characters",

      form: {
        email: "",
        emailError: "",
        pass: "",
        passError: "",
        rePass: "",
        rePassError: ""
      },
    };
  }
};
</script>
