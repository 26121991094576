<template>
  <ModalWrapper
    size="md"
    fix-height
  >
    <template slot="title">
      Add money
    </template>

    <template slot="toolbox">
      <ButtonCircle
        icon="icon-print"
        btn-style="light"
        title="Print"
      />

      <ButtonCircle
        icon="icon-save"
        btn-style="light"
        title="Save"
      />

      <ButtonCircle
        icon="icon-accounts"
        btn-style="light"
        title="Copy"
      />
    </template>

    <div class="addmoney-modal__body">
      <div class="addmoney-modal__section">
        <div class="addmoney-modal-dropdown">
          <Dropdown mob-overlay>
            <template slot="toggle">
              <span class="addmoney-modal-dropdown__toggle">
                {{ dropdownTitle }}
              </span>
            </template>

            <template slot="menu">
              <div class="addmoney-modal-dropdown__list">
                <div class="addmoney-modal-dropdown__item">
                  <Toggle
                    :value="selectAll"
                    @change="selectAll = $event"
                  />
                  <span class="ml-8 text-grey">Select all</span>
                </div>

                <div
                  v-for="(item, j) in accountDetails"
                  :key="j"
                  class="addmoney-modal-dropdown__item"
                >
                  <FormCheckbox
                    :value="selected.includes(item.id)"
                    @change="
                      $event => {
                        handleSelect(item.id, $event);
                      }
                    "
                  >
                    {{ item.title }}
                  </FormCheckbox>

                  <img
                    v-if="item.flag"
                    width="40"
                    height="28"
                    :src="require(`@/assets/images/flags/${item.flag}.svg`)"
                    :alt="item.title"
                    class="addmoney-modal-dropdown__flag"
                  >
                </div>
              </div>
            </template>
          </Dropdown>
        </div>

        <div
          v-if="selected"
          class="addmoney-modal__tag-wrapper"
        >
          <ul class="addmoney-modal__tag-list">
            <li
              v-for="(item, t) in selectedAccounts"
              :key="t"
              class="addmoney-modal__tag-item"
            >
              <Tag
                el-style="white"
                :label="item.title"
                :handler="
                  e => {
                    handleSelect(item.id, false, e);
                  }
                "
              />
            </li>
          </ul>
        </div>
      </div>

      <div class="addmoney-modal__list">
        <div
          v-for="(item, i) in selectedAccounts"
          :key="i"
          class="addmoney-modal__item"
        >
          <ButtonCircle
            icon="icon-accounts"
            btn-style="light"
            title="Copy"
            class="addmoney-modal__item-action"
          />

          <div class="addmoney-modal__item-header">
            <img
              v-if="item.flag"
              width="30"
              height="20"
              :src="require(`@/assets/images/flags/${item.flag}.svg`)"
              :alt="item.title"
              class="addmoney-modal__item-flag"
            >

            {{ item.title }}
          </div>

          <div class="addmoney-modal__item-details">
            {{ item.details }}
          </div>

          <div
            v-if="item.comment"
            class="addmoney-modal__item-comment"
          >
            {{ item.comment }}
          </div>
        </div>
      </div>
    </div>
  </ModalWrapper>
</template>

<script>
import { mapState } from "vuex";

import ModalWrapper from "@/components/Modal/ModalWrapper";
import ButtonCircle from "@/components/ButtonCircle";
import Dropdown from "@/components/Dropdown";
import Toggle from "@/components/Toggle";
import FormCheckbox from "@/components/Form/FormCheckbox";
import Tag from "@/components/Tag";

export default {
  name: "AddMoneyModal",

  components: {
    ModalWrapper,
    ButtonCircle,
    Dropdown,
    Toggle,
    FormCheckbox,
    Tag
  },

  data() {
    return {
      selectAll: true,

      selected: []
    };
  },

  computed: {
    ...mapState(["modal"]),

    accountDetails() {
      return this.modal.data.details || [];
    },

    selectedAccounts() {
      const accounts = this.accountDetails.filter(item =>
        this.selected.includes(item.id)
      );

      return accounts;
    },

    dropdownTitle() {
      let title;

      switch (this.selected.length) {
        case 0:
          title = "None selected";
          break;
        case this.accountDetails.length:
          title = "All currencies";
          break;
        default:
          title = "Different currencies";
      }

      return title;
    }
  },

  watch: {
    selectAll(val) {
      this.handleSelectAll(val);
    }
  },

  mounted() {
    this.accountDetails.map(item => {
      if (!this.selected.includes(item.id)) {
        this.selected.push(item.id);
      }
    });
  },

  methods: {
    handleSelectAll(e) {
      if (e === true) {
        this.accountDetails.map(item => {
          if (!this.selected.includes(item.id)) {
            this.selected.push(item.id);
          }
        });
      }
    },

    handleSelect(id, val, e) {
      if (e) e.stopPropagation();

      if (val === true) {
        this.selected.push(id);
      } else {
        this.selected.splice(this.selected.indexOf(id), 1);
        this.selectAll = false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.addmoney-modal {
  &__body {
    margin: 0 -16px;
  }

  &__section {
    padding: 16px;
  }

  &__list {
    min-height: 280px;
  }

  &__item {
    position: relative;
    padding: 16px;
    border-top: 1px solid $grey-light-01;
  }

  &__item-header {
    display: flex;
    align-items: center;
  }

  &__item-action {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  &__item-flag {
    margin-right: 8px;
  }

  &__item-details {
    margin-top: 24px;
    font-size: 16px;
    line-height: 1.25;
    color: $grey-dark-01;
    white-space: pre-line;
  }

  &__item-comment {
    display: inline-block;
    margin-top: 24px;
    background-color: $grey-light-02;
    border-radius: 6px;
    padding: 6px 16px;
    line-height: 1.25;
  }

  &__tag-wrapper {
    margin: 8px -24px -16px -24px;
    padding-bottom: 8px;
  }

  &__tag-list {
    display: inline-flex;
    flex-wrap: wrap;
    padding: 0 16px;
  }

  &__tag-item {
    padding: 8px;
    white-space: nowrap;
  }

  @media screen and (max-width: $screen-sm-max) {
    &__item /deep/ .btn-circle__icon {
      width: 36px;
      height: 36px;
      font-size: 16px;
    }

    &__tag-wrapper {
      overflow: auto;
      margin: 8px -16px -16px -16px;
      padding-bottom: 8px;
    }

    &__tag-list {
      flex-wrap: nowrap;
      padding: 0 8px;
    }
  }

  @media screen and (min-width: $screen-md-min) {
    &__body {
      margin: 0 -24px;
    }

    &__section,
    &__item {
      padding: 24px;
    }

    &__item-action {
      top: 24px;
      right: 24px;
    }
  }
}

.addmoney-modal-dropdown {
  & .dropdown {
    width: 100%;
  }

  &__toggle {
    position: relative;
    display: inline-block;
    width: 100%;
    background-color: $grey-light-02;
    border-radius: 8px;
    padding: 14px 16px;
    font-size: 16px;
    line-height: 20px;
  }

  &__toggle:before {
    content: "\e911";
    @include inline-icon;
    line-height: 16px;
    position: absolute;
    right: 8px;
    top: 16px;
    color: $grey-dark-01;
  }

  .dropdown.is-open &__toggle:before {
    content: "\e912";
  }

  &__item {
    display: flex;
    align-items: center;
    min-height: 50px;
    padding: 10px 12px;
  }

  &__item + &__item {
    border-top: 1px solid $grey-light-01;
  }

  &__flag {
    margin-left: auto;
  }

  @media (hover: hover) {
    &__toggle:hover {
      background-color: $grey-light-02;
    }
  }
}
</style>
