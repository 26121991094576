<template>
  <div class="form-item-group">
    <span
      v-if="label"
      class="form-item-group__label"
    >{{ label }}</span>
    <div class="arrange">
      <slot />
    </div>
    <span
      v-if="comment"
      class="form-item-group__comment"
    >{{ comment }}</span>
  </div>
</template>

<script>
export default {
  name: "FormItemGroup",

  props: {
    label: { type: String, required: false, default: "" },
    comment: { type: String, required: false, default: "" }
  }
};
</script>

<style scoped lang="scss">
.form-item-group {
  position: relative;

  &__label,
  &__comment {
    position: absolute;
    font-size: 14px;
    line-height: 18px;
    color: $grey-dark-01;
    margin: 4px 0;
  }

  &__label {
    left: 0;
    bottom: 100%;
  }

  &__comment {
    right: 0;
    top: 100%;
  }
}
</style>
