<template>
  <OnboardingBusinessLayout
    :current-step="currentStep"
    :side-msg="sideMsg"
  >
    <Header>
      <template slot="title">
        <div class="text-center">
          Verification
        </div>
      </template>
    </Header>

    <div class="mt-lg-32 mt-sm-16 arrange arrange--center">
      <img
        :src="require('@/assets/images/verification.svg')"
        alt="illustration"
      >
    </div>

    <div class="mt-lg-32 mt-sm-24">
      <h5 class="text-center text-sb">
        You are 30 seconds away from finishing the application
      </h5>

      <p class="text-center text-grey mt-4">
        Please prepare your passport or ID card for identification. Make sure
        you are located in a well lighted place.
      </p>
    </div>

    <div class="arrange mt-lg-32 mt-sm-24">
      <Button
        icon="icon-back-mobile"
        label="Return"
        btn-style="transparent"
        btn-width="92px"
      />

      <Button
        label="Continue"
        btn-style="primary"
        class="w-100 ml-24"
      />
    </div>
  </OnboardingBusinessLayout>
</template>

<script>
import OnboardingBusinessLayout from "@/components/Layout/OnboardingBusinessLayout";
import Header from "@/components/Onboarding/Header";
import Button from "@/components/Button";

export default {
  components: {
    OnboardingBusinessLayout,
    Header,
    Button
  },

  data() {
    return {
      currentStep: 10,

      sideMsg:
        "Some short tooltip. Why do we need to know about this info about user"
    };
  }
};
</script>
