export const freezeBody = doFreeze => {
  const body = document.body;
  const scrollbarWidth = getScrollBarWidth();

  if (doFreeze) {
    body.classList.toggle("_freeze", true);
    body.style.paddingRight = scrollbarWidth + "px";
  } else {
    setTimeout(() => {
      body.classList.toggle("_freeze", false);
      body.removeAttribute("style");
    }, 150);
  }
};

export const getScrollBarWidth = () => {
  const outer = document.createElement("div");

  outer.style.visibility = "hidden";
  outer.style.overflow = "scroll";
  outer.style.msOverflowStyle = "scrollbar";

  document.body.appendChild(outer);

  const inner = document.createElement("div");

  outer.appendChild(inner);

  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  outer.parentNode.removeChild(outer);

  return scrollbarWidth;
};
