<template>
  <div
    ref="selectRef"
    class="lang-select"
    :class="{ 'is-open': isOpen }"
  >
    <button
      class="lang-select__toggle"
      @click="handleDrop"
    >
      {{ selected }}
    </button>

    <div class="lang-select__dropdown-container">
      <ul>
        <li
          v-for="(option, i) in options"
          :key="i"
        >
          <button
            :class="{
              'is-selected': option === selected
            }"
            @click="handleSelect(option)"
          >
            {{ option }}
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "LangSelect",

  props: {
    selected: { type: String, required: false, default: "" },
    options: { type: Array, required: false, default: null }
  },

  data() {
    return {
      isOpen: false
    };
  },

  destroyed() {
    document.removeEventListener("click", this.handleCloseAll);
  },

  methods: {
    handleDrop() {
      this.isOpen = !this.isOpen;

      if (this.isOpen) {
        document.addEventListener("click", this.handleCloseAll);
      } else {
        document.removeEventListener("click", this.handleCloseAll);
      }
    },

    handleCloseAll(e) {
      const el = this.$refs.selectRef;
      const target = e.target;
      if (el !== target && !el.contains(target)) {
        this.isOpen = false;
        document.removeEventListener("click", this.handleCloseAll);
      }
    },

    handleSelect(val) {
      this.isOpen = false;

      this.$emit("change", val);
    }
  }
};
</script>

<style scoped lang="scss">
.lang-select {
  position: relative;
  display: inline-block;
  margin-left: auto;

  &__toggle {
    font-family: $font-regular;
    font-size: 16px;
    line-height: 20px;
    color: $additional-brown;
    text-transform: capitalize;
    border: 1px solid transparent;
    transition: color 0.1s linear;
  }

  &__toggle:after {
    content: "\e911";
    @include inline-icon;
    line-height: 16px;
    vertical-align: baseline;
  }

  &.is-open &__toggle:after {
    content: "\e912";
  }

  .color-change &__toggle {
    color: $white;
  }

  &__dropdown-container {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 99;
    min-width: 100%;
    color: $grey-dark;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-4px);
    transition: opacity 0.1s linear, transform 0.1s linear,
      visibility 0s linear 0.2s;
  }

  &__dropdown-container li + li {
    border-top: 1px solid $grey-light-01;
  }

  &.is-open &__dropdown-container {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: opacity 0.1s linear, transform 0.1s linear,
      visibility 0s linear 0s;
  }

  &__dropdown-container ul {
    background-color: $white;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08),
      0px 8px 12px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    margin: 4px 0;
    max-height: 250px;
    overflow: auto;
  }

  &__dropdown-container ul button {
    position: relative;
    display: block;
    width: 100%;
    padding: 16px 36px 16px 12px;
    text-align: left;
    white-space: nowrap;
    text-transform: capitalize;
    transition: background-color 0.1s linear;
  }

  &__dropdown-container button.is-selected:after {
    content: "\e919";
    @include inline-icon;
    position: absolute;
    right: 12px;
    top: 16px;
    color: $accent-normal;
  }

  @media (hover: hover) {
    &__toggle:hover {
      color: $accent-light;
    }

    &__dropdown-container ul button:hover {
      background-color: $grey-light-02;
    }
  }

  @media screen and (min-width: $screen-lg-min) {
    .color-change &__toggle {
      color: $additional-brown;
    }
  }
}
</style>
