<template>
  <div
    class="form-radio-box"
    :class="{ 'is-disabled': disabled, 'has-focus': hasFocus }"
    :style="{ width: elWidth }"
  >
    <label class="form-radio-box__wrapper">
      <input
        :id="id"
        v-model="model"
        :name="name"
        type="radio"
        :value="label"
        :disabled="disabled"
        @focus="hasFocus = true"
        @focusout="hasFocus = false"
      >
      <span class="form-radio-box__check" />

      <span class="form-radio-box__label">
        <span
          v-if="hasTitle"
          class="form-radio-box__title"
        >
          <slot name="title" />
        </span>

        <span
          v-if="hasComment"
          class="form-radio-box__comment"
        >
          <slot name="comment" />
        </span>
      </span>

      <span
        v-if="hasImg"
        class="form-radio-box__img"
      >
        <slot name="img" />
      </span>
    </label>
  </div>
</template>

<script>
export default {
  name: "FormRadioBox",

  props: {
    id: { type: String, required: false, default: "" },
    name: { type: String, required: false, default: "" },
    value: { type: [String, Number], required: false, default: null },
    label: { type: [String, Boolean], required: false, default: "" },
    elWidth: { type: String, required: false, default: "" },
    disabled: { type: Boolean, required: false, default: false }
  },

  data() {
    return {
      hasFocus: false
    };
  },

  computed: {
    hasTitle() {
      return !!this.$slots.title;
    },

    hasComment() {
      return !!this.$slots.comment;
    },

    hasImg() {
      return !!this.$slots.img;
    },

    model: {
      get() {
        return this.value;
      },
      set() {
        this.$emit("change", this.label);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.form-radio-box {
  &__wrapper {
    display: inline-flex;
    align-items: center;
    width: 100%;
    padding: 12px 16px;
    border-radius: 8px;
    box-shadow: inset 0 0 0 1px $grey-light-01;
    transition: box-shadow 0.1s linear;
    cursor: pointer;
  }

  &.is-disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &__wrapper input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }

  &__check {
    position: relative;
    display: block;
    width: 18px;
    min-width: 18px;
    height: 18px;
    margin-right: 10px;
    box-shadow: inset 0 0 0 1.5px $grey-dark-02;
    border-radius: 50%;
    transition: box-shadow 0.1s linear;
  }

  &__check:before {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -4px 0 0 -4px;
    display: block;
    width: 8px;
    height: 8px;
    content: "";
    border-radius: 50%;
    background-color: transparent;
    transition: background-color 0.15s linear;
  }

  &__wrapper input:checked ~ &__check {
    box-shadow: inset 0 0 0 1.5px $accent-normal;
  }

  &__wrapper input:checked ~ &__check:before {
    background-color: $accent-normal;
  }

  &__title {
    display: block;
    font-size: 16px;
    line-height: 20px;
    color: $additional-brown;
  }

  &__comment {
    display: block;
    font-size: 12px;
    line-height: 16px;
    margin-top: 4px;
    color: $grey-dark-01;
  }

  &__img {
    display: block;
    width: 48px;
    padding-left: 8px;
    margin-left: auto;
  }

  &__img img {
    display: block;
  }

  @media (hover: hover) {
    &__wrapper:hover {
      box-shadow: inset 0 0 0 1px $accent-normal;
    }

    &.has-focus &__wrapper {
      box-shadow: inset 0 0 0 2px $additional-brown;
    }
  }
}
</style>
