<template>
  <div class="onboarding-layout">
    <aside class="onboarding-layout-aside">
      <div class="onboarding-layout-aside__inner">
        <div class="onboarding-layout-progress">
          <span :style="{ width: progress }" />
        </div>

        <ul class="onboarding-layout-aside__steps">
          <li
            v-for="(step, i) in steps"
            :key="i"
            class="onboarding-layout-aside__steps-item"
            :class="{
              'is-current': i + 1 === currentStep,
              'is-complete': i + 1 < currentStep
            }"
          >
            <span>{{ step }}</span>
          </li>
        </ul>

        <div class="onboarding-layout-aside__footer">
          {{ sideMsg }}
        </div>
      </div>
    </aside>

    <div class="onboarding-layout__content">
      <header class="onboarding-layout-header">
        <img
          :src="require('@/assets/images/logo.svg')"
          alt="logo"
          class="onboarding-layout-header__logo"
        >

        <div class="onboarding-layout-progress">
          <span :style="{ width: progress }" />
        </div>

        <LangSelect
          :selected="lang"
          :options="['eng', 'est']"
          @change="lang = $event"
        />
      </header>

      <main class="onboarding-layout__main">
        <Button
          v-if="currentStep === 1"
          class="onboarding-layout__main-btn"
          label="Continue my application"
          btn-style="dark-opacity"
        />

        <div class="onboarding-layout__wrapper">
          <div class="onboarding-layout__wrapper-header">
            <img
              :src="require('@/assets/images/logo.svg')"
              alt="logo"
              class="onboarding-layout__wrapper-logo"
            >
          </div>

          <slot />
        </div>
      </main>

      <footer class="onboarding-layout-footer">
        <ul class="onboarding-layout-footer__links">
          <li>
            <a
              href="/"
              class="onboarding-layout-footer__link"
            >
              Terms and Conditions
            </a>
          </li>

          <li>
            <a
              href="tel:+442038079680"
              class="onboarding-layout-footer__link"
            >
              +44 20380 79680
            </a>
          </li>

          <li>
            <a
              href="mailto:info@tpf.com"
              class="onboarding-layout-footer__link"
            >
              info@tpf.com
            </a>
          </li>
        </ul>
      </footer>
    </div>
  </div>
</template>

<script>
import LangSelect from "@/components/Auth/LangSelect";
import Button from "@/components/Button";

export default {
  name: "OnboardingLayout",

  bodyClass: "_tpl-onboarding",

  components: {
    LangSelect,
    Button
  },

  props: {
    currentStep: { type: Number, required: false, default: null },
    sideMsg: { type: String, required: false, default: "" }
  },

  data() {
    return {
      lang: "eng",

      steps: [
        "Signup",
        "Create password",
        "Personal info",
        "Attach utility bill",
        "Personal profile",
        "Verification",
        "Finish registration"
      ]
    };
  },

  computed: {
    progress() {
      if (!this.currentStep || !this.steps) return "0";

      const percent = (100 / this.steps.length) * this.currentStep;

      return `${percent}%`;
    }
  }
};
</script>

<style scoped lang="scss">
.onboarding-layout-aside {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 428px;
  background-color: $additional-brown;

  &__inner {
    display: flex;
    flex-direction: column;
    width: 268px;
    height: 100%;
    margin: 0 auto 0 auto;
    padding: 48px 0 32px 0;
  }

  &__steps {
    counter-reset: steps;
    width: 100%;
    margin: auto;
  }

  &__steps-item {
    position: relative;
    padding-left: 46px;
    color: rgba($white, 0.4);
    font-family: $font-semibold;
    font-size: 16px;
  }

  &__steps-item:before {
    counter-increment: steps;
    content: counter(steps);
    position: absolute;
    left: 0;
    top: -3px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: rgba($white, 0.1);
    color: rgba($white, 0.5);
    text-align: center;
    line-height: 30px;
  }

  &__steps-item.is-current {
    color: $white;
  }

  &__steps-item.is-current:before {
    background-color: $white;
    color: $additional-brown;
  }

  &__steps-item.is-complete:before {
    content: "\e919";
    @include inline-icon;
    background-color: $additional-normal;
    color: $additional-brown;
    font-size: 20px;
    line-height: 30px;
  }

  &__steps-item + &__steps-item {
    margin-top: 38px;
  }

  &__footer {
    font-size: 12px;
    line-height: 16px;
    color: rgba($white, 0.5);
  }

  @media screen and (max-width: $screen-md-max) {
    display: none;
  }
}

.onboarding-layout {
  background-color: $additional-brown;

  &__content {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  &__main {
    max-width: 100%;
    margin: auto;
    padding: 0 16px;
  }

  &__main-btn {
    width: 100%;
    margin-bottom: 8px;
  }

  &__wrapper {
    max-width: 100%;
    width: 514px;
    background-color: $white;
    border-radius: 8px;
    padding: 20px 24px 24px 24px;
  }

  &__wrapper-header {
    display: flex;
    justify-content: center;
  }

  &__wrapper-logo {
    width: 41px;
    height: 32px;
    margin-bottom: 16px;
  }

  @media screen and (min-width: $screen-lg-min) {
    padding-left: 428px;
    background-color: $grey-light-02;

    &__main {
      margin: auto;
      padding: 0;
    }

    &__main-btn {
      width: 236px;
      margin-bottom: 16px;
    }

    &__wrapper {
      padding: 32px;
    }

    &__wrapper-header {
      display: none;
    }
  }
}

.onboarding-layout-header {
  position: relative;
  display: flex;
  align-items: center;
  padding: 20px 16px;

  @media screen and (max-width: $screen-md-max) {
    &__logo {
      display: none;
    }
  }

  @media screen and (min-width: $screen-lg-min) {
    padding: 32px;
  }
}

.onboarding-layout-footer {
  margin-top: auto;
  padding: 32px 16px;

  &__links li {
    padding: 0 16px;
  }

  &__link {
    font-size: 14px;
    line-height: 18px;
    color: $white;
    transition: color 0.1s linear;
  }

  @media (hover: hover) {
    &__link:hover {
      color: $accent-light;
    }
  }

  @media screen and (max-width: $screen-md-max) {
    &__links li {
      text-align: center;
    }

    &__links li + li {
      margin-top: 12px;
    }
  }

  @media screen and (min-width: $screen-lg-min) {
    &__links {
      display: flex;
    }

    &__links li + li {
      border-left: 1px solid $grey-dark-02;
    }

    &__link {
      color: $grey-dark-01;
    }
  }
}

.onboarding-layout-progress {
  position: relative;
  width: 100%;
  background: rgba($white, 0.1);
  border-radius: 10px;
  height: 4px;

  .onboarding-layout-header & {
    position: absolute;
    left: 50%;
    right: 50%;
    transform: translateX(-50%);
    width: 164px;
  }

  & span {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background: $additional-normal;
    border-radius: 10px;
  }

  @media screen and (min-width: $screen-lg-min) {
    .onboarding-layout-header & {
      display: none;
    }
  }
}
</style>

<style lang="scss">
._tpl-onboarding {
  @media screen and (max-width: $screen-md-max) {
    background-color: $additional-brown;
  }
}
</style>
