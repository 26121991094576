<template>
  <header class="dashboard-header">
    <img
      :src="require('@/assets/images/logo.svg')"
      alt="logo"
      class="dashboard-header__logo"
    >

    <div class="dashboard-header__toolbox">
      <div class="dashboard-header__toolbox-item dashboard-header__balance">
        <span class="text-grey">Total:</span> {{ user.balance | moneyformat }}
        {{ user.currency }}
      </div>

      <router-link
        :to="{ name: 'messages' }"
        :data-count="user.notifications"
        class="dashboard-header__toolbox-item dashboard-header__notifications has-notifications"
      >
        <i class="icon icon-mail" />
        <span>Notifications</span>
      </router-link>

      <div class="dashboard-header__toolbox-item dashboard-header__user">
        <Dropdown is-right>
          <template slot="toggle">
            <div class="dashboard-header-user">
              <img
                :src="user.avatar"
                alt="avatar"
                class="dashboard-header-user__avatar"
              >

              <div class="dashboard-header-user__info">
                <div class="dashboard-header-user__info-primary">
                  {{ user.primary }}
                </div>
                <div
                  v-if="user.name"
                  class="dashboard-header-user__info-secondary"
                >
                  {{ user.name }}
                </div>
              </div>
            </div>
          </template>

          <template slot="menu">
            <ul class="dashboard-header-user__dropdown-list">
              <li
                v-for="(item, i) in accounts"
                :key="i"
              >
                <button
                  class="dashboard-header-user__dropdown-list-item"
                  :class="{ 'is-selected': item.id === user.id }"
                  @click="handleSelect(item.id)"
                >
                  <i
                    class="icon"
                    :class="{
                      'icon-case': item.type === 'company',
                      'icon-profile': item.type === 'private'
                    }"
                  />
                  <span>{{ item.primary || item.name }}</span>
                </button>
              </li>

              <li>
                <button
                  class="dashboard-header-user__dropdown-list-item logout"
                >
                  <span>Log out</span><i class="icon icon-logout" />
                </button>
              </li>
            </ul>
          </template>
        </Dropdown>
      </div>
    </div>
  </header>
</template>

<script>
import { mapActions } from "vuex";

import Dropdown from "@/components/Dropdown";

export default {
  name: "DashboardHeader",

  components: {
    Dropdown
  },

  computed: {
    user() {
      return this.$store.state.currentUser;
    },

    accounts() {
      return this.$store.state.availableUsers;
    }
  },

  methods: {
    ...mapActions(["setUser"]),

    handleSelect(id) {
      const selected = this.accounts.find(item => {
        return item.id === id;
      });

      this.setUser(selected);
    }
  }
};
</script>

<style scoped lang="scss">
.dashboard-header {
  display: flex;
  align-items: center;
  padding: 32px;
  width: 100%;
  max-width: 1456px;
  margin: 0 auto;

  &__logo {
    display: block;
    width: 54px;
    height: 40px;
  }

  &__toolbox {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  &__toolbox-item + &__toolbox-item {
    margin-left: 24px;
  }

  &__notifications {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    color: $additional-brown;
    transition: color 0.1s linear;
  }

  &__notifications:hover {
    color: $accent-light;
  }

  &__notifications span {
    font-size: 0;
  }

  &__notifications .icon {
    font-size: 22px;
  }

  &__notifications.has-notifications:before {
    content: attr(data-count);
    position: absolute;
    top: -4px;
    right: -4px;
    min-width: 14px;
    height: 18px;
    line-height: 18px;
    font-size: 12px;
    font-family: $font-semibold;
    border-radius: 13px;
    padding: 0 2px;
    background-color: $accent-normal;
    color: $white;
    border: 2px solid $grey-light-02;
    text-align: center;
  }

  @media screen and (max-width: $screen-sm-max) {
    display: none;
  }
}

.dashboard-header-user {
  display: flex;
  align-items: center;

  &:after {
    content: "\e911";
    @include inline-icon;
    color: $additional-brown;
    margin-left: 6px;
  }

  .is-open &:after {
    content: "\e912";
  }

  &__avatar {
    display: block;
    width: 40px;
    height: 40px;
    margin-right: 12px;
    border-radius: 50%;
  }

  &__info {
    color: $additional-brown;
  }

  &__info-primary {
    font-size: 16px;
    line-height: 20px;
  }

  &__info-secondary {
    font-size: 12px;
    line-height: 16px;
    margin-top: 2px;
  }

  &__dropdown-list {
    min-width: 226px;
  }

  &__dropdown-list li + li {
    border-top: 1px solid $grey-light-01;
  }

  &__dropdown-list-item {
    position: relative;
    display: flex;
    align-items: center;
    padding: 16px 32px 16px 16px;
    white-space: nowrap;
    font-size: 14px;
    width: 100%;
    transition: background-color 0.1s linear;
  }

  &__dropdown-list-item .icon + span {
    margin-left: 8px;
  }

  &__dropdown-list-item.logout {
    color: $red;
  }

  &__dropdown-list-item.logout .icon {
    margin-left: auto;
    font-size: 20px;
  }

  &__dropdown-list-item.is-selected:after {
    content: "\e919";
    @include inline-icon;
    position: absolute;
    right: 16px;
    top: 16px;
    font-size: 16px;
    color: $accent-normal;
  }

  @media (hover: hover) {
    &__dropdown-list-item:hover {
      background-color: $grey-light-02;
    }
  }
}
</style>
