var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-selector"},[_c('h2',{staticClass:"card-selector__title h2-bold"},[_vm._v(" Cards ")]),_c('div',{staticClass:"card-selector__inner"},[_c('div',{staticClass:"card-selector__card-box"},[_c('div',{staticClass:"card-selector-carousel"},[_c('hooper',{ref:"hooper",attrs:{"settings":_vm.hooperSettings},on:{"slide":_vm.handleSlide}},[_vm._l((_vm.cards),function(card,i){return _c('slide',{key:i},[_c('div',{staticClass:"card-selector-carousel__slide"},[_c('PaymentCard',{attrs:{"card-style":card.style,"issuer":card.issuer,"type-label":card.name,"card-number":card.number,"primary-label":card.balance,"secondary-label":card.onHold,"valid":card.valid,"holder":card.holder}})],1)])}),_c('hooper-pagination',{attrs:{"slot":"hooper-addons"},slot:"hooper-addons"})],2)],1),(_vm.cards.length > 1)?_c('div',{staticClass:"card-selector-carousel__nav"},[(_vm.cards.length > 4)?_c('ButtonCircle',{attrs:{"icon":"icon-arrow-left","btn-style":"dark","btn-size":"sm","click-handler":_vm.handlePrevPage,"disabled":_vm.currentPage === 1}}):_vm._e(),_c('div',{staticClass:"card-selector-carousel__nav-list"},_vm._l((Math.ceil(_vm.cards.length / 4)),function(p){return _c('div',{key:p,staticClass:"card-selector-carousel__nav-page",class:{ 'is-selected': p === _vm.currentPage }},_vm._l((_vm.cards.slice((p - 1) * 4, p * 4)),function(item,j){return _c('div',{key:j,staticClass:"card-selector-carousel__nav-item-wrapper"},[_c('button',{staticClass:"card-selector-carousel__nav-item",class:[
                  ("card-selector-carousel__nav-item--" + (item.style)),
                  {
                    'is-selected': _vm.cards.indexOf(item) === _vm.currentCard
                  }
                ],on:{"click":function($event){_vm.handleSelect(_vm.cards.indexOf(item))}}},[_c('svg',[_c('use',{attrs:{"xlink:href":require('@/assets/images/svg-sprites.svg') +
                        '#' +
                        item.issuer}})]),_c('span',{staticClass:"card-selector-carousel__nav-item-label"},[_vm._v(" "+_vm._s(item.name)+" ")])])])}),0)}),0),(_vm.cards.length > 4)?_c('ButtonCircle',{attrs:{"icon":"icon-right","btn-style":"dark","btn-size":"sm","click-handler":_vm.handleNextPage,"disabled":_vm.currentPage === Math.ceil(_vm.cards.length / 4)}}):_vm._e()],1):_vm._e()]),_c('div',{staticClass:"card-selector__action-box"},[_c('ul',{staticClass:"card-selector__action-list"},[_c('li',[_c('ButtonCircle',{attrs:{"icon":"icon-add","label":"Top-up","btn-style":"dark"}})],1),_c('li',[_c('ButtonCircle',{attrs:{"icon":"icon-statement","label":"Statement","btn-style":"dark"}})],1),_c('li',[_c('ButtonCircle',{attrs:{"icon":"icon-lock","label":"Block","btn-style":"dark"}})],1),_c('li',[_c('ButtonCircle',{attrs:{"icon":"icon-pin","label":"View PIN","btn-style":"dark"}})],1),_c('li',[_c('ButtonCircle',{attrs:{"icon":"icon-more","label":"More","btn-style":"dark"}})],1)]),_c('div',{staticClass:"card-selector__action-footer"},[_c('Button',{attrs:{"label":"Add card","btn-style":"dark","btn-width":"100%","click-handler":_vm.handleNewCardPopup}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }