<template>
  <div class="onboarding-personal-info">
    <slot />
  </div>
</template>

<script>
export default {
  name: "PersonalInfo"
};
</script>

<style scoped lang="scss">
.onboarding-personal-info {
  @media screen and (max-width: $screen-sm-max) {
    width: 100%;
    margin-top: 12px;
  }
}
</style>
