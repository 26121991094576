export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";

export const SET_USER = "SET_USER";
export const SET_AVAILABLE_USERS = "SET_AVAILABLE_USERS";

export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const HIDE_NOTIFICATION = "HIDE_NOTIFICATION";

export const ADD_PUSH_NOTIFICATION = "ADD_PUSH_NOTIFICATION";
export const REMOVE_PUSH_NOTIFICATION = "REMOVE_PUSH_NOTIFICATION";
